import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const UploadIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M19,22H5c-1.7,0-3-1.3-3-3v-4c0-0.6,0.4-1,1-1s1,0.4,1,1v4c0,0.6,0.4,1,1,1h14c0.6,0,1-0.4,1-1v-4
		c0-0.6,0.4-1,1-1s1,0.4,1,1v4C22,20.7,20.7,22,19,22z"/>
            <path d="M12,2c0.3,0,0.5,0.1,0.7,0.3l5,5c0.4,0.4,0.4,1,0,1.4s-1,0.4-1.4,0L12,4.4L7.7,8.7c-0.4,0.4-1,0.4-1.4,0
s-0.4-1,0-1.4l5-5C11.5,2.1,11.7,2,12,2z"/>
            <path d="M12,2c0.6,0,1,0.4,1,1v12c0,0.6-0.4,1-1,1s-1-0.4-1-1V3C11,2.4,11.4,2,12,2z"/>
        </Icon>
    );

export default withTheme(UploadIcon);
