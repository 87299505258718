import buildRequest from "./actions";
import {HTTP_METHODS} from "../constants";

export const getConnectedApplicationCredentials = async ({issuer}) => {
    return await buildRequest({
        method: HTTP_METHODS.GET,
        url: `/auth/okta/credentials?type=spa&issuer=${issuer}`,
        isAuth: false
    });
};
