import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../constants/styles';
import { H6 } from '../Typography';

const ContainerPanel = (props) => (
  <Container {...props}>
    <Title data-testid={props.dataTestId}>{props.title}</Title>
    <Body>{props.children}</Body>
  </Container>
);

export default ContainerPanel;

ContainerPanel.propTypes = {
  title: PropTypes.string.isRequired,
  dataTestId: PropTypes.string
};

ContainerPanel.defaultProps = {
  dataTestId: ''
}

const Container = styled.div`
  border-radius: 20px;
  border: 1px solid ${styles.borders.color};
  padding: 24px 32px 32px;
  margin-top: 0;
  margin-bottom: 30px;
  width: 100%;
`;
const Title = styled(H6)`
  margin-bottom: 24px;
  text-transform: uppercase;
`;
const Body = styled.div``;
