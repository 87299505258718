import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const UpApproveIcon = props => (
    <Icon {...props} viewBox={'0 0 24 24'}>
        <path d="m3.5 15.3c-.3 0-.5-.1-.7-.3l-2.5-2.5c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l.5.5c.1-4.2 3-7.9 7.2-9.1 3.4-.9 7 .1 9.4 2.5.4.4.4 1 0 1.4s-1 .4-1.4 0c-2-2-4.8-2.7-7.5-2-3.5.9-5.8 4.2-5.7 7.6l1-1c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-2.3 2.6c-.3.2-.6.3-.8.3z"/>
        <path d="m12 21.8c-2.6 0-5.1-1-6.9-2.9-.4-.4-.4-1 0-1.4s1-.4 1.4 0c2 2 4.8 2.7 7.5 2 2-.5 3.7-1.8 4.7-3.6.7-1.2 1.1-2.6 1-4l-1 1c-.4.4-1 .4-1.4 0s-.4-1 0-1.4l2.5-2.5c.4-.4 1-.4 1.4 0l2.5 2.5c.4.4.4 1 0 1.4s-1 .4-1.4 0l-.5-.5c-.1 1.6-.5 3.1-1.3 4.5-1.3 2.3-3.4 3.9-5.9 4.5-.9.2-1.7.4-2.6.4z"/>
        <path d="m10 15c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l4-4c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-4 4c-.2.2-.4.3-.7.3z"/>
        <path d="m14 15c-.3 0-.5-.1-.7-.3l-4-4c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l4 4c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3z"/>
    </Icon>
);

export default withTheme(UpApproveIcon);
