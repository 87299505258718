import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../constants/styles';
import { H6 } from '../../Typography';

const FieldLabel = (props) => (
    <Label data-testid={props.dataTestId}>{props.text || props.children}</Label>
);

export default FieldLabel;

FieldLabel.propTypes = {
    dataTestId: PropTypes.string,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.element
    ])
};

FieldLabel.defaultTypes = {
    dataTestId: 'label',
    text: null
};

const Label = styled(H6)`
    font-family: ${styles.fonts.family.primaryBook};
    color: ${styles.colors.silver};
    margin-bottom: 6px;
`;