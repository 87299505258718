import React from 'react';
import styled from 'styled-components';
import { P1 } from '../components/Typography';
import styles from '../constants/styles';

import SchoolPlan from '../assets/images/svg/baseball.svg';
import ExtraSmallPlan from '../assets/images/svg/plans/extrasmall.svg';
import SmallPlan from '../assets/images/svg/plans/small.svg';
import MediumPlan from '../assets/images/svg/plans/medium.svg';
import LargePlan from '../assets/images/svg/plans/large.svg';
import ExtraLargePlan from '../assets/images/svg/plans/extralarge.svg';
import FreePlan from '../assets/images/svg/plans/free.svg';

const renderPlan = (type, plan) => {
  if (type === 'school') {
    return (
      <BlockPlan>
        <img height={24} src={SchoolPlan} alt={'Plan'}/>
        <Desc><Span>$2</Span> / per year</Desc>
      </BlockPlan>
    );
  }
  let imagePlan = '';

  switch (plan.name) {
    case 'paid100':
      imagePlan = ExtraSmallPlan;
      break;
    case 'paid500':
      imagePlan = SmallPlan;
      break;
    case 'paid1000':
      imagePlan = MediumPlan;
      break;
    case 'paid2000':
      imagePlan = LargePlan;
      break;
    case 'paid4000':
      imagePlan = ExtraLargePlan;
      break;
    default:
      imagePlan = FreePlan;
  }

  return (
    <BlockPlan>
      <img height={24} src={imagePlan} alt={'Plan'}/>
      <Desc><Span>{plan.title || 'Plan'}</Span></Desc>
    </BlockPlan>
  );
};

export default renderPlan;

const BlockPlan = styled.div`
  display: flex;
  align-items: center;
`;
const Desc = styled(P1)`
  margin: 0 0 0 10px;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.text.medium};
  font-family: ${styles.fonts.family.primaryMedium};
`;
const Span = styled.span`
  color: ${props => props.theme.colors.text.dark};
  font-size: 16px;
  font-family: ${styles.fonts.family.primaryMedium};
`;