import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const ResendIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M23,11h-5c-0.6,0-1-0.4-1-1s0.4-1,1-1h4V5c0-0.6,0.4-1,1-1s1,0.4,1,1v5C24,10.6,23.6,11,23,11z"/>
            <path d="M1,20c-0.6,0-1-0.4-1-1v-5c0-0.6,0.4-1,1-1h5c0.6,0,1,0.4,1,1s-0.4,1-1,1H2v4C2,19.6,1.6,20,1,20z"/>
            <path d="M12,22L12,22c-2.7,0-5.2-1-7.1-2.9l-4.6-4.3c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l4.6,4.4
	C7.9,19.2,9.9,20,12,20l0,0c2.1,0,4.1-0.8,5.7-2.3c0.8-0.8,1.5-1.9,1.9-3c0.2-0.5,0.8-0.8,1.3-0.6s0.8,0.8,0.6,1.3
	c-0.5,1.4-1.3,2.7-2.4,3.7C17.2,21,14.7,22,12,22z M23,11c-0.2,0-0.5-0.1-0.7-0.3l-4.6-4.4c-0.9-0.9-1.9-1.5-3-1.9
	c-2-0.7-4.2-0.6-6.1,0.3c-2,1-3.4,2.6-4.1,4.6c-0.2,0.5-0.8,0.8-1.3,0.6C2.7,9.8,2.4,9.2,2.6,8.7C3.5,6.1,5.3,4.1,7.7,3
	c2.4-1.2,5.1-1.3,7.6-0.4C16.7,3.1,18,3.9,19,5l4.6,4.3c0.4,0.4,0.4,1,0,1.4C23.5,10.9,23.3,11,23,11z"/>
            <path d="M12.5,16c-0.3,0-0.6-0.2-0.7-0.5l-1.2-2.6L8,11.8c-0.3-0.1-0.5-0.4-0.5-0.8c0-0.3,0.2-0.6,0.5-0.7L14.5,8
	c0.3-0.1,0.6,0,0.8,0.2c0.2,0.2,0.3,0.5,0.2,0.8l-2.2,6.4C13.1,15.8,12.9,16,12.5,16C12.5,16,12.5,16,12.5,16z"/>
        </Icon>
    );

export default withTheme(ResendIcon);
