import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const CoHostEventInvitedIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M8,22H3c-1.7,0-3-1.3-3-3V5c0-1.7,1.3-3,3-3h14c1.7,0,3,1.3,3,3v5c0,0.6-0.4,1-1,1s-1-0.4-1-1V5c0-0.6-0.4-1-1-1H3C2.4,4,2,4.4,2,5v14c0,0.6,0.4,1,1,1h5c0.6,0,1,0.4,1,1S8.6,22,8,22z"/>
            <path d="M14,6c-0.6,0-1-0.4-1-1V1c0-0.6,0.4-1,1-1s1,0.4,1,1v4C15,5.6,14.6,6,14,6z"/>
            <path d="M6,6C5.4,6,5,5.6,5,5V1c0-0.6,0.4-1,1-1s1,0.4,1,1v4C7,5.6,6.6,6,6,6z"/>
            <path d="M19,10H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h18c0.6,0,1,0.4,1,1S19.6,10,19,10z"/>
            <path d="M3.5,14.9c0-1.8,1.3-3,2.9-3c1.7,0,2.5,1.1,2.6,1.9l-1.2,0.4c-0.1-0.4-0.4-1.1-1.4-1.1c-0.8,0-1.6,0.5-1.6,1.7c0,1.1,0.8,1.7,1.6,1.7c1,0,1.4-0.7,1.5-1.1l1.2,0.3c-0.2,0.8-0.9,2-2.7,2C4.8,17.8,3.5,16.6,3.5,14.9z"/>
            <path d="M21.8,24h-9.6C11,24,10,23,10,21.8v-7.5c0-1.2,1-2.2,2.2-2.2h9.6c1.2,0,2.2,1,2.2,2.2v7.5C24,23,23,24,21.8,24z M12.2,14c-0.1,0-0.2,0.1-0.2,0.2v7.5c0,0.1,0.1,0.2,0.2,0.2h9.6c0.1,0,0.2-0.1,0.2-0.2v-7.5c0-0.1-0.1-0.2-0.2-0.2C21.8,14,12.2,14,12.2,14z"/>
            <path d="M17,19.6c-0.2,0-0.4-0.1-0.6-0.2l-6-4.2c-0.5-0.3-0.4-1-0.2-1.4c0.3-0.5,0.9-0.6,1.4-0.2l5.4,3.8l5.4-3.8c0.5-0.3,1.1-0.2,1.4,0.2c0.3,0.5,0.2,1.1-0.2,1.4l-6,4.2C17.4,19.5,17.2,19.6,17,19.6z"/>
        </Icon>
    );

export default withTheme(CoHostEventInvitedIcon);
