import { GET_EVENTS_LIST } from '../actions/types';

const initialState = {
  details: null,
  detailsClient: null,
  list: [],
  total: null
};

function eventsReducer (state = initialState, action) {
  switch (action.type) {
    case GET_EVENTS_LIST:
      return {
        ...state,
        list: action.payload.items,
        total: action.payload.total
      };

    default:
      return {
        ...state
      };
  }
}

export default eventsReducer;
