import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const EmailIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M17.8,9c0.3,0.5,0.2,1.1-0.3,1.4l-5,3.3c-0.2,0.1-0.4,0.2-0.6,0.2s-0.4-0.1-0.6-0.2l-5-3.3
	C6,10.1,5.9,9.5,6.2,9c0.3-0.5,0.9-0.6,1.4-0.3l4.4,2.9l4.4-2.9C16.9,8.5,17.5,8.6,17.8,9z M20,5H4C3.4,5,3,5.4,3,6v12
	c0,0.6,0.4,1,1,1h16c0.6,0,1-0.4,1-1V6C21,5.4,20.6,5,20,5z M20,3c1.7,0,3,1.3,3,3v12c0,1.7-1.3,3-3,3H4c-1.7,0-3-1.3-3-3V6
	c0-1.7,1.3-3,3-3H20z"/>
        </Icon>
    );

export default withTheme(EmailIcon);
