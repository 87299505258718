import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const StarSolidIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M12,17.5l4.1,2.5c0.8,0.5,1.7-0.2,1.5-1.1l-1.1-4.7l3.7-3.2c0.7-0.6,0.3-1.7-0.6-1.8l-4.8-0.4l-1.9-4.5
	c-0.3-0.8-1.5-0.8-1.8,0L9.2,8.9L4.4,9.3C3.5,9.4,3.1,10.5,3.8,11l3.7,3.2l-1.1,4.7c-0.2,0.9,0.7,1.5,1.5,1.1L12,17.5z"/>
        </Icon>
    );

export default withTheme(StarSolidIcon);
