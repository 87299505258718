import React from 'react';
import styled from 'styled-components';
import styles from '../../constants/styles';
import { H5 } from '../Typography';

const ListItemWithIcon = (props) => {
    return (
        <Container>
            <IconCol>{props.Icon}</IconCol>
            <ContentCol style={props.styleContent}>{props.content}</ContentCol>
        </Container>
    );
};

export default ListItemWithIcon;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 8px;
`;
const IconCol = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 8px 0 0;
`;
const ContentCol = styled(H5)`
    font-family: ${styles.fonts.family.primaryBook};
    color: ${styles.colors.dark};
    word-break: break-word;
    margin: 0;
`;
