import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const FacebookIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M12,4.1c-4.4,0-8,3.6-8,8c0,4,3,7.3,6.7,7.9v-5.6h-2v-2.3h2v-1.8c0-2,1.2-3.1,3-3.1c0.9,0,1.8,0.2,1.8,0.2v2
	h-1c-1,0-1.3,0.6-1.3,1.3v1.5h2.2l-0.3,2.2h-1.8V20c3.8-0.6,6.7-3.9,6.7-7.9C20,7.6,16.4,4.1,12,4.1z"/>
        </Icon>
    );

export default withTheme(FacebookIcon);
