import renderCompanyPlan from './renderCompanyPlan';
import getPasswordStrength from './getPasswordStrength';
import { getAddress } from '../actions/eventsActions';
import validator from 'validator';
import { CITIES, DEFAULT_TIMEZONE, LINK_REGX, NETWORK_ACCESS, ORGANIZATION_TYPES, PROFILE_TYPES } from '../constants';
import firebaseConfig from '../api/firebaseConfig';
import {
  CAUSES_ROUTE,
  CHECK_IN_CO_HOST_VOLUNTEERS,
  CHECK_IN_VOLUNTEERS,
  EMPLOYEES_ROUTE,
  EVENT_CO_HOST_EDIT_ROUTE,
  EVENT_CO_HOST_ROUTE,
  EVENT_COPY_ROUTE,
  EVENT_CREATE_ROUTE,
  EVENT_EDIT_ROUTE,
  EVENT_ROUTE,
  EVENTS_CREATE_ROUTE,
  EVENTS_LIST_ROUTE,
  EVENTS_ROUTE,
  EVENTS_STATS_ROUTE,
  GROUP_EDIT_ROUTE,
  GROUP_ROUTE,
  GROUPS_ROUTE,
  HOME_LINK,
  MANUAL_USER_EDIT_ROUTE,
  MEMBERS_ROUTE,
  NOTE_CREATE_ROUTE,
  NOTE_EDIT_ROUTE,
  NOTE_ROUTE,
  NOTES_ROUTE,
  STUDENTS_ROUTE,
  SUBGROUPS_ROUTE,
  USER_ADD_ROUTE,
  USER_PROFILE_ROUTE,
  VOLUNTEERS_ROUTE
} from '../constants/links';

const { cdnBase } = firebaseConfig;
const moment = require('moment-timezone');

const getShortTimeZone = (idTimeZone) => moment.tz(idTimeZone).format('z');

const getDataTime = (startTime, endTime = null, timezone = DEFAULT_TIMEZONE, showZone) => {
  let timeString = '';
  let dateString = '';

  startTime = parseInt(startTime, 10);
  endTime = parseInt(endTime, 10);

  let shortTimeZone = getShortTimeZone(timezone);

  if (endTime) {
    const yearStartTime = moment.tz(startTime, timezone).format('YYYY');
    const monthStartTime = moment.tz(startTime, timezone).format('M');
    const dayStartTime = moment.tz(startTime, timezone).format('D');

    const yearEndTime = moment.tz(endTime, timezone).format('YYYY');
    const monthEndTime = moment.tz(endTime, timezone).format('M');
    const dayEndTime = moment.tz(endTime, timezone).format('D');

    let timeStartString = moment.tz(startTime, timezone).format('h:mm A');
    if (moment.tz(startTime, timezone).format('mm') === '00') {
      timeStartString = moment.tz(startTime, timezone).format('h A');
    }
    let timeEndString = moment.tz(endTime, timezone).format('h:mm A');
    if (moment.tz(endTime, timezone).format('mm') === '00') {
      timeEndString = moment.tz(endTime, timezone).format('h A');
    }
    timeString = `${timeStartString} - ${timeEndString}`;
    if (showZone) {
      timeString = `${timeStartString} - ${timeEndString} (${shortTimeZone})`;
    }

    dateString = `${moment.tz(startTime, timezone).format('ddd, MMM Do, YYYY')} - ${moment.tz(endTime, timezone).format('ddd, MMM Do, YYYY')}`;
    if (yearStartTime === yearEndTime) {
      dateString = `${moment.tz(startTime, timezone).format('ddd, MMM Do')} - ${moment.tz(endTime, timezone).format('ddd, MMM Do')}, ${yearStartTime}`;
    }
    if (yearStartTime === yearEndTime && monthStartTime === monthEndTime && dayStartTime === dayEndTime) {
      dateString = moment.tz(startTime, timezone).format('ddd, MMM Do, YYYY');
    }
  } else {
    timeString = moment.tz(startTime, timezone).format('h:mm A');
    if (moment.tz(startTime, timezone).format('mm') === '00') {
      timeString = moment.tz(startTime, timezone).format('h A');
    }
    dateString = moment.tz(startTime, timezone).format('ddd, MMM Do YYYY');
  }

  const currentTimeZone = moment.tz(startTime, timezone).format('Z z');

  return {
    dateString,
    timeString,
    currentTimeZone
  };
};

const getDataTimeForShift = (startTime, endTime = null, timezone = DEFAULT_TIMEZONE, isYear) => {
  let timeString = '';
  let dateString = '';

  startTime = parseInt(startTime, 10);
  endTime = parseInt(endTime, 10);

  if (endTime) {
    const yearStartTime = moment.tz(startTime, timezone).format('YYYY');
    const yearEndTime = moment.tz(endTime, timezone).format('YYYY');

    let timeStartString = moment.tz(startTime, timezone).format('h:mm A');
    if (moment.tz(startTime, timezone).format('mm') === '00') {
      timeStartString = moment.tz(startTime, timezone).format('h A');
    }
    let timeEndString = moment.tz(endTime, timezone).format('h:mm A');
    if (moment.tz(endTime, timezone).format('mm') === '00') {
      timeEndString = moment.tz(endTime, timezone).format('h A');
    }

    timeString = `${timeStartString} - ${timeEndString}`;

    dateString = `${moment.tz(startTime, timezone).format('ddd, MMM D')} at ${timeString}`;

    if (moment.tz(startTime, timezone).format('MMM ddd YYYY') !== moment.tz(endTime, timezone).format('MMM ddd YYYY')) {
      dateString = `${moment.tz(startTime, timezone).format('ddd, MMM D')} at ${timeStartString} - 
      ${moment.tz(endTime, timezone).format('ddd, MMM D')} at ${timeEndString}`;
    }

    if (isYear) {
      dateString = `${moment.tz(startTime, timezone).format('ddd, MMM D')} at ${timeString}, ${yearStartTime}`;


      if (moment.tz(startTime, timezone).format('MMM ddd YYYY') !== moment.tz(endTime, timezone).format('MMM ddd YYYY')) {
        dateString = `${moment.tz(startTime, timezone).format('ddd, MMM D')} at ${timeStartString} - 
        ${moment.tz(endTime, timezone).format('ddd, MMM D')} at ${timeEndString}, ${yearStartTime}`;
      }

      if (yearStartTime !== yearEndTime) {
        dateString = `${moment.tz(startTime, timezone)
          .format('ddd, MMM D')} at ${timeStartString}, ${yearStartTime} - ${moment.tz(endTime, timezone)
          .format('ddd, MMM D')} at ${timeEndString}, ${yearEndTime}`;
      }
    }
  }

  const currentTimeZone = moment.tz(startTime, timezone).format('Z z');

  return {
    dateString,
    timeString,
    currentTimeZone
  };
};

const getTypeNameByType = (type) => {
  switch (type) {
    case PROFILE_TYPES.USER:
      return 'User';
    case PROFILE_TYPES.SCHOOL:
      return 'School';
    case PROFILE_TYPES.RELIGION:
      return 'Religious Congregation';
    case PROFILE_TYPES.NONPROFIT:
      return 'Nonprofit';
    case PROFILE_TYPES.LOCALGOVERNMENT:
      return 'Local Government';
    case PROFILE_TYPES.BUSINESS:
      return 'Business';
    case PROFILE_TYPES.CLUB:
      return 'Club';
    case PROFILE_TYPES.SYSTEM:
      return 'Nonprofit';
    default:
      return 'Unknown Organization';
  }
};

const getTypeActiveByType = (type) => {
  switch (type) {
    case ORGANIZATION_TYPES.SCHOOL:
    case ORGANIZATION_TYPES.CLUB:
      return 'users';
    case ORGANIZATION_TYPES.RELIGION:
      return 'members';
    case ORGANIZATION_TYPES.LOCALGOVERNMENT:
    case ORGANIZATION_TYPES.NONPROFIT:
      return 'volunteers';
    case ORGANIZATION_TYPES.BUSINESS:
      return 'employees';
    default:
      return 'volunteers';
  }
};

const getUserTypeByOrgType = (type) => {
  switch (type) {
    case ORGANIZATION_TYPES.SCHOOL:
    case ORGANIZATION_TYPES.CLUB:
      return 'Volunteer';
    case ORGANIZATION_TYPES.RELIGION:
      return 'Member';
    case ORGANIZATION_TYPES.LOCALGOVERNMENT:
    case ORGANIZATION_TYPES.NONPROFIT:
      return 'Volunteer';
    case ORGANIZATION_TYPES.BUSINESS:
      return 'Employee';
    default:
      return 'Volunteer';
  }
};

const getColorByOrgType = (type) => {
  switch (type) {
    case PROFILE_TYPES.USER:
      return '#8F33FF';
    case PROFILE_TYPES.SCHOOL:
      return '#FFAD0D';
    case PROFILE_TYPES.RELIGION:
      return '#00ABBA';
    case PROFILE_TYPES.NONPROFIT:
      return '#F6921E';
    case PROFILE_TYPES.LOCALGOVERNMENT:
      return '#3BA5FF';
    case PROFILE_TYPES.BUSINESS:
      return '#3686FF';
    case PROFILE_TYPES.CLUB:
      return '#FF7E47';
    case PROFILE_TYPES.SYSTEM:
      return '#F6921E';
    default:
      return '#A6AEBC';
  }
};

const capitalize = (s) => (typeof s !== 'string') ? '' : s.charAt(0).toUpperCase() + s.slice(1);

const getGenderForShow = (gender) => {
  switch (gender) {
    case 2:
      return 'Male';
    case 3:
      return 'Female';
    case 4:
      return 'Non-Binary';
    default:
      return 'Anyone';
  }
};

const isShowBlock = (type = 'non_profit') => {
  let organizationsType = '';
  const profileData = JSON.parse(localStorage.getItem('POINT.profileData'));
  if (profileData && profileData.activeOrganization) {
    organizationsType = profileData.activeOrganization.type;
  }

  let isShow = false;
  if (type && type === ORGANIZATION_TYPES.BUSINESS) {
    if (organizationsType && organizationsType === ORGANIZATION_TYPES.BUSINESS) {
      isShow = true;
    }
    return isShow;
  }

  if (organizationsType &&
    (organizationsType === ORGANIZATION_TYPES.SCHOOL || organizationsType === ORGANIZATION_TYPES.CLUB)) {
    isShow = true;
  }
  return isShow;
};

const millisecToHours = (millisec) => (Number(millisec) / (60 * 60 * 1000)).toFixed(2);

const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = (`${String(phoneNumberString)}`).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = (match[1] ? '+1 ' : '');
    return [ intlCode, '(', match[2], ') ', match[3], '-', match[4] ].join('');
  }
  return phoneNumberString;
};

const getImageURI = (originalURI) => {
  if (originalURI && typeof originalURI === 'string') {
    if (originalURI[0] === '/') {
      originalURI = originalURI.substring(1);
    }
    return (originalURI.indexOf('https://') !== -1) ?
      originalURI :
      ((originalURI.length > 0) ? `${cdnBase}/${originalURI}` : '');
  }
  return '';
};

const removeSpacesFromStartAndEnd = (str) => {
  const wsRegex = /^\s*(.*\S)\s*$/;
  return str.replace(wsRegex, '$1');
};

const fract = (n) => Number(String(n).split('.')[1] || 0);

const capitalizeFLetter = (str) => (str[0].toUpperCase() + str.slice(1));

const getNumberWithCommas = (num) => {
  return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0;
};

const dollarsToCents = (dollars) => Math.round(dollars * 100);
const centsToDollars = (cents) => +((parseInt(cents, 10) / 100).toFixed(2));

const millisecondToHours = (ms) => (ms / (1000 * 60 * 60)).toFixed(2);

const goToOrganization = async (organization, toURL = HOME_LINK) => {
  const profileDataStorage = await JSON.parse(localStorage.getItem('POINT.profileData'));
  localStorage.setItem('POINT.profileData', JSON.stringify(Object.assign(profileDataStorage, {
    activeOrganization: {
      id: organization.id,
      isOwner: organization.isOwner,
      organizationName: organization.organizationName,
      type: organization.type,
      status: organization.status,
      timezone: organization.timezone,
      createTime: organization.createTime
    },
    network: {
      id: 0,
      name: null,
      access: null,
      type: null,
      ownerOrganization: null
    }
  })));
  window.location = toURL;
};

const goToNetworkOrganization = async ({ organization, network, toURL = HOME_LINK }) => {
  const profileDataStorage = await JSON.parse(localStorage.getItem('POINT.profileData'));
  localStorage.setItem('POINT.profileData', JSON.stringify(Object.assign(profileDataStorage, {
    activeOrganization: {
      id: organization.id,
      isOwner: organization.isOwner,
      organizationName: organization.organizationName,
      type: organization.type,
      status: organization.status,
      timezone: organization.timezone,
      createTime: organization.createTime
    },
    network: {
      id: network.id,
      name: network.name,
      access: network.access,
      type: network.type,
      ownerOrganization: profileDataStorage.activeOrganization
    }
  })));
  window.location = toURL;
};

const getCorrectUrl = (urlString) => {
  if (urlString && urlString.match(LINK_REGX)) {
    if (urlString.slice(0, 8) === 'https://' || urlString.slice(0, 7) === 'http://') return urlString;
    return `//${urlString}`;
  }
  return false;
};

const bytesToSize = (bytes) => {
  const sizes = [ 'Bytes', 'KB', 'MB', 'GB', 'TB' ];
  if (bytes === 0) {
    return '0 Byte';
  }
  let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

const getAddressShort = async (address) => {
  const resLocation = await getAddress(address);
  let cityLocation = '';
  let stateLocation = '';
  if (resLocation && (resLocation.status.toLowerCase() === 'ok') && resLocation.results && resLocation.results[0]) {
    resLocation.results[0].address_components.forEach((element) => {
      const state = element.types.indexOf('administrative_area_level_1');
      if (state >= 0) stateLocation = element.short_name;

      const city = element.types.indexOf('locality');
      if (city >= 0) cityLocation = element.short_name;
    });
  }
  return (cityLocation && stateLocation) ? `${cityLocation}, ${stateLocation}` : null;
};

const accessConfiguration = (page) => {
  const profileData = JSON.parse(localStorage.getItem('POINT.profileData'));
  const network = profileData.network;
  switch (page) {
    case EVENTS_STATS_ROUTE:
    case CAUSES_ROUTE:
      return true;
    case STUDENTS_ROUTE:
    case EMPLOYEES_ROUTE:
    case MEMBERS_ROUTE:
    case VOLUNTEERS_ROUTE:
    case CHECK_IN_VOLUNTEERS:
    case CHECK_IN_CO_HOST_VOLUNTEERS:
    case USER_PROFILE_ROUTE:
    case USER_ADD_ROUTE:
    case MANUAL_USER_EDIT_ROUTE:
      return [
        NETWORK_ACCESS.FULL_ADMIN
      ].includes(network.access);
    case EVENTS_CREATE_ROUTE:
    case EVENT_CO_HOST_EDIT_ROUTE:
    case EVENT_CREATE_ROUTE:
    case GROUP_EDIT_ROUTE:
    case EVENT_COPY_ROUTE:
    case EVENT_EDIT_ROUTE:
      return [
        NETWORK_ACCESS.LIMITED_ADMIN,
        NETWORK_ACCESS.FULL_ADMIN
      ].includes(network.access);
    case EVENTS_ROUTE:
    case EVENTS_LIST_ROUTE:
    case EVENT_ROUTE:
    case EVENT_CO_HOST_ROUTE:
    case GROUPS_ROUTE:
    case GROUP_ROUTE:
    case SUBGROUPS_ROUTE:
    case NOTES_ROUTE:
    case NOTE_ROUTE:
    case NOTE_CREATE_ROUTE:
    case NOTE_EDIT_ROUTE:
      return [
        NETWORK_ACCESS.DETAILED_VIEW,
        NETWORK_ACCESS.LIMITED_ADMIN,
        NETWORK_ACCESS.FULL_ADMIN
      ].includes(network.access);
    default:
      return false;
  }
};

const isValidEmail = (email) => validator.isEmail(email);

const nFormatter = (num, digits = 2) => {
  var si = [
    { value: 1, symbol: '' },
    { value: 1E3, symbol: 'k' },
    { value: 1E6, symbol: 'm' },
    { value: 1E9, symbol: 'G' },
    { value: 1E12, symbol: 'T' },
    { value: 1E15, symbol: 'P' },
    { value: 1E18, symbol: 'E' }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
};

const ein = str => str.replace(/-/, '');

const fullAddress = (address) => {

  let addressLine = [];
  if (address) {
    if (address.addressLine1) addressLine.push(address.addressLine1);
    if (address.addressLine2) addressLine.push(address.addressLine2);
    if (address.city) addressLine.push(address.city);
    if (address.state || address.zipCode) {
      addressLine.push(`${address.state}${address.zipCode ? ' ' + address.zipCode : ''}`);
    }
    if (address.country) addressLine.push(address.country);
  }
  return addressLine.join(', ');
};

const loadGoogleMaps = (callback) => {
  const existingScript = document.getElementById('googlePlacesScript');
  const existingSecondMaps = document.getElementById('googleMaps');
  if (!existingScript && !existingSecondMaps) {
    const script = document.createElement('script');
    script.src =
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}&libraries=places`;
    script.id = 'googleMaps';
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }
  if ((existingScript || existingSecondMaps) && callback) callback();
};

const unloadGoogleMaps = () => {
  let googlePlacesScript = document.getElementById('googlePlacesScript');
  let googlePlacesScriptMaps = document.getElementById('googleMaps');

  if (googlePlacesScript) {
    googlePlacesScript.remove();
  }
  if (googlePlacesScriptMaps) {
    googlePlacesScriptMaps.remove();
  }
};


const renderAddressObject = async (components) => {
  let address = {};

  components.forEach((element) => {

    const streetNumber = element.types.indexOf('street_number');
    if (streetNumber >= 0) address.addressLine1 = element.long_name;

    const street = element.types.indexOf('route');
    if (street >= 0) address.addressLine1 = address.addressLine1 + ' ' + element.long_name;

    const room = element.types.indexOf('room');
    if (room >= 0) address.addressLine2 = element.long_name;

    const state = element.types.indexOf('administrative_area_level_1');
    if (state >= 0) address.state = element.long_name;

    const level_2 = element.types.indexOf('administrative_area_level_2');
    if (level_2 >= 0 && !address.addressLine1) address.addressLine1 = element.long_name;

    const level_3 = element.types.indexOf('administrative_area_level_3');
    if (level_3 >= 0 && !address.addressLine1) address.addressLine1 = element.long_name;

    const city = element.types.indexOf('locality');
    if (city >= 0) address.city = element.short_name;

    const zipCode = element.types.indexOf('postal_code');
    if (zipCode >= 0) address.zipCode = element.short_name;

    const country = element.types.indexOf('country');
    if (country >= 0) address.country = element.short_name;
  });

  return address;
};

const isURL = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (e) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

const getDataLocationByCity = (city) => {
  let data = {
    lat: CITIES.COLUMBUS.lat,
    lng: CITIES.COLUMBUS.lng,
    miles: CITIES.COLUMBUS.miles
  };

  const index = city.toUpperCase();
  if (index && CITIES[index]) {
    data.lat = CITIES[index].lat;
    data.lng = CITIES[index].lng;
    data.miles = CITIES[index].miles;
  }

  return data;
};

const getIsZipCode = (str) => {
  const regexp = /^[0-9]{5}?$/;
  return regexp.test(str);
}

const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

const downloadFileByURL = async ({fileURL, fileName = 'file'}) => {
  const image = await fetch(fileURL);
  const imageBlob = await image.blob();
  const imageUrl = URL.createObjectURL(imageBlob);

  const anchor = document.createElement('a');
  anchor.href = imageUrl;
  anchor.download = fileName;
  anchor.target = '_blank';

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  anchor.remove();
  URL.revokeObjectURL(imageUrl);
};

const getFileName = (filePath, n) => {
  if (!filePath) {
    return null;
  }
  const arr = filePath.split('/');
  let fileName = arr[arr.length - 1];
  const fileNameLength = fileName.length;
  if (n && fileNameLength > n) {
    fileName = fileName.substring(0,8) + '...' + fileName.substring(fileNameLength-9, fileNameLength);
  }
  return fileName;
};

export {
  ein,
  capitalize,
  getDataTime,
  getImageURI,
  getNumberWithCommas,
  capitalizeFLetter,
  getGenderForShow,
  getPasswordStrength,
  getColorByOrgType,
  renderCompanyPlan,
  getTypeNameByType,
  getTypeActiveByType,
  millisecToHours,
  isShowBlock,
  formatPhoneNumber,
  goToOrganization,
  goToNetworkOrganization,
  fract,
  dollarsToCents,
  centsToDollars,
  getCorrectUrl,
  getAddressShort,
  isValidEmail,
  nFormatter,
  millisecondToHours,
  accessConfiguration,
  removeSpacesFromStartAndEnd,
  bytesToSize,
  getUserTypeByOrgType,
  fullAddress,
  loadGoogleMaps,
  unloadGoogleMaps,
  renderAddressObject,
  isURL,
  getShortTimeZone,
  getDataTimeForShift,
  getDataLocationByCity,
  getIsZipCode,
  asyncForEach,
  downloadFileByURL,
  getFileName
};
