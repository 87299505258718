import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const ProgramSolidIcon = props => (
  <Icon {...props} viewBox={'0 0 24 24'}>
    <path d="M20.1,5.7c-1.1-0.5-2.4-0.7-3.6-0.7c-1.6,0-3.4,0.3-4.6,1.2
	c-1.2-0.9-3-1.2-4.6-1.2c-1.2,0-2.5,0.2-3.6,0.7c-0.6,0.3-1,0.9-1,1.5v9.4c0,1.1,1,1.9,2.1,1.6C5.7,18.1,6.6,18,7.4,18
	c1.3,0,2.7,0.2,3.8,0.8c0.5,0.2,1.1,0.2,1.6,0c1.1-0.6,2.5-0.8,3.8-0.8c0.8,0,1.7,0.1,2.5,0.3c1.1,0.3,2.1-0.5,2.1-1.6V7.3
	C21.2,6.6,20.8,6,20.1,5.7z M19.5,15.7c0,0.5-0.5,0.9-1,0.8c-0.6-0.1-1.3-0.2-1.9-0.2c-1.1,0-2.6,0.3-3.8,0.8V7.6
	c1.1-0.5,2.6-0.8,3.8-0.8c0.8,0,1.5,0.1,2.2,0.2c0.4,0.1,0.7,0.4,0.7,0.8V15.7z"/>
  </Icon>
);
export default withTheme(ProgramSolidIcon);
