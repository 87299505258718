import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const BulbIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M15,18.5H9c-0.6,0-1-0.4-1-1v-1c0-2.2-0.4-3-0.7-3.3c-2.8-2.8-2.9-7-0.2-9.7c1.3-1.3,3.1-2,5-2
	s3.6,0.7,4.9,2c1.3,1.3,2,3,2,4.8c-0.1,1.8-0.8,3.5-2.2,4.8v0c-0.3,0.3-0.7,1-0.7,3.3v1C16,18.1,15.5,18.5,15,18.5L15,18.5z
	 M14,22.5h-4c-0.6,0-1-0.4-1-1s0.4-1,1-1h4c0.6,0,1,0.4,1,1S14.5,22.5,14,22.5L14,22.5z"/>
        </Icon>
    );

export default withTheme(BulbIcon);
