import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ItemEvent } from '../../../../components/ItemEvent';
import NoData from '../../../../components/NoData';
import EventsEmpty from '../../../../assets/images/svg/empty-state/events.svg';

const EventsList = ({ events, history }) => {

  const listEvents = events.map((event, index) => (
      <ItemEvent
        indexItem={index}
        key={event.id}
        item={event}
        history={history}
        type={'mobile'}
        onClick={() => history.push('/' + event.id)}
        list={true}
      />
    )
  );

  return (
    <Container>
      {events && events.length > 0 ? (
        <List>
          {listEvents}
        </List>
      ) : (
        <NoData
          image={EventsEmpty}
          title={'There are no events to see here...yet.'}
        />
      )}
    </Container>
  );
};

export default EventsList;

EventsList.propTypes = {
  history: PropTypes.shape().isRequired,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      address: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      startTime: PropTypes.number.isRequired,
      timezone: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      organization: PropTypes.shape({
        id: PropTypes.number.isRequired,
        logo: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      }).isRequired
    })
  )
};

EventsList.defaultProps = {
  events: []
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 10px 0;
  width: 100%;
`;
const List = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 -16px;
  flex-wrap: wrap;
  width: calc(100% + 32px);
`;

