import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const LinkIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M6.5,10.2L6.5,10.2c0.5,0.5,0.5,1,0.2,1.4l-0.1,0.1l-1.4,1.4C5,13.2,5,13.3,4.9,13.4
	c-1.4,1.7-1.2,4.2,0.5,5.6c1.6,1.3,3.9,1.1,5.4-0.2l0.2-0.2l1.3-1.3c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,0.9,0.1,1.3l-0.1,0.1l-1.4,1.4
	c-0.2,0.2-0.4,0.3-0.6,0.5c-2.7,2-6.5,1.6-8.5-1.1c-1.8-2.4-1.6-5.7,0.5-7.9l0.2-0.2l1.3-1.3l0,0C5.5,9.9,6.1,9.9,6.5,10.2z
	 M14.7,9.3c0.4,0.4,0.4,0.9,0.1,1.3l-0.1,0.1l-4,4c-0.4,0.4-0.9,0.4-1.3,0.1l-0.1-0.1c-0.4-0.4-0.4-0.9-0.1-1.3l0.1-0.1l4-4
	C13.7,8.9,14.3,8.9,14.7,9.3z M11.5,3.9c2.2-2.2,5.6-2.5,8.1-0.7c0.2,0.2,0.4,0.3,0.6,0.5c2.4,2.4,2.4,6.2,0,8.6l0,0l-1.4,1.4
	c-0.4,0.4-1,0.4-1.4,0l0,0c-0.4-0.4-0.4-1,0-1.4l0,0l1.4-1.4c0.1-0.1,0.2-0.2,0.3-0.3c1.4-1.7,1.1-4.2-0.6-5.6
	c-1.7-1.3-4.1-1.1-5.6,0.4l0,0l-1.3,1.3c-0.4,0.4-1,0.4-1.4,0c0,0,0-0.1-0.1-0.1C9.8,6,10,5.3,10.4,5l0,0L11.5,3.9z"/>
        </Icon>
    );

export default withTheme(LinkIcon);
