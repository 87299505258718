import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Loader from '../../assets/icons/Loader';
import styles from '../../constants/styles';
import { Wrapper } from '../../components/Elements';

const WaitingRes = (props) => {
  const { message } = props;

  return (
    <Wrapper>
      <CenterOfScreen>
        <Loader fill={styles.colors.primary} size={100}/>
        <Text>{message}</Text>
      </CenterOfScreen>
    </Wrapper>
  );
};

WaitingRes.propTypes = {
  message: PropTypes.string.isRequired
};

export default WaitingRes;

const CenterOfScreen = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
`;
const Text = styled.div`
  color: ${styles.colors.primary};
  font-size: 16px;
  margin-top: 20px;
`;
