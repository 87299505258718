import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const StarIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M12,16.1c0.2,0,0.3,0,0.5,0.1l3.8,2L15.5,14c-0.1-0.3,0.1-0.7,0.3-0.9l3-2.9l-4.2-0.6
	C14.3,9.5,14,9.3,13.9,9L12,5.3L10.1,9C9.9,9.3,9.6,9.5,9.3,9.5l-4.2,0.6l3,2.9c0.2,0.2,0.3,0.6,0.3,0.9l-0.7,4.2l3.8-2
	C11.6,16.1,11.8,16.1,12,16.1 M17.5,21c-0.2,0-0.3,0-0.5-0.1L12,18.2l-5.1,2.7c-0.5,0.3-1.1,0.1-1.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.6
	l1-5.6l-4.1-4C2,9.9,1.9,9.6,2,9.2c0.1-0.4,0.4-0.6,0.8-0.7l5.7-0.8l2.5-5.1c0.2-0.5,0.8-0.7,1.3-0.5c0.2,0.1,0.4,0.3,0.5,0.5
	l2.5,5.1l5.7,0.8c0.4,0.1,0.7,0.3,0.8,0.7c0.1,0.4,0,0.7-0.2,1l-4.1,4l1,5.6c0.1,0.4-0.1,0.8-0.4,1C18,21,17.7,21,17.5,21"/>
        </Icon>
    );

export default withTheme(StarIcon);
