import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const CloseCircleIcon = props => (
  <Icon {...props} viewBox={'0 0 24 24'}>
    <rect fill="none" width="24" height="24"/>
    <path d="M12,2c5.5,0,10,4.5,10,10c0,5.5-4.5,10-10,10C6.5,22,2,17.5,2,12C2,6.5,6.5,2,12,2z M12,4
			c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S16.4,4,12,4z M9.7,8.3l2.3,2.3l2.3-2.3c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4L13.4,12
			l2.3,2.3c0.4,0.4,0.4,1,0,1.4C15.5,15.9,15.3,16,15,16s-0.5-0.1-0.7-0.3L12,13.4l-2.3,2.3C9.5,15.9,9.3,16,9,16s-0.5-0.1-0.7-0.3
			c-0.4-0.4-0.4-1,0-1.4l2.3-2.3L8.3,9.7c-0.4-0.4-0.4-1,0-1.4C8.7,7.9,9.3,7.9,9.7,8.3z"/>
  </Icon>
);

export default withTheme(CloseCircleIcon);
