import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const RemoveIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M14,1c1.7,0,3,1.3,3,3v1h4c0.6,0,1,0.4,1,1s-0.4,1-1,1h-1v13c0,1.7-1.3,3-3,3H7
	c-1.7,0-3-1.3-3-3V7H3C2.4,7,2,6.6,2,6s0.4-1,1-1h4V4c0-1.7,1.3-3,3-3H14z M18,7H6v13c0,0.6,0.4,1,1,1h10c0.6,0,1-0.4,1-1V7z M10,10
	c0.6,0,1,0.4,1,1v6c0,0.6-0.4,1-1,1s-1-0.4-1-1v-6C9,10.4,9.4,10,10,10z M14,10c0.6,0,1,0.4,1,1v6c0,0.6-0.4,1-1,1s-1-0.4-1-1v-6
	C13,10.4,13.4,10,14,10z M14,3h-4C9.4,3,9,3.4,9,4v1h6V4C15,3.4,14.6,3,14,3z"/>
        </Icon>
    );

export default withTheme(RemoveIcon);
