import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const ExportIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M18,23H6c-1.7,0-3-1.3-3-3v-8c0-0.6,0.4-1,1-1s1,0.4,1,1v8c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1v-8
	c0-0.6,0.4-1,1-1s1,0.4,1,1v8C21,21.7,19.7,23,18,23z"/>
            <path d="M16,7c-0.3,0-0.5-0.1-0.7-0.3L12,3.4L8.7,6.7c-0.4,0.4-1,0.4-1.4,0s-0.4-1,0-1.4l4-4c0.4-0.4,1-0.4,1.4,0
	l4,4c0.4,0.4,0.4,1,0,1.4C16.5,6.9,16.3,7,16,7z"/>
            <path d="M12,16c-0.6,0-1-0.4-1-1V2c0-0.6,0.4-1,1-1s1,0.4,1,1v13C13,15.6,12.6,16,12,16z"/>
        </Icon>
    );

export default withTheme(ExportIcon);
