import React from 'react';
import { withTheme } from 'styled-components';
import Icon from './components/Icon';

const ActivityMenu = props => (
        <Icon {...props}>
            <path d="M20.7,6.3l-7.1,7.1l-3.5-3.5c-0.4-0.4-1-0.4-1.4,0l-6.4,6.4c-0.4,0.4-0.4,1,0,1.4
	c0.4,0.4,1,0.4,1.4,0l5.7-5.7l3.5,3.5c0.4,0.4,1,0.4,1.4,0l7.8-7.8c0.4-0.4,0.4-1,0-1.4C21.7,5.9,21.1,5.9,20.7,6.3"/>
        </Icon>
    );

export default withTheme(ActivityMenu);
