import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import styles from '../../../constants/styles';
import NoData from '../../NoData';
import { findIndex } from 'lodash';
import { isValidEmail } from '../../../_helpers';
import { getIsUserEmailAvailable, getIsUsernameAvailable } from '../../../actions/accountActions';
import {
    CardIcon,
    CheckCircleSolidIcon,
    CloseCircleSolidIcon,
    EyeIcon,
    MinusIcon,
    MoneyIcon,
    PlusCircleIcon,
    PlusIcon,
    SearchIcon,
    TimeIcon
} from '../../Icons/General';
import { H6, P2 } from '../../Typography';
import { INPUT_SIZE, INPUT_STATE_OF_DATA, INPUT_TYPE } from '../../../constants';

let timer = null;

class Input extends Component {
    constructor (props) {
        super(props);
        this.state = {
            showBg: false,
            maxHeightOptionsModal: 200,
            selectWidth: 0,
            offsetTop: 0,
            offsetLeft: 0,
            offsetRight: 0,
            inputStateData: null,
            isShowPwd: false,
            isActive: false
        };
        this.selectRef = React.createRef();
        this.togglePassword = this.togglePassword.bind(this);
        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
        this.inputRender = this.inputRender.bind(this);
        this.showInputStateIcon = this.showInputStateIcon.bind(this);
        this.showStateOfData = this.showStateOfData.bind(this);
        this.showIconAtStart = this.showIconAtStart.bind(this);
        this.showIconAtEnd = this.showIconAtEnd.bind(this);
        this.showSearchResult = this.showSearchResult.bind(this);
        this.triggerSelect = this.triggerSelect.bind(this);
    }

    triggerSelect = () => {
        let offsetTop = this.selectRef.current.getBoundingClientRect().top;
        let offsetLeft = this.selectRef.current.getBoundingClientRect().left;
        let offsetRight = this.selectRef.current.getBoundingClientRect().right;
        let offsetHeight = this.selectRef.current.offsetHeight;
        let innerHeight = window.innerHeight;
        let innerWidth = window.innerWidth;

        let maxHeightOptionsModal = innerHeight - (offsetTop + offsetHeight) - 32;

        this.setState({
            showBg: !this.state.showBg,
            maxHeightOptionsModal: maxHeightOptionsModal,
            selectWidth: this.selectRef.current.getBoundingClientRect().width,
            offsetTop: offsetTop + offsetHeight,
            offsetLeft: offsetLeft,
            offsetRight: innerWidth - offsetRight
        });
    };

    togglePassword = () => {
        this.setState({ isShowPwd: !this.state.isShowPwd });
    };

    increment = () => {
        this.props.onChange(Number(this.props.value + 1));
    };

    decrement = () => {
        if (this.props.value >= 1) {
            this.props.onChange(Number(this.props.value - 1));
        }
    };

    onFocus = () => {
        if (this.props.onFocus && typeof (this.props.onFocus) === 'function') {
            this.props.onFocus();
        }
        this.setState({ isActive: true });
    };

    onBlur = (el) => {
        this.setState({ isActive: false });
        if (this.props.onBlur && typeof (this.props.onBlur) === 'function') {
            this.props.onBlur(el);
        }
    };

    showInputStateIcon = () => {
        switch (this.state.inputStateData) {
            case INPUT_STATE_OF_DATA.BAD:
                return (
                  <CloseCircleSolidIcon
                    defaultFill={styles.colors.system.error}
                    height={this.props.size === INPUT_SIZE.NORMAL ? 20 : 12}
                    width={this.props.size === INPUT_SIZE.NORMAL ? 20 : 12}
                  />
                );
            case INPUT_STATE_OF_DATA.SUCCESS:
                return (
                  <CheckCircleSolidIcon
                    defaultFill={styles.colors.system.success}
                    height={this.props.size === INPUT_SIZE.NORMAL ? 20 : 12}
                    width={this.props.size === INPUT_SIZE.NORMAL ? 20 : 12}
                  />
                );
            default:
                return null;
        }
    };

    showStateOfData = async ({ inputType, inputValue }) => {
        // input type = {email, username}
        if ([ INPUT_TYPE.EMAIL, INPUT_TYPE.USERNAME ].includes(inputType) && inputValue && inputValue !== '') {
            if (this.props.currentValue && inputValue === this.props.currentValue) {
                this.setState({ inputStateData: INPUT_STATE_OF_DATA.SUCCESS });
                return null;
            }
            if (inputType === INPUT_TYPE.EMAIL) {
                if (!isValidEmail(inputValue)) {
                    this.setState({ inputStateData: INPUT_STATE_OF_DATA.BAD });
                    return null;
                }

                if (this.props.isUniqueEmail) {
                    clearTimeout(timer);
                    timer = setTimeout(async () => {
                        const isEmailAvailable = await getIsUserEmailAvailable(inputValue.toLowerCase());
                        if (!isEmailAvailable) {
                            this.setState({ inputStateData: INPUT_STATE_OF_DATA.BAD });
                            return null;
                        }
                    }, 500);
                }

                this.setState({ inputStateData: INPUT_STATE_OF_DATA.SUCCESS });
                return null;
            }
            if (inputType === INPUT_TYPE.USERNAME) {
                const currentUsername = inputValue.replace(/[^-_0-9a-zA-Z]/g, '').toLowerCase();
                if (String(currentUsername).trim().length < 4 || String(currentUsername).trim().length >= 50) {
                    this.setState({ inputStateData: INPUT_STATE_OF_DATA.BAD });
                    return null;
                }

                clearTimeout(timer);
                timer = setTimeout(async () => {
                    const isUsernameAvailable = await getIsUsernameAvailable(currentUsername);
                    if (!isUsernameAvailable || isUsernameAvailable.error) {
                        this.setState({ inputStateData: INPUT_STATE_OF_DATA.BAD });
                        return null;
                    }
                }, 500);

                this.setState({ inputStateData: INPUT_STATE_OF_DATA.SUCCESS });
                return null;
            }
        }
        this.setState({ inputStateData: null });
        return null;
    };

    overlayRulesOnValue = (value) => {
        let newValue = (typeof value === 'object') ? value.target.value : value;
        this.showStateOfData({ inputType: this.props.type, inputValue: newValue.replace(/ /g, '') });
        switch (this.props.type) {
            case INPUT_TYPE.EMAIL:
                this.props.onChange(newValue.replace(/ /g, '').toLowerCase());
                break;
            case INPUT_TYPE.SEARCH_WITH_SELECT:
                if (newValue.length > 0) {
                    this.setState({ showBg: true });
                } else {
                    this.setState({ showBg: false });
                }
                this.props.onChange(newValue);
                break;
            case INPUT_TYPE.USERNAME:
                this.props.onChange(newValue.replace(/[^-_0-9a-zA-Z]/g, '').toLowerCase());
                break;
            case INPUT_TYPE.NUMBER:
                this.props.onChange(+(newValue.replace(/[^0-9]/gm, '')));
                break;
            case INPUT_TYPE.DATETIME:
                this.props.onChange(newValue);
                break;
            case INPUT_TYPE.ZIP:
                this.props.onChange(newValue.replace(/[^0-9-_ ]/gm, ''));
                break;
            default:
                this.props.onChange(value);
        }
    };

    renderLastNumberDay = (value) => {
        if (value) {
            if (value.startsWith('02')) {
                return '[0-9]';
            } else {
                if (value.startsWith('3',3)) {
                    let month = this.props.value.substring(0, 2);
                    switch (month) {
                        case '01':
                        case '03':
                        case '05':
                        case '07':
                        case '08':
                        case '10':
                        case '12':
                            return '[0-1]';
                        case '04':
                        case '06':
                        case '09':
                        case '11':
                            return '[0]';
                        default:
                            return '[0-1]';
                    }
                } else {
                    return '[0-9]';
                }
            }
        }
        return '_';
    }

    inputRender = (type) => {
        const formatCharsForDuration = {
            '5': '[0-5]',
            '9': '[0-9]'
        };
        const formatCharsForDateTime = {
            '1': '[0-1]',
            '2': this.props.value && this.props.value.startsWith('1') ? '[0-2]' : '[1-9]',
            '3': this.props.value && this.props.value.startsWith('02') ? '[0-2]' : '[0-3]',
            '4': this.renderLastNumberDay(this.props.value),
            '5': '[1-2]',
            '9': '[0-9]'
        };
        switch (type) {
            case INPUT_TYPE.PHONE:
                return (<InputPhoneNumber
                  {...this.props}
                  disabled={this.props.disabled}
                  type={'tel'}
                  placeholder={this.props.placeholder}
                  size={this.props.size}
                  value={this.props.value}
                  mask={'+1 (999) 999 9999'}
                  maxLength={this.props.maxLength || ''}
                  onChange={this.overlayRulesOnValue}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  data-testid={this.props.dataTestId || ''}
                />);
            case INPUT_TYPE.DURATION:
                return (<InputTime
                  {...this.props}
                  disabled={this.props.disabled}
                  type={'text'}
                  placeholder={this.props.placeholder}
                  size={this.props.size}
                  value={this.props.value}
                  mask={'99h 59m'}
                  maxLength={this.props.maxLength || ''}
                  onChange={this.overlayRulesOnValue}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  data-testid={this.props.dataTestId || ''}
                  formatChars={formatCharsForDuration}
                />);
            case INPUT_TYPE.DATETIME:
                return (<InputDate
                  {...this.props}
                  disabled={this.props.disabled}
                  type={'text'}
                  placeholder={this.props.placeholder}
                  size={this.props.size}
                  value={this.props.value}
                  mask={'12/34/5999'}
                  maxLength={this.props.maxLength || ''}
                  onChange={this.overlayRulesOnValue}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  data-testid={this.props.dataTestId || ''}
                  formatChars={formatCharsForDateTime}
                />);
            case INPUT_TYPE.ZIP:
                return (<InputZip
                  {...this.props}
                  disabled={this.props.disabled}
                  type={'text'}
                  placeholder={this.props.placeholder}
                  size={this.props.size}
                  value={this.props.value}
                  mask={'99999'}
                  maskChar={'_'}
                  formatChars={{ '9': '[0-9]' }}
                  maxLength={this.props.maxLength || ''}
                  onChange={this.overlayRulesOnValue}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  data-testid={this.props.dataTestId || ''}
                />);
            case INPUT_TYPE.EIN:
                return (<InputEIN
                  {...this.props}
                  disabled={this.props.disabled}
                  type={'text'}
                  placeholder={this.props.placeholder}
                  size={this.props.size}
                  value={this.props.value}
                  mask={'99-9999999'}
                  maskChar={'_'}
                  formatChars={{ '9': '[0-9]' }}
                  maxLength={this.props.maxLength || ''}
                  onChange={this.overlayRulesOnValue}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  data-testid={this.props.dataTestId || ''}
                />);
            case INPUT_TYPE.CVV:
                return (<InputCVV
                  {...this.props}
                  disabled={this.props.disabled}
                  type={'text'}
                  placeholder={this.props.placeholder}
                  size={this.props.size}
                  value={this.props.value}
                  mask={'999'}
                  maskChar={'_'}
                  formatChars={{ '9': '[0-9]' }}
                  maxLength={this.props.maxLength || ''}
                  onChange={this.overlayRulesOnValue}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  data-testid={this.props.dataTestId || ''}
                />);
            case INPUT_TYPE.DATE_CARD:
                let formatCharsDate = { '1': '[0-1]', '2': '[0-2]', '9': '[0-9]' };

                return (<InputDateCard
                  {...this.props}
                  disabled={this.props.disabled}
                  type={'text'}
                  placeholder={this.props.placeholder}
                  size={this.props.size}
                  value={this.props.value}
                  mask={'99/99'}
                  maskChar={'_'}
                  formatChars={formatCharsDate}
                  maxLength={this.props.maxLength || ''}
                  onChange={this.overlayRulesOnValue}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  data-testid={this.props.dataTestId || ''}
                />);
            case INPUT_TYPE.CARD:
                return (<InputCard
                  {...this.props}
                  disabled={this.props.disabled}
                  type={'text'}
                  placeholder={this.props.placeholder}
                  size={this.props.size}
                  value={this.props.value}
                  mask={'9999 9999 9999 9999'}
                  maskChar={'_'}
                  formatChars={{ '9': '[0-9]' }}
                  maxLength={this.props.maxLength || ''}
                  onChange={this.overlayRulesOnValue}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  data-testid={this.props.dataTestId || ''}
                />);
            case INPUT_TYPE.PASSWORD:
                return (<InputField
                  {...this.props}
                  disabled={this.props.disabled}
                  type={this.state.isShowPwd ? 'text' : 'password'}
                  placeholder={this.props.placeholder}
                  size={this.props.size}
                  value={this.props.value}
                  maxLength={this.props.maxLength || ''}
                  onChange={this.overlayRulesOnValue}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  data-testid={this.props.dataTestId || ''}
                />);
            case INPUT_TYPE.NUMBER:
                return (<InputNumber
                  {...this.props}
                  disabled={this.props.disabled}
                  type={'text'}
                  placeholder={this.props.placeholder}
                  size={this.props.size}
                  value={this.props.value}
                  onChange={this.overlayRulesOnValue}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  data-testid={this.props.dataTestId || ''}
                />);
            case INPUT_TYPE.TIME:
                const formatChars = {
                    '1': '[0-1]',
                    '2': this.props.value.startsWith('1') ? '[0-2]' : '[0-9]',
                    '3': '[0-5]',
                    '4': '[0-9]',
                    'p': '[APap]',
                    'm': '[Mm]'
                };
                return (<InputTime
                  {...this.props}
                  disabled={this.props.disabled}
                  type={'text'}
                  placeholder={this.props.placeholder}
                  size={this.props.size}
                  value={this.props.value}
                  mask={'12:34 pm'}
                  onChange={this.overlayRulesOnValue}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  data-testid={this.props.dataTestId || ''}
                  formatChars={formatChars}
                />);
            default:
                return (<InputField
                  {...this.props}
                  disabled={this.props.disabled}
                  autocomplete={this.props.autoComplete}
                  type={'text'}
                  placeholder={this.props.placeholder}
                  size={this.props.size}
                  value={this.props.value}
                  maxLength={this.props.maxLength || ''}
                  onChange={this.overlayRulesOnValue}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  data-testid={this.props.dataTestId || ''}
                />);
        }
    };

    showIconAtStart = (type) => {
        if (type === INPUT_TYPE.MONEY) {
            return (
              <IconPlace size={this.props.size}>
                  <MoneyIcon
                    defaultFill={styles.colors.silver}
                    hoverFill={styles.colors.dark}
                    height={20}
                    width={20}
                    dataTestId={'money-input-icon'}
                  />
              </IconPlace>
            );
        }
        if (type === INPUT_TYPE.SEARCH || type === INPUT_TYPE.SEARCH_WITH_SELECT) {
            return (
              <IconPlace size={this.props.size}>
                  <SearchIcon
                    defaultFill={styles.colors.silver}
                    hoverFill={styles.colors.dark}
                    height={20}
                    width={20}
                    dataTestId={'search-input-icon'}
                  />
              </IconPlace>
            );
        }
        if (type === INPUT_TYPE.TIME) {
            return (
              <IconPlace size={this.props.size}>
                  <TimeIcon
                    defaultFill={this.props.disabled ? styles.colors.grey : styles.colors.silver}
                    hoverFill={styles.colors.dark}
                    height={20}
                    width={20}
                    dataTestId={'time-input-icon'}
                  />
              </IconPlace>
            );
        }
        if (type === INPUT_TYPE.WEBSITE) {
            return (
              <WebProtocolPlace
                onClick={() => this.props.changeProtocol()}
                size={this.props.size}
              >
                  {this.props.webProtocol}
              </WebProtocolPlace>
            );
        }
        if (type === INPUT_TYPE.CARD) {
            return (
              <IconPlace size={this.props.size}>
                  <CardIcon
                    defaultFill={styles.colors.silver}
                    hoverFill={styles.colors.dark}
                    height={20}
                    width={20}
                    dataTestId={'search-input-icon'}
                  />
              </IconPlace>
            );
        }
    };

    showIconAtEnd = (type) => {
        if (type === INPUT_TYPE.PASSWORD) {
            return (
              <IconPlaceRight size={this.props.size} onClick={() => this.togglePassword()}>
                  <EyeIcon
                    defaultFill={this.state.isShowPwd ? styles.colors.primary : styles.colors.silver}
                    height={20}
                    width={20}
                    dataTestId={'password-input-icon'}
                  />
              </IconPlaceRight>
            );
        }
        if (type === INPUT_TYPE.MONEY && this.props.currency) {
            return (
              <IconPlaceRight size={this.props.size} onClick={() => null}>
                  <CurrencyItem>{this.props.currency}</CurrencyItem>
              </IconPlaceRight>
            );
        }
    };

    showSearchResult = (type) => {
        if (
          type === INPUT_TYPE.SEARCH_WITH_SELECT &&
          this.props.searchResult &&
          Array.isArray(this.props.searchResult) &&
          this.state.showBg
        ) {
            if (this.props.searchResult.length > 0) {
                return (
                  <SearchResult
                    maxHeightOptionsModal={this.state.maxHeightOptionsModal}
                    selectWidth={this.state.selectWidth}
                    offsetTop={this.state.offsetTop}
                    offsetLeft={this.state.offsetLeft}
                    offsetRight={this.state.offsetRight}
                  >
                      {this.props.searchResult.map((item) => {
                          let isSelected = findIndex(this.props.selectedItems, (s) => s.id === item.id) !== -1;
                          return (
                            <SearchItem data-item-id={item.id} onClick={() => this.props.setSelectedItem({ id: item.id })}>
                                <SearchItemText>{item.value}</SearchItemText>
                                <SearchItemButton>
                                    {isSelected ? <CheckCircleSolidIcon defaultFill={styles.colors.system.success}/> :
                                      <PlusCircleIcon defaultFill={styles.colors.medium}/>}
                                </SearchItemButton>
                            </SearchItem>
                          );
                      })}
                  </SearchResult>
                );
            } else {
                return (
                  <SearchResult
                    maxHeightOptionsModal={this.state.maxHeightOptionsModal}
                    selectWidth={this.state.selectWidth}
                    offsetTop={this.state.offsetTop}
                    offsetLeft={this.state.offsetLeft}
                    offsetRight={this.state.offsetRight}
                  >
                      <NoData desc={'No data.'} minHeight={200}/>
                  </SearchResult>
                );
            }
        }
    };

    render () {
        const { label, labelUnder, disabled, type, size, isNotValidData } = this.props;

        return (
          <InputContainer>
              <InputPlace
                size={size}
                disabled={disabled}
                isActive={this.state.isActive}
                isNotValidData={isNotValidData}
                ref={this.selectRef}
                onClick={() => this.triggerSelect()}
              >
                  {label ? <Label size={size}>{label}</Label> : null}
                  {this.showIconAtStart(type)}
                  {this.inputRender(type)}
                  {this.showInputStateIcon()}
                  {this.showIconAtEnd(type)}
                  {this.showSearchResult(type)}
                  {this.state.showBg && type === INPUT_TYPE.SEARCH_WITH_SELECT ?
                    <SelectBg onClick={() => this.setState({ showBg: false })}/> : null}
                  {(type === INPUT_TYPE.NUMBER) ? (
                    <NumberButtonPlace size={size} disabled={disabled}>
                        <NumberUpButton size={size} onClick={() => disabled ? null : this.increment()} disabled={disabled}>
                            <PlusIcon defaultFill={styles.colors.silver} hoverFill={styles.colors.silver} height={16} width={16}/>
                        </NumberUpButton>
                        <NumberDownButton size={size} onClick={() => disabled ? null : this.decrement()} disabled={disabled}>
                            <MinusIcon defaultFill={styles.colors.silver} hoverFill={styles.colors.silver} height={16} width={16}/>
                        </NumberDownButton>
                    </NumberButtonPlace>
                  ) : null}
              </InputPlace>
              {labelUnder ? <LabelUnder size={size}>{labelUnder}</LabelUnder> : null}
          </InputContainer>
        );
    }
}

export default Input;

Input.propTypes = {
    size: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    labelUnder: PropTypes.string,
    autoComplete: PropTypes.string,
    placeholder: PropTypes.string,
    webProtocol: PropTypes.string,
    changeProtocol: PropTypes.func,
    isUniqueEmail: PropTypes.bool,
    isNotValidData: PropTypes.bool
};
Input.defaultProps = {
    size: INPUT_SIZE.NORMAL,
    type: INPUT_TYPE.TEXT,
    label: null,
    value: '',
    labelUnder: null,
    autoComplete: null,
    placeholder: null,
    webProtocol: null,
    changeProtocol: () => null,
    isUniqueEmail: true,
    isNotValidData: false
};

const InputContainer = styled.div``;
const InputPlace = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: ${props => {
    switch (props.size) {
        case INPUT_SIZE.SMALL:
            return '36px';
        case INPUT_SIZE.NORMAL:
            return '48px';
        default:
            return '48px';
    }
}};
  background: ${props => props.disabled ? styles.colors.mostlyWhite : 'transparent'};
  border: 1px solid ${props => props.isActive ? styles.borders.input.color.active
  : (props.isNotValidData ? styles.colors.system.error : styles.borders.input.color.default)};
  border-radius: ${styles.borders.input.radius};
  cursor: ${props => props.disabled ? 'not-allowed' : 'default'};
  padding: ${props => {
    switch (props.size) {
        case INPUT_SIZE.SMALL:
            return '7px 12px';
        case INPUT_SIZE.NORMAL:
            return '13px 16px';
        default:
            return '13px 16px';
    }
}};

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 32px white inset !important;
  }

  transition: all .25s ease-out;

  &:hover {
    border-color: ${props => props.disabled ? styles.borders.input.color.default : styles.borders.input.color.active};
  }
`;
const Label = styled(H6)`
  display: inline-block;
  position: absolute;
  color: ${styles.colors.silver};
  font-family: ${styles.fonts.family.primaryBook};
  padding: 0 4px;
  background: white;
  bottom: ${props => {
    switch (props.size) {
        case INPUT_SIZE.SMALL:
            return '26px';
        case INPUT_SIZE.NORMAL:
            return '38px';
        default:
            return '38px';
    }
}};
  margin: 0;
  left: ${props => {
    switch (props.size) {
        case INPUT_SIZE.SMALL:
            return '8px';
        case INPUT_SIZE.NORMAL:
            return '12px';
        default:
            return '12px';
    }
}};
  max-width: ${props => {
    switch (props.size) {
        case INPUT_SIZE.SMALL:
            return 'calc(100% - 16px)';
        case INPUT_SIZE.NORMAL:
            return 'calc(100% - 24px)';
        default:
            return 'calc(100% - 24px)';
    }
}};
`;
const LabelUnder = styled(H6)`
  color: ${styles.colors.silver}88;
  font-family: ${styles.fonts.family.primaryBook};
  margin-top: 2px;
  margin-left: ${props => {
    switch (props.size) {
        case INPUT_SIZE.SMALL:
            return '12px';
        case INPUT_SIZE.NORMAL:
            return '16px';
        default:
            return '16px';
    }
}};
`;
const IconPlace = styled.div`
  display: block;
  position: relative;
  left: ${props => {
    switch (props.size) {
        case INPUT_SIZE.SMALL:
            return '-4px';
        case INPUT_SIZE.NORMAL:
            return '-4px';
        default:
            return '-4px';
    }
}};
  margin-right: ${props => {
    switch (props.size) {
        case INPUT_SIZE.SMALL:
            return '4px';
        case INPUT_SIZE.NORMAL:
            return '6px';
        default:
            return '6px';
    }
}};
`;
const IconPlaceRight = styled.div`
  display: block;
  position: relative;
  cursor: pointer;
  right: ${props => {
    switch (props.size) {
        case INPUT_SIZE.SMALL:
            return '-4px';
        case INPUT_SIZE.NORMAL:
            return '-4px';
        default:
            return '-4px';
    }
}};
  margin-left: ${props => {
    switch (props.size) {
        case INPUT_SIZE.SMALL:
            return '4px';
        case INPUT_SIZE.NORMAL:
            return '6px';
        default:
            return '6px';
    }
}};

  svg:hover g {
    fill: ${styles.colors.primary} !important;
  }
`;
const WebProtocolPlace = styled.div`
  color: ${styles.colors.silver};
  cursor: pointer;
  margin-right: 8px;
`;
const InputField = styled.input`
  font-family: ${styles.fonts.family.primaryBook};
  flex: 1;
  font-size: 14px;
  border: none;
  background: none;
  line-height: 20px;
  padding: 0;
  border-radius: 0;
  color: ${props => props.disabled ? styles.colors.silver : styles.colors.input.active};
  width: 100%;
  outline: none;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${styles.colors.input.placeholder};
  }

  &::-webkit-input-placeholder {
    color: ${styles.colors.input.placeholder};
  }

  &::-moz-placeholder {
    color: ${styles.colors.input.placeholder};
  }

  &:-ms-input-placeholder {
    color: ${styles.colors.input.placeholder};
  }

  &:-moz-placeholder {
    color: ${styles.colors.input.placeholder};
  }

  @media screen and (max-width: 991px) {
    font-size: 16px;
  }
`;
const InputPhoneNumber = styled(InputMask)`
  flex: 1;
  font-size: 14px;
  font-family: ${styles.fonts.family.primaryBook};
  line-height: 20px;
  padding: 0;
  border-radius: 0;
  cursor: ${props => props.disabled ? 'not-allowed' : 'default'};
  color: ${styles.colors.input.active};
  border: none;
  background: none;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${styles.colors.input.placeholder};
  }

  &::-webkit-input-placeholder {
    color: ${styles.colors.input.placeholder};
  }

  &::-moz-placeholder {
    color: ${styles.colors.input.placeholder};
  }

  &:-ms-input-placeholder {
    color: ${styles.colors.input.placeholder};
  }

  &:-moz-placeholder {
    color: ${styles.colors.input.placeholder};
  }

  @media screen and (max-width: 991px) {
    font-size: 16px;
  }
`;
const InputZip = styled(InputPhoneNumber)``;
const InputEIN = styled(InputPhoneNumber)``;
const InputCVV = styled(InputPhoneNumber)``;
const InputDateCard = styled(InputPhoneNumber)``;
const InputCard = styled(InputPhoneNumber)``;
const InputTime = styled(InputMask)`
  font-family: ${styles.fonts.family.primaryBook};
  flex: 1;
  font-size: 14px;
  line-height: 20px;
  padding: 0;
  border-radius: 0;
  cursor: ${props => props.disabled ? 'not-allowed' : 'default'};
  color: ${props => props.disabled ? styles.colors.input.placeholder : styles.colors.input.active};
  border: none;
  background: none;
  max-width: 100%;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${styles.colors.input.placeholder};
  }

  &::-webkit-input-placeholder {
    color: ${styles.colors.input.placeholder};
  }

  &::-moz-placeholder {
    color: ${styles.colors.input.placeholder};
  }

  &:-ms-input-placeholder {
    color: ${styles.colors.input.placeholder};
  }

  &:-moz-placeholder {
    color: ${styles.colors.input.placeholder};
  }

  @media screen and (max-width: 991px) {
    font-size: 16px;
  }
`;
const InputDate = styled(InputTime)``;
const InputNumber = styled(InputField)``;
const NumberButtonPlace = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${props => {
    switch (props.size) {
        case INPUT_SIZE.SMALL:
            return '36px';
        case INPUT_SIZE.NORMAL:
            return '48px';
        default:
            return '48px';
    }
}};
  width: 32px;
  border: none;
  position: relative;
  top: ${props => {
    switch (props.size) {
        case INPUT_SIZE.SMALL:
            return '-8px';
        case INPUT_SIZE.NORMAL:
            return '-14px';
        default:
            return '-14px';
    }
}};
  right: ${props => {
    switch (props.size) {
        case INPUT_SIZE.SMALL:
            return '-13px';
        case INPUT_SIZE.NORMAL:
            return '-17px';
        default:
            return '-17px';
    }
}};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;
const NumberUpButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${props => {
    switch (props.size) {
        case INPUT_SIZE.SMALL:
            return '17px';
        case INPUT_SIZE.NORMAL:
            return '23px';
        default:
            return '23px';
    }
}};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  border-bottom: 1px solid ${styles.borders.input.color.default};
  border-left: 1px solid ${styles.borders.input.color.default};

  &:hover {
    border-bottom: 1px solid ${props => props.disabled ? styles.borders.input.color.default : styles.borders.input.color.active};
    border-left: 1px solid ${props => props.disabled ? styles.borders.input.color.default : styles.borders.input.color.active};
  }
`;
const NumberDownButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => {
    switch (props.size) {
        case INPUT_SIZE.SMALL:
            return '18px';
        case INPUT_SIZE.NORMAL:
            return '24px';
        default:
            return '24px';
    }
}};
  width: 100%;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  border-left: 1px solid ${styles.borders.input.color.default};

  &:hover {
    border-top: 1px solid ${props => props.disabled ? styles.borders.input.color.default : styles.borders.input.color.active};
    border-left: 1px solid ${props => props.disabled ? styles.borders.input.color.default : styles.borders.input.color.active};
  }
`;
const CurrencyItem = styled(H6)`
  margin: 2px 0;
  text-transform: uppercase;
  color: ${styles.colors.silver};
`;
const SearchResult = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  background: white;
  overflow: hidden;
  overflow-y: auto;
  top: ${props => props.offsetTop}px;
  left: ${props => props.offsetLeft}px;
  width: ${props => props.selectWidth}px;
  max-height: ${props => props.maxHeightOptionsModal}px;
  border-radius: 4px;
  padding: 4px;
  border: 1px solid ${styles.borders.input.color.default};
  z-index: 9999;
  @media screen and (max-width: 768px) {
    position: absolute;
    left: -1px;
    bottom: 42px;
    top: auto;
    max-width: 100%;
  }
`;
const SearchItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  cursor: pointer;
  border-bottom: 1px solid ${styles.borders.input.color.default}44;

  &:hover {
    background: ${styles.colors.light};
  }

  &:last-of-type {
    border-bottom: none;
  }
`;
const SearchItemText = styled(P2)`
  margin: 0;
  color: ${styles.colors.medium};
`;
const SearchItemButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const SelectBg = styled.div`
  display: block;
  background: transparent;
  z-index: 1;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
`;
