import buildRequest from './actions';
import { HTTP_METHODS } from '../constants';

export const getDocumentDownloadLink = async (data) => {
  return await buildRequest({
    method: HTTP_METHODS.POST,
    url: `client/documents/downloader`,
    bodyData: data,
    isAuth: false,
  });
};
