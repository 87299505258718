import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const LocationSolidIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="m12 2.3c-3.9 0-7 3.1-7 7 0 4.2 4.4 9.9 6.2 12.1.4.5 1.1.5 1.5 0 1.8-2.2 6.2-7.9 6.2-12.1.1-3.9-3-7-6.9-7m0 9.5c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5 2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5" />
        </Icon>
    );

export default withTheme(LocationSolidIcon);
