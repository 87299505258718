import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { find } from 'lodash';
import { Row } from 'antd';
import CauseItem from '../CauseItem';
import styles from '../../constants/styles';
import { H4 } from '../Typography';
import { CloseIcon } from '../../components/Icons/General';
import { Button } from '../../components/Buttons';

class Causes extends Component {
  constructor (props) {
    super (props);
    this.state = {
      width: this.props.width || 0,
      height: this.props.height || 0
    };
    this.updateDimensions = this.updateDimensions.bind(this);
    this.renderSelectedCauses = this.renderSelectedCauses.bind(this);
    this.renderEditCauses = this.renderEditCauses.bind(this);
    this.renderPopupCauses = this.renderPopupCauses.bind(this);
  }

  updateDimensions = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };

  componentDidMount () {
    window.addEventListener('unload', this.updateDimensions);
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateDimensions);
  }

  renderSelectedCauses = (causes) => (
    <CausesView>
      {causes.map((causeItem) => (
        <CauseItem
          isActive={true}
          key={`cause_${causeItem.id}`}
          item={causeItem}
          isPrimary={causeItem.id === this.props.primaryCauseId}
        />
      ))}
    </CausesView>
  );

  renderEditCauses = (causes, selectedCauses, setCause) => (
    <CausesEdit>
      {causes.map((causeItem) => {
        return (
          <CauseItem
            key={`cause_${causeItem.id}`}
            item={causeItem}
            isActive={!!find(selectedCauses, (el) => el.id === causeItem.id)}
            setCause={setCause}
            type={'edit'}
            isPrimary={causeItem.id === this.props.primaryCauseId}
            setPrimary={(id) => this.props.setPrimary(id)}
            showPrimary={this.props.showPrimary}
          />
        );
      })}
    </CausesEdit>
  );

  renderPopupCauses = (causes, closePopup, selectedCauses, setCause) => (
    <div className={'causes-popup'}>
      <Wrapper />
      <Popup height={this.state.height} width={this.state.width}>
        <ContainerPopUp style={{'height': `${Number(this.state.height)-80}px`}}>
          <HeaderWrapper>
            <Header>
              <H4>Choose Cause(s)</H4>
              <Close onClick={() => closePopup(selectedCauses)}>
                <CloseIcon
                  defaultFill={styles.colors.medium}
                  height={18}
                  width={18}
                />
              </Close>
            </Header>
          </HeaderWrapper>
          <RowPB style={{'maxHeight': `${Number(this.state.height)-250}px`}}>
            <Container>
              {this.renderEditCauses(causes, selectedCauses, setCause)}
            </Container>
          </RowPB>
          <ActionWrapper>
            <Button
              size="lg"
              value="Apply"
              onClick={() => closePopup(selectedCauses)}
            />
          </ActionWrapper>
        </ContainerPopUp>
      </Popup>
    </div>
  );

  render () {
    const {
      causes,
      causesState,
      selectedCauses,
      closePopup,
      setCause
    } = this.props;

    switch (causesState) {
      case 'view':
        return this.renderSelectedCauses(selectedCauses);
      case 'edit':
        return this.renderEditCauses(causes, selectedCauses, setCause);
      case 'popup':
        return this.renderPopupCauses(causes, closePopup, selectedCauses, setCause);
      default:
        return 'Choose Cause(s)';
    }
  }
}
export default Causes;

Causes.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  showPrimary: PropTypes.bool,
  causes: PropTypes.arrayOf(PropTypes.shape()),
  selectedCauses: PropTypes.arrayOf(PropTypes.shape()),
  closePopup: PropTypes.func,
  causesState: PropTypes.oneOf(['popup', 'view', 'edit', 'form']),
  setCause: PropTypes.func,
  setPrimary: PropTypes.func,
  className: PropTypes.string,
  primaryCauseId: PropTypes.number,
  isForm: PropTypes.bool
};
Causes.defaultProps = {
  width: '',
  height: '',
  showPrimary: false,
  causesState: 'view',
  selectedCauses: [],
  causes: [],
  setCause: () => null,
  setPrimary: () => null,
  closePopup: () => null,
  isForm: false,
  primaryCauseId: 0,
  className: ''
};

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgba(225, 228, 232, 0.9);
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
`;
const Popup = styled.div`
  width: 800px;
  border-radius: 20px;
  background-color: white;
  margin: 0 auto 0;
  position: fixed;
  height: ${props => (Number(props.height) - 80)}px;
  max-height: 800px;
  top: 40px;
  left: calc((100% - 800px)/2);
  overflow: hidden;
  z-index: 111;
  @media screen and (max-width:865px){
    width: ${props => (Number(props.width) - 60)}px;
    max-height: min-content;
    margin: 0 30px;
    left: 0;
  }
`;
const ContainerPopUp = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
`;
const RowPB = styled(Row)`
  padding: 40px 0 20px 40px;
  max-height: 600px;
  overflow: scroll;
  @media screen and (max-width:660px){
    padding: 30px;
  }
`;
const HeaderWrapper = styled.div`
  border-bottom: 1px solid ${styles.colors.gray};
  position: relative;
  padding: 20px 40px 10px;
  z-index: 1;
  background: white;
  @media screen and (max-width:660px){
    padding: 20px 30px 10px;
  }
`;
const ActionWrapper = styled.div`
  border-top: 1px solid ${styles.colors.gray};
  position: relative;
  padding: 20px 40px 10px;
  z-index: 1;
  background: white;
  @media screen and (max-width:660px){
    padding: 20px 30px 10px;
  }
`;
const Container = styled.div`
  display: flex;
  font-size: 0;
  flex-wrap:wrap;
  margin: 0;
`;
const Item = styled.div`
  display: inline-block;
  margin: ${props => props.toList ? '20px 0 0 20px' : '10px'};
  width: ${props => props.toList ? 'auto' : '160px'};
  @media screen and (max-width:660px) {
    margin: 5px;
    width: 145px;
    height: 145px;
  }
  @media screen and (max-width:575px) {
    width: calc((100vw - 110px)/3 - 10px);
    height: calc((100vw - 110px)/3 - 10px);
  }
  @media screen and (max-width:425px) {
    width: calc((100vw - 110px)/2 - 10px);
    height: calc((100vw - 110px)/2 - 10px);
  }
`;
const Header = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
`;
const Close = styled.div`
  cursor: pointer;
`;

Item.propTypes = {
  list: PropTypes.bool
};

const CausesView = styled.div`
  display: flex;
  position: relative;
  font-size: 0;
  flex-wrap: wrap;
  margin: auto -12px;
`;
const CausesEdit = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -12px;
`;
