import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../constants/styles';

const Homless = (props) => (
    <Container isActive={props.isActive}>
      <svg
          width="20px"
          height="20px"
          id="homeless"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          enableBackground="new 0 0 24 24"
          xmlSpace="preserve"
      >
        <g id="mask">
          <path fill={props.isActive ? styles.colors.causes.homeless : styles.colors.silver} d="M17.5,7.5C17.5,4.5,15,2,12,2S6.5,4.5,6.5,7.5
	c0,1.8,0.9,3.4,2.3,4.5l0.9,0.7l-1.2,8.3c0,0.3,0,0.6,0.2,0.8C8.9,21.9,9.2,22,9.5,22h5c0.3,0,0.6-0.1,0.8-0.3
	c0.2-0.2,0.3-0.5,0.2-0.8l-1.2-8.3l0.9-0.7C16.6,10.9,17.5,9.2,17.5,7.5"/>
        </g>
      </svg>
    </Container>
);

export default Homless;

Homless.propTypes = {
  isActive: PropTypes.bool
};

Homless.defaultProps = {
  isActive: true
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: ${props => props.isActive ? `${styles.colors.causes.homeless}1f` : styles.colors.mostlyWhite};
    border-radius: 20px;
`;

