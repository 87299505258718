import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const TrashIcon = props => (
  <Icon {...props} viewBox={'0 0 24 24'}>
    <path d="M10.5616 5C10.1027 5 9.70272 5.3123 9.59143 5.75746L9.28079 7H14.7192L14.4086 5.75746C14.2973 5.3123 13.8973 5 13.4385 5H10.5616ZM7.65114 5.27239C7.98502 3.93689 9.18496 3 10.5616 3H13.4385C14.8151 3 16.015 3.93689 16.3489 5.27239L17.2808 9H6.71924L7.65114 5.27239Z" />
    <path d="M14.2397 19C15.2799 19 16.1464 18.2027 16.2328 17.1661L16.9133 9L7.08692 9L7.76742 17.1661C7.8538 18.2027 8.72034 19 9.76051 19L14.2397 19ZM18.2259 17.3322C18.0531 19.4054 16.3201 21 14.2397 21L9.76051 21C7.68016 21 5.94709 19.4053 5.77433 17.3322L4.91332 7L19.0869 7L18.2259 17.3322Z" />
    <path d="M3 8C3 7.44772 3.44772 7 4 7L20 7C20.5523 7 21 7.44771 21 8C21 8.55228 20.5523 9 20 9L4 9C3.44772 9 3 8.55228 3 8Z" />
    <path d="M13.3761 16.9923C12.8281 16.9238 12.4393 16.424 12.5078 15.876L13.0078 11.876C13.0763 11.328 13.5761 10.9392 14.1241 11.0077C14.6722 11.0762 15.0609 11.576 14.9924 12.1241L14.4924 16.1241C14.4239 16.6721 13.9241 17.0608 13.3761 16.9923Z" />
    <path d="M10.6239 16.9923C11.1719 16.9238 11.5607 16.424 11.4922 15.876L10.9922 11.876C10.9237 11.328 10.4239 10.9392 9.87586 11.0077C9.32784 11.0762 8.93911 11.576 9.00762 12.1241L9.50762 16.1241C9.57612 16.6721 10.0759 17.0608 10.6239 16.9923Z" />
  </Icon>
);

export default withTheme(TrashIcon);
