import React from 'react';
import styled from 'styled-components';
import { H4, P2 } from '../Typography';
import PropTypes from 'prop-types';
import styles from '../../constants/styles';

const OptionPanel = (props) => (
  <Container
    onClick={() => props.onClick()}
    data-testid={props.dataTestId}
    bgColor={props.bgColor}
    borderColor={props.borderColor}
  >
    {props.Icon ? (
      <IconPlace>
        {props.Icon}
      </IconPlace>
    ) : null}
    <Content>
      <Title>{props.title}</Title>
      <Desc>{props.desc}</Desc>
    </Content>
  </Container>
);
export default OptionPanel;

OptionPanel.propTypes = {
  Icon: PropTypes.element,
  onClick: PropTypes.func,
  title: PropTypes.string,
  desc: PropTypes.string,
  dataTestId: PropTypes.string,
  bgColor: PropTypes.string,
  borderColor: PropTypes.string

};
OptionPanel.defaultProps = {
  Icon: '',
  onClick: () => null,
  title: '',
  desc: '',
  dataTestId: '',
  bgColor: null,
  borderColor: null
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  width: 100%;
  padding: 12px 16px;
  margin: 8px 0;
  border-radius: 8px;
  border: 1px solid ${props => props.borderColor || styles.colors.light};
  background: ${props => props.bgColor || 'white'};
`;
const IconPlace = styled.div`
  margin-top: 2px;
  margin-right: 12px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const Title = styled(H4)`
  color: ${styles.colors.dark};
  font-family: ${styles.fonts.family.primaryMedium};
  margin-bottom: 2px;
`;
const Desc = styled(P2)`
  color: ${styles.colors.medium};
  font-family: ${styles.fonts.family.primaryBook};
  margin-bottom: 0;
`;
