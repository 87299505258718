import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const FundIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M12,6.8c0.4,0,0.7,0.3,0.7,0.7v1c0.2,0,0.4,0.1,0.6,0.2
	c0.2,0.1,0.3,0.2,0.4,0.2l0.1,0.1c0.9,0.7,0,1.9-0.8,1.2c-0.5-0.5-1.8-0.6-1.8,0.3c0,0.2,0.1,0.3,0.2,0.4c0.2,0.1,0.4,0.2,0.6,0.2
	c0.2,0,0.5,0.1,0.8,0.2c0.3,0.1,0.5,0.2,0.8,0.3c0.2,0.1,0.4,0.3,0.6,0.6c0.2,0.3,0.2,0.6,0.2,1.1c0,0.6-0.2,1.1-0.5,1.5
	c-0.4,0.4-0.8,0.6-1.3,0.7v1.1c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7v-1.1c-0.3-0.1-0.6-0.2-0.9-0.4
	c-0.3-0.2-0.4-0.3-0.5-0.4l-0.2-0.2c-0.6-0.8,0.4-1.8,1-1.1c0.1,0.1,0.2,0.2,0.2,0.3c0.3,0.3,0.6,0.3,0.9,0.3c0.6,0,0.9-0.2,0.9-0.7
	c0-0.2-0.1-0.3-0.3-0.4c-0.2-0.1-0.5-0.2-0.8-0.3c-0.3-0.1-0.6-0.2-0.9-0.3c-0.3-0.1-0.6-0.3-0.8-0.7c-0.2-0.3-0.3-0.7-0.3-1.2
	c0-0.5,0.2-0.9,0.4-1.3c0.3-0.4,0.7-0.6,1.2-0.7v-1C11.3,7.1,11.6,6.8,12,6.8z M20,6H4C3.4,6,3,6.4,3,7v10c0,0.6,0.4,1,1,1h16
	c0.6,0,1-0.4,1-1V7C21,6.4,20.6,6,20,6z M20,4c1.7,0,3,1.3,3,3v10c0,1.7-1.3,3-3,3H4c-1.7,0-3-1.3-3-3V7c0-1.7,1.3-3,3-3H20z"/>
        </Icon>
    );

export default withTheme(FundIcon);
