import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const CloseCircleSolidIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M14.7,13.3c0.4,0.4,0.4,1,0,1.4
			c0,0,0,0,0,0c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0L12,13.4l-1.3,1.3c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0c-0.4-0.4-0.4-1,0-1.4
			c0,0,0,0,0,0l1.3-1.3l-1.3-1.3c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l1.3,1.3l1.3-1.3c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4L13.4,12
			L14.7,13.3z"/>
        </Icon>
    );

export default withTheme(CloseCircleSolidIcon);
