import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../../../../constants/styles';
import { Modal } from '../../../../components/Modal';
import { ShortProfile } from '../../../../components/Elements';
import { InfoCircleIcon, OldPhoneIcon } from '../../../../components/Icons/General';
import styled from 'styled-components';
import { H4, H5, P2 } from '../../../../components/Typography';
import { formatPhoneNumber, getTypeNameByType } from '../../../../_helpers';
import {
  CANCELLATION_AND_NO_SHOW_POLICY,
  PUBLIC_ORGANIZATION_ROUTE
} from '../../../../constants/links';
import { Button } from '../../../../components/Buttons';
import { SaveIcon, AskIcon, CloseCircleIcon, RemoveIcon, EventIcon } from '../../../../components/Icons/General';
import { shiftSubscription } from '../../../../actions/eventsActions';
import { Shift } from '../../../../components/Events';
import Tip from '../../../../components/Tip';

const InstructionPopup = (props) => {
  const [loading, setData] = useState(false);
  const [isShowShifts, setShiftsPopup] = useState(false);
  const [isShowSelectShifts, setSelectShifts] = useState(false);
  const [selectedShifts, setShifts] = useState([]);

  const [counter, setCounter] = useState(0);


  useEffect(() => {
    if (props.singleEvent && props.singleEvent.shifts && props.singleEvent.shifts.length > 1) {
      let data = [];
      props.singleEvent.shifts.forEach((el) => {
        data.push(Object.assign({}, el));
      });
      setShifts(data);
    }
  }, [isShowSelectShifts]);

  const { singleEvent } = props;

  const renderResults = (results) => {
    if (!results) {
      return false;
    }
    let signedUpShifts = 0;
    let error = null;
    const isOneShifts = singleEvent.shifts.length === 1;

    results.forEach((result) => {
      if (result && result.data) {
        let status = '';
        if (result.data.status) {
          status = result.data.status;
        } else {
          status = result.data;
        }
        if (status === 'signed_up' || status === 'no_changes') {
          signedUpShifts++;
        }
      }
      if (result.error) {
        error = result.error;
      }
    });

    let data;
    if (signedUpShifts) {
      data = { message: isOneShifts ? 'You have saved this event to "Your Upcoming Events".' : `You have saved ${signedUpShifts} shift${signedUpShifts > 1 ? 's' : ''} for this event to "Your Upcoming Events".` };
    } else {
      if (!error) {
        data = { message: `You have removed this event from your POINT profile.` };
      } else {
        data = { error: error };
      }
    }

    props.updateEvent();
    props.setInfo({
      messageShift: data,
      resultsPopup: true
    });
  }

  const signetUp = () => {
    setData(true);
    const fetchData = async () => {
      let data = null;
      if (singleEvent.shifts && singleEvent.shifts.length > 1) {
        let shifts = [];
        selectedShifts.forEach((elem) => {
          if (elem.isAttending) {
            shifts.push({ id: elem.eventShiftId });
          }
        });
        data = { shifts: shifts };
      } else {
        data = { shifts: [{ id: singleEvent.shifts[0].eventShiftId }] };
      }
      let results;
      if (data) {
        results = await shiftSubscription({
          eventId: singleEvent.id,
          shifts: data.shifts
        });
      }

      setData(false);
      setSelectShifts(false);
      props.setInfo({ showInfoPopup: false, showPopupCancel: false });
      if (results && (typeof results === 'object') && results.error) {
        props.setInfo({ messageShift: { error: results.error }, resultsPopup: true });
      } else {
        renderResults(results);
      }
    }
    fetchData();
  }

  const cancelSignetUp = () => {
    setData(true);
    const fetchData = async () => {
      let shifts = [];
      let params = { eventId: singleEvent.id, shifts: shifts };
      const results = await shiftSubscription(params);
      setData(false);
      props.setInfo({ showInfoPopup: false, showPopupCancel: false });
      if (results && (typeof results === 'object') && results.error) {
        props.setInfo({ messageShift: { error: results.error }, resultsPopup: true });
      } else {
        renderResults(results);
      }
    }
    fetchData();
  }

  if (isShowShifts || isShowSelectShifts) {
    let shifts = isShowSelectShifts ? selectedShifts : singleEvent.shifts;
    let count = 0;
    let disabled = false;
    let isSame = false;
    if (isShowSelectShifts) {

      disabled = true;
      let sameShifts = [];
      selectedShifts.forEach((element) => {
        let indexElem = singleEvent.shifts.findIndex((elem) => elem.eventShiftId ? elem.eventShiftId === element.eventShiftId : elem.shiftId === element.shiftId);
        if (indexElem > -1 && element.isAttending !== singleEvent.shifts[indexElem].isAttending) {
          disabled = false;
        }
        if (element.isAttending) {
          sameShifts.push(element);
          count++;
        }
      });
      if (sameShifts.length) {
        sameShifts.forEach((itemSame, index) => {
          sameShifts.forEach((elSame, indexSame) => {
            if (elSame.eventShiftId ? elSame.eventShiftId !== itemSame.eventShiftId : elSame.shiftId !== itemSame.shiftId) {
              if ((itemSame.startTime >= elSame.startTime && itemSame.startTime < elSame.endTime)
                || (itemSame.endTime > elSame.startTime && itemSame.endTime <= elSame.endTime)) {
                isSame = true;
              }
            }
          });
        });
      }
    }

    const text = 'Clicking "Confirm" will update this opportunity in your POINT account.';

    return (
      <Modal
        width={styles.modal.size.large}
        visible={true}
        title={isShowSelectShifts ? 'Tell us which shifts you are signed up for.' : 'Shifts'}
        onClose={() => {
          if (isShowSelectShifts) {
            setSelectShifts(false);
            setCounter(counter + 1);
          } else {
            setShiftsPopup(false);
          }
        }}
        buttons={[
          {
            onPress: () => isShowSelectShifts ? signetUp() : setShiftsPopup(false),
            value: isShowSelectShifts ? 'Confirm' : 'Back',
            variant: isShowSelectShifts ? 'primary' : 'outline-light',
            dataTestId: isShowSelectShifts ? 'close-modal-select-shifts' : 'close-modal-list-shifts',
            isLoading: loading,
            disabled: disabled,
            hint: disabled ? 'Change or Select a shift to sign up.' : null
          },
        ]}
      >
        {isSame && singleEvent && singleEvent.isAttending ? (
          <FullBlock>
            <InfoCircleIcon width={20} height={20} defaultFill={styles.colors.system.alert}/>
            <Span>You signed up for multiple shifts at the same time.</Span>
          </FullBlock>
        ) : null}
        <ListShifts counter={counter}>
          {shifts.map((item, index) => {

            return (
              <Shift
                key={index}
                item={item}
                eventStartTime={singleEvent.startTime}
                eventEndTime={singleEvent.endTime}
                eventTimeZone={singleEvent.timezone || 'Etc/UTC'}
                counter={index + 1}
                isHideReserve={true}
                isSpots={true}
                size={'full'}
                type={'details'}
                isOpportunity={true}
                isShowSelectItem={isShowSelectShifts}
                onSelectShift={(value) => {
                  let indexItem = selectedShifts.findIndex((elem) => elem.eventShiftId ? elem.eventShiftId === item.eventShiftId : elem.shiftId === item.shiftId);
                  if (indexItem > -1) {
                    selectedShifts[indexItem].isAttending = value;
                    setShifts(selectedShifts);
                    setCounter(counter + 1);
                  }
                }}
              />
            );
          })}
        </ListShifts>
        {isShowSelectShifts && singleEvent && singleEvent.isAttending || isShowSelectShifts && count && singleEvent && !singleEvent.isAttending ? (
          <TipInfo
            text={count ? `You\'re telling POINT that you signed up for ${count} shift${count > 1 ? 's' : ''} with the nonprofit. ${text}` : `You\'re telling POINT that you have canceled with the nonprofit. ${text}`}/>
        ) : null}
      </Modal>
    );
  }

  const phone = singleEvent && singleEvent.organization && singleEvent.organization.phone ? singleEvent.organization.phone : null;

  let isMoreShifts = false;
  if (singleEvent && singleEvent.shifts && singleEvent.shifts.length > 1) {
    isMoreShifts = true;
  }

  if (singleEvent && singleEvent.isAttending) {
    if (props.showPopupCancel) {
      isMoreShifts = false;
    }
    return (
      <Modal
        width={styles.modal.size.medium}
        visible={props.showInfoPopup}
        title={isMoreShifts ? 'How to Change Your Shift(s)' : 'How to Cancel'}
        onClose={() => props.setInfo({ showInfoPopup: false, showPopupCancel: false })}
        buttons={null}
      >
        <Step key={1}>
          <Title>Step 1. Contact the nonprofit at {formatPhoneNumber(phone)}.</Title>
          <DataRow>
            <ShortProfile
              onClick={() => history.push(PUBLIC_ORGANIZATION_ROUTE.replace(':id', singleEvent.organization.id))}
              image={singleEvent.organization && singleEvent.organization.logo ? singleEvent.organization.logo : null}
              title={singleEvent.organization.organizationName || singleEvent.organization.name}
              subtitle={getTypeNameByType(singleEvent.organization.type)}
              dataTestId={'event-creator'}
            />
            <Buttons>
              <Button
                size="md"
                Icon={<OldPhoneIcon/>}
                value="Call"
                href={`tel:${phone}`}
                dataTestId="btn-phone-event"
              />
            </Buttons>
          </DataRow>
        </Step>
        <Step key={2}>
          <Title>{isMoreShifts ? 'Step 2. Tell them about the changes you want to make.' : 'Step 2. Tell them if you want to cancel.'}</Title>
          <DataRow>
            <Data>
              <BlockIcon>
                <CloseCircleIcon height={20} width={20} defaultFill={styles.colors.primary}/>
              </BlockIcon>
              <BlockText>
                <Text isMoreShifts={isMoreShifts}>{isMoreShifts ? 'If you wan to add, remove, or change a shift, make sure that you tell the nonprofit.' : 'If you can\'t make it, ensure that the nonprofit knows you can\'t attend.'} <br/>
                  Take a look at <Link href={CANCELLATION_AND_NO_SHOW_POLICY} target="_blank">POINT's cancelation policy</Link>.</Text>
              </BlockText>
            </Data>
            {isMoreShifts ? (
              <Button
                value="See Shifts"
                onClick={() => setShiftsPopup(true)}
                dataTestId={'button-see-shifts'}
              />
            ) : null}
          </DataRow>
        </Step>
        <Step key={3}>
          <Title>{isMoreShifts ? 'Step 3. Tell us about the change(s) you made to your shift(s).' : 'Step 3. Click "I Canceled" below.'}</Title>
          <DataRow>
            <Data>
              <BlockIcon>
                {isMoreShifts ? (
                  <EventIcon height={20} width={20} defaultFill={styles.colors.primary}/>
                ) : (
                  <RemoveIcon height={20} width={20} defaultFill={styles.colors.primary}/>
                )}
              </BlockIcon>
              <BlockText>
                <Text>{isMoreShifts ? 'This way POINT can send you reminder emails.' : 'This will remove the opportunity from your<br/> POINT account.'}</Text>
              </BlockText>
            </Data>
            <Button
              value={isMoreShifts ? 'Next' : 'I Canceled'}
              onClick={() => isMoreShifts ? setSelectShifts(true) : cancelSignetUp()}
              dataTestId={isMoreShifts ? 'button-next' : 'button-cancel'}
              isLoading={loading}
            />
          </DataRow>
        </Step>
      </Modal>
    )
  }

  return (
    <Modal
      width={styles.modal.size.medium}
      visible={props.showInfoPopup}
      title="How to Sign Up"
      onClose={() => props.setInfo({ showInfoPopup: false, showPopupCancel: false })}
      buttons={null}
    >
      <Step key={1}>
        <Title>Step 1. Contact the nonprofit at {formatPhoneNumber(phone)}.</Title>
        <DataRow>
          <ShortProfile
            onClick={() => history.push(PUBLIC_ORGANIZATION_ROUTE.replace(':id', singleEvent.organization.id))}
            image={singleEvent.organization && singleEvent.organization.logo ? singleEvent.organization.logo : null}
            title={singleEvent.organization.organizationName || singleEvent.organization.name}
            subtitle={getTypeNameByType(singleEvent.organization.type)}
            dataTestId={'event-creator'}
          />
          <Buttons>
            <Button
              size="md"
              Icon={<OldPhoneIcon/>}
              value="Call"
              href={`tel:${phone}`}
              dataTestId="btn-phone-event"
            />
          </Buttons>
        </DataRow>
      </Step>
      <Step key={2}>
        <Title>Step 2. Ask the nonprofit to sign you up.</Title>
        <DataRow>
          <Data>
            <BlockIcon>
              <AskIcon height={20} width={20} defaultFill={styles.colors.primary}/>
            </BlockIcon>
            <BlockText>
              <Text isMoreShifts={isMoreShifts}>Tell the nonprofit that you found out about their event on POINT.<br/> They will help you complete the sign up process over the phone.</Text>
            </BlockText>
          </Data>
          {isMoreShifts ? (
            <Button
              value="See Shifts"
              onClick={() => setShiftsPopup(true)}
              dataTestId={'button-see-shifts'}
            />
          ) : null}
        </DataRow>
      </Step>
      <Step key={3}>
        <Title>{isMoreShifts ? 'Step 3. Tell us which shift(s) you signed up for.' : 'Step 3. Tap the "I Signed Up" button.'}</Title>
        <DataRow>
          <Data>
            <BlockIcon>
              {isMoreShifts ? (
                <EventIcon height={20} width={20} defaultFill={styles.colors.primary}/>
              ) : (
                <SaveIcon height={20} width={20} defaultFill={styles.colors.primary}/>
              )}
            </BlockIcon>
            <BlockText>
              <Text>{isMoreShifts ? 'This way POINT can send you reminder emails.' : 'This will save the opportunity to your POINT account.'}</Text>
            </BlockText>
          </Data>
          <Button
            value={isMoreShifts ? 'Next' : 'I Signed Up'}
            onClick={() => isMoreShifts ? setSelectShifts(true) : signetUp()}
            dataTestId={isMoreShifts ? 'button-next' : 'button-signed-up'}
            isLoading={loading}
          />
        </DataRow>
      </Step>
    </Modal>
  );
}

export default InstructionPopup;

InstructionPopup.propTypes = {
  showInfoPopup: PropTypes.bool,
  showPopupCancel: PropTypes.bool,
  singleEvent: PropTypes.shape({}),
  setInfo: PropTypes.func.isRequired,
  updateEvent: PropTypes.func.isRequired
};

InstructionPopup.defaultProps = {
  singleEvent: null,
  showInfoPopup: false,
  showPopupCancel: false,
  reservations: []
};

const Step = styled.div`
  margin: 0 0 16px;
  position: relative;
  border-radius: 16px;
  border: 1px solid ${styles.borders.input.color.default};
  padding: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  &:last-of-type {
    margin: 0;
  }
`;
const Title = styled(H4)`
  margin: 0 0 24px;
`;
const Data = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
`;
const DataRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  .btn {
    margin-left: 24px;
  }
  @media screen and (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
    .btn {
      margin: 16px 0 0 48px;
    }
  }
`;
const Text = styled(P2)`
  margin: 0;
  color: ${styles.colors.medium};
  line-height: 18px;
  br {
    display: ${props => props.isMoreShifts ? 'none' : 'block'};
  }
  @media screen and (max-width: 991px) {
    br {
      display: none;
    }
  }
`;
const BlockText = styled.div`
  min-height: 36px;
  align-items: center;
  display: flex;
`;
const Link = styled.a`
  color: ${styles.colors.primary};
  &:hover {
    color: ${styles.colors.secondary};
  }
`;
const BlockIcon = styled.div`
  width: 36px;
  min-width: 36px;
  height: 36px;
  border-radius: 50%;
  margin: 0 12px 0 0;
  background: linear-gradient(0deg, ${styles.colors.primary}1a 0%, ${styles.colors.secondary}1a 100%);
  align-items: center;
  justify-content: center;
  display: flex;
`;
const ListShifts = styled.div`
  flex-direction: column;
  margin: -8px;
  display: flex;
  .shift-item {
    margin: 8px;
    width: calc(100% - 16px);
  }
`;
const TipInfo = styled(Tip)`
  margin-top: 24px;
  svg {
    margin-top: 2px;
  }
  h6 {
    font-size: 14px;
    line-height: 18px;
  }
`;
const FullBlock = styled.div`
  background: ${styles.colors.system.alert}0d;
  border: 1px solid ${styles.colors.system.alert}1a;
  border-radius: 8px;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 24px;
  svg {
    margin: 0 0 auto;
  }
`;
const Span = styled(H5)`
  color: ${styles.colors.medium};
  margin: 0 0 0 8px;
  font-family: ${styles.fonts.family.primaryMedium};
`;
const Buttons = styled.div`
  display: none;
  @media screen and (max-width: 1024px) {
    display: block;
  }
`;
