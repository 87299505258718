/* Routes */
export const EVENT_ROUTE = '/:id'
export const EVENTS_STATS_ROUTE = '/';
export const ADMINS_ROUTE = '/admins';
export const EVENTS_LIST_ROUTE = '/events';
export const EVENTS_ROUTE = '/events/:param';
export const EVENT_CO_HOST_ROUTE = '/cohost/event/:id';
export const EVENT_CO_HOST_EDIT_ROUTE = '/cohost/event/:id/edit';
export const EVENT_EDIT_ROUTE = '/event/:id/edit';
export const EVENT_COPY_ROUTE = '/event/:id/copy';
export const EVENTS_CREATE_ROUTE = '/event/create';
export const EVENT_CREATE_ROUTE = '/event/create/new';
export const EVENT_PREVIEW_ROUTE = '/preview/event/:id';
export const TEMPLATE_TO_EVENT_CREATE_ROUTE = '/event/create/new/:template_id';
export const EVENT_TEMPLATE_ROUTE = '/event/template/:id';
export const EVENT_TEMPLATE_CREATE_ROUTE = '/event/template/new';
export const EVENT_TEMPLATE_EDIT_ROUTE = '/event/template/:id/edit';
export const EVENT_TEMPLATES_ROUTE = '/event/templates';
export const PROFILE_ROUTE = '/profile';
export const PROFILE_EDIT_ROUTE = '/profile/edit';
export const VOLUNTEERS_ROUTE = '/volunteers';
export const EMPLOYEES_ROUTE = '/employees';
export const MEMBERS_ROUTE = '/members';
export const USER_ADD_ROUTE = '/users/add';
export const MANUAL_USER_EDIT_ROUTE = '/users/:uid/edit';
export const PENDING_HOURS = '/pending-hours';
export const STUDENTS_ROUTE = '/students';
export const CHECK_IN_VOLUNTEERS = '/event/:id/check-in-volunteers';
export const CHECK_IN_CO_HOST_VOLUNTEERS = '/cohost/event/:id/check-in-volunteers';
export const SUPPORT_ROUTE = '/support';
export const SETTINGS_ROUTE = '/settings';
export const NOTIFICATIONS_ROUTE = '/notifications';
export const ORGANIZATION_LIST_ROUTE = '/organization-list';
export const ORGANIZATION_PROFILE_ROUTE = '/organization-profile/:id';
export const USERS_LIST_ROUTE = '/users-list';
export const USER_PROFILE_ROUTE = '/users/:id';
export const USER_PROFILE_FOR_SYSTEM_ROUTE = '/system/users/:id';
export const USER_SETTINGS_ROUTE = '/settings';
export const GROUPS_ROUTE = '/groups';
export const GROUP_ROUTE = '/group/:id';
export const GROUP_EDIT_ROUTE = '/group/:id/edit';
export const GROUP_GOAL_ROUTE = '/goaldetails';
export const NOTES_ROUTE = '/group/:id/notes';
export const SUBGROUPS_ROUTE = '/group/:id/groups';
export const NOTE_CREATE_ROUTE = '/group/:groupId/note/create';
export const NOTE_ROUTE = '/group/:groupId/note/:id';
export const NOTE_EDIT_ROUTE = '/group/:groupId/note/:id/edit';
export const ORG_PLAN_ROUTE = '/plan';
export const CHARITY_CAUSE_ROUTE = '/nonprofits';
export const CHARITY_EVENTS_LIST_ROUTE = '/nonprofits/events';
export const CHARITY_SEARCH_EVENTS_LIST_ROUTE = '/nonprofits/events/:id';
export const CAUSES_ROUTE = '/causes';
export const VERIFY_EMAIL_ROUTE = '/email/verify/:token';
export const ACTIVITY_PAST_ROUTE = '/activity/past';
export const ACTIVITY_UPCOMING_ROUTE = '/activity/upcoming';
export const WEBRESOURCES_HOURS_ROUTE = '/webresources/hours';
export const SYSTEM_LIMITED_ACCESS = '/limited-access';
export const ORGANIZATION_ADD = '/organization/add';

export const EVENT_LINK = '/event/';

export const REGISTRY_LIST_ROUTE = '/registries';
export const REGISTRY_ROUTE = '/registries/:registryId';
export const REGISTRY_ADD_ROUTE = '/registries/add';
export const REGISTRY_EDIT_ROUTE = '/registries/:registryId/edit';
export const REGISTRY_VIEW_ROUTE = '/registries/:registryId/view';
export const REGISTRY_ARCHIVE_ROUTE = '/registries/:registryId/archive';
export const REGISTRY_GIFTED_ROUTE = '/registries/:registryId/gifted';
export const REGISTRY_FUNDED_ROUTE = '/registries/:registryId/funded';
export const REGISTRY_ITEM_EDIT_ROUTE = '/registries/:registryId/edit/:id';
export const REGISTRY_ACTIVITY_ROUTE = '/registries/activity';
export const REGISTRY_ITEM_EDIT_REDIRECT_ROUTE = '/redirect/:oid/:nid/:item_id';
export const REGISTRY_REDIRECT_ROUTE = '/redirect/:oid/:nid';

export const NETWORKS_ROUTE = '/networks';
export const NETWORKS_OPEN_ROUTE = '/networks/open';
export const NETWORK_ROUTE = '/networks/:networkId';
export const NETWORK_EDIT_ROUTE = '/networks/:networkId/edit';
export const NETWORK_STATS_ROUTE = '/networks/:networkId/stats';
export const NETWORK_STATS_ORGANIZATIONS_ROUTE = '/networks/:networkId/stats/organizations';
export const NETWORK_STATS_VOLUNTEERS_ROUTE = '/networks/:networkId/stats/volunteers';
export const NETWORK_STATS_EVENTS_ROUTE = '/networks/:networkId/stats/events';
export const NETWORK_JOIN_INVITE_ROUTE = '/networks/join-invite/:token';
export const SYSTEM_NETWORKS = '/system/networks';
export const SYSTEM_NETWORK = '/system/networks/:network_id';
export const DONATIONS_ROUTE = '/donations';
export const DONATIONS_ACTIVITY_ROUTE = '/donations/activity';
export const DONATIONS_COLLISION_ROUTE = '/donations/collision/:token';

export const HOME_LINK = '/';
export const PUBLIC_ORGANIZATION_ROUTE = process.env.REACT_APP_PUBLIC_ORG_ROUTE;
export const REGISTRY_LIST_HOST = process.env.REACT_APP_PUBLIC_REGISTRIES;
export const POINT_DASH_URL = process.env.REACT_APP_DASH_URL;
export const POINT_WEB_APP_URL = process.env.REACT_APP_WEB_APP_URL;
export const POINT_URL= 'https://pointapp.org';
export const POINT_CONTACT_URL= 'https://pointapp.org/contact-us';
export const POINT_TERMS = 'https://pointapp.org/terms-of-use';
export const COMMUNITY_GUIDELINES = 'https://pointapp.org/community-guidelines';
export const POINT_BLOG= 'https://pointapp.org/blog/';
export const POINT_PRIVACY_POLICY= 'https://pointapp.org/privacy-policy';
export const GLOBAL_GOALS = 'https://www.un.org/sustainabledevelopment/sustainable-development-goals/';
export const APPLE_STORE = 'https://itunes.apple.com/us/app/point-volunteer-in-columbus/id1173737657?mt=8&ign-mpt=uo%3D4';
export const GOOGLE_PLAY = 'https://play.google.com/store/apps/details?id=org.pointapp.point';
export const BOOK_A_DEMO = 'https://calendly.com/point-team/dashboard-demo?back=1';
export const CHROME_EXTENSION_LINK = 'https://chrome.google.com/webstore/detail/point/giepmbokoepimipfhdcpmphmgolmbfnf';
export const CANCELLATION_AND_NO_SHOW_POLICY = 'https://pointapp.org/point-cancellation-and-no-show-policy';
