import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const NonBinaryIcon = props => (
    <Icon {...props} viewBox={'0 0 24 24'}>
        <path d="M12,11c2.8,0,5,2.2,5,5s-2.2,5-5,5s-5-2.2-5-5S9.2,11,12,11 M12,9c-3.9,0-7,3.1-7,7s3.1,7,7,7s7-3.1,7-7
	S15.9,9,12,9L12,9z"/>
        <path d="M12,11c-0.6,0-1-0.4-1-1V1.9c0-0.6,0.4-1,1-1s1,0.4,1,1V10C13,10.6,12.6,11,12,11z"/>
        <path d="M15.5,8c-0.2,0-0.3,0-0.5-0.1L8,3.9C7.6,3.6,7.4,3,7.7,2.5C7.9,2,8.6,1.9,9,2.1l6.9,4
	c0.5,0.3,0.6,0.9,0.4,1.4C16.1,7.8,15.8,8,15.5,8z"/>
        <path d="M8.5,8C8.2,8,7.9,7.8,7.7,7.5C7.4,7,7.6,6.4,8,6.1l6.9-4c0.5-0.3,1.1-0.1,1.4,0.4C16.6,3,16.4,3.6,16,3.9
	L9,7.9C8.9,8,8.7,8,8.5,8z"/>
    </Icon>
);

export default withTheme(NonBinaryIcon);
