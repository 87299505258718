import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const DotsHorizontalIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M5,10c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S3.9,10,5,10z M19,10c1.1,0,2,0.9,2,2s-0.9,2-2,2
	s-2-0.9-2-2S17.9,10,19,10z M12,10c1.1,0,2,0.9,2,2s-0.9,2-2,2c-1.1,0-2-0.9-2-2S10.9,10,12,10z" />
        </Icon>
    );

export default withTheme(DotsHorizontalIcon);
