import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';

const WrapperAccount = (props) => (
    <Container>
        <Header />
        <Content>
            {props.children}
        </Content>
        <Footer />
    </Container>
);
export default WrapperAccount;

WrapperAccount.propTypes = {
    children: PropTypes.element
};

WrapperAccount.defaultProps = {
    children: ''
};

const Container = styled.div`
  height: 100%;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
  min-height: calc(100% - 63px);
`;

