import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const AlertIcon = props => (
    <Icon {...props} viewBox={'0 0 24 24'}>
        <path d="M22.6,16.8L14.9,4.1c-1-1.6-3.1-2.1-4.7-1c-0.4,0.3-0.8,0.6-1,1L1.4,16.8c-0.6,0.9-0.6,2.1,0,3
	c0.6,1.1,1.7,1.7,2.9,1.7h15.3c1.2,0,2.3-0.6,2.9-1.7C23.1,18.9,23.1,17.7,22.6,16.8z M12,17.5c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1
	s1,0.4,1,1C13,17.1,12.5,17.5,12,17.5z M13,13.5c0,0.6-0.4,1-1,1s-1-0.4-1-1v-4c0-0.6,0.4-1,1-1s1,0.4,1,1V13.5z"/>
    </Icon>
);

export default withTheme(AlertIcon);
