import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const ChatIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M12.5,4c-1.2,0-2.3,0.3-3.3,0.8C6.6,6.1,5,8.6,5,11.5c0,1.2,0.3,2.3,0.8,3.3c0.1,0.2,0.1,0.5,0.1,0.8
	l-1.3,3.8l3.8-1.3c0.3-0.1,0.5-0.1,0.8,0.1c1,0.5,2.2,0.8,3.3,0.8c2.9,0,5.4-1.6,6.7-4.1c0.5-1,0.8-2.2,0.8-3.3v0V11
	c-0.2-3.8-3.2-6.8-7.1-7L12.5,4L12.5,4z M3,22c-0.3,0-0.5-0.1-0.7-0.3c-0.3-0.3-0.4-0.7-0.2-1l1.8-5.3C3.3,14.2,3,12.8,3,11.5
	C3,7.9,5,4.6,8.3,3c1.3-0.7,2.8-1,4.2-1h0H13c4.9,0.3,8.7,4.1,9,8.9l0,0.6c0,1.5-0.3,2.9-1,4.3C19.4,19,16.1,21,12.5,21
	c-1.3,0-2.7-0.3-3.9-0.8l-5.3,1.8C3.2,22,3.1,22,3,22L3,22z"/>
        </Icon>
    );

export default withTheme(ChatIcon);
