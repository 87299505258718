import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const OrganizationBuildingIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M18.3,22H5.7c-1.5,0-2.8-1.3-2.8-2.8v-8.9c0-0.9,0.4-1.8,1.2-2.4l6.1-4.7c1.1-0.8,2.6-0.8,3.7,0l6.1,4.7
		c0.7,0.6,1.2,1.4,1.2,2.4v8.9C21.1,20.7,19.8,22,18.3,22z M12,4.5c-0.2,0-0.4,0.1-0.6,0.2L5.3,9.5l0,0C5,9.7,4.9,10,4.9,10.3v8.9
		c0,0.4,0.4,0.8,0.8,0.8h12.6c0.4,0,0.8-0.4,0.8-0.8v-8.9c0-0.3-0.1-0.6-0.4-0.8l-6.1-4.7C12.4,4.6,12.2,4.5,12,4.5z M4.7,8.7
		L4.7,8.7L4.7,8.7z"/>
            <path d="M16,18H8c-0.6,0-1-0.4-1-1s0.4-1,1-1h8c0.6,0,1,0.4,1,1S16.6,18,16,18z"/>
        </Icon>
    );

export default withTheme(OrganizationBuildingIcon);
