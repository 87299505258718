import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const VirtualIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M20,2H4C2.3,2,1,3.3,1,5v10c0,1.7,1.3,3,3,3h7v2H8c-0.6,0-1,0.4-1,1s0.4,1,1,1h8c0.6,0,1-0.4,1-1s-0.4-1-1-1h-3v-2h7
	c1.7,0,3-1.3,3-3V5C23,3.3,21.7,2,20,2z M21,15c0,0.6-0.4,1-1,1H4c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1h16c0.6,0,1,0.4,1,1V15z"/>
        </Icon>
    );

export default withTheme(VirtualIcon);
