import React from 'react';
import { withTheme } from 'styled-components';
import Icon from './components/Icon';

const RegistryActivityMenu = props => (
        <Icon {...props}>
            <path d="M4,13c-0.6,0-1-0.4-1-1V7c0-1.7,1.3-3,3-3h1c0-1.1,0.9-2,2-2h6c1.1,0,2,0.9,2,2v1.9c0,1.1-0.9,2-2,2H9C7.9,7.9,7,7.1,7,6H6C5.4,6,5,6.4,5,7v5C5,12.5,4.6,13,4,13z M9,5v0.9h6V4H9V5z"/>
            <path d="M18,22H6c-0.6,0-1-0.4-1-1s0.4-1,1-1h12c0.6,0,1-0.4,1-1V7c0-0.6-0.4-1-1-1h-2c-0.6,0-1-0.4-1-1s0.4-1,1-1h2c1.7,0,3,1.3,3,3v12C21,20.7,19.7,22,18,22z"/>
            <path d="M4,18.5c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l4.1-4.1c0.4-0.4,1-0.4,1.4,0l2,2l4.5-4.5c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-5.2,5.2c-0.4,0.4-1,0.4-1.4,0l-2-2l-3.4,3.4C4.5,18.4,4.3,18.5,4,18.5z"/>
            <path d="M16,16c-0.6,0-1-0.4-1-1v-3h-3c-0.6,0-1-0.4-1-1s0.4-1,1-1h4c0.6,0,1,0.4,1,1v4C17,15.6,16.6,16,16,16z"/>
        </Icon>
    );

export default withTheme(RegistryActivityMenu);
