import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../constants/styles';
import { H6 } from '../../Typography';
import { Button } from '../../Buttons';
import { bytesToSize } from '../../../_helpers';
import { EditIcon, RemoveIcon } from '../../Icons/General';
import { Modal } from '../../Modal';
import { ImageCrop } from './components';
import ErrorMessage from '../../ErrorMessage';

import defaultPicture from '../../../assets/images/svg/picture-default.svg';

class ImageUploader extends Component {
    constructor (props) {
        super(props);
        this.state = {
            uploadImage: false,
            imgSrc: null,
            cropSrc: null,
            imgTitle: null,
            imgSize: 0,
            isEditImg: false
        };
        this.url2blob = this.url2blob.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.selectImage = this.selectImage.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.updateImage = this.updateImage.bind(this);
        this.updateImageData = this.updateImageData.bind(this);
        this.fileInput = React.createRef();
    }

    url2blob = async (url) => {
        try {
            const data = await fetch(url);
            return await data.blob();
        } catch (err) {
            return null;
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
          nextProps.image &&
          nextProps.loadImage &&
          nextProps.image !== prevState.imgSrc &&
          prevState.cropSrc === null
        ) {
            return {
                imgSrc: nextProps.image,
                uploadImage: true
            };
        } else {
            return null;
        }
    }

    updateImageData = async () => {
        if (this.state.uploadImage) {
            const loadedImg = await this.url2blob(this.state.imgSrc);

            this.setState({
                imgSrc: URL.createObjectURL(loadedImg),
                cropSrc: loadedImg,
                imgTitle: loadedImg.name || 'manual_avatar.jpeg',
                imgSize: loadedImg.size,
                uploadImage: false
            });
            if (this.props.updateState) {
                this.props.updateState({ loadImage: false });
            }
        }
    };

    async componentDidMount() {
        await this.updateImageData();
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        await this.updateImageData();
    }

    selectImage = () => {
        this.fileInput.current.click();
    };

    removeImage = () => {
        this.setState({
            imgSrc: null,
            cropSrc: null,
            imgTitle: null,
            imgSize: 0
        });
        this.props.onChange('');
        if (this.props.updateState) {
            this.props.updateState({ loadImage: false });
        }
    };

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.fileInput.current.files && this.fileInput.current.files.length > 0) {
            this.setState({
                imgSrc: URL.createObjectURL(this.fileInput.current.files[0]),
                cropSrc: this.fileInput.current.files[0],
                imgTitle: this.fileInput.current.files[0].name,
                imgSize: this.fileInput.current.files[0].size,
                isEditImg: true
            });
        }
    }

    updateImage = async (prom) => {
        const cropImg = await prom;
        this.setState({
            cropSrcNew: cropImg,
            imgSizeNew: cropImg.size,
            imgTitleNew: cropImg.name
        });
    };

    saveImage = async () => {
        const { cropSrcNew, imgSizeNew, imgTitleNew } = this.state;

        this.props.onChange(cropSrcNew, cropSrcNew ? URL.createObjectURL(cropSrcNew) : this.state.imgSrc);
        this.setState({
            cropSrc: cropSrcNew,
            imgSize: imgSizeNew,
            imgTitle: imgTitleNew,
            isEditImg: false
        });
    }

    render () {
        return (
            <Wrapper>
                <ImageUploaderContainer>
                    <Modal
                        title={'Image Editor'}
                        visible={this.state.isEditImg}
                        onClose={() => this.setState({ isEditImg: false })}
                        buttons={[
                            {
                                onPress: () => this.saveImage(),
                                value: 'Save',
                                variant: 'primary',
                            }
                        ]}
                    >
                        <CropPlace>
                            <ImageCrop
                                src={this.state.imgSrc}
                                crop={{
                                    aspect: this.props.cropAspect || 1,
                                    unit: '%',
                                    width: 100,
                                }}
                                updateImage={e => this.updateImage(e)}
                            />
                        </CropPlace>
                    </Modal>
                    <ImageBlock cropAspect={this.props.cropAspect || 1}>
                        {this.state.cropSrc ? (
                            <LoadedImage src={URL.createObjectURL(this.state.cropSrc)} alt={''} cropAspect={this.props.cropAspect || 1} />
                        ) : (
                            <ImagePlaceholder>
                                <ImagePlaceholderBorder cropAspect={this.props.cropAspect || 1} />
                                <ImgDefault src={defaultPicture} onClick={() => null} alt={''} />
                            </ImagePlaceholder>
                        )}
                    </ImageBlock>
                    <ActionBlock>
                        <HideBlock>
                            <input
                                type={'file'}
                                ref={this.fileInput}
                                accept={'image/png, image/jpeg'}
                                onChange={this.handleSubmit}
                                data-testid={this.props.dataTestId || 'input-file'}
                            />
                        </HideBlock>
                        <Desc>{this.state.imgTitle || 'Browse to upload.'}</Desc>
                        <SubDesc>{this.state.imgSize ? bytesToSize(this.state.imgSize) : `Maximum file size ${bytesToSize(this.props.maxFileSize)}`}</SubDesc>
                        {this.state.cropSrc ? (
                            <Buttons>
                                <Button
                                    variant={'outline-light'}
                                    Icon={<EditIcon />}
                                    onClick={() => this.setState({ isEditImg: true })}
                                    dataTestId={'btn-edit-photo'}
                                />
                                <Button
                                    variant={'outline-light'}
                                    Icon={<RemoveIcon />}
                                    onClick={() => this.removeImage()}
                                    dataTestId={'btn-remove-photo'}
                                />
                            </Buttons>
                        ) : (
                            <Buttons>
                                <Button
                                    variant={'outline-light'}
                                    value={'Upload Photo'}
                                    onClick={() => this.selectImage()}
                                    dataTestId={'btn-upload-photo'}
                                />
                            </Buttons>
                        )}
                    </ActionBlock>
                </ImageUploaderContainer>
                {this.state.imgSize > this.props.maxFileSize ? (
                    <ErrorMessage text={'Oops! Selected image size exceeds allowed 5 MB. Please choose a smaller image. Keep in mind that you can only use JPG/JPEG and PNG formats.'} dataTestId={'image-error'} />
                ) : null}
            </Wrapper>
        );
    }
}

export default ImageUploader;

ImageUploader.propTypes = {
    cropAspect: PropTypes.number,
    buttonText: PropTypes.string,
    onChange: PropTypes.func,
    imgExtension: PropTypes.array,
    maxFileSize: PropTypes.number,
    dataTestId: PropTypes.string
};

ImageUploader.defaultProps = {
    cropAspect: 16 / 16,
    buttonText: 'Upload',
    onChange: () => null,
    imgExtension: ['.jpg', '.png'],
    maxFileSize: 5242880,
    dataTestId: ''
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const ImageUploaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    @media screen and (max-width: 386px) {
        flex-wrap: wrap;
        flex-direction: column;
    }
`;
const ImageBlock = styled.div`
    width: ${props => props.cropAspect === 1 ? 96 : 176}px;
    height: ${props => props.cropAspect === 1 ? 96 : 99}px;
    max-width: ${props => props.cropAspect === 1 ? 96 : 176}px;
    max-height: ${props => props.cropAspect === 1 ? 96 : 99}px;;
    margin-right: 24px;
    margin-bottom: 0;
    @media screen and (max-width: 386px) {
        margin-bottom: 16px;
    }
`;
const ImagePlaceholder = styled.div`
    width: inherit;
    height: inherit;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
const ImagePlaceholderBorder = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: ${props => props.cropAspect === 1 ? '50%' : '12px'};
    border: 2px dashed ${styles.colors.grey};
    transition: border-color 0.5s ease;
    @keyframes circle {
        from { transform:rotate(0deg); }
        to { transform:rotate(360deg); }
    }
    &:hover {
        border-color: ${styles.colors.silver};
        animation: ${props => props.cropAspect === 1 ? 'circle 5s linear infinite' : 'none'};
    }
`;
const ImgDefault = styled.img`
    width: 36px;
    height: 36px;
    border-radius: 4px;
`;
const LoadedImage = styled.img`
    width: inherit;
    height: inherit;
    border-radius: ${props => props.cropAspect === 1 ? '50%' : '12px'};
    object-fit: contain;
`;
const ActionBlock = styled.div``;
const HideBlock = styled.div`
    display: none;
    visibility: hidden;
`;
const Desc = styled(H6)`
    font-family: ${styles.fonts.family.primaryBook};
    color: ${styles.colors.medium};
    margin: 0;
    word-break: break-word;
`;
const SubDesc = styled(H6)`
    font-family: ${styles.fonts.family.primaryBook};
    color: ${styles.colors.silver};
    margin: 0;
    word-break: break-word;
`;
const Buttons = styled.div`
    margin-top: 8px;
    .btn {
        margin-right: 8px;
    }
`;
const CropPlace = styled.div`
    width: inherit;
    height: inherit;
    img {
        max-width: 100%;
    }
`
