import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const PointLogoIcon = props => (
        <Icon {...props} viewBox={'0 0 36 36'}>
            <path d="M18,0c-2.9,0-5.6,0.7-8,1.9L3,0l1.1,6.6C1.5,9.7,0,13.7,0,18c0,9.9,8.1,18,18,18c9.9,0,18-8.1,18-18
	C36,8.1,27.9,0,18,0z M18,30c-6.6,0-12-5.4-12-12c0-6.6,5.4-12,12-12c6.6,0,12,5.4,12,12C30,24.6,24.6,30,18,30z"/>
        </Icon>
    );

export default withTheme(PointLogoIcon);
