import React from 'react';
import { isNil } from 'lodash';
import { withTheme } from 'styled-components';
import './style.scss';
import PropTypes from 'prop-types';

const variantArray = ['internal', 'internalstatus', 'external', 'rejected', 'dismissed', 'connected', 'dismiss', 'noshow', 'virtual', 'invitesent', 'invitepending', 'draft', 'cohost', 'started', 'pending', 'confirmed', 'skipped', 'visited', 'rejected', 'beta', 'declined', 'going', 'network-owner', 'network-your-organization', 'network-declined', 'network-pending', 'network-invitation', 'registry-funded', 'registry-gifted', 'registry-high', 'registry-medium', 'registry-standard', 'manual'];
const sizeArray = ['sm', 'md', 'lg'];

const Badge = props => {
    const {
        className = '',
        style = {},
        variant = 'internal',
        value = null,
        Icon = null,
        size = 'md',
        dataTestId = ''
    } = props;

    const cClassType = (variantArray.indexOf(variant) > -1) ? `badge-${variant}` : 'badge-internal';
    const cClassSize = (sizeArray.indexOf(size) > -1) ? `badge-${size}` : 'badge-md';
    const isIcon = !isNil(Icon);
    const classNames = `badge ${cClassType} ${className} ${cClassSize}`;
    const iconContent = isIcon ? (<span className="badge-icon">{Icon}</span>) : '';
    const content = (value) ? (<span className="badge-content">{value}</span>) : '';

    return (
        <span
            data-testid={dataTestId}
            style={style}
            className={classNames}
        >
            {iconContent}
            {content}
        </span>
    );
};

export default withTheme(Badge);

Badge.propTypes = {
    className: PropTypes.string,
    style: PropTypes.shape(),
    variant: PropTypes.string,
    value: PropTypes.string,
    Icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    size: PropTypes.string,
    dataTestId: PropTypes.string
};

Badge.defaultProps = {
    className: '',
    style: {},
    variant: 'internal',
    value: null,
    Icon: null,
    size: 'md',
    dataTestId: ''
};
