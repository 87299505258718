import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const NoDataIcon = props => (
        <Icon {...props} viewBox={'0 0 48 40'}>
            <path d="M31,30.8H17c-0.8,0-1.5-0.7-1.5-1.5v-5.5H3c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h14
	c0.8,0,1.5,0.7,1.5,1.5v5.5h11v-5.5c0-0.8,0.7-1.5,1.5-1.5h14c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5H32.5v5.5
	C32.5,30.2,31.8,30.8,31,30.8z"/>
            <path d="M41,40H7c-3,0-5.5-2.5-5.5-5.5V22.9c0-0.4,0-0.8,0.1-1.2L5.5,4.3C6.1,1.8,8.3,0,10.9,0h26.2
	c2.6,0,4.8,1.8,5.4,4.3l3.9,17.4c0.1,0.4,0.1,0.8,0.1,1.2v11.6C46.5,37.6,44,40,41,40z M10.9,3C9.7,3,8.7,3.8,8.5,5L4.6,22.4l0,0
	c0,0.2-0.1,0.4-0.1,0.5v11.6C4.5,35.9,5.6,37,7,37h34c1.4,0,2.5-1.1,2.5-2.5V22.9c0-0.2,0-0.4-0.1-0.5L39.6,5c-0.3-1.1-1.3-2-2.4-2
	H10.9z M3.1,22.1L3.1,22.1L3.1,22.1z"/>
        </Icon>
    );

export default withTheme(NoDataIcon);
