import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const ArrowDownIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M12,16c-0.3,0-0.5-0.1-0.7-0.3l-6-6c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l5.3,5.3l5.3-5.3c0.4-0.4,1-0.4,1.4,0
	s0.4,1,0,1.4l-6,6C12.5,15.9,12.3,16,12,16z"/>
        </Icon>
    );

export default withTheme(ArrowDownIcon);
