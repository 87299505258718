import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import styles from '../../../../constants/styles';
import AddToCalendarHOC from 'react-add-to-calendar-hoc';
import { ListItemWithIcon, ProgressDonut, ShortProfile } from '../../../../components/Elements';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  BackgroundCheckIcon,
  CalendarIcon, InfoCircleIcon,
  LockIcon,
  PointLogoIcon,
  ProfileIcon,
  SignedFormIcon,
  TrainingIcon,
  UnlockEmptyIcon,
  OldPhoneIcon,
  EditIcon,
  MinimumAgeIcon
} from '../../../../components/Icons/General';
import { POINT_URL, PUBLIC_ORGANIZATION_ROUTE } from '../../../../constants/links';
import { H2, H3, H4, H5, H6 } from '../../../../components/Typography';
import { formatPhoneNumber, getDataTime, getDataTimeForShift, getGenderForShow, getTypeNameByType } from '../../../../_helpers';
import { Button } from '../../../../components/Buttons';
import { Shift } from '../../../../components/Events/Shift';
import { SocialShareable } from '../../../../components/SocialShareable';
import { Modal } from '../../../../components/Modal';
import { EVENT_SOURCES } from '@pointapp/constants';

import logo from '../../../../assets/images/svg/point-logo.svg';
import congratulations from '../../../../assets/images/svg/congratulations-green.svg';

const moment = require('moment-timezone');
const EventsServerURL = process.env.REACT_APP_EVENTS_SERVER;

class RightBar extends Component {
  constructor (props) {
    super(props);
    this.state = {
      loading: false,
      showShift: true
    };
  }

  logOut = () => {
    this.setState({ loading: true });
    localStorage.removeItem('POINT.profileData');
    this.props.logoutUser();
    this.setState({ loading: false });
  };

  showShifts = (shifts, timezone) => {
    const { singleEvent, selectShift, reservations } = this.props;

    return shifts.map((item, index) => {
      let isReserved = false;
      let indexShift = singleEvent.shifts.findIndex(e => e.eventShiftId === item.eventShiftId);
      let isAttending = singleEvent.shifts[indexShift].isAttending;
      if (reservations.findIndex(el => el.eventShiftId === item.eventShiftId) > -1) {
        isReserved = true;
      }
      return (
        <Shift
          key={index}
          item={item}
          eventStartTime={singleEvent.startTime}
          eventEndTime={singleEvent.endTime}
          eventTimeZone={timezone}
          counter={index + 1}
          isSpots={true}
          size={'full'}
          onClick={(id, isSelected) => selectShift(id, isSelected)}
          isSelected={item.isAttending}
          isReserved={isReserved}
          isAttending={isAttending}
          claimSpot={() => this.props.claimSpot(item)}
        />
      );
    });
  };

  renderAddToCalendar = (item, index, isList) => {
    const AddToCalendarModal = AddToCalendarHOC(Button, Modal);
    const { singleEvent } = this.props;

    const startDatetime = moment(item.startTime).tz(singleEvent.timezone).format('YYYYMMDDTHHmmss');
    const endDatetime = moment(item.endTime).tz(singleEvent.timezone).format('YYYYMMDDTHHmmss');
    const durationEvent = moment.duration(moment(item.endTime, 'x').diff(moment(item.startTime, 'x'))).asHours();
    const description = item.description ? item.description : singleEvent.description;

    let titleShift = item.name || `Shift ${index || 1}`;

    let title = singleEvent.title;
    if (singleEvent.shifts && singleEvent.shifts.length > 1) {
      title = `${singleEvent.title} (${titleShift})`;
    }
    if (singleEvent.isVirtual) {
      title = `${title} - Virtual`;
    }
    let content = description ? `${description.split('. ', 1)[0]}.` : '';
    const event = {
      description: content.replace(/(<([^>]+)>)/gi, ''),
      duration: durationEvent+'&et='+endDatetime,
      endDatetime: endDatetime,
      location: singleEvent.address,
      startDatetime: startDatetime,
      title: title,
      timezone: singleEvent.timezone
    };

    const titleBtn = !isList ? 'Add to Calendar' : null;

    return (
      <AddToCalendarModal
        className={'outline-light'}
        key={index}
        linkProps={{
          className: 'link-style',
          icon: true
        }}
        buttonText={titleBtn}
        buttonProps={{
          Icon: <CalendarIcon/>,
          value: titleBtn,
          size: isList ? 'sm' : 'lg',
          variant: 'outline-light',
          block: true,
          onClick: () => this.setState({ visible: true }),
          hint: isList ? 'Add to Calendar' : null,
          key: index
        }}
        dropdownProps={{
          visible: true,
          width: styles.modal.size.extraSmall,
          onClose: () => this.setState({ visible: false }),
          buttons: [],
          title: 'Add to Calendar'
        }}
        event={event}
      />
    );
  };

  renderButtonByShifts = () => {

    const { singleEvent } = this.props;
    if (singleEvent && singleEvent.shifts.length === 1) {
      return this.renderAddToCalendar(singleEvent, 1);
    }

    return singleEvent.shifts.map((item, index) => {
      if (item.isAttending) {
        const dataDateTime = getDataTimeForShift(item.startTime, item.endTime, singleEvent.timezone, true);

        const eventDate = getDataTime(item.startTime, item.endTime, singleEvent.timezone);
        const startTimeEvent = getDataTime(parseInt(singleEvent.startTime, 10), null, singleEvent.timezone);
        const endTimeEvent = getDataTime(parseInt(singleEvent.endTime, 10), null, singleEvent.timezone);

        let shiftDate = eventDate.timeString;
        if (startTimeEvent.dateString !== endTimeEvent.dateString) {
          shiftDate = dataDateTime.dateString
        }

        return (
          <ListItem key={index}>
            <NameShift>{item.name || `Shift ${index + 1}`}</NameShift>
            <ItemData>
              <ShiftDates>{shiftDate}</ShiftDates>
              {this.renderAddToCalendar(item, index + 1, true)}
            </ItemData>
          </ListItem>
        );
      }
      return false;
    });

  };


  renderThings = () => {
    const { singleEvent } = this.props;
    return (
      <BlockList>
        <ListItemWithIcon
          Icon={<MinimumAgeIcon defaultFill={styles.colors.dark} width={24} height={24}/>}
          content={`Minimum age: ${singleEvent.minimumAge} year(s)`}
        />
        {(singleEvent.eventGender === 2 || singleEvent.eventGender === 3) ? (
          <ListItemWithIcon
            Icon={<UnlockEmptyIcon defaultFill={styles.colors.dark} width={24} height={24}/>}
            content={`Open to ${getGenderForShow(singleEvent.eventGender)}`}
          />
        ) : null}
        {singleEvent.backgroundCheckRequired ? (
          <ListItemWithIcon
            Icon={<BackgroundCheckIcon defaultFill={styles.colors.darkBlue} width={24} height={24}/>}
            content={'Background check required'}
          />
        ) : null}
        {singleEvent.signedFormRequired ? (
          <ListItemWithIcon
            Icon={<SignedFormIcon defaultFill={styles.colors.dark} width={24} height={24}/>}
            content={'Signed forms required'}
          />
        ) : null}
        {singleEvent.trainingRequired ? (
          <ListItemWithIcon
            Icon={<TrainingIcon defaultFill={styles.colors.dark} width={24} height={24}/>}
            content={'Training required'}
          />
        ) : null}
      </BlockList>
    );
  }

  renderButtons = () => {
    const { singleEvent, showPopupSignUp, showConfirm, showPopupSignIn, selectedShifts, reservations, onClickConfirm } = this.props;
    const profileData = JSON.parse(localStorage.getItem('POINT.profileData'));

    let count = 0;
    let isAttending = false;
    selectedShifts.forEach((el) => {
      count += el.spotsTaken + (el.spotsReserved || 0);
      if (el.isAttending) {
        isAttending = true;
      }
    });

    let isFull = true;
    if (singleEvent && singleEvent.shifts) {
      singleEvent.shifts.forEach((item) => {
        if (item.spots > item.spotsTaken + item.spotsReserved) {
          isFull = false;
        } else {
          if (reservations && reservations.length) {
            let indexShift = reservations.findIndex(e => e.eventShiftId === item.eventShiftId);
            if (indexShift > -1) {
              isFull = false;
            }
          }
          if (singleEvent.isAttending) {
            isFull = false;
          }
        }
      });
    }

    let disabled = false;

    if (
      (!profileData && !isAttending) ||
      (profileData && profileData.id && !isAttending && singleEvent && singleEvent.shifts && singleEvent.shifts.length > 1)
    ) {
      disabled = true;
    }
    if (!profileData && singleEvent.shifts && singleEvent.shifts.length === 1 && !isFull) {
      disabled = false;
    }
    if (isFull) {
      disabled = true;
    }

    let hint = 'Select a shift to sign up.';
    if (isFull) {
      hint = 'No more spots available for sign up.';
    }

    let today = moment().format('x');
    let isEnded = false;
    if (+singleEvent.endTime < +today) {
      disabled = true;
      isEnded = true;
      hint = 'Event has been already ended.';
    }

    let isChange = false;
    selectedShifts.forEach((element) => {
      let index = singleEvent.shifts.findIndex(e => e.eventShiftId === element.eventShiftId);
      if (index > -1 && element.isAttending !== singleEvent.shifts[index].isAttending) {
        isChange = true;
      }
    });

    let isOpportunity = false;
    if (singleEvent && singleEvent.source === EVENT_SOURCES.VOLUNTEER_MATCH) {
      isOpportunity = true;
    }

    return (
      <div>
        {isEnded ? (
          <Button
            value={'ENDED'}
            onClick={() => null}
            size="lg"
            style={{ padding: '14px' }}
            disabled={true}
            hint={hint}
            block
          />
        ) : (singleEvent.isAttending ? (
          <div>
            <Button
              value={singleEvent.shifts.length > 1 ? (isOpportunity ? 'Change' : 'Change or Cancel') : 'Cancel'}
              onClick={() => onClickConfirm()}
              size="lg"
              style={{ padding: '14px' }}
              variant="outline-light"
              Icon={(singleEvent.shifts && singleEvent.shifts.length > 1 && isOpportunity) ? <EditIcon/> : null}
              disabled={singleEvent.shifts.length > 1 && !isChange}
              hint={singleEvent.shifts.length > 1 && !isChange ? 'Change or Select a shift to sign up.' : null}
              block
            />
            {singleEvent.shifts && singleEvent.shifts.length > 1 && isOpportunity ? (
              <Button
                size="lg"
                value="Cancel"
                variant="outline-light"
                onClick={() => onClickConfirm()}
                dataTestId="change-or-cancel-event"
                block
                style={{ marginTop: 16 }}
              />
            ) : null}
          </div>
        ) : (
          <Button
            value={!isFull ? 'Sign Up with POINT' : 'FULL'}
            onClick={() => profileData && profileData.id ? onClickConfirm() : showPopupSignIn()}
            Icon={!isFull ? <PointLogoIcon/> : null}
            size="lg"
            style={{ width: '100%', padding: '14px' }}
            disabled={disabled}
            hint={disabled ? hint : null}
            block
          />
        ))}

        {!profileData && !isFull && !isEnded ? (
          <Actions>
            <BlockLine>
              <HorizontalLine/>
              <Span>OR</Span>
            </BlockLine>
            <Button
              value="Create a POINT Account"
              onClick={() => showPopupSignUp()}
              size="lg"
              style={{ marginBottom: '8px', padding: '14px' }}
              block
            />
            <Paragraph>Powered by<LogoLink href={POINT_URL} target="_blank"><Image src={logo} alt="Point Logo" className="logo-desktop"/></LogoLink></Paragraph>
          </Actions>
        ) : null}
      </div>
    );
  }

  render () {
    const { singleEvent, showPopupSignUp, showConfirm, showPopupSignIn, selectedShifts, reservations } = this.props;
    const profileData = JSON.parse(localStorage.getItem('POINT.profileData'));

    let count = 0;
    let isAttending = false;
    selectedShifts.forEach((el) => {
      count += el.spotsTaken + (el.spotsReserved || 0);
      if (el.isAttending) {
        isAttending = true;
      }
    });

    let params = '';
    if (this.props.token) {
      params = `?token=${this.props.token}`;
    }

    const phone = singleEvent.organization && singleEvent.organization.phone ? singleEvent.organization.phone : null;

    if (this.props.isPrivate) {
      return (
        <FixedBlock>
          <Block>
            <BlockPadding isPaddingBottom>
              {!(profileData && profileData.id) ? (
                <DataPrivate>
                  <Icon>
                    <LockIcon defaultFill={styles.colors.system.alert} width={16} height={16}/>
                  </Icon>
                  <Title>Private Event</Title>
                </DataPrivate>
              ) : null}
              {(profileData && profileData.id) ? (
                <BlockUser isMargin={true}>
                  <ShortProfile
                    image={profileData.avatar}
                    title={`${profileData.firstName || ''} ${profileData.lastName || ''}`}
                    subtitle={profileData.email}
                  />
                  <ButtonLogOut
                    value="Log Out"
                    onClick={() => this.logOut()}
                    size="sm"
                    variant="outline-light"
                    isLoading={this.state.loading}
                  />
                </BlockUser>
              ) : null}
              <Button
                value={(profileData && profileData.id) ? 'Sign Up with POINT' : 'Log In'}
                Icon={(profileData && profileData.id) ? <PointLogoIcon/> : null}
                onClick={() => showPopupSignIn()}
                size="lg"
                style={{ width: '100%', padding: '14px' }}
                disabled={(profileData && profileData.id)}
                hint={(profileData && profileData.id) ? 'Event is Private.' : null}
                block
              />
              {!(profileData && profileData.id) ? (
                <Actions>
                  <BlockLine>
                    <HorizontalLine/>
                    <Span>OR</Span>
                  </BlockLine>
                  <Button
                    value="Create a POINT Account"
                    onClick={() => showPopupSignUp()}
                    size="lg"
                    style={{ marginBottom: '8px', padding: '14px' }}
                    block
                  />
                </Actions>
              ) : null}
            </BlockPadding>
          </Block>
        </FixedBlock>
      );
    }

    let isReserved = false;
    if (singleEvent && singleEvent.shifts && singleEvent.shifts.length === 1) {
      if (reservations.findIndex(el => el.eventShiftId === singleEvent.shifts[0].eventShiftId) > -1) {
        isReserved = true;
      }
    }

    return (
      <FixedBlock>
        <Block>
          <BlockPadding borderBottom>
            <BlockSignIn>
              <TitleBlock>{`Hello${profileData && profileData.id ? ',' : ' there!'}`}</TitleBlock>
              {(profileData && profileData.id) ? (
                <Button
                  value="Log Out"
                  onClick={() => this.logOut()}
                  size="sm"
                  variant={'outline-light'}
                  isLoading={this.state.loading}
                />
              ) : (
                <Button
                  onClick={() => showPopupSignIn()}
                  value="Log In"
                  size="sm"
                  variant={'outline-light'}
                />
              )}
            </BlockSignIn>
            {(profileData && profileData.id) ? (
              <BlockUser>
                <ShortProfile
                  image={profileData.avatar}
                  title={`${profileData.firstName} ${profileData.lastName || ''}`}
                  subtitle={profileData.email}
                />
              </BlockUser>
            ) : null}
          </BlockPadding>

          {singleEvent && singleEvent.id && singleEvent.isAttending ? (
            <BlockPadding
              borderBottom={singleEvent && singleEvent.source && singleEvent.source === EVENT_SOURCES.POINT}
              isPaddingBottom={singleEvent && singleEvent.source && singleEvent.source === EVENT_SOURCES.VOLUNTEER_MATCH}
            >
              <SuccessBlock>
                <SuccessData isMarginBottom={singleEvent.shifts && singleEvent.shifts.length > 1}>
                  <Img src={congratulations} alt="You're in! Thanks for signing up to do some good"/>
                  <BlockTitle>
                    <Title>You're in! Thanks for signing up to do some good</Title>
                  </BlockTitle>
                </SuccessData>
                {(singleEvent.shifts && singleEvent.shifts.length > 1) ? (
                  <RowTitle>You are signed up for:</RowTitle>
                ) : null}
                {this.renderButtonByShifts()}
                {singleEvent.source && singleEvent.source === EVENT_SOURCES.VOLUNTEER_MATCH ? (
                  <Buttons>
                    {this.renderButtons()}
                  </Buttons>
                ) : null}
              </SuccessBlock>
            </BlockPadding>
          ) : (singleEvent.source && singleEvent.source === EVENT_SOURCES.VOLUNTEER_MATCH ? (
            <BlockPadding isPaddingBottom>
              <RowTitle>View Sign Up Instructions</RowTitle>
              {this.renderButtons()}
            </BlockPadding>
          ) : null)}

          {singleEvent && singleEvent.id && singleEvent.source && singleEvent.source === EVENT_SOURCES.POINT ? (
            <BlockPadding isPaddingBottom>
              <Title>{`Sign up to volunteer: ${singleEvent.title}`}</Title>
              <DataProgress>
                <Spots><ProgressDonut percent={(count / singleEvent.spots) * 100}/></Spots>
                <SpotsData textColor="medium">{`${count}/${singleEvent.spots}`}</SpotsData>
                <Span>{`Spot${singleEvent.spots > 1 ? 's' : ''} Taken`}</Span>
              </DataProgress>

              {(singleEvent.shifts && singleEvent.shifts.length === 1 && !singleEvent.shifts[0].isAttending && isReserved) ? (
                <SpotReserved>
                  <Reserved>You have a spot reserved for you.</Reserved>
                  <Button
                    size="sm"
                    variant="info"
                    value="Claim Spot"
                    onClick={() => showConfirm()}
                    dataTestId={'claim-spot-button'}
                    style={{ margin: 0 }}
                  />
                </SpotReserved>
              ) : null}

              <ColEvent>
                {this.renderThings()}
              </ColEvent>

              {(singleEvent.shifts && singleEvent.shifts.length > 1) ? (
                <div>
                  <Data>
                    <RowTitle>View Shifts</RowTitle>
                    <ShowShifts onClick={() => this.setState({ showShift: !this.state.showShift })}>
                      {this.state.showShift ? (
                        <ArrowUpIcon defaultFill={styles.colors.medium} width={20} height={20}/>
                      ) : (
                        <ArrowDownIcon defaultFill={styles.colors.medium} width={20} height={20}/>
                      )}
                    </ShowShifts>
                  </Data>
                  {this.state.showShift ? (
                    <List>{this.showShifts(selectedShifts, singleEvent.timezone)}</List>
                  ) : null}
                </div>
              ) : (singleEvent && singleEvent.shifts && singleEvent.shifts[0].hasAttendanceSameTime ? (
                <FullBlock>
                  <InfoCircleIcon width={20} height={20} defaultFill={styles.colors.system.alert}/>
                  <SpanMessage>You signed up for multiple shifts at the same time.</SpanMessage>
                </FullBlock>
              ) : null)}
              {this.renderButtons()}
            </BlockPadding>
          ) : null}
        </Block>

        {singleEvent.source && singleEvent.source === EVENT_SOURCES.VOLUNTEER_MATCH ? (
          <Block>
            <BlockPadding isPaddingBottom>
              <RowTitle>Who to contact</RowTitle>
              <ContactFullName>
                <ShortProfile
                  onClick={() => singleEvent && singleEvent.organization && singleEvent.organization.id ? window.open(PUBLIC_ORGANIZATION_ROUTE.replace(':oid', singleEvent.organization.id)) : null}
                  image={singleEvent.organization.logo || ''}
                  title={singleEvent.organization.organizationName || singleEvent.organization.name}
                  subtitle={getTypeNameByType(singleEvent.organization.type)}
                  dataTestId={'event-creator'}
                />
              </ContactFullName>
              {phone ? (
                <BlockRow>
                  <LinkPhone href={`tel:${phone}`} data-testid="link-phone-event">{formatPhoneNumber(phone)}</LinkPhone>
                  <ButtonCall>
                    <Button
                      size="md"
                      Icon={<OldPhoneIcon/>}
                      value="Call"
                      href={`tel:${phone}`}
                      dataTestId="btn-phone-event"
                    />
                  </ButtonCall>
                </BlockRow>
              ) : null}
            </BlockPadding>
          </Block>
        ) : null}

        {singleEvent.source && singleEvent.source === EVENT_SOURCES.VOLUNTEER_MATCH ? (
          <Block>
            <BlockPadding>
              <RowTitle>THINGS TO KNOW:</RowTitle>
              {this.renderThings()}
            </BlockPadding>
          </Block>
        ) : null}

        {singleEvent && singleEvent.id ? (
          <Block>
            <BlockPadding>
              <RowTitle>Share</RowTitle>
              <SocialShareable
                link={`${EventsServerURL}/${singleEvent.id}${params}`}
                title={singleEvent.title}
              />
            </BlockPadding>
          </Block>
        ) : null}
      </FixedBlock>
    );
  }
}

RightBar.propTypes = {
  singleEvent: PropTypes.shape({}).isRequired,
  selectShift: PropTypes.func.isRequired,
  showPopupSignUp: PropTypes.func.isRequired,
  showConfirm: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  bottomBoxFixed: PropTypes.number,
  selectedShifts: PropTypes.array,
};

RightBar.defaultProps = {
  bottomBoxFixed: 0,
  selectedShifts: []
};

export default RightBar;

const FixedBlock = styled.div`
  background: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;
const Block = styled.div`
  margin: 16px;
  border: 1px solid ${styles.borders.color};
  border-radius: 20px;
`;
const BlockPadding = styled.div`
  padding: ${props => props.isPaddingBottom ? '24px 32px 32px' : '24px 32px 24px'};
  border-bottom: ${props => props.borderBottom ? '1px solid ' + styles.borders.color : 'none'};
`;
const Title = styled(H4)`
  margin: 0 0 24px;
  word-break: break-word;
`;
const ColEvent = styled.div`
  width: 100%;
  margin: 0 0 24px;
`;
const SpotsData = styled(H2)`
  margin: 0;
  font-family: ${styles.fonts.family.primaryMedium};
`;
const Spots = styled.div`
  position: absolute;
  left: 0;
  top: 7px;
`;
const DataProgress = styled.div`
  position: relative;
  padding: 0 0 0 52px;
  border-right: none;
  margin: 0 0 24px;
`;
const Span = styled(H6)`
  margin: 0;
  font-family: ${styles.fonts.family.primaryMedium};
  color: ${styles.colors.silver};
`;
const RowTitle = styled(H5)`
  color: ${styles.colors.medium};
  font-family: ${styles.fonts.family.primaryBold};
  margin: 0 0 16px;
  text-transform: uppercase;
`;
const Text = styled(H5)`
  color: ${styles.colors.medium};
  font-family: ${styles.fonts.family.primaryBook};
  margin: 16px 0 0;
  font-weight: normal;
  text-align: center;
`;
const Paragraph = styled(Text)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Link = styled.a`
  color: ${styles.colors.primary};
  font-family: ${styles.fonts.family.primaryBook};
  text-decoration: none;

  &:hover {
    color: ${styles.colors.secondary};
  }
`;
const LogoLink = styled(Link)`
  margin-left: 4px;
  display: flex;
`;
const Image = styled.img`
  height: 12px;
`;
const Img = styled.img`
  height: 46px;
  margin: 0 8px 0 0;
`;
const BlockTitle = styled.div`
  min-height: 46px;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${Title} {
    margin: 0;
  }
`;
const BlockLine = styled.div`
  position: relative;
  display: flex;

  ${Span} {
    padding: 4px;
    background-color: white;
    position: absolute;
    top: 20px;
    margin-left: -13px;
    left: 50%;
    font-family: ${styles.fonts.family.primaryBook};
  }
`;
const HorizontalLine = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  margin: 32px 0;
  background-color: ${styles.borders.color};
`;
const List = styled.div`
  margin: -4px -4px 20px;
  display: flex;
  flex-direction: column;
`;
const BlockUser = styled.div`
  margin: ${props => props.isMargin ? '0 0 24px' : '16px 0 0'};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: 425px) {
    flex-direction: column-reverse;
  }
`;
const Actions = styled.div`
  width: 100%;
`;
const SuccessBlock = styled.div`
  width: 100%;
  margin: 0;

  ${BlockUser} {
    margin-top: 16px;
  }

  ${Title} {
    margin: 0 0 12px;
  }

  .link-style {
    text-align: center;
    font-size: 14px;
    line-height: 22px;
    font-family: ${styles.fonts.family.primaryMedium};
    color: ${styles.colors.medium};
    border-radius: 4px;
    height: 48px;
    max-height: 48px;
    margin: 4px 0;
    border: 1px solid ${styles.borders.input.color.default};
    transition: 0.25s;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      border-color: ${styles.borders.input.color.active};
    }
  }

  .modal-body {
    padding: 24px 32px 32px;
  }
`;
const SuccessData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: ${props => props.isMarginBottom ? '0 0 16px' : '0 0 24px'};
  padding: 8px;
  background: ${styles.colors.system.success}1a;
  border-radius: 8px;

  ${Title} {
    margin: 0;
  }
`;
const DataPrivate = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 24px;

  ${Title} {
    margin: 0;
  }
`;
const Icon = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${styles.colors.system.alert}1a;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 8px 0 0;
`;
const BlockSignIn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
`;
const ShowShifts = styled.a`
  display: flex;
  margin: 0 0 0 8px;
`;
const TitleBlock = styled(H3)`
  display: flex;
  margin: 0 12px 0 0
`;
const Data = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 16px;

  ${RowTitle} {
    margin: 0;
  }
`;
const ListItem = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:last-of-type {
    margin-bottom: 0;
  }
`;
const NameShift = styled.div`
  position: relative;
  color: ${styles.colors.dark};
  font-family: ${styles.fonts.family.primaryMedium};
  font-size: 14px;
  line-height: 18px;
  padding-left: 0;
  margin: 0 0 8px;
`;
const ItemData = styled.span`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  .outline-light {
    display: flex;
    margin-left: auto;
  }
`;
const ShiftDates = styled(H6)`
  display: flex;
  flex-wrap: wrap;
  padding: 4px 8px;
  margin: 0 8px 0 0;
  color: ${styles.colors.dark};
  border-radius: 4px;
  font-family: ${styles.fonts.family.primaryMedium};
  background: ${styles.colors.secondary}1a;
`;
const SpotReserved = styled.div`
  border: 1px solid ${styles.colors.system.info}1a;
  border-radius: 8px;
  background: ${styles.colors.system.info}0d;
  padding: 6px 8px 6px 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 24px;
`;
const Reserved = styled(H5)`
  color: ${styles.colors.dark};
  margin: 0 12px 0 0;
  font-family: ${styles.fonts.family.primaryBook};
`;
const ButtonLogOut = styled(Button)`
  margin: 0 0 0 16px;
  @media screen and (max-width: 425px) {
    margin: 0 0 10px auto;
  }
`;
const FullBlock = styled.div`
  background: ${styles.colors.system.alert}14;
  border: 1px solid ${styles.colors.system.alert}1f;
  border-radius: 8px;
  padding: 4px 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: -4px 0 16px;
  width: 100%;
  svg {
    margin: 0 0 auto;
  }
`;
const SpanMessage = styled(H6)`
  color: ${styles.colors.dark};
  margin: 0 0 0 8px;
  font-family: ${styles.fonts.family.primaryMedium};
`;
const ContactFullName = styled.div`
  display: flex;
  margin-bottom: 16px;
`;
const LinkPhone = styled.a`
  color: ${styles.colors.medium};
  cursor: pointer;
  font-family: ${styles.fonts.family.primaryBook};
  &:hover {
    color: ${styles.colors.medium};
  }
`;
const Buttons = styled.div`
  margin: 24px 0 0;
`;
const BlockRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;
const ButtonCall = styled.div`
  display: none;
  @media screen and (max-width: 1024px) {
    display: block;
  }
`;
const BlockList = styled.div`
  h5 {
    font-size: 16px;
    line-height: 20px;
    margin-top: 2px;
    color: ${styles.colors.silverDark};
  }
`;
