import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const OldPhoneIcon = props => (
  <Icon {...props} viewBox={'0 0 24 24'}>
    <path d="M17.4,22C8.9,22,2,15.1,2,6.6C2,4.1,4,2,6.6,2c0.3,0,0.5,0,0.8,0.1
	c0.2,0,0.5,0.1,0.7,0.2C8.4,2.4,8.7,2.7,8.7,3l1.4,6c0.1,0.3,0,0.7-0.3,0.9c-0.1,0.1-0.1,0.1-1.4,0.8c1,2.2,2.7,3.9,4.9,4.9
	c0.7-1.2,0.7-1.2,0.8-1.4c0.2-0.2,0.6-0.3,0.9-0.3l6,1.4c0.3,0.1,0.6,0.3,0.7,0.6c0.1,0.2,0.1,0.5,0.2,0.7c0,0.3,0.1,0.5,0.1,0.8
	C22,20,19.9,22,17.4,22z M6.6,4C5.1,4,4,5.2,4,6.6C4,14,10,20,17.4,20c1.4,0,2.6-1.2,2.6-2.6v-0.3L15.3,16l-0.3,0.5
	c-0.4,0.9-0.8,1.5-1.6,1.2c-3.4-1.2-6-3.8-7.2-7.2C5.9,9.7,6.6,9.4,7.4,8.9L8,8.6L6.9,4H6.6z"/>
  </Icon>
);

export default withTheme(OldPhoneIcon);
