import moment from 'moment';
import { isValidEmail } from '../../../_helpers';
import { getIsUserEmailAvailable, getIsUsernameAvailable } from '../../../actions/accountActions';

const validateSignUp = async (values) => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = 'Whoops! First Name is required.';
  } else if (values.firstName.trim().length > 50) {
    errors.firstName = 'Whoops! The maximum number of characters for this field is 50.';
    return errors;
  }

  if (!values.lastName) {
    errors.lastName = 'Whoops! Last Name is required.';
  } else if (values.lastName.trim().length > 50) {
    errors.lastName = 'Whoops! The maximum number of characters for this field is 50.';
    return errors;
  }

  if (!values.username) {
    errors.username = 'Whoops! Username is required.';
  } else if (values.username.trim().length < 4) {
    errors.username = 'Whoops! Please, enter min 4 letters.';
  } else if (values.username.trim().length > 50) {
    errors.username = 'Whoops! The maximum number of characters for this field is 50.';
  } else {
    if (!(values.currentUsername && values.username === values.currentUsername)) {
      const isUsernameAvailable = await getIsUsernameAvailable(values.username);
      if (!isUsernameAvailable) {
        errors.username = 'Whoops! Username has already been taken.';
      }
    }
  }

  if (!values.email || (values.email && (values.email.length < 6 || !isValidEmail(values.email)))) {
    errors.email = 'Please, enter a valid Email.';
  } else if (values.email && values.email.trim().length > 255) {
    errors.email = 'Whoops! The maximum number of characters for this field is 255.';
  } else if (values.email) {
    if (!(values.currentEmail && values.email === values.currentEmail)) {
      const isEmailAvailable = await getIsUserEmailAvailable(values.email);
      if (!isEmailAvailable) {
        errors.email = 'Whoops! Email has already been taken.';
      }
    }
  }

  if (!values.address.zipCode || !values.address.zipCode.trim().length) {
    errors.zipCode = 'Whoops! Zip Code is required.';
  }
  if (values.address.zipCode && (values.address.zipCode.replace(/\D/g, '').length !== 5)) {
    errors.zipCode = 'Whoops! Zip Code field should be 5 digits.';
  }

  if (!values.birthday) {
    errors.birthday = 'Whoops! Birthday is required.';
  } else {
    const year = (moment().format('YYYY') - 13).toString();
    const today = moment().format('MMMM Do');
    const day = moment(`${today} ${year}`, 'MMMM Do YYYY');
    const birthdayUser = moment(values.birthday).startOf('day').format('x');

    if (Number(birthdayUser) > Number(moment(day).format('x'))) {
      errors.birthday = 'Whoops! You must be at least 13 years old to use POINT.';
    }
  }

  if (values.password.length < 8) {
    errors.password = 'The minimum number of characters for a password is 8.';
  }
  if (values.password.replace(/[^A-ZА-Я]/gm, '').length < 1) {
    errors.password = 'The password has to contain at least one uppercase letter.';
  }
  if (values.password.replace(/[^a-zа-я]/gm, '').length < 1) {
    errors.password = 'The password requires at least one lowercase and one uppercase letter.';
  }
  if (values.password.replace(/[^0-9]/gm, '').length < 1) {
    errors.password = 'The password has to contain at least one number.';
  }
  if (!values.password) {
    errors.password = 'Whoops! Password is required.';
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = 'Whoops! Confirm Password is required.';
  } else if (values.password !== values.confirmPassword) {
    errors.confirmPassword = 'Your password and confirmation password do not match.';
  }

  if (!values.isTermsAccepted) {
    errors.terms = 'Whoops! You must agree to our Terms of Use, Community Guidelines and Privacy Policy to continue.';
  }

  return errors;
};

export default validateSignUp;
