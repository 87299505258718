import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const MobilePhoneIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M17,1c1.7,0,3,1.3,3,3v16c0,1.7-1.3,3-3,3H7c-1.7,0-3-1.3-3-3V4c0-1.7,1.3-3,3-3H17z M17,3H7
	C6.4,3,6,3.4,6,4v16c0,0.6,0.4,1,1,1h10c0.6,0,1-0.4,1-1V4C18,3.4,17.6,3,17,3z M12.7,17.3c0.1,0.1,0.2,0.2,0.2,0.3
	C13,17.7,13,17.9,13,18c0,0.3-0.1,0.5-0.3,0.7C12.5,18.9,12.3,19,12,19s-0.5-0.1-0.7-0.3C11.1,18.5,11,18.3,11,18
	c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2-0.1
	C12.1,17,12.5,17.1,12.7,17.3z"/>
        </Icon>
    );

export default withTheme(MobilePhoneIcon);
