import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const MoneyIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M12,21c-0.6,0-1-0.4-1-1v-1.1c-2.4-0.3-3.2-1.6-3.5-2.7c-0.1-0.5,0.2-1.1,0.7-1.2c0.5-0.1,1.1,0.2,1.2,0.7
	C9.6,16.1,9.8,17,12,17c2.1,0,2.5-1.1,2.5-2c0-1.1-0.8-1.5-2.7-2c-1.8-0.5-4.3-1.2-4.3-4c0-1.6,0.9-3.5,3.5-3.9V4c0-0.6,0.4-1,1-1
	s1,0.4,1,1v1.1c2.4,0.3,3.2,1.6,3.5,2.7c0.1,0.5-0.2,1.1-0.7,1.2c-0.5,0.1-1.1-0.2-1.2-0.7C14.4,7.9,14.2,7,12,7
	C9.9,7,9.5,8.1,9.5,9c0,1.2,0.8,1.5,2.8,2.1c1.8,0.5,4.2,1.1,4.2,3.9c0,1.6-0.9,3.5-3.5,3.9V20C13,20.6,12.6,21,12,21z"/>
        </Icon>
    );

export default withTheme(MoneyIcon);
