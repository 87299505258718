const styles = {
  colors: {
    primary: '#f6921e',
    secondary: '#ffad0d',
    dark: '#262626',
    silverDark: '#586069',
    medium: '#464f59',
    silver: '#a6aebc',
    grey: '#e1e4e8',
    light: '#f2f3f5',
    mostlyWhite: '#f9f9f9',
    money: '#28C177',
    darkBlue: '#043F93',
    input: {
      active: '#464f59',
      placeholder: '#e1e4e8'
    },
    causes: {
      climate: '#6b9362',
      animals: '#57abd8',
      food: '#d4a112',
      equality: '#e20083',
      artsandCulture: '#ff8a3c',
      health: '#1e9c44',
      poverty: '#ee1625',
      communityDevelopment: '#fc9e00',
      waterSanitation: '#d4a112',
      conservation: '#36b243',
      education: '#c41b2d',
      energy: '#feb800',
      peaceJustice: '#2275b1',
      family: '#8bc832',
      crisis: '#be3359',
      refugees: '#498df2',
      homeless: '#7c76cf',
      consumption: '#d08e10',
      religious: '#eab300',
      jobs: '#af7b52'
    },
    system: {
      info: '#3ba5ff',
      success: '#00D000',
      alert: '#ffbb00',
      error: '#ff5353'
    },
    badges: {
      internal: '#00D000',
      external: '#385DFF',
      invitePending: '#FFBB00',
      pendingApproval: '#FFBB00',
      inviteSent: '#3BA5FF'
    }
  },
  borders: {
    input: {
      color: {
        default: '#e1e4e8',
        active: '#a6aebc'
      },
      radius: '4px'
    },
    color: '#F2F3F5'
  },
  fonts: {
    family: {
      primaryBook: '"CircularStd-Book"',
      primaryBold: '"CircularStd-Bold"',
      primaryMedium: '"CircularStd-Medium"'
    }
  },
  modal: {
    size: {
      extraSmall: 352,
      small: 448,
      normal: 544,
      medium: 640,
      large: 736,
      extraLarge: 940
    }
  }
};

export default styles;
