import buildRequest from './actions';
import { HTTP_METHODS } from '../constants';
import Cookies from 'js-cookie';

export const getIsUsernameAvailable = async (username) => {
  return await buildRequest({
    method: HTTP_METHODS.GET,
    url: 'users/username/available',
    isAuth: false,
    queryData: { username: username },
    showNotifications: false
  });
};

export const getIsUserEmailAvailable = async (email) => {
  return await buildRequest({
    method: HTTP_METHODS.GET,
    url: 'users/email/available',
    isAuth: false,
    queryData: { email: email }
  });
};

export const createUserProfile = async (obj) => {
  return await buildRequest({
    method: HTTP_METHODS.POST,
    url: 'users',
    isAuth: true,
    bodyData: obj
  });
};

export const updateProfile = async (obj) => {
  return await buildRequest({
    method: HTTP_METHODS.PUT,
    url: 'client/user',
    isAuth: true,
    bodyData: obj
  });
};

export const getVocabulary = async (options) => {
  return await buildRequest({
    method: HTTP_METHODS.GET,
    url: 'admin/translations',
    isAuth: true,
    queryData: options
  });
};

export const getAccountShortInfo =  (oid = null) => async (dispatch) => {
  return await buildRequest({
    method: HTTP_METHODS.GET,
    url: 'admin/user',
    isAuth: true,
    queryData: oid ? { oid: oid } : null,
    actionsWhenSuccess: ({ authToken }) => {
      Cookies.set('POINT_FST', authToken, { expires: 365, path: '/', domain: '.pointapp.org' });
    },
    actionsWhenError: () => {
      localStorage.removeItem('POINT.profileData');
    }
  });
};

export const getUserEmailsStatus = async () => {
  return await buildRequest({
    method: HTTP_METHODS.GET,
    url: 'client/emails/status',
    isAuth: true
  });
};

export const resendEmailVerificationEmail = async (obj) => {
  return await buildRequest({
    method: HTTP_METHODS.POST,
    url: 'client/emails/claim',
    isAuth: true,
    bodyData: obj,
    messages: {
      success: 'The verification email has been sent. Check your email to verify.'
    }
  });
};

export const customPostToken = async () => {
  return await buildRequest({
    method: HTTP_METHODS.POST,
    url: 'client/auth/custom',
    isAuth: true
  });
};
