import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const RegistryMenu = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M15,4H9l0,1c0,0,0,0,0,0v1h6V4z M15.7,10.3c0.4,0.4,0.4,1,0,1.4
	l-4,4C11.5,15.9,11.3,16,11,16c-0.3,0-0.5-0.1-0.7-0.3l-2-2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l1.3,1.3l3.3-3.3
	C14.7,9.9,15.3,9.9,15.7,10.3z M7,6H6C5.4,6,5,6.4,5,7v12c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1V7c0-0.6-0.4-1-1-1h-1c0,1.1-0.9,2-2,2
	H9C7.9,8,7,7.1,7,6z M15,2c1.1,0,2,0.9,2,2h1c1.7,0,3,1.3,3,3v12c0,1.7-1.3,3-3,3H6c-1.7,0-3-1.3-3-3V7c0-1.7,1.3-3,3-3h1
	c0-1.1,0.9-2,2-2H15z"/>
        </Icon>
    );

export default withTheme(RegistryMenu);
