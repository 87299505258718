import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../constants/styles';

class InnerHTML extends Component {
  constructor (props) {
    super(props);
    this.rawMarkup = this.rawMarkup.bind(this);
  }

  rawMarkup = () => ({ __html: this.props.content });

  render () {
    return (
      <HTMLContainer>
        <div className={'block-text'} dangerouslySetInnerHTML={this.rawMarkup()}/>
      </HTMLContainer>
    );
  }
}

export default InnerHTML;

InnerHTML.propTypes = {
  content: PropTypes.string.isRequired
};

const HTMLContainer = styled.div`
  display: flex;

  .block-text {
    font-size: 14px;
    line-height: 22px;
    font-family: ${styles.fonts.family.primaryBook};
    margin: 0 0 16px;
    color: ${styles.colors.medium};
    max-width: 100%;
    word-break: break-word;
    display: block;

    strong {
      font-family: ${styles.fonts.family.primaryMedium};
    }
  }
  
  h3 {
    color: ${styles.colors.medium};
    font-size: 16px;
    line-height: 24px;
    font-family: ${styles.fonts.family.primaryMedium};
    margin: 0 0 16px;
    max-width: 100%;
    word-break: break-word;
  }

 

  p {
    font-size: 14px;
    line-height: 22px;
    font-family: ${styles.fonts.family.primaryBook};
    margin: 0 0 16px;
    color: ${styles.colors.medium};
    max-width: 100%;
    word-break: break-word;

    strong {
      font-family: ${styles.fonts.family.primaryMedium};
    }
  }

  li {
    font-size: 14px;
    line-height: 22px;
    font-family: ${styles.fonts.family.primaryBook};
    margin: 0;
    color: ${styles.colors.medium};
    max-width: 100%;
    word-break: break-word;
  }

  ol {
    margin: 0 0 16px;
    padding-left: 24px;
    counter-reset: item;

    li {
      position: relative;
      list-style: none;

      &:before {
        content: counter(item) ". ";
        counter-increment: item;
        position: absolute;
        left: -24px;
      }
    }
  }

  ul {
    margin: 0 0 16px;
    padding-left: 15px;

    li {
      padding-left: 9px;
    }
  }

  blockquote {
    border-left: 2px solid #F6921E;
    padding-left: 22px;
    margin: 0 0 16px;

    p {
      margin: 0;
    }
  }

  a {
    color: #F6921E;

    &:hover {
      color: #f79f39;
    }
  }
`;
