import React from 'react';
import { withTheme } from 'styled-components';
import Icon from './components/Icon';

const NetworksMenu = props => (
        <Icon {...props}>
            <path d="M11.9,0c0.4,0,0.8,0,1.1,0.2c0.3,0.2,0.4,0.5,0.4,0.8
	c0,0.5-0.5,1-1,1h0c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0-1.2,0.1c0,0-0.1,0-0.1,0c-0.5,0-0.9-0.4-1-0.9c-0.1-0.5,0.3-1,0.9-1.1
	C11,0,11.4,0,11.9,0z M17.6,1.5c0.3,0,0.6,0.2,0.9,0.4c0.3,0.2,0.6,0.4,0.8,0.6c0.4,0.3,0.5,1,0.2,1.4c-0.2,0.3-0.5,0.4-0.8,0.4
	c-0.2,0-0.4-0.1-0.6-0.2c-0.3-0.3-0.7-0.5-1-0.7c-0.5-0.3-0.6-0.9-0.4-1.4C16.9,1.7,17.3,1.5,17.6,1.5z M5.6,2.1
	c0.3,0,0.6,0.1,0.8,0.4c0.3,0.4,0.2,1.1-0.2,1.4c-0.3,0.2-0.7,0.5-1,0.8C5,4.9,4.8,5,4.5,5C4.3,5,4,4.8,3.8,4.6
	c-0.4-0.4-0.4-1,0.1-1.4C4.2,2.9,4.6,2.6,5,2.3c0,0,0,0,0,0l0,0l0,0c0,0,0,0,0,0l0,0C5.2,2.1,5.4,2.1,5.6,2.1z M22.1,6.6
	c0.4,0,0.8,0.2,0.9,0.6c0.1,0.3,0.2,0.6,0.3,0.9c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0
	l0,0l0,0c0,0,0,0,0,0c0,0.1,0.1,0.2,0.1,0.3c0.2,0.5-0.1,1.1-0.7,1.2c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8-0.3-1-0.7
	c-0.1-0.4-0.3-0.8-0.4-1.2c-0.2-0.5,0-1.1,0.5-1.3C21.8,6.6,22,6.6,22.1,6.6z M1.6,7.5c0.1,0,0.2,0,0.3,0.1c0.5,0.2,0.8,0.7,0.6,1.3
	c-0.1,0.4-0.2,0.8-0.3,1.2c-0.1,0.5-0.5,0.8-1,0.8c-0.1,0-0.1,0-0.2,0c-0.5-0.1-0.9-0.6-0.8-1.2c0.1-0.5,0.2-1,0.4-1.5
	C0.8,7.8,1.2,7.5,1.6,7.5z M9.8,6.4c-2,0.8-3.4,2.6-3.7,4.8c0.6,0.1,1.4,0.3,2.4,0.4C8.6,9.4,9.1,7.6,9.8,6.4z M14.3,6.4
	c0.6,1.2,1.2,2.9,1.3,5.2c1-0.1,1.8-0.3,2.4-0.4C17.7,9.1,16.2,7.3,14.3,6.4z M12,6.5c-1.1,1.5-1.5,3.4-1.6,5.3
	c1.1,0.1,2.1,0.1,3.1,0C13.5,9.9,13.1,8,12,6.5z M22.8,13.3c0.1,0,0.1,0,0.2,0c0.5,0.1,0.9,0.6,0.8,1.2c-0.1,0.3-0.2,0.7-0.3,1
	c0,0,0,0,0,0l0,0v0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0l0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0l0,0c0,0.1,0,0.1-0.1,0.2c-0.1,0.4-0.5,0.7-0.9,0.7c-0.1,0-0.2,0-0.3-0.1
	c-0.5-0.2-0.8-0.8-0.6-1.3c0.1-0.4,0.3-0.8,0.3-1.2C21.9,13.6,22.3,13.3,22.8,13.3z M13.5,13.8c-1,0-1.9,0-2.9,0
	c0.2,1.3,0.7,2.6,1.4,3.7C12.8,16.4,13.2,15.1,13.5,13.8z M6.2,13.2c0.4,2,1.8,3.6,3.6,4.3c-0.5-1-1-2.3-1.2-3.9
	C7.6,13.5,6.8,13.4,6.2,13.2z M17.9,13.2c-0.6,0.1-1.5,0.3-2.4,0.4c-0.2,1.7-0.7,3-1.2,3.9C16.1,16.8,17.5,15.2,17.9,13.2z
	 M1.5,14.3c0.4,0,0.8,0.3,1,0.7c0.1,0.4,0.3,0.8,0.4,1.2c0.2,0.5,0,1.1-0.5,1.3c-0.1,0.1-0.3,0.1-0.4,0.1c-0.4,0-0.7-0.2-0.9-0.6
	c-0.1-0.3-0.3-0.6-0.4-0.9c0,0,0,0,0,0l0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0l0,0c0,0,0,0,0,0l0,0
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1-0.1-0.2-0.1-0.3
	c-0.2-0.5,0.1-1.1,0.7-1.3C1.3,14.3,1.4,14.3,1.5,14.3z M12,4c4.4,0,8,3.6,8,8s-3.6,8-8,8c-4.4,0-8-3.6-8-8S7.6,4,12,4z M19.4,19.2
	c0.3,0,0.5,0.1,0.7,0.3c0.4,0.4,0.3,1-0.1,1.4C20,21,19.9,21,19.9,21.1v0l0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0c-0.2,0.2-0.4,0.3-0.6,0.4l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0l0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0l0,0c0,0,0,0,0,0
	c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0C18.7,22,18.5,22,18.3,22
	c-0.3,0-0.6-0.1-0.8-0.4c-0.3-0.5-0.2-1.1,0.2-1.4c0.3-0.2,0.7-0.5,1-0.8C18.9,19.3,19.1,19.2,19.4,19.2z M5.4,19.8
	c0.2,0,0.4,0.1,0.6,0.2c0.3,0.2,0.7,0.5,1,0.7c0.5,0.3,0.6,0.9,0.4,1.4c-0.2,0.3-0.5,0.5-0.9,0.5c-0.2,0-0.3,0-0.5-0.1
	c-0.4-0.2-0.9-0.5-1.3-0.8c-0.4-0.3-0.5-1-0.2-1.4C4.8,20,5.1,19.8,5.4,19.8z M13.2,21.9c0.5,0,0.9,0.4,1,0.9c0.1,0.5-0.3,1-0.9,1.1
	c-0.3,0-0.5,0-0.8,0.1l0,0l0,0l0,0l0,0l0,0l0,0h0l-0.1,0l0,0l0,0l0,0l0,0l0,0l0,0l-0.1,0l0,0h-0.3c0,0,0,0-0.1,0c-0.5,0-1-0.5-1-1
	c0-0.5,0.5-1,1-1h0l0.2,0c0.4,0,0.7,0,1.1-0.1C13.1,21.9,13.2,21.9,13.2,21.9z"/>
        </Icon>
    );

export default withTheme(NetworksMenu);
