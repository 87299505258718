import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../constants/styles';
import { isNil } from 'lodash';

const Avatar = (props) => {
  return (
    <Container {...props}>
      {(isNil(props.src) || props.src.length === 0) ? (
        <AvatarInitials>
          {(!isNil(props.firstName) && typeof (props.firstName) === 'string' && props.firstName.length > 0) ? props.firstName.charAt(0) : 'A'}
          {(!isNil(props.lastName) && typeof (props.lastName) === 'string' && props.lastName.length > 0) ? props.lastName.charAt(0) : ''}
        </AvatarInitials>
      ) : null}
    </Container>
  );
};

export default Avatar;

Avatar.propTypes = {
  src: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  size: PropTypes.number
};

Avatar.defaultProps = {
  src: null,
  size: 50,
  firstName: 'Anonymous',
  lastName: ''
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => (isNil(props.src) || props.src.length === 0) ? styles.colors.secondary : 'transparent'};
  background-image: url("${props => props.src}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  min-width: ${props => props.size}px;
  min-height: ${props => props.size}px;
  border-radius: 50%;
`;
const AvatarInitials = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  line-height: 16px;
`;
