import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getDataLocationByCity } from '../../../_helpers';
import { getEventsListShare } from '../../../actions/eventsActions';
import { Content, Wrapper } from '../../../components/Elements';
import { H2 } from '../../../components/Typography';
import { Pagination } from '../../../components/Pagination';
import { EventsFilters, EventsList } from './components';

let timer = null;

class Events extends Component {
  constructor (props) {
    super(props);
    this.state = {
      page: 1,
      size: 12,
      search: '',
      city: null
    };
  }

  componentDidMount () {
    document.title = 'Public Events | POINT';
    this.props.getEventsListShare({
      size: 12,
      page: 1
    });
  }

  handleChangeParams = (data) => {
    let par = {
      page: this.state.page,
      search: this.state.search,
      city: this.state.city
    };

    if ('page' in data) {
      par.page = data.page;
    }
    if ('search' in data) {
      par.search = data.search;
      par.page = 1;
    }
    if ('city' in data) {
      par.city = data.city;
      par.page = 1;
    }

    this.setState(par);

    let params = {
      size: this.state.size,
      page: par.page,
      search: par.search,
    };

    if (par.city && par.city !== 'all') {
      const location = getDataLocationByCity(par.city);
      params = Object.assign(params, location);
    }

    clearTimeout(timer);
    timer = setTimeout(() => {
      this.props.getEventsListShare(params);
    }, 500);
  };


  render () {
    const count = Math.ceil(this.props.total / this.state.size);

    return (
      <Wrapper>
        <Content>
          <Container>
            <Title>Public Events</Title>
            <EventsFilters
              searchValue={this.state.search}
              sortValue={this.state.city}
              updateParams={this.handleChangeParams}
            />
            <EventsList
              history={this.props.history}
              events={this.props.events}
            />
            {count > 1 ? (
              <Pagination
                pageCount={count}
                pageRangeDisplayed={3}
                marginPagesDisplayed={3}
                onPageChange={(p) => this.handleChangeParams({ page: p.selected + 1 })}
                forcePage={this.state.page - 1}
              />
            ) : null}
          </Container>
        </Content>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  events: state.events.list,
  total: state.events.total
});

export default connect(mapStateToProps, { getEventsListShare })(Events);

Events.propTypes = {
  getEventsListShare: PropTypes.func.isRequired,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      address: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      startTime: PropTypes.number.isRequired,
      timezone: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      organization: PropTypes.shape({
        id: PropTypes.number.isRequired,
        logo: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      }).isRequired
    })
  ),
  history: PropTypes.shape().isRequired,
  total: PropTypes.number
};

Events.defaultProps = {
  events: [],
  total: 0
};

const Title = styled(H2)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 24px;
  word-break: break-word;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 15px;
`;
