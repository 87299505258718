import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { notification } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FacebookIcon, EmailIcon, TwitterIcon, LinkIcon, LinkedinIcon } from '../../components/Icons/General'
import styles from '../../constants/styles';
import { ListItemWithIcon } from '../Elements';

class SocialShareable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowPopUp: false
        };
        this.copyToClipboard = this.copyToClipboard.bind(this);
    }

    copyToClipboard = () => {
        notification.success({
            message: 'Copied to Clipboard!'
        });
    };

    render() {
        const { link, title} = this.props;
        const message = `${title} ${link}`;
        return (
            <Container>
                <CopyToClipboard onCopy={this.copyToClipboard} text={`${link}`}>
                    <TextLink>
                        <ListItemWithIcon
                            Icon={<LinkIcon defaultFill={styles.colors.dark} width={18} height={18}/>}
                            styleContent={{fontFamily: `${styles.fonts.family.primaryMedium}`}}
                            content={'Copy invite link'}
                        />
                    </TextLink>
                </CopyToClipboard>
                <Link href={`http://www.facebook.com/share.php?u=${link}&title=${title}`} target="_blank">
                    <ListItemWithIcon
                        Icon={<FacebookIcon defaultFill={'#1977F3'} width={24} height={24}/>}
                        styleContent={{fontFamily: `${styles.fonts.family.primaryMedium}`}}
                        content={'Share on Facebook'}
                    />
                </Link>
                <Link href={`http://twitter.com/intent/tweet?text=${title}&url=${link}`} target="_blank">
                    <ListItemWithIcon
                        Icon={<TwitterIcon defaultFill={'#1FABF4'} width={24} height={24}/>}
                        styleContent={{fontFamily: `${styles.fonts.family.primaryMedium}`}}
                        content={'Share on Twitter'}
                    />
                </Link>
                <Link
                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${link}&title=${title}&summary=${title}&source=LinkedIn`}
                    target="_blank"
                >
                    <ListItemWithIcon
                        Icon={<LinkedinIcon defaultFill={'#0082BD'} width={20} height={20}/>}
                        styleContent={{ fontFamily: `${styles.fonts.family.primaryMedium}` }}
                        content={'Share on LinkedIn'}
                    />
                </Link>
                <Link href={`mailto:?body=${message}&subject=${title}`} target="_blank">
                    <ListItemWithIcon
                        Icon={<EmailIcon defaultFill={styles.colors.dark} width={18} height={18} />}
                        styleContent={{fontFamily: `${styles.fonts.family.primaryMedium}`}}
                        content={'Share via Email'}
                    />
                </Link>
            </Container>
        );
    }
}

SocialShareable.propTypes = {
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

export default SocialShareable;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    margin: -4px -4px 8px;
`;
const TextLink = styled.div`
    display: flex;
    justify-content: center;
    padding: 11px 13px;
    cursor: copy;
    height: 48px;
    border-radius: 4px;
    margin: 4px;
    border: 1px solid ${styles.borders.input.color.default};
    transition: 0.25s;
    &>div {
        margin: 0;
        align-items: center;
    }
    &:hover {
        background: ${styles.colors.mostlyWhite};
        border: 1px solid ${styles.borders.input.color.active};
    }
`;
const Link = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4px;
    padding: 11px 12px;
    border-radius: 4px;
    height: 48px;
    border: 1px solid ${styles.borders.input.color.default};
    text-decoration: none;
    text-align: center;
    transition: 0.25s;
    &>div {
        margin: 0;
        align-items: center;
    }
    &:hover {
        border: 1px solid ${styles.borders.input.color.active};
    }
`;
