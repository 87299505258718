import React from 'react';
import { withTheme } from 'styled-components';
import Icon from './components/Icon';

const LogoutMenu = props => (
  <Icon {...props}>
    <path d="M12.5,8.3c0.4-0.4,1-0.4,1.4,0l2.9,3c0,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2
	c0,0.1,0.1,0.2,0.1,0.3V12c0,0.1,0,0.3-0.1,0.4c0,0.1-0.1,0.2-0.2,0.3l-3,3c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4l1.3-1.3H8
	c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1h5.7l-1.2-1.3C12,9.3,12.1,8.7,12.5,8.3z M12,4c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S16.4,4,12,4
	z M12,2c5.5,0,10,4.5,10,10c0,5.5-4.5,10-10,10C6.5,22,2,17.5,2,12C2,6.5,6.5,2,12,2z"/>
  </Icon>
);

export default withTheme(LogoutMenu);
