import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const InfoCircleIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M12,16c-0.6,0-1-0.4-1-1v-3c0-0.6,0.4-1,1-1s1,0.4,1,1v3C13,15.6,12.6,16,12,16z"/>
            <path d="M12,10c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.1-0.3-0.2C11.1,9.5,11,9.3,11,9c0-0.1,0-0.1,0-0.2
		c0-0.1,0-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.2,0.3-0.2C12,7.9,12.4,8,12.7,8.3
		c0,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.1,0,0.2c0,0.3-0.1,0.5-0.3,0.7c-0.1,0-0.1,0.1-0.1,0.1
		c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0.1C12.1,10,12.1,10,12,10z"/>
		    <path d="M12,2c5.5,0,10,4.5,10,10s-4.5,10-10,10S2,17.5,2,12S6.5,2,12,2z M12,4c-4.4,0-8,3.6-8,8
	    s3.6,8,8,8s8-3.6,8-8S16.4,4,12,4z"/>
        </Icon>
    );

export default withTheme(InfoCircleIcon);
