import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const CodeIcon = props => (
    <Icon {...props} viewBox={'0 0 24 24'}>
        <path d="M16,19c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l5.3-5.3l-5.3-5.3c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l6,6
	c0.4,0.4,0.4,1,0,1.4l-6,6C16.5,18.9,16.3,19,16,19z"/>
        <path d="M8,19c-0.3,0-0.5-0.1-0.7-0.3l-6-6c-0.4-0.4-0.4-1,0-1.4l6-6c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4L3.4,12l5.3,5.3
	c0.4,0.4,0.4,1,0,1.4C8.5,18.9,8.3,19,8,19z"/>
    </Icon>
);

export default withTheme(CodeIcon);
