import React from 'react';
import styled, {withTheme} from 'styled-components';
import { BulbIcon } from '../Icons/General';
import PropTypes from 'prop-types';

const ButtonTooltip = props => {
    return (
        <Button onClick={() => props.onPress()}>
            <BulbIcon
                defaultFill={'white'}
                hoverFill={'white'}
                height={18}
                width={18}
            />
        </Button>
    );
};

export default withTheme(ButtonTooltip);

const Button = styled.button`
    padding: 0;
    width: 36px;
    height: 36px;
    border-radius: 12px;
    border: none;
    box-shadow: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 10px;
    cursor: pointer;
    transition: all 1s ease-out;
    background: rgb(255,187,0);
    background: -moz-linear-gradient(180deg, rgba(255,187,0,1) 0%, rgba(255,173,13,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(255,187,0,1) 0%, rgba(255,173,13,1) 100%);
    background: linear-gradient(180deg, rgba(255,187,0,1) 0%, rgba(255,173,13,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffbb00",endColorstr="#ffad0d",GradientType=1);
    &:hover {
        background: rgb(255,173,13);
        background: -moz-linear-gradient(180deg, rgba(255,173,13,1) 0%, rgba(255,187,0,1) 100%);
        background: -webkit-linear-gradient(180deg, rgba(255,173,13,1) 0%, rgba(255,187,0,1) 100%);
        background: linear-gradient(180deg, rgba(255,173,13,1) 0%, rgba(255,187,0,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffad0d",endColorstr="#ffbb00",GradientType=1);
    }
    &:hover g{
        fill: ${props => props.white};
    }
`;

ButtonTooltip.propTypes = {
    onPress: PropTypes.func
};

ButtonTooltip.defaultProps = {
    onPress: () => null
};
