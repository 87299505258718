import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const DraftIcon = props => (
    <Icon {...props} viewBox={'0 0 24 24'}>
        <path d="M18,23H6c-1.7,0-3-1.3-3-3V4c0-1.7,1.3-3,3-3h7c0.3,0,0.5,0.1,0.7,0.3l7,7C20.9,8.5,21,8.7,21,9v11C21,21.7,19.7,23,18,23z
	 M6,3C5.4,3,5,3.4,5,4v16c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1V9.4L12.6,3H6z"/>
        <path d="M20,10h-7c-0.6,0-1-0.4-1-1V2c0-0.6,0.4-1,1-1s1,0.4,1,1v6h6c0.6,0,1,0.4,1,1S20.6,10,20,10z"/>
    </Icon>
);

export default withTheme(DraftIcon);
