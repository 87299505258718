import React, { useState, useCallback, useRef, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const pixelRatio = window.devicePixelRatio || 1;

export default function ImageCrop(props) {
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState(props.crop);
    const [completedCrop, setCompletedCrop] = useState(null);

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);


    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext("2d");

        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

         const prom = new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (blob) {
                    blob.name = `p_${moment().format('x')}`;
                    resolve(blob);
                }
            }, 'image/jpeg', 1);
        });
        props.updateImage(prom);

    }, [completedCrop]);

    return (
        <div>
            <HidePlace>
                <canvas
                    ref={previewCanvasRef}
                    style={{
                        width: Math.round(completedCrop?.width ?? 0),
                        height: Math.round(completedCrop?.height ?? 0)
                    }}
                />
            </HidePlace>
            <ReactCrop
                src={props.src}
                onImageLoaded={onLoad}
                crop={crop}
                onChange={(c) => {
                    setCrop(c);
                }}
                onComplete={(c) => {
                    setCompletedCrop(c);
                }}
            />
        </div>
    );
}

const HidePlace = styled.div`
    display: none;
    visibility: hidden;
`;