import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const OptionsVerticalIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <circle cx="12" cy="12" r="2"/>
            <circle cx="12" cy="5" r="2"/>
            <circle cx="12" cy="19" r="2"/>
        </Icon>
    );

export default withTheme(OptionsVerticalIcon);
