import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const PlayIcon = props => (
    <Icon {...props} viewBox={'0 0 24 24'}>
        <path d="M19.5,11.2l-14-9c-0.3-0.2-0.7-0.2-1,0C4.2,2.3,4,2.6,4,3v18c0,0.4,0.2,0.7,0.5,0.9C4.7,22,4.8,22,5,22
	c0.2,0,0.4-0.1,0.5-0.2l14-9c0.3-0.2,0.5-0.5,0.5-0.8C20,11.7,19.8,11.3,19.5,11.2z"/>
    </Icon>
);

export default withTheme(PlayIcon);
