import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const StudentsIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M12,14.5c-0.2,0-0.3,0-0.4-0.1l-10-5C1.2,9.2,1,8.9,1,8.5s0.2-0.7,0.6-0.9l10-5c0.3-0.1,0.6-0.1,0.9,0l10,5
		C22.8,7.8,23,8.1,23,8.5s-0.2,0.7-0.6,0.9l-10,5C12.3,14.5,12.2,14.5,12,14.5z M4.2,8.5l7.8,3.9l7.8-3.9L12,4.6L4.2,8.5z"/>
            <path d="M12,20.5c-0.2,0-0.3,0-0.4-0.1l-6-3C5.2,17.2,5,16.9,5,16.5v-5.6c0-0.6,0.4-1,1-1s1,0.4,1,1v4.9l5,2.5
l5-2.5V11c0-0.6,0.4-1,1-1s1,0.4,1,1v5.5c0,0.4-0.2,0.7-0.6,0.9l-6,3C12.3,20.5,12.2,20.5,12,20.5z"/>
            <path d="M22,17c-0.6,0-1-0.4-1-1V8.5c0-0.6,0.4-1,1-1s1,0.4,1,1V16C23,16.6,22.6,17,22,17z"/>
        </Icon>
    );

export default withTheme(StudentsIcon);
