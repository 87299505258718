import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const ProfileIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M12,13c-3.9,0-7,3.1-7,7c0,0.6,0.4,1,1,1s1-0.4,1-1c0-2.8,2.2-5,5-5s5,2.2,5,5c0,0.6,0.4,1,1,1s1-0.4,1-1
	C19,16.1,15.9,13,12,13z"/>
            <path d="M16,7c0-2.2-1.8-4-4-4S8,4.8,8,7s1.8,4,4,4S16,9.2,16,7z M10,7c0-1.1,0.9-2,2-2s2,0.9,2,2s-0.9,2-2,2
	S10,8.1,10,7z"/>
        </Icon>
    );

export default withTheme(ProfileIcon);
