import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const CloseIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M6,19c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l12-12c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-12,12
	C6.5,18.9,6.3,19,6,19z"/>
            <path d="M18,19c-0.3,0-0.5-0.1-0.7-0.3l-12-12c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l12,12c0.4,0.4,0.4,1,0,1.4
	C18.5,18.9,18.3,19,18,19z"/>
        </Icon>
    );

export default withTheme(CloseIcon);
