import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { filter, find, isNil } from 'lodash';
import { LockIcon, UnlockIcon } from '../../../components/Icons/General';
import { EVENT_HOSTING_STATUSES, EVENT_HOSTING_VISIBILITY, ORGANIZATION_TYPES } from '../../../constants';
import { getGenderForShow } from '../../../_helpers';
import styles from '../../../constants/styles';
import Hint from '../../Hint';

const EventPrivacy = (props) => {
  const {
    host,
    coHosts,
    eventGender,
    location = 'list',
    size = 16,
    dataTestId
  } = props;
  let openTo, isPrivate, privateGroups, visibility;

  const privateHostingVisibility = [
    { 'value': EVENT_HOSTING_VISIBILITY.NONE },
    { 'value': EVENT_HOSTING_VISIBILITY.GROUPS },
    { 'value': EVENT_HOSTING_VISIBILITY.ORGANIZATION }
  ];

  if (coHosts && coHosts.length > 0) {
    const isCoHostPublic = !isNil(find(coHosts, {
      'visibility': EVENT_HOSTING_VISIBILITY.EVERYONE,
      'type': ORGANIZATION_TYPES.NONPROFIT,
      'status': EVENT_HOSTING_STATUSES.CONFIRMED
    }));
    if (isCoHostPublic || host.visibility === EVENT_HOSTING_VISIBILITY.EVERYONE) {
      isPrivate = false;
      visibility = EVENT_HOSTING_VISIBILITY.EVERYONE;
      privateGroups = [];
    } else {
      const profileData = JSON.parse(localStorage.getItem('POINT.profileData'));
      const oid = profileData.activeOrganization.id;
      const currentHosts = filter(coHosts, (h) => h.hostId === oid);
      const currentHost = currentHosts.length > 0 ? currentHosts[0] : host;

      isPrivate = !isNil(find(privateHostingVisibility, (item) => item.value === currentHost.visibility));
      visibility = currentHost.visibility;
      privateGroups = currentHost.groups || [];
    }
  } else {
    isPrivate = !isNil(find(privateHostingVisibility, (item) => item.value === host.visibility));
    visibility = host.visibility;
    privateGroups = host.groups || [];
  }

  switch (visibility) {
    case EVENT_HOSTING_VISIBILITY.GROUPS:
      openTo = `Private to ${privateGroups.map(e => e.title).join(', ')}`;
      break;
    case EVENT_HOSTING_VISIBILITY.ORGANIZATION:
      openTo = 'Private to Your Organization';
      break;
    case EVENT_HOSTING_VISIBILITY.NONE:
      openTo = 'Private to You';
      break;
    default:
      if (eventGender) {
        openTo = `Public Event: Open to ${getGenderForShow(eventGender)}`;
      } else {
        openTo = 'Open to Anyone';
      }
  }
  return (
    <Wrapper {...props} size={size} location={location} data-testid={dataTestId}>
      {isPrivate ? (
        <PrivateFragment>
          <Hint
            shortValue={<LockIcon defaultFill={styles.colors.system.alert} height={14} width={14}/>}
            value={openTo}
          />
        </PrivateFragment>
      ) : (
        <PublicFragment>
          <Hint
            shortValue={<UnlockIcon defaultFill={styles.colors.system.info} height={14} width={14}/>}
            value={openTo}
          />
        </PublicFragment>
      )}
    </Wrapper>
  );
};

EventPrivacy.propTypes = {
  host: PropTypes.shape(),
  coHosts: PropTypes.shape(),
  eventGender: PropTypes.string,
  location: PropTypes.string,
  isPrivate: PropTypes.bool,
  privateGroup: PropTypes.string,
  size: PropTypes.number,
  dataTestId: PropTypes.string
};

EventPrivacy.defaultProps = {
  host: {},
  coHosts: {},
  eventGender: '',
  location: '',
  isPrivate: false,
  privateGroup: null,
  size: 16,
  dataTestId: ''
};

export default EventPrivacy;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.location === 'list' ? 'flex-end' : 'flex-start'};
  align-items: flex-start;
  width: auto;

  svg {
    min-width: 16px;
  }
;
`;
const PublicFragment = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: ${styles.colors.system.info}1f;
`;
const PrivateFragment = styled(PublicFragment)`
  background: ${styles.colors.system.alert}1f;
`;
