import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const ManIcon = props => (
    <Icon {...props} viewBox={'0 0 24 24'}>
        <path d="M21,2h-6c-0.6,0-1,0.4-1,1s0.4,1,1,1h3.6l-5.4,5.4C12,8.5,10.5,8,9,8c-3.9,0-7,3.1-7,7s3.1,7,7,7s7-3.1,7-7
	c0-1.6-0.5-3.1-1.4-4.2L20,5.4V9c0,0.6,0.4,1,1,1s1-0.4,1-1V3C22,2.4,21.6,2,21,2z M9,20c-2.8,0-5-2.2-5-5s2.2-5,5-5s5,2.2,5,5
	S11.8,20,9,20z"/>
    </Icon>
);

export default withTheme(ManIcon);
