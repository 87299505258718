import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const MenuIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M21,13H3c-0.6,0-1-0.4-1-1s0.4-1,1-1h18c0.6,0,1,0.4,1,1S21.6,13,21,13z"/>
            <path d="M21,7H3C2.4,7,2,6.6,2,6s0.4-1,1-1h18c0.6,0,1,0.4,1,1S21.6,7,21,7z"/>
            <path d="M21,19H3c-0.6,0-1-0.4-1-1s0.4-1,1-1h18c0.6,0,1,0.4,1,1S21.6,19,21,19z"/>
        </Icon>
    );

export default withTheme(MenuIcon);
