import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import styles from '../../../constants/styles';

const Icon = ({
                  width,
                  height,
                  isActive,
                  isHover,
                  defaultFill,
                  activeFill,
                  hoverFill,
                  viewBox,
                  children,
                  dataTestId='',
                  onClick=() => null
              }) => {
    let fill = defaultFill || styles.colors.medium;
    fill = isActive ? (activeFill || styles.colors.primary) : fill;
    fill = isHover ? (hoverFill || styles.colors.primary) : fill;

    const _viewBox = viewBox || '0 0 20 20';

    return (
        <Svg
            data-testid={dataTestId}
            width={width}
            height={height}
            viewBox={_viewBox}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            onClick={() => onClick()}
        >
            <g fill={fill}>
                {children}
            </g>
        </Svg>
    );
};

const Svg = styled.svg`
  height: ${({width}) => width}px;
  width: ${({height}) => height}px;
  min-width: ${({width}) => width}px;
  g {
    transition: .25s;
    &:hover {
      fill: ${({hoverFill}) => hoverFill} !important;
    }
  }
`;

Icon.propTypes = {
    children: PropTypes.node,
    width: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
    height: PropTypes.number,
    isActive: PropTypes.bool,
    isHover: PropTypes.bool,
    defaultFill: PropTypes.string,
    activeFill: PropTypes.string,
    hoverFill: PropTypes.string
};

Icon.defaultProps = {
    children: null,
    width: 24,
    height: 24,
    isActive: false,
    isHover: false,
    defaultFill: styles.colors.medium,
    activeFill: styles.colors.primary,
    hoverFill: styles.colors.primary,
};

export default Icon;
