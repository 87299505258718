import React from 'react';
import PropTypes from 'prop-types';
import styled, {withTheme} from 'styled-components';
import { InfoIcon } from '../Icons/General';
import styles from '../../constants/styles';

const InfoBanner = (props) => (
  <Block data-testid={`${props.dataTestId}-info-banner`}>
    <Span>
      <InfoIcon width={20} height={20} defaultFill={'white'} />
    </Span>
    {props.text}
  </Block>
);
export default withTheme(InfoBanner);

InfoBanner.propTypes = {
  dataTestId: PropTypes.string,
  text: PropTypes.string
};

InfoBanner.defaultProps = {
    dataTestId: '',
    text: ''
};

const Block = styled.div`
  background: linear-gradient(to right, ${props => styles.colors.primary}, ${props => props.theme.colors.background.primaryLight});
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  color: white;
  border-radius: 4px;
  padding: 14px 25px 14px 48px;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  font-family: ${styles.fonts.family.primaryMedium};
  width: 100%;
  margin: 0 0 20px 0;
  @media screen and (max-width: 425px) {
    padding: 14px 16px 14px 48px;
  }
`;
const Span = styled.div`
  position: absolute;
  top: 14px;
  left: 16px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
