import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';
import PropTypes from 'prop-types';

const Wrapper = (props) => (
  <Container onClick={props.onClick}>
    <Header/>
    <Content>
      {props.children}
    </Content>
    <Footer/>
  </Container>
);
export default Wrapper;

Wrapper.propTypes = {
  children: PropTypes.oneOfType([ PropTypes.array, PropTypes.element ]),
  onClick: PropTypes.func
};

Wrapper.defaultProps = {
  children: '',
  onClick: () => null
};

const Container = styled.div`
  height: 100%;
`;
const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;
  max-width: 1184px;
  padding: 16px;
  margin: 0 auto;
  min-height: calc(100vh - 148px);
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

