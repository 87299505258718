import React from 'react';
import { withTheme } from 'styled-components';
import Icon from './components/Icon';

const GroupsMenu = props => (
        <Icon {...props}>
            <path d="M17,14c-1.1,0-2.2,0.4-3.1,1c-2.7-2.7-7.2-2.7-9.9,0c-1.3,1.3-2,3.1-2,4.9c0,0.6,0.4,1,1,1s1-0.4,1-1c0-2.8,2.2-5,5-5s5,2.2,5,5c0,0.6,0.4,1,1,1s1-0.4,1-1c0-1.2-0.3-2.3-0.9-3.3c1.3-1,3.2-0.8,4.2,0.5c0.4,0.5,0.6,1.2,0.6,1.9c0,0.6,0.4,1,1,1s1-0.4,1-1C22,16.2,19.8,14,17,14z"/>
            <path d="M20,10c0-1.7-1.3-3-3-3s-3,1.3-3,3s1.3,3,3,3S20,11.7,20,10z M16,10c0-0.6,0.4-1,1-1s1,0.4,1,1s-0.4,1-1,1S16,10.6,16,10z"/>
            <path d="M13,7c0-2.2-1.8-4-4-4S5,4.8,5,7s1.8,4,4,4S13,9.2,13,7z M7,7c0-1.1,0.9-2,2-2s2,0.9,2,2s-0.9,2-2,2S7,8.1,7,7z"/>
        </Icon>
    );

export default withTheme(GroupsMenu);
