import React from 'react';
import { withTheme } from 'styled-components';
import Icon from './components/Icon';

const OrganizationsMenu = props => (
    <Icon {...props}>
      <path d="M14,14h-4v6h4V14z M12,4.4l-7,7.1V20h3v-7c0-0.6,0.4-1,1-1h6c0.6,0,1,0.4,1,1v7h3v-8.4L12,4.4z M11.3,2.3
	c0.4-0.4,1-0.4,1.4,0l7.7,7.9c0.4,0.4,0.6,0.9,0.6,1.4V20c0,1.1-0.8,1.9-1.9,2H4.9C3.8,21.9,3,21.1,3,20v-8.4c0-0.5,0.2-1.1,0.6-1.4
	L11.3,2.3L11.3,2.3z"/>
    </Icon>
);

export default withTheme(OrganizationsMenu);
