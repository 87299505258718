import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../constants/styles';

const Climate = (props) => (
    <Container isActive={props.isActive}>
      <svg
          width="20px"
          height="20px"
          id="climate"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          enableBackground="new 0 0 24 24"
          xmlSpace="preserve"
      >
        <g id="mask">
          <path fill={props.isActive ? styles.colors.causes.climate : styles.colors.silver} d="M12,0.2c0.4,0,0.8,0.3,0.8,0.8v2c0,0.4-0.3,0.8-0.8,0.8
	S11.2,3.4,11.2,3V1C11.2,0.6,11.6,0.2,12,0.2z M20.3,3.7c0.3,0.3,0.3,0.8,0,1.1l-1.4,1.4c-0.1,0.1-0.3,0.2-0.5,0.2
	c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.8,0-1.1l1.4-1.4C19.5,3.4,20,3.4,20.3,3.7z M4.8,3.7l1.4,1.4c0.3,0.3,0.3,0.8,0,1.1
	C6,6.3,5.8,6.4,5.6,6.4S5.3,6.3,5.1,6.2L3.7,4.8C3.4,4.5,3.4,4,3.7,3.7C4,3.4,4.5,3.4,4.8,3.7z M23,11.2c0.4,0,0.8,0.3,0.8,0.8
	s-0.3,0.8-0.8,0.8h-2c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8H23z M3,11.2c0.4,0,0.8,0.3,0.8,0.8S3.4,12.8,3,12.8H1
	c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8H3z M12,7c2.8,0,5,2.2,5,5c0,2.8-2.2,5-5,5c-2.8,0-5-2.2-5-5C7,9.2,9.2,7,12,7z M6.2,17.8
	c0.3,0.3,0.3,0.8,0,1.1l-1.4,1.4c-0.1,0.1-0.3,0.2-0.5,0.2s-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.8,0-1.1l1.4-1.4
	C5.4,17.5,5.9,17.5,6.2,17.8z M18.9,17.8l1.4,1.4c0.3,0.3,0.3,0.8,0,1.1c-0.1,0.1-0.3,0.2-0.5,0.2s-0.4-0.1-0.5-0.2l-1.4-1.4
	c-0.3-0.3-0.3-0.8,0-1.1C18.1,17.5,18.6,17.5,18.9,17.8z M12,20.2c0.4,0,0.8,0.3,0.8,0.8v2c0,0.4-0.3,0.8-0.8,0.8s-0.8-0.3-0.8-0.8
	v-2C11.2,20.6,11.6,20.2,12,20.2z"/>
        </g>
      </svg>
    </Container>
);

export default Climate;

Climate.propTypes = {
  isActive: PropTypes.bool
};

Climate.defaultProps = {
  isActive: true
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: ${props => props.isActive ? `${styles.colors.causes.climate}1f` : styles.colors.mostlyWhite};
    border-radius: 20px;
`;
