import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const CardIcon = props => (
  <Icon {...props} viewBox={'0 0 24 24'}>
    <path d="M21,5H3C2.4,5,2,5.4,2,6v3h20V6C22,5.4,21.6,5,21,5z M22,11H2v7c0,0.6,0.4,1,1,1h18c0.6,0,1-0.4,1-1V11z
	 M21,3c1.7,0,3,1.3,3,3v12c0,1.7-1.3,3-3,3H3c-1.7,0-3-1.3-3-3V6c0-1.7,1.3-3,3-3H21z"/>
  </Icon>
);

export default withTheme(CardIcon);
