import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CITIES, INPUT_SIZE, INPUT_TYPE } from '../../../../constants';
import { Input, Select } from '../../../../components/Fields';

const sortOptions = [
  { value: 'all', label: 'All' },
  { value: CITIES.ATLANTA.value, label: CITIES.ATLANTA.label },
  { value: CITIES.AUSTIN.value, label: CITIES.AUSTIN.label },
  { value: CITIES.CINCINNATI.value, label: CITIES.CINCINNATI.label },
  { value: CITIES.CLEVELAND.value, label: CITIES.CLEVELAND.label },
  { value: CITIES.COLUMBUS.value, label: CITIES.COLUMBUS.label },
  { value: CITIES.DENVER.value, label: CITIES.DENVER.label }
];

const EventsFilters = ({ updateParams, sortValue, searchValue }) => {
  return (
    <Container>
      <SortPlace>
        <Select
          size={INPUT_SIZE.SMALL}
          minWidth={200}
          onChange={(val) => updateParams({ city: val })}
          value={sortValue}
          placeholder={sortOptions[0].label}
          options={sortOptions}
        />
      </SortPlace>
      <Form>
        <Input
          type={INPUT_TYPE.SEARCH}
          size={INPUT_SIZE.SMALL}
          name="search"
          placeholder="Search Events"
          value={searchValue}
          onChange={(e) => updateParams({ search: e.target.value, page: 1 })}
          dataTestId={'events-list-input-search'}
        />
      </Form>
    </Container>
  );
};

export default EventsFilters;

EventsFilters.propTypes = {
  updateParams: PropTypes.func.isRequired,
  sortValue: PropTypes.string,
  searchValue: PropTypes.string
};

EventsFilters.defaultProps = {
  sortValue: null,
  searchValue: ''
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px -5px 5px;
  position: relative;
  flex-wrap: wrap;
  @media screen and (max-width: 375px) {
    justify-content: center;
  }
`;
const SortPlace = styled.div`
  display: flex;
  margin: 5px;
`;
const Form = styled.form`
  width: 240px;
  margin: 5px;
  @media screen and (max-width: 1330px) {
    width: 180px;
  }
  @media screen and (max-width: 575px) {
    width: calc(100% - 10px);
  }
`;
