import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const CameraIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M21,22H3c-1.7,0-3-1.3-3-3V8c0-1.7,1.3-3,3-3h3.5l1.7-2.6C8.4,2.2,8.7,2,9,2h6c0.3,0,0.6,0.2,0.8,0.4L17.5,5
	H21c1.7,0,3,1.3,3,3v11C24,20.7,22.7,22,21,22z M3,7C2.4,7,2,7.4,2,8v11c0,0.6,0.4,1,1,1h18c0.6,0,1-0.4,1-1V8c0-0.6-0.4-1-1-1h-4
	c-0.3,0-0.6-0.2-0.8-0.4L14.5,4H9.5L7.8,6.6C7.6,6.8,7.3,7,7,7H3z"/>
            <path d="M12,17c-2.8,0-5-2.2-5-5s2.2-5,5-5s5,2.2,5,5S14.8,17,12,17z M12,9c-1.7,0-3,1.3-3,3s1.3,3,3,3s3-1.3,3-3
	S13.7,9,12,9z"/>
        </Icon>
    );

export default withTheme(CameraIcon);
