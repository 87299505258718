import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const ArchiveRegistryIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M12,2c0.6,0,1,0.4,1,1v5.6l1.3-1.3c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4l-3,3c-0.1,0.1-0.2,0.2-0.3,0.2
	C12.3,12,12.1,12,12,12h0c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2l-3-3c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0L11,8.6V3
	C11,2.4,11.4,2,12,2z M7.1,14H4v6h16v-6h-3.1c-0.5,2.3-2.5,4-4.9,4S7.6,16.3,7.1,14z M6.8,3L8,3c0.6,0,1,0.4,1,1S8.6,5,8,5H6.7
	l-2.3,7H8c0.6,0,1,0.4,1,1c0,1.7,1.3,3,3,3s3-1.3,3-3c0-0.6,0.4-1,1-1h3.6l-2.3-7L16,5c-0.6,0-1-0.4-1-1s0.4-1,1-1h1.3
	c0.9,0,1.6,0.5,1.9,1.4l2.8,8.3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2v7c0,1.1-0.9,2-2,2H4c-1.1,0-2-0.9-2-2v-7c0-0.1,0-0.1,0-0.2
	c0-0.1,0-0.1,0-0.2l2.7-8.3C5.1,3.5,5.9,2.9,6.8,3z" />
        </Icon>
    );

export default withTheme(ArchiveRegistryIcon);
