import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const AdminViewIcon = props => (
    <Icon {...props} viewBox={'0 0 24 24'}>
        <path d="M12,24c-0.2,0-0.3,0-0.4-0.1c-0.4-0.2-9.4-4.8-9.4-11.9V4.3c0-0.4,0.3-0.8,0.6-0.9l8.8-3.3c0.2-0.1,0.5-0.1,0.7,0l8.8,3.3
	c0.4,0.1,0.6,0.5,0.6,0.9V12c0,7.1-9,11.7-9.4,11.9C12.3,24,12.2,24,12,24z M4.2,5v7c0,5.1,6.1,8.9,7.8,9.9c1.7-1,7.8-4.8,7.8-9.9V5
	L12,2.1L4.2,5z"/>
        <path d="M12,17c-4.1,0-6.3-4.4-6.4-4.6c-0.1-0.3-0.1-0.6,0-0.9C5.7,11.4,7.9,7,12,7s6.3,4.4,6.4,4.6c0.1,0.3,0.1,0.6,0,0.9
	C18.3,12.6,16.1,17,12,17z M7.7,12c0.6,0.9,2.1,3,4.3,3c2.2,0,3.8-2.1,4.3-3c-0.6-0.9-2.1-3-4.3-3C9.8,9,8.2,11.1,7.7,12z M6.5,12
	L6.5,12L6.5,12z"/>
        <circle cx="12" cy="12" r="1.5"/>
    </Icon>
);

export default withTheme(AdminViewIcon);
