import React from 'react';
import { withTheme } from 'styled-components';
import Icon from './components/Icon';

const SchoolProfileMenu = props => (
        <Icon {...props}>
            <path d="M16,0c0.5,0,0.9,0.4,1,0.9L17,1v2c0,1.6-1.2,2.9-2.8,3L14,6h-3v3h11c0.5,0,0.9,0.4,1,0.9
	l0,0.1v14H1V10c0-0.5,0.4-0.9,0.9-1L2,9h7V1c0-0.5,0.4-0.9,0.9-1L10,0H16z M13,19h-2v3h2V19z M21,11H3v11h6v-4c0-0.5,0.4-0.9,0.9-1
	l0.1,0h4c0.5,0,0.9,0.4,1,0.9l0,0.1v4h6V11z M18,17c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S17.4,17,18,17z M6,17c0.6,0,1,0.4,1,1
	s-0.4,1-1,1s-1-0.4-1-1S5.4,17,6,17z M18,13c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S17.4,13,18,13z M14,13c0.6,0,1,0.4,1,1
	s-0.4,1-1,1s-1-0.4-1-1S13.4,13,14,13z M10,13c0.6,0,1,0.4,1,1s-0.4,1-1,1c-0.6,0-1-0.4-1-1S9.4,13,10,13z M6,13c0.6,0,1,0.4,1,1
	s-0.4,1-1,1s-1-0.4-1-1S5.4,13,6,13z M15,2h-4v2h3c0.5,0,0.9-0.4,1-0.9L15,3V2z"/>
        </Icon>
    );

export default withTheme(SchoolProfileMenu);
