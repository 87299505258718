import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const AskIcon = props => (
  <Icon {...props} viewBox={'0 0 24 24'}>
    <rect fill="none" width="24" height="24"/>
    <path d="M12.4,2.8c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1H6c-1.1,0-2,0.9-2,2V18c0,1.1,0.9,2,2,2h11.2
			c1.1,0,2-0.9,2-2v-6.4c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1V18c0,2.2-1.8,4-4,4H6c-2.2,0-4-1.8-4-4V6.8c0-2.2,1.8-4,4-4H12.4z
			 M18.6,2.3l3.1,3.1C21.9,5.6,22,5.9,22,6.1c0,0.3-0.1,0.5-0.3,0.7l-9.4,9.4c-0.2,0.2-0.4,0.3-0.7,0.3H8.5c-0.6,0-1-0.4-1-1v-3.1
			c0-0.3,0.1-0.5,0.3-0.7l9.4-9.4C17.6,1.9,18.2,1.9,18.6,2.3z M15.5,6.8l-6,6v1.7h1.7l6-6L15.5,6.8z M17.9,4.4l-0.9,0.9l1.7,1.7
			l0.9-0.9L17.9,4.4z"/>
  </Icon>
);

export default withTheme(AskIcon);
