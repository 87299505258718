import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const PlusCircleIcon = props => (
    <Icon {...props} viewBox={'0 0 24 24'}>
        <path d="M12,8c0.6,0,1,0.4,1,1v2h2c0.6,0,1,0.4,1,1s-0.4,1-1,1h-2v2c0,0.6-0.4,1-1,1
	s-1-0.4-1-1v-2H9c-0.6,0-1-0.4-1-1s0.4-1,1-1h2V9C11,8.4,11.4,8,12,8z M12,4c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S16.4,4,12,4z
	 M12,2c5.5,0,10,4.5,10,10c0,5.5-4.5,10-10,10C6.5,22,2,17.5,2,12C2,6.5,6.5,2,12,2z"/>
    </Icon>
);

export default withTheme(PlusCircleIcon);
