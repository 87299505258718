import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import styles from '../../../../constants/styles';
import { bytesToSize, formatPhoneNumber, getDataTime, getImageURI, getTypeNameByType, downloadFileByURL, getFileName } from '../../../../_helpers';
import { H2, H3, H4, H5, H6, P1, P2 } from '../../../../components/Typography';
import Causes from '../../../../components/Causes';
import { EmailIcon, EventIcon, LocationSolidIcon, MobilePhoneIcon, TimeIcon, OnGoingIcon } from '../../../../components/Icons/General';
import { ProfileMenu } from '../../../../components/Icons/Menu';
import { InnerHTML } from '../../../../components/PEditor';
import Badge from '../../../../components/Badge';
import { ListItemWithIcon, Map, ProgramBadge, ShortProfile, ShortFile } from '../../../../components/Elements';
import { Modal } from '../../../../components/Modal';
import { EventVirtualUrl } from '../../components';
import { PUBLIC_ORGANIZATION_ROUTE } from '../../../../constants/links';
import { Button } from '../../../../components/Buttons';
import { acceptInviteOrganization, cancelRequestOrganization, declineInviteOrganization, leaveOrganization, requestOrganization } from '../../../../actions/profileActions';
import { getUserEmailsStatus } from '../../../../actions/accountActions';
import { getDocumentDownloadLink } from '../../../../actions/documentsActions';
import Avatar from '../../../../components/Avatar';
import { Calendar } from '../../../../components/Calendar';
import Hint from '../../../../components/Hint';

import imagePopup from '../../../../assets/images/svg/request-sent.svg';
import eventDetailsEmpty from '../../../../assets/images/default-event.png';
import privateEventDetails from '../../../../assets/images/svg/private-event.svg';

class Details extends Component {
  constructor (props) {
    super(props);
    this.state = {
      showText: false,
      showDescription: false,
      isLoading: false,
      successPopup: false,
      leavePopup: false,
      isLoadingLeave: false,
      showAcceptPopup: false,
      isLoadingAccept: false,
      declinePopup: false,
      requestStatus: null,
      showPopupCalendar: false
    };
  }

  joinToOrganization = async () => {
    this.setState({ isLoading: true });

    const profileData = JSON.parse(localStorage.getItem('POINT.profileData'));
    if (profileData && !profileData.isPrimaryEmailVerified) {

      const data = await getUserEmailsStatus();
      if (data.isPrimaryEmailVerified) {
        profileData.isPrimaryEmailVerified = true;
        localStorage.setItem('POINT.profileData', JSON.stringify(profileData));
      } else {
        this.props.setInfo({ showEmailVerifyPopUp: true });
        this.setState({ isLoading: false });
        return false;
      }
    }
    const { singleEvent } = this.props;
    let organizationId = singleEvent.organization.id || null;
    if (organizationId) {
      const res = await requestOrganization({ organizationId: organizationId });
      if (res && res.error) {
        this.setState({ isLoading: false });
        return false;
      }
      this.setState({ requestStatus: res.requestStatus });
      this.setState({ successPopup: true });
      this.props.updateStatus();
    }
    this.setState({ isLoading: false });
  };

  leaveOrganization = async () => {
    this.setState({ isLoadingLeave: true });
    const { singleEvent, currentOrganization } = this.props;
    let organizationId = singleEvent.organization.id || null;
    if (organizationId) {

      let result;
      if (currentOrganization.hasJoinRequest) {
        result = await cancelRequestOrganization({ organizationId: organizationId });
        if (result) {
          this.setState({ leavePopup: false });
          this.props.updateStatus();
        }
      } else if (currentOrganization.isClient) {
        result = await leaveOrganization({ organizationId: organizationId });
        if (result && result.status && result.status === 200 || result && !result.status) {
          this.setState({ leavePopup: false });
          this.props.updateStatus();
        }
      }
    }
    this.setState({ isLoadingLeave: false });
  };

  declineOrganization = async () => {
    this.setState({ isLoadingLeave: true });
    const { singleEvent } = this.props;
    let organizationId = singleEvent.organization.id || null;
    if (organizationId) {
      let result = await declineInviteOrganization({ organizationId: organizationId });

      if (result && result.status && result.status !== 200) {
        this.setState({ isLoadingLeave: false });
        return false;
      }
      if (result) {
        this.setState({
          declinePopup: false
        });
        this.props.updateStatus();
      }
    }
    this.setState({ isLoadingLeave: false });
  };

  acceptToOrganization = async () => {
    this.setState({ isLoadingAccept: true });
    const { singleEvent } = this.props;
    let organizationId = singleEvent.organization.id || null;
    if (organizationId) {
      const result = await acceptInviteOrganization({ organizationId: organizationId });
      if (result) {
        this.setState({ showAcceptPopup: true });
        this.props.updateStatus();
      }
    }
    this.setState({ isLoadingAccept: false });
  };

  renderJoinButtons = () => {
    const profileData = JSON.parse(localStorage.getItem('POINT.profileData'));
    if (!(profileData && profileData.id)) {
      return false;
    }

    let joinStatus = null;
    const { currentOrganization } = this.props;
    if (currentOrganization && currentOrganization.isClient) {
      joinStatus = <Badge variant="joined" size="sm"/>;
    }
    if (currentOrganization && currentOrganization.hasJoinRequest) {
      joinStatus = <Badge variant="pending" size="sm"/>;
    }

    if (currentOrganization && currentOrganization.hasInvite) {
      return (
        <Buttons>
          <Button
            value="Decline Organization"
            onClick={() => this.setState({ declinePopup: true })}
            size="sm"
            variant="outline-light"
          />
          <Button
            value="Accept Organization"
            onClick={() => this.acceptToOrganization()}
            size="sm"
            isLoading={this.state.isLoading}
          />
        </Buttons>
      );
    }
    if (currentOrganization && (currentOrganization.isClient || currentOrganization.hasJoinRequest)) {
      return (
        <Buttons>
          {joinStatus}
          <Button
            size="sm"
            variant="outline-light"
            value="Leave Organization"
            onClick={() => this.setState({ leavePopup: true })}
          />
        </Buttons>
      );
    }
    return (
      <Buttons>
        {joinStatus}
        <Button
          value="Join Organization"
          onClick={() => this.joinToOrganization()}
          size="sm"
          isLoading={this.state.isLoading}
        />
      </Buttons>
    );
  };

  renderCoHosts = (coHosts) => {
    return coHosts.map((coHost, index) => {
      return (
        <CoHostBlock key={index}>
          <ShortProfile
            onClick={() => window.open(PUBLIC_ORGANIZATION_ROUTE.replace(':oid', coHost.id))}
            image={coHost.logo}
            title={coHost.organizationName}
            subtitle={getTypeNameByType(coHost.type)}
          />
        </CoHostBlock>
      );
    });
  };

  renderCausesItem = () => {
    const primaryCauseObj = filter(this.props.singleEvent.causes, { 'isPrimary': true });
    const primaryCauseId = primaryCauseObj && Array.isArray(primaryCauseObj) && primaryCauseObj.length > 0 ? primaryCauseObj[0].id : 0;
    return (
      <Causes
        causesState={'view'}
        selectedCauses={this.props.singleEvent.causes}
        primaryCauseId={primaryCauseId}
      />
    );
  };

  renderPrivateEvent = () => {

    let text = 'This a private event. Please sign in with your POINT account to see more details.';
    const profileData = JSON.parse(localStorage.getItem('POINT.profileData'));
    if (profileData && profileData.id) {
      text = 'This a private event.  This event is not available to you.';
    }

    return (
      <EventDetails>
        <BlockPrivate>
          <ImagePrivate src={privateEventDetails} alt="PrivateEvent" />
          <TextPrivate>{text}</TextPrivate>
        </BlockPrivate>
      </EventDetails>
    )
  }

  openFile = async (item) => {
    const results = await getDocumentDownloadLink({uuids: [item.uuid ]});
    if (results && results.items && results.items.length) {
      const index = results.items.findIndex(el => el.uuid === item.uuid);
      if (index > -1) {
        let dataPath = results.items[index].downloadUrl;
        let link = dataPath ? `${process.env.REACT_APP_FIREBASE_PUBLIC_ENDPOINT.replace(':bucket', process.env.REACT_APP_FIREBASE_ORGANIZATIONBUCKET)}/${encodeURIComponent(dataPath)}?alt=media` : null;
        downloadFileByURL({
          fileURL: link,
          fileName: getFileName(dataPath)
        });
      }
    }
  }

  render () {
    const { singleEvent, currentOrganization } = this.props;
    const {
      title: eventTitle,
      startTime,
      endTime,
      timezone
    } = singleEvent;

    const eventImg = (singleEvent.images && singleEvent.images.length > 0 && typeof (singleEvent.images[0]) === 'string') ? getImageURI(singleEvent.images[0]) : '';
    if (this.props.isPrivate) {
      return (
        <Wrapper>
          {this.renderPrivateEvent()}
        </Wrapper>
      );
    }

    const dictionary = JSON.parse(localStorage.getItem('POINT.dictionary'));
    const dataDateTime = getDataTime(startTime, endTime, timezone, true);

    let organizationName = '';
    if (currentOrganization) {
      organizationName = currentOrganization.organizationName;
    }
    let textJoin = `Your request to join ${organizationName} has been sent. The ${organizationName} admin ` +
      `needs to approve your request before you'll see their private feed of events.`;
    if (this.state.requestStatus && this.state.requestStatus === 'accepted') {
      textJoin = `You have joined ${organizationName}.`;
    }

    return (
      <Wrapper>
        <Modal
          width={styles.modal.size.large}
          visible={this.state.showPopupCalendar}
          onClose={() => this.setState({ showPopupCalendar: false })}
          buttons={null}
        >
          <TitleModal>{'See More Dates'}</TitleModal>
          <TextModal>{'This opportunity is part of an ongoing series. Select a date to view details.'}</TextModal>
          <Calendar
            event={singleEvent}
            history={this.props.history}
            updateData={() => this.setState({ showPopupCalendar: false })}
          />
        </Modal>
        <Modal
          width={styles.modal.size.small}
          visible={this.state.successPopup}
          onClose={() => this.setState({ successPopup: false, requestStatus: null })}
          buttons={[ {
            onPress: () => this.setState({ successPopup: false, requestStatus: null }),
            value: 'Ok, I got it',
            dataTestId: 'cancel-modal-button'
          } ]}
        >
          <Image src={imagePopup}/>
          <Text>{textJoin}</Text>
        </Modal>
        <Modal
          width={styles.modal.size.small}
          visible={this.state.leavePopup}
          onClose={() => this.setState({ leavePopup: false })}
          buttons={[ {
            onPress: () => this.setState({ leavePopup: false }),
            value: 'Cancel',
            variant: 'outline-light',
            dataTestId: 'cancel-leave-modal-button'
          }, {
            onPress: () => this.leaveOrganization(),
            value: 'Leave',
            dataTestId: 'leave-modal-button',
            isLoading: this.state.isLoadingLeave
          } ]}
        >
          <ImageLogo
            size={48}
            src={currentOrganization ? currentOrganization.logo || '' : ''}
            firstName={currentOrganization ? currentOrganization.organizationName : ''}
          />
          <Text>{`Are you sure you want to leave this organization?`}</Text>
        </Modal>

        <Modal
          width={styles.modal.size.small}
          visible={this.state.declinePopup}
          onClose={() => this.setState({ declinePopup: false })}
          buttons={[ {
            onPress: () => this.setState({ declinePopup: false }),
            value: 'Cancel',
            variant: 'outline-light',
            dataTestId: 'hide-modal-button'
          }, {
            onPress: () => this.declineOrganization(),
            value: 'Decline',
            dataTestId: 'decline-modal-button',
            isLoading: this.state.isLoadingLeave
          } ]}
        >
          <ImageLogo
            size={48}
            src={currentOrganization ? currentOrganization.logo || '' : ''}
            firstName={currentOrganization ? currentOrganization.organizationName : ''}
          />
          <Text>{`Are you sure you would like to decline the invitation from ${currentOrganization ? currentOrganization.organizationName : ''}?`}</Text>
        </Modal>


        <Modal
          width={styles.modal.size.small}
          visible={this.state.showAcceptPopup}
          onClose={() => this.setState({ showAcceptPopup: false })}
          buttons={[ {
            onPress: () => this.setState({ showAcceptPopup: false }),
            value: 'Ok, I got it',
            dataTestId: 'hide-modal-button'
          } ]}
        >
          <ImageLogo
            size={48}
            src={currentOrganization ? currentOrganization.logo || '' : ''}
            firstName={currentOrganization ? currentOrganization.organizationName : ''}
          />
          <Text>{`You have joined ${currentOrganization ? currentOrganization.organizationName : ''}.`}</Text>
        </Modal>


        {(singleEvent && singleEvent.id) ? (
          <EventDetails>
            <RowEvent>
              <EventImagePlace>
                {eventImg ? (
                  <EventImage src={eventImg} alt={eventTitle || 'Event Image'}/>
                ) : (
                  <EventEmptySpace>
                    <EventEmptyImage src={eventDetailsEmpty} alt={'Event Empty Image'}/>
                  </EventEmptySpace>
                )}
              </EventImagePlace>
            </RowEvent>
            <RowEventHeader>
              <ColEvent>
                <TitleEvent data-testid={'event-detail-title'}>{eventTitle}</TitleEvent>
                {singleEvent.isVirtual ? (
                  <Badges data-testid={'event-detail-title'}>
                    <Badge dataTestId={'virtual-badge'} variant={'virtual'}/>
                  </Badges>
                ) : null}
              </ColEvent>
            </RowEventHeader>
            <RowOrganization>
              <ColEvent>
                <RowTitle>{'CREATED BY'}</RowTitle>
                <BlockRow>
                  <ShortProfile
                    onClick={() => window.open(PUBLIC_ORGANIZATION_ROUTE.replace(':oid', singleEvent.organization.id))}
                    image={singleEvent.organization.logo}
                    title={singleEvent.organization.organizationName || singleEvent.organization.name}
                    subtitle={getTypeNameByType(singleEvent.organization.type)}
                    dataTestId={'event-creator'}
                  />
                  {this.renderJoinButtons()}
                </BlockRow>
              </ColEvent>
            </RowOrganization>
            <HorizontalLine/>
            {singleEvent.coHosts && singleEvent.coHosts.length > 0 ? (
              <>
                <RowEvent>
                  <ColEvent>
                    <RowTitle>{'CO-HOSTS'}</RowTitle>
                    {this.renderCoHosts(singleEvent.coHosts)}
                  </ColEvent>
                </RowEvent>
                <RemoverMarginBottom16/>
                <HorizontalLine/>
              </>
            ) : null}

            <RowEventDate>
              <ColEvent>
                <RowTitle>{'DATE & TIME'}</RowTitle>
                <RowDate>
                  <ColDate isOngoing={this.props.singleEvent.isRepeatable}>
                    <DateBlock>
                      <EventIcon height={24} width={24} defaultFill={styles.colors.dark}/>
                      <Per>{dataDateTime.dateString}</Per>
                      {this.props.singleEvent.isRepeatable ? (
                        <GeneralFragment>
                          <Hint
                            variant={'dark'}
                            shortValue={<OnGoingIcon defaultFill={styles.colors.primary} height={14} width={14}/>}
                            value={'This volunteer opportunity repeats, see the calendar to see more available dates to help.'}
                          />
                        </GeneralFragment>
                      ) : null}
                    </DateBlock>
                    <TimeBlock>
                      <TimeIcon height={24} width={24} defaultFill={styles.colors.dark}/>
                      <Per>{dataDateTime.timeString}</Per>
                    </TimeBlock>
                  </ColDate>
                  {this.props.singleEvent.isRepeatable && this.props.singleEvent.repeats && this.props.singleEvent.repeats.length  ? (
                    <Button
                      value={'See More Dates'}
                      variant="outline-light"
                      onClick={() => this.setState({ showPopupCalendar: true })}
                      Icon={<EventIcon/>}
                      dataTestId={'show-popup-calendar-button'}
                    />
                  ) : null}
                </RowDate>
              </ColEvent>
            </RowEventDate>

            <HorizontalLine/>
            <RowEventWrap>
              <ColHalfEvent>
                <RowTitle>{'ADDRESS'}</RowTitle>
                {singleEvent.address ? (
                  <ListItemWithIcon
                    Icon={<LocationSolidIcon defaultFill={styles.colors.dark} width={16} height={16}/>}
                    content={singleEvent.address}
                  />
                ) : null}
                <RemoverMarginBottom8/>
              </ColHalfEvent>
            </RowEventWrap>
            <HorizontalLine/>

            <RowEventWrap>
              <ColHalfEvent>
                <RowTitle>{'CONTACT INFO'}</RowTitle>
                {singleEvent.charityContactName ? (
                  <ListItemWithIcon
                    Icon={<ProfileMenu defaultFill={styles.colors.dark} width={16} height={16}/>}
                    content={`Contact is ${singleEvent.charityContactName} `}
                  />
                ) : null}
                {singleEvent.charityPhoneNumber ? (
                  <ListItemWithIcon
                    Icon={<Link href={`tel:${singleEvent.charityPhoneNumber}`}
                                target="_blank"><MobilePhoneIcon defaultFill={styles.colors.dark} width={16}
                                                                 height={16}/></Link>}
                    content={<Link href={`tel:${singleEvent.charityPhoneNumber}`}
                                   target="_blank">{formatPhoneNumber(singleEvent.charityPhoneNumber)}</Link>}
                  />
                ) : null}
                {singleEvent.contactEmail ? (
                  <ListItemWithIcon
                    Icon={(<Link href={`mailto:${singleEvent.contactEmail}`} target="_blank"
                                 rel="noopener noreferrer"><EmailIcon defaultFill={styles.colors.dark}
                                                                      width={16} height={16}/></Link>)}
                    content={(<Link href={`mailto:${singleEvent.contactEmail}`} target="_blank"
                                    rel="noopener noreferrer">{singleEvent.contactEmail}</Link>)}
                  />
                ) : null}
                <RemoverMarginBottom8/>
              </ColHalfEvent>
            </RowEventWrap>
            <HorizontalLine/>

            {singleEvent.isVirtual ? (
              <>
                <RowEvent>
                  <ColEvent>
                    <RowTitle>{'INSTRUCTIONS ON HOW TO PARTICIPATE VIRTUALLY OR AT HOME'}</RowTitle>
                    {singleEvent.onlineDescription ? <EventInstruction><InnerHTML
                      content={singleEvent.onlineDescription}/></EventInstruction> : null}
                    <EventVirtualUrl onlineAddressUrl={singleEvent.onlineAddressUrl}/>
                  </ColEvent>
                </RowEvent>
                <HorizontalLine/>
              </>
            ) : null}

            <RowEvent>
              <ColEvent>
                <RowTitle>ABOUT</RowTitle>
                <SubRowTitle>About the event</SubRowTitle>
                {singleEvent.description ? <InnerHTML content={singleEvent.description}/> : null}
                <SubRowTitle>Causes supported</SubRowTitle>
                {this.renderCausesItem()}

                {singleEvent.volunteerTasks && (
                  <div>
                    <SubRowTitle>{(this.props.singleEvent.coHosts && this.props.singleEvent.coHosts.length > 0) ? 'What will volunteers do?' : ((dictionary && dictionary._events_text_whatwillvolunteersdo) ? dictionary._events_text_whatwillvolunteersdo : 'What will volunteers do?')}</SubRowTitle>
                    <InnerHTML content={singleEvent.volunteerTasks}/>
                  </div>
                )}
                {singleEvent.volunteerDressCode && (
                  <div>
                    <SubRowTitle>{(this.props.singleEvent.coHosts && this.props.singleEvent.coHosts.length > 0) ? 'What will volunteers need to bring or wear?' : ((dictionary && dictionary._events_text_whatwillvolunteersneed) ? dictionary._events_text_whatwillvolunteersneed : 'What will volunteers need to bring or wear?')}</SubRowTitle>
                    <EventText>{singleEvent.volunteerDressCode}</EventText>
                  </div>
                )}
                {(singleEvent.locationDescription && !singleEvent.isVirtual) && (
                  <div>
                    <SubRowTitle>{(this.props.singleEvent.coHosts && this.props.singleEvent.coHosts.length > 0) ? 'What should volunteers know about the location?' : ((dictionary && dictionary._events_text_volunteersaboutlocation) ? dictionary._events_text_volunteersaboutlocation : 'What should volunteers know about the location?')}</SubRowTitle>
                    <InnerHTML content={singleEvent.locationDescription}/>
                  </div>
                )}

                {(singleEvent.lat && singleEvent.lng && !singleEvent.isVirtual) ?
                  <Map lat={singleEvent.lat} lng={singleEvent.lng}/> : null}
                {singleEvent.address && !singleEvent.isVirtual ? (
                  <AddressUnderMap>
                    <ListItemWithIcon
                      Icon={<LocationSolidIcon width={14} heigth={14} defaultFill={styles.colors.dark}/>}
                      content={singleEvent.address}
                    />
                  </AddressUnderMap>
                ) : null}
              </ColEvent>
            </RowEvent>

            {singleEvent.documents && singleEvent.documents.length > 0 ? (
              <HorizontalLine/>
            ) : null}
            {singleEvent.documents && singleEvent.documents.length > 0 ? (
              <RowEvent>
                <ColEvent>
                  <RowTitle>DOCUMENTS</RowTitle>
                  <AttachmentFiles>
                    {singleEvent.documents.map((attachment) => (
                      <ShortFile
                        openFile={() => this.openFile(attachment)}
                        title={attachment.title}
                        subtitle={bytesToSize(attachment.fileSize)}
                        dataTestId={`document-${attachment.uuid}`}
                        typeFile={attachment.fileContentType}
                        variant={'plate'}
                        isOnlyIcon
                      />
                    ))}
                  </AttachmentFiles>
                </ColEvent>
              </RowEvent>
            ) : null}

            {singleEvent.programs && Array.isArray(singleEvent.programs) && singleEvent.programs.length > 0 && (
              <RowPrograms>
                <ColEvent>
                  <RowTitle>PROGRAMS</RowTitle>
                  <ProgramsBlock>
                    {singleEvent.programs.map((el, index) => (
                      <ProgramBadge color={el.color} text={el.title} key={index}/>
                    ))}
                  </ProgramsBlock>
                </ColEvent>
              </RowPrograms>
            )}
          </EventDetails>) : this.renderPrivateEvent()}

      </Wrapper>
    );
  }
}

Details.propTypes = {
  singleEvent: PropTypes.shape({}).isRequired,
  updateStatus: PropTypes.func.isRequired,
  setInfo: PropTypes.func.isRequired,
  currentOrganization: PropTypes.shape({}),
};

Details.defaultTypes = {
  currentOrganization: null
};

export default Details;

const Wrapper = styled.div`
  padding-top: 32px;
  border-radius: 20px;
  border: 1px solid ${styles.borders.color};
  margin: 16px;
  width: calc(100% - 32px);
`;
const ColEvent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  p {
    font-size: 16px;
    line-height: 24px;
  }
`;
const ColHalfEvent = styled(ColEvent)`
  padding: 0 16px 32px;
  width: 50%;
  @media screen and (max-width: 1199px) {
    width: 100%;
  }
`;
const EventDetails = styled.div`

`;
const RowEventWithoutSpace = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;
const RowEvent = styled(RowEventWithoutSpace)`
  padding: 0 32px 24px;
`;
const RowEventPrivate = styled(RowEventWithoutSpace)`
  padding: 0 32px 32px;
`;
const RowOrganization = styled(RowEventWithoutSpace)`
  padding: 0 32px 32px;
`;
const RowPrograms = styled(RowEventWithoutSpace)`
  border-top: 1px solid ${styles.borders.color};
  padding: 32px;
`;
const ProgramsBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: -12px -4px -4px;

  .program-badge {
    margin: 4px;
  }
`;
const RowEventWrap = styled(RowEventWithoutSpace)`
  padding: 0 16px 0;
  flex-wrap: wrap;
`;
const RowEventHeader = styled(RowEvent)`
  padding-bottom: 24px;
`;
const TitleEvent = styled(H2)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  word-break: break-word;
`;
const EventImagePlace = styled.div`
  width: 100%;
  height: 280px;
  max-height: 280px;
  overflow: hidden;
  position: relative;
  border-radius: 12px;
  @media screen and (max-width: 1199px) {
    height: auto;
  }
`;
const EventPrivateImagePlace = styled(EventImagePlace)`
  @media screen and (max-width: 1199px) {
    height: 280px;
  }
  @media screen and (max-width: 575px) {
    height: 200px;
  }
  @media screen and (max-width: 375px) {
    height: 150px;
  }
`;
const EventImage = styled.img`
  width: 100%;
`;
const EventEmptySpace = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${styles.colors.mostlyWhite};
  width: 100%;
  height: 100%;
`;
const ImageBlur = styled(EventEmptySpace)`
  background: ${styles.colors.light};
`;
const EventEmptyImage = styled.img`
  border-radius: 8px;
  width: 150px;
  margin: 32px;
`;
const RowTitle = styled(H5)`
  color: ${styles.colors.medium};
  font-family: ${styles.fonts.family.primaryBold};
  margin: 0 0 24px;
`;
const SubRowTitle = styled(H3)`
  color: ${styles.colors.dark};
  font-family: ${styles.fonts.family.primaryBold};
  margin: 8px 0;
`;
const EventDateTime = styled.div``;
const EventText = styled(P2)`
  margin: 0 0 16px;
  color: ${styles.colors.medium};
  font-family: ${styles.fonts.family.primaryBook};
`;
const AddressUnderMap = styled(H5)`
  color: ${styles.colors.dark};
  font-family: ${styles.fonts.family.primaryMedium};
  margin-top: 16px;
  margin-bottom: 0;

  & > div {
    margin-bottom: 0;
  }
`;
const HorizontalLine = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  margin-bottom: 24px;
  background-color: ${styles.borders.color};
`;
const EventInstruction = styled.div`
  color: ${styles.colors.medium};
  font-family: ${styles.fonts.family.primaryMedium};
  font-size: 14px;
  line-height: 22px;
  word-break: break-word;
  margin: 0 0 8px;
`;
const CoHostBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin-bottom: 16px;
  @media screen and (max-width: 575px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
`;
const Badges = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 8px -8px -8px;

  & > div, & > span {
    margin: 8px;
  }
`;
const Link = styled.a`
  color: ${styles.colors.dark};
  font-family: ${styles.fonts.family.primaryBook};
  text-decoration: none;
  color: ${styles.colors.dark};
  cursor: pointer;

  &:hover {
    color: ${styles.colors.dark};
  }
`;
const RemoverMarginBottom8 = styled.div`
  margin-bottom: -8px;
`;
const RemoverMarginBottom16 = styled.div`
  margin-bottom: -16px;
`;
const Title = styled(H4)`
  margin: 0;
  word-break: break-word;
  color: ${styles.colors.dark};
`;

const DataPrivate = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  margin: 0;
  justify-content: center;
  flex-direction: column;
`;
const Icon = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: ${styles.colors.system.alert};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 0 8px;
`;

const BlockRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 475px) {
    flex-direction: column;
    align-items: flex-start;
    .btn {
      margin-top: 12px;
    }
  }
`;
const Image = styled.img`
  width: 64px;
  height: 64px;
  margin: 0 0 24px;
`;
const ImageLogo = styled(Avatar)`
  margin: 0 0 24px;
`;
const Text = styled(H4)`
  color: ${styles.colors.medium};
  font-family: ${styles.fonts.family.primaryBook};
  line-height: 24px;
  margin: 0;
`;
const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin: 0 -4px 0 8px;

  .btn, .badge {
    margin: 4px;
  }

  @media screen and (max-width: 475px) {
    margin: 12px -4px 0 -4px;
    justify-content: flex-start;
  }
`;

const BlockPrivate = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 32px 32px;
  padding: 24px;
  border-radius: 12px;
  background: ${styles.colors.primary}33;
  min-height: 320px;
`;
const TextPrivate = styled(H3)`
  margin: 0;
  text-align: center;
  font-family: ${styles.fonts.family.primaryMedium};
  max-width: 450px;
`;
const ImagePrivate = styled.img`
  margin: 0 0 24px;
  text-align: center;
  max-width: 64px;
`;
const AttachmentFiles = styled.div`
  display: flex;
  flex-direction: row;
  margin: -4px;
  width: calc(100% + 8px);
  max-width: calc(100% + 8px);
  flex-wrap: wrap;
  h6 {
    color: ${styles.colors.medium};
  }
`;
const Doc = styled.div`
  background: ${styles.colors.mostlyWhite};
  border-radius: 12px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 4px;
`;
const Open = styled.div`
  cursor: pointer;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  height: 20px;
  margin: 8px 6px 8px 16px;
`;

const TitleModal = styled(H2)`
  margin: 0 0 16px;
  color: ${styles.colors.dark};
`;
const TextModal = styled(P1)`
  line-height: 20px;
  margin: 0 0 24px;
  color: ${styles.colors.medium};
`;
const RowDate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  @media screen and (max-width: 620px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;
const ColDate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: ${props => props.isOngoing ? '24px' : 0};
  @media screen and (max-width: 620px) {
    padding: ${props => props.isOngoing ? '0 0 16px' : 0};
  }
`;
const GeneralFragment = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  min-width: 24px;
  border-radius: 12px;
  margin: 0 0 0 8px;
  border: 1px solid ${styles.colors.primary}33;
  background: transparent;
  transition: 0.5s;
  span {
    display: flex;
  }
  &:hover {
    background: ${styles.colors.primary}1a;
    transition: 0.5s;
  }
`;
const DateBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 16px 8px 0;
`;
const Per = styled(H4)`
  font-family: ${styles.fonts.family.primaryMedium};
  color: ${styles.colors.dark};
  margin: 2px 0 0 8px;
`;
const TimeBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
  ${Per} {
    white-space: nowrap;
  }
`;
const RowEventDate = styled(RowEventWithoutSpace)`
  padding: 0 32px 24px;
  @media screen and (max-width: 425px) {
    padding: 0 0 24px;
  }
`;
