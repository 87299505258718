import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const ArrowLeftIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M8,12c0-0.3,0.1-0.5,0.3-0.7l6-6c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4L10.4,12l5.3,5.3c0.4,0.4,0.4,1,0,1.4
	s-1,0.4-1.4,0l-6-6C8.1,12.5,8,12.3,8,12z"/>
        </Icon>
    );

export default withTheme(ArrowLeftIcon);
