import React, {Component} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
    centsToDollars,
    getNumberWithCommas,
    nFormatter
} from '../../_helpers';
import styles from '../../constants/styles';

class NumberWithHint extends Component {
    constructor (props) {
        super (props);
        this.state = {
            showHintText: false
        };
        this.showTooltip = this.showTooltip.bind(this);
    }

    showTooltip = () => {
        let count = this.props.isDollars ? 1000000 : 10000;

        if (+this.props.value > count) {
            this.setState({
                showHintText: !this.state.showHintText
            });
        }
    };

    mathRound = (value) => Math.round(value*100)/100;

    render () {
        const {showHintText} = this.state;
        let hintText = this.props.isDollars ? `$${getNumberWithCommas(this.mathRound(centsToDollars(this.props.value || 0)))}` : getNumberWithCommas(this.mathRound(this.props.value || 0));
        let text = hintText;

        if (+this.props.value > 10000) {
            text = this.props.isDollars ? `$${nFormatter(this.mathRound(centsToDollars(this.props.value || 0)))}` : nFormatter(this.mathRound(this.props.value || 0));
        }

        return (
            <HintBlock
                onMouseEnter={this.showTooltip}
                onMouseLeave={this.showTooltip}
                data-testid={this.props.dataTestId}
                className={this.props.className}
            >
                {text}
                {showHintText && <HintText>{hintText}<Triangle /></HintText>}
            </HintBlock>
        );
    }
}
export default NumberWithHint;

NumberWithHint.propTypes = {
    value: PropTypes.number,
    isDollars: PropTypes.bool,
    dataTestId: PropTypes.string,
    className: PropTypes.string
};

NumberWithHint.defaultProps = {
    isDollars: false,
    value: 0,
    dataTestId: null,
    className: null
};

const HintBlock = styled.span`
    position: relative;
    white-space: nowrap;
`;
const HintText = styled.div`
    position: absolute;
    bottom: calc(100% + 10px);
    left: 0;
    width: auto;
    max-width: 450px;
    background: white;
    color: ${props => props.theme.colors.text.dark};
    border-radius: 4px;
    padding: 4px 8px;
    font-family: ${styles.fonts.family.primaryMedium};
    font-size: 12px;
    line-height: 16px;
    z-index: 9;
    white-space: nowrap;
    text-align: center;
    box-shadow: ${props => props.theme.shadows.tooltip};
`;
const Triangle = styled.div`
    position: absolute;
    bottom: -2px;
    margin-left: -3px;
    left: 50%;
    z-index: 10;
    border-radius: 0 0 1px 0;
    width: 6px;
    height: 6px;
    background: white;
    transform: rotate(45deg);
    box-shadow: 1px 1px 3px 0 rgba(38, 38, 38, 0.04);
`;
