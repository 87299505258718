import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const SaveIcon = props => (
  <Icon {...props} viewBox={'0 0 24 24'}>
    <rect fill="none" width="24" height="24"/>
    <path d="M7.5,12.1c0.6,0,1,0.4,1,1s-0.4,1-1,1H3.2v5.5h17.5v-5.5h-4.2c-0.6,0-1-0.4-1-1s0.4-1,1-1
			H21c1,0,1.8,0.8,1.8,1.8v6c0,1-0.8,1.8-1.8,1.8H3c-1,0-1.8-0.8-1.8-1.8v-6c0-1,0.8-1.8,1.8-1.8H7.5z M17.6,15.8
			c0.6,0,1.1,0.5,1.1,1.1S18.2,18,17.6,18s-1.1-0.5-1.1-1.1S17,15.8,17.6,15.8z M12,2.4c0.6,0,1,0.4,1,1v7.3l2.8-2.8
			c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4l-4.5,4.5c0,0-0.1,0.1-0.1,0.1l0.1-0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1
			c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0
			c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1L6.8,9.3
			c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l2.8,2.8V3.4C11,2.8,11.4,2.4,12,2.4z"/>

  </Icon>
);

export default withTheme(SaveIcon);
