import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const SearchIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M20.7,19.3l-3.4-3.4c1.1-1.4,1.7-3.1,1.7-4.9c0-4.4-3.6-8-8-8s-8,3.6-8,8s3.6,8,8,8c1.8,0,3.5-0.6,4.9-1.7
                l3.4,3.4c0.4,0.4,1,0.4,1.4,0l0,0C21.1,20.3,21.1,19.7,20.7,19.3L20.7,19.3z M11,17c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6
                S14.3,17,11,17z"/>
        </Icon>
    );

export default withTheme(SearchIcon);
