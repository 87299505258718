import { isNil } from 'lodash';
import { getClientEmailsList, getUser } from '../../../actions/profileActions';
import validateClientProfile from '../validate/profile';
import { asyncForEach } from '../../../_helpers';

const getVerificationEmail = async () => {

  const userInfo = JSON.parse(localStorage.getItem('POINT.profileData'));
  let profileLocal = {};
  if (!isNil(userInfo)) {
    profileLocal = userInfo;
  } else {
    return false;
  }

  if (!profileLocal.isCompleteProfile) {
    let profile = await getUser();

    if (profile && profile.id) {
      const errorsProfile = validateClientProfile(profile);
      if (errorsProfile && Object.keys(errorsProfile).length) {
        profileLocal.isCompleteProfile = false;
      } else {
        profileLocal = profile;
        profileLocal.isCompleteProfile = true;
        profileLocal.isPrimaryEmailVerified = userInfo.isPrimaryEmailVerified
      }
    } else {
      const errors = validateClientProfile(profileLocal);
      if (errors && Object.keys(errors).length) {
        profileLocal.isCompleteProfile = false;
      } else {
        profileLocal.avatar = userInfo.avatar;
        profileLocal.biography = userInfo.biography;
        profileLocal.birthday = userInfo.birthday;
        profileLocal.causes = userInfo.causes;
        profileLocal.address = userInfo.address;
        profileLocal.firstName = userInfo.firstName;
        profileLocal.lastName = userInfo.lastName;
        profileLocal.gender = userInfo.gender;
        profileLocal.phone = userInfo.phone;
        profileLocal.username = userInfo.username;
        profileLocal.website = userInfo.website;
        profileLocal.isCompleteProfile = true;
      }
    }
  }

  if (!profileLocal.isPrimaryEmailVerified) {
    const emails = await getClientEmailsList();

    if (emails && emails.items) {
      await asyncForEach(emails.items, async (item) => {
        if (item.email === profileLocal.email) {
          profileLocal.isPrimaryEmailVerified = item.isVerified;
        }
      });
    }
  }
  localStorage.setItem('POINT.profileData', JSON.stringify(profileLocal));

  return {
    isPrimaryEmailVerified: profileLocal.isPrimaryEmailVerified,
    isCompleteProfile: profileLocal.isCompleteProfile,
    address: profileLocal.address
  };
};

export default getVerificationEmail;
