import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../constants/styles';

const Education = (props) => (
    <Container isActive={props.isActive}>
      <svg
          width="20px"
          height="20px"
          id="education"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          enableBackground="new 0 0 24 24"
          xmlSpace="preserve"
      >
        <g id="mask">
          <path fill={props.isActive ? styles.colors.causes.education : styles.colors.silver} d="M19.2,2H7C5.3,2,3.8,3.5,3.8,5.2v13.5C3.8,20.5,5.3,22,7,22h12.2
	c0.6,0,1-0.4,1-1V3C20.2,2.4,19.8,2,19.2,2z M7,20c-0.7,0-1.2-0.6-1.2-1.2s0.6-1.2,1.2-1.2h11.1V20H7z"/>
        </g>
      </svg>
    </Container>
);

export default Education;

Education.propTypes = {
  isActive: PropTypes.bool
};

Education.defaultProps = {
  isActive: true
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: ${props => props.isActive ? `${styles.colors.causes.education}1f` : styles.colors.mostlyWhite};
    border-radius: 20px;
`;