import React from 'react';
import {withTheme} from 'styled-components';
import Icon from '../../components/Icon';

const HelpIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M12,2c5.5,0,10,4.5,10,10c0,5.5-4.5,10-10,10C6.5,22,2,17.5,2,12C2,6.5,6.5,2,12,2z M12,4
	c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S16.4,4,12,4z M12,16c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S11.4,16,12,16z M12,6
	c1.6,0,2.9,1,3.4,2.5c0.5,1.8-0.5,3.7-2.2,4.3L13,12.8V14c0,0.6-0.4,1-1,1c-0.5,0-0.9-0.4-1-0.9l0-0.1v-2c0-0.6,0.4-1,1-1
	c0.8,0,1.5-0.7,1.5-1.5S12.8,8,12,8s-1.5,0.7-1.5,1.5c0,0.6-0.4,1-1,1s-1-0.4-1-1C8.5,7.6,10.1,6,12,6z"/>
        </Icon>
    );

export default withTheme(HelpIcon);
