import buildRequest from "./actions";
import {HTTP_METHODS} from "../constants";

export const getFirebaseTokenByOktaToken = async (data) => {
    return await buildRequest({
        method: HTTP_METHODS.POST,
        url: 'auth/okta',
        isAuth: false,
        bodyData: data
    });
};

export const getOktaUserInfoByToken = async ({baseUrl, accessToken}) => {
    return await buildRequest({
        method: HTTP_METHODS.POST,
        apiPath: `${baseUrl}/oauth2/default/v1/userinfo`,
        isAuth: false,
        config: {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        }
    });
};
