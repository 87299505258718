import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import styles from '../../../../constants/styles';
import { shiftSubscription, getClientEvents } from '../../../../actions/eventsActions';
import { Modal, ModalDesc, ModalImage, ModalTitle } from '../../../../components/Modal';
import { H2, H3, H4, H5, H6, P2 } from '../../../../components/Typography';
import { ListItemWithIcon } from '../../../../components/Elements';
import {
  BackgroundCheckIcon,
  ProfileIcon,
  SignedFormIcon,
  TrainingIcon,
  UnlockEmptyIcon,
  InfoCircleIcon
} from '../../../../components/Icons/General';
import { getGenderForShow, asyncForEach } from '../../../../_helpers';
import { ItemEvent } from '../../../../components/ItemEvent';
import { Shift } from '../../../../components/Events/Shift';

import warningImage from '../../../../assets/images/svg/warning.svg';
import { CANCELLATION_AND_NO_SHOW_POLICY } from '../../../../constants/links';

class ConfirmPopup extends Component {
  constructor (props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  confirmButtonPopup = async () => {
    this.setState({ loading: true });
    const { singleEvent, selectedShifts } = this.props;

    let accessKey;
    const url_string = window.location.href;
    const url = new URL(url_string);
    accessKey = url.searchParams.get('token');

    let params = { eventId: singleEvent.id, shifts: [] };

    let selectedShiftIds = [];
    selectedShifts.forEach((item) => {
      if (singleEvent && singleEvent.shifts && (singleEvent.shifts.length > 1)) {
        if (item.isAttending) {
          selectedShiftIds.push({ id: item.eventShiftId});
        }
      } else {
        if (!item.isAttending) {
          selectedShiftIds.push({ id: item.eventShiftId});
        }
      }
    });
    params.shifts = selectedShiftIds;
    if (accessKey) {
      params.accessKey = accessKey;
    }

    const results = await shiftSubscription(params);
    let shifts = [];
    let items_no_changes = [];
    if (results && (typeof results === 'object') && results.error) {
      this.props.setInfo({ messageShift: { error: results.error } });
    } else if (results) {
      await asyncForEach(results, async(item) => {
        if (item && ((item.data && item.data !== 'no_changes') || item.error)) {
          let index = singleEvent.shifts.findIndex(x => x.eventShiftId === item.id);
          let element = {
            name: singleEvent.shifts[index].name || `Shift ${index + 1}`
          };

          if (item.error) {
            element.error = item.error;
          }

          let startTime = Number(singleEvent.shifts[index].startTime);
          let endTime = Number(singleEvent.shifts[index].endTime);
          if (item && item.data) {
            element.message = item.data === 'signed_up' ? 'You have officially signed up.' : 'You have officially canceled.';
          }
          shifts.push(element);
        }
        if (item && item.data && item.data === 'no_changes') {
          let index = singleEvent.shifts.findIndex(x => x.eventShiftId === item.id);
          items_no_changes.push({
            name: singleEvent.shifts[index].name || `Shift ${index + 1}`,
            message: 'No changes'
          });
        }
      });

      if (shifts && !shifts.length && items_no_changes.length) {
        shifts = items_no_changes;
      }
      if (!results.length) {
        this.props.setInfo({ messageShift: { name: 'Shift 1', message: 'No changes' } });
      } else {
        if (singleEvent && singleEvent.shifts && (singleEvent.shifts.length === 1)) {
          this.props.setInfo({ messageShift: shifts[0] });
        } else {
          this.props.setInfo({ resultsByShift: shifts });
        }
      }
    }

    this.setState({ showListShifts: true });
    this.props.setInfo({ resultsPopup: true });

    this.props.closePopup();
    this.props.onClickConfirm();
    this.setState({ loading: false });
  };

  renderShifts = () => {
    const { singleEvent, selectedShifts, reservations } = this.props;

    return selectedShifts.map((item, index) => {

      if (item.isAttending && singleEvent.shifts.length > 1) {

        let isReserved = false;
        if (reservations.findIndex(el => el.eventShiftId === item.eventShiftId) > -1) {
          isReserved = true;
        }

        let indexShift = singleEvent.shifts.findIndex(e => e.eventShiftId === item.eventShiftId);
        let isAttending = singleEvent.shifts[indexShift].isAttending;

        return (
          <Shift
            key={index}
            item={item}
            eventStartTime={singleEvent.startTime}
            eventEndTime={singleEvent.endTime}
            eventTimeZone={singleEvent.timezone}
            counter={index + 1}
            isSpots={true}
            size={'full'}
            type={'details'}
            isSelected={true}
            isReserved={isReserved}
            isAttending={isAttending}
          />
        );
      }
      return false;
    });
  };

  render () {
    const { singleEvent } = this.props;

    if (!singleEvent) {
      return false;
    }
    const profileData = JSON.parse(localStorage.getItem('POINT.profileData'));
    const organizationName = singleEvent.organization ? (singleEvent.organization.name || singleEvent.organization.organizationName) : '';

    if (this.props.isChangeCancel) {
      return (
        <Modal
          width={styles.modal.size.small}
          visible={this.props.showConfirmPopup}
          onClose={() => this.props.closePopup()}
          buttons={[
            {
              onPress: () => this.confirmButtonPopup(),
              value: 'Cancel Signing Up',
              variant: 'danger',
              dataTestId: 'cancel-modal-button',
              isLoading: this.state.loading
            }
          ]}
        >
          <ModalImage src={warningImage} alt="Cancel SignUp"/>
          <ModalTitle>You’re almost canceled.</ModalTitle>
          <ModalDesc>Please confirm again that you will not show up the event.</ModalDesc>
        </Modal>
      );
    }

    return (
      <Modal
        width={styles.modal.size.medium}
        visible={this.props.showConfirmPopup}
        onClose={() => this.props.closePopup()}
        buttons={[
          {
            size: 'lg',
            value: 'Confirm',
            onPress: () => this.confirmButtonPopup(),
            variant: 'primary',
            block: true,
            dataTestId: 'sign-up-button',
            isLoading: this.state.loading,
          }
        ]}
      >
        <Content>
          <Title>Hi {profileData ? profileData.firstName : ''}</Title>
          <SubTitle>{this.props.isChangeCancel ? 'You’ve almost canceled.' : 'You’re almost signed up!'}</SubTitle>
          <Paragraph>Please confirm again that you will show up to volunteer and we will send your info to {organizationName}. {organizationName} is counting on you, so be aware of <TermsLink
            href={CANCELLATION_AND_NO_SHOW_POLICY} target="_blank">POINT’s Cancellation and No Show Policy.</TermsLink></Paragraph>
          <Label>Things to know:</Label>
          <Data>
            <ListItemWithIcon
              Icon={<ProfileIcon defaultFill={styles.colors.dark} width={16} height={16}/>}
              content={`Minimum age: ${singleEvent.minimumAge} year(s)`}
            />
            {(singleEvent.eventGender === 2 || singleEvent.eventGender === 3) ? (
              <ListItemWithIcon
                Icon={<UnlockEmptyIcon defaultFill={styles.colors.dark} width={16} height={16}/>}
                content={`Open to ${getGenderForShow(singleEvent.eventGender)}`}
              />
            ) : null}
            {singleEvent.backgroundCheckRequired && (
              <ListItemWithIcon
                Icon={<BackgroundCheckIcon defaultFill={styles.colors.dark} width={16} height={16}/>}
                content={'Background check required'}
              />
            )}
            {singleEvent.signedFormRequired && (
              <ListItemWithIcon
                Icon={<SignedFormIcon defaultFill={styles.colors.dark} width={16} height={16}/>}
                content={'Signed forms required'}
              />
            )}
            {singleEvent.trainingRequired && (
              <ListItemWithIcon
                Icon={<TrainingIcon defaultFill={styles.colors.dark} width={16} height={16}/>}
                content={'Training required'}
              />
            )}
          </Data>
          <ItemEvent
            indexItem={singleEvent.id}
            key={singleEvent.id}
            item={singleEvent}
            type={'mobile'}
            block
          />
          {this.props.selectedShifts && this.props.selectedShifts.length > 1 ? (
            <List>
              {this.renderShifts()}
            </List>
          ) : (singleEvent && singleEvent.shifts && singleEvent.shifts[0].hasAttendanceSameTime ? (
            <FullBlock>
              <InfoCircleIcon width={20} height={20} defaultFill={styles.colors.system.alert}/>
              <Span>You signed up for multiple shifts at the same time.</Span>
            </FullBlock>
          ) : null)}
        </Content>
        <Footer>
          <Text>{`${organizationName} might email you information before you volunteer, so keep a look out.`}</Text>
        </Footer>
      </Modal>
    );
  }
}

ConfirmPopup.propTypes = {
  closePopup: PropTypes.func.isRequired,
  onClickConfirm: PropTypes.func.isRequired,
  singleEvent: PropTypes.shape({
    id: PropTypes.number,
    address: PropTypes.string,
    charityContactName: PropTypes.string,
    charityPhoneNumber: PropTypes.string,
    contactEmail: PropTypes.string,
    description: PropTypes.string,
    endTime: PropTypes.number,
    eventGender: PropTypes.number,
    minimumAge: PropTypes.number,
    spots: PropTypes.number,
    startTime: PropTypes.number,
    timezone: PropTypes.string,
    title: PropTypes.string,
    volunteerDressCode: PropTypes.string,
    volunteerTasks: PropTypes.string,
    backgroundCheckRequired: PropTypes.bool,
    isCheckedIn: PropTypes.bool,
    coHosts: PropTypes.array,
    isVirtual: PropTypes.bool,
    images: PropTypes.arrayOf(PropTypes.string),
    eventImages: PropTypes.arrayOf(PropTypes.string),
    lat: PropTypes.number,
    lng: PropTypes.number,
    locationDescription: PropTypes.string,
    onlineAddressUrl: PropTypes.string,
    onlineDescription: PropTypes.string,
    primaryImage: PropTypes.string,
    image: PropTypes.string,
    signedFormRequired: PropTypes.bool,
    spotsTaken: PropTypes.number,
    trainingRequired: PropTypes.bool,
    volunteerParking: PropTypes.string,
    volunteersCount: PropTypes.number,
    hasReservations: PropTypes.bool,
    isAttending: PropTypes.bool,
    numberOfVolunteers: PropTypes.number,
    spotsReserved: PropTypes.number,
    hasPersonalReservations: PropTypes.bool,
    causes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        description: PropTypes.string,
        image: PropTypes.string,
        title: PropTypes.string,
        isPrimary: PropTypes.bool
      })
    ),
    organization: PropTypes.shape({
      id: PropTypes.number,
      email: PropTypes.string,
      phone: PropTypes.string,
      type: PropTypes.string,
      name: PropTypes.string,
      logo: PropTypes.string
    }),
    shifts: PropTypes.arrayOf(
      PropTypes.shape({
        endTime: PropTypes.number.isRequired,
        eventShiftId: PropTypes.number.isRequired,
        spots: PropTypes.number.isRequired,
        startTime: PropTypes.number.isRequired,
        name: PropTypes.string,
        spotsReserved: PropTypes.number,
        spotsTaken: PropTypes.number,
        description: PropTypes.string,
        isAttending: PropTypes.bool,
        isCheckedIn: PropTypes.bool
      })
    ),
    topUsers: PropTypes.arrayOf(
      PropTypes.shape({
        userFirstName: PropTypes.string.isRequired,
        userId: PropTypes.number.isRequired,
        userLastName: PropTypes.string.isRequired,
        userAvatar: PropTypes.string
      })
    )
  }),
  isChangeCancel: PropTypes.bool,
  selectedShifts: PropTypes.array,
  showConfirmPopup: PropTypes.bool,
};

ConfirmPopup.defaultProps = {
  isChangeCancel: false,
  selectedShifts: [],
  showConfirmPopup: false,
  singleEvent: null
};

export default ConfirmPopup;

const Content = styled.div`
  padding: 8px 0 0;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const Title = styled(H2)`
  margin: 0 0 8px;
  text-align: center;
`;
const Label = styled(H3)`
  margin: 0 0 16px;
  text-align: center;
`;
const SubTitle = styled(H4)`
  margin: 0 0 24px;
  font-family: ${styles.fonts.family.primaryMedium};
`;
const Text = styled(H5)`
  color: ${styles.colors.medium};
  font-family: ${styles.fonts.family.primaryBook};
  text-align: center;
  margin: 0;
`;
const Paragraph = styled(P2)`
  color: ${styles.colors.medium};
  font-family: ${styles.fonts.family.primaryBook};
  margin: 0 0 24px;
`;
const Data = styled.div`
  margin: 0 0 16px;
`;
const TermsLink = styled.a`
  color: ${styles.colors.primary};
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;

  &:hover {
    color: ${styles.colors.secondary};
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 0 0 auto;
  width: calc(100% + 24px);
  margin: -12px -12px 12px;
`;
const RowFlex = styled(Row)`
  display: flex;
  flex: none;
  flex-direction: row-reverse;
`;
const Footer = styled(RowFlex)`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex: 0 0 auto;
  width: 100%;
  color: ${styles.colors.medium};
  font-family: ${styles.fonts.family.primaryBook};
  font-size: 14px;
  line-height: 18px;
  margin: 0;

  a {
    color: ${styles.colors.primary};
    font-family: ${styles.fonts.family.primaryMedium};
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
    margin-left: 4px;
  }
`;
const List = styled.div`
  margin: -4px -4px 20px;
  width: calc(100% + 8px);
  display: flex;
  flex-direction: column;
`;
const FullBlock = styled.div`
  background: ${styles.colors.system.alert}14;
  border: 1px solid ${styles.colors.system.alert}1f;
  border-radius: 8px;
  padding: 4px 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: -4px 0 16px;
  width: 100%;
  svg {
    margin: 0 0 auto;
  }
`;
const Span = styled(H6)`
  color: ${styles.colors.dark};
  margin: 0 0 0 8px;
  font-family: ${styles.fonts.family.primaryMedium};
`;
