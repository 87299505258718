import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import styles from '../../constants/styles';
import { findIndex } from 'lodash';
import { Checkbox } from '../Fields';
import { H5 } from '../Typography';
import Loader from '../../assets/icons/Loader';

class FlexTable extends Component {
  constructor (props) {
    super(props);
    this.state = {};
    this.renderHeader = this.renderHeader.bind(this);
    this.renderBody = this.renderBody.bind(this);
  }

  renderHeader = () => {
    if (this.props.hideHeader) {
      return null;
    }

    let countChecked = 0;
    let countDisabled = 0;
    this.props.dataSource.forEach((el) => {
      if (!el[this.props.rowKey] || el.disableCheck) {
        countDisabled++;
      }
      const index = this.props.selectedRows.findIndex((e) => e[this.props.rowKey] === el[this.props.rowKey]);
      if (index > -1) {
        countChecked++;
      }
    });

    let columnFirstIndex = [];

    this.props.columns.forEach((item) => {
      if (!item.rowIndex || item.rowIndex === 1) {
        columnFirstIndex.push(item);
      }
    });

    return (
      <Header className="header-row">
        {!this.props.hideCheckbox && (<CheckBoxPlace>
          <Checkbox
            onChange={() => {
              const rows = this.props.dataSource;
              let isSelectAll = false;

              if (countChecked === rows.length || rows.length === (countChecked + countDisabled)) {
                isSelectAll = true;
              }
              rows.forEach((item) => {
                if (isSelectAll) {
                  this.props.selectRow(item);
                } else {
                  let index = this.props.selectedRows.findIndex((e) => e[this.props.rowKey] === item[this.props.rowKey]);
                  if (index < 0) {
                    this.props.selectRow(item);
                  }
                }
              });
            }}
            checked={this.props.dataSource.length && (countChecked === this.props.dataSource.length)}
            isIncomplete={countChecked > 0}
            dataTestId={'checkbox-all'}
            disabled={this.props.dataSource && this.props.dataSource.length === countDisabled}
          />
        </CheckBoxPlace>)}
        {columnFirstIndex.map((col) => (
          <HeaderCol
            numOfCols={columnFirstIndex.length}
            width={col.width || null}
            className={typeof col.className === 'function' ? col.className() : col.className}
            justifyContent={col.justifyContent}
          >
            {col.title}
          </HeaderCol>
        ))}
      </Header>
    );
  };

  renderBody = () => {
    const rows = this.props.dataSource;

    let columnFirstIndex = [];
    let columnSecondIndex = [];
    let columnThirdIndex = [];

    this.props.columns.forEach((item) => {
      if (!item.rowIndex || item.rowIndex === 1) {
        columnFirstIndex.push(item);
      } else if (item.rowIndex === 2) {
        columnSecondIndex.push(item);
      } else {
        columnThirdIndex.push(item);
      }
    });

    if (this.props.isLoading) {
      return (
        <RowLoader>
          <Loader fill={styles.colors.primary} size={50}/>
          <Text>Loading</Text>
        </RowLoader>
      );
    }

    return (<Body className="body-table">
      {rows.length ? rows.map((item) => {
        const blocked = -1 !== findIndex(this.props.blockedRows, (s) => (s).toString() === (item[this.props.rowKey]).toString());
        const checked = blocked || -1 !== findIndex(this.props.selectedRows, (s) => (s[this.props.rowKey]).toString() === (item[this.props.rowKey]).toString());

        return (
          <FlexWrapper key={item[this.props.rowKey]} className={`table-row row-item-${this.props.rowKey ? item[this.props.rowKey] : ''}`}>
            {blocked && <BlockedBg/>}
            <FlexRow key={`first_${item[this.props.rowKey]}`} onClick={() => this.props.onPressRow(item)}>
              {!this.props.hideCheckbox && (
                <CheckBoxPlace className="check-box">
                  <Checkbox
                    onChange={() => this.props.selectRow(item)}
                    checked={checked}
                    dataTestId={`checkbox-row-${item[this.props.rowKey]}`}
                    disabled={!item[this.props.rowKey] || item.disableCheck}
                  />
                </CheckBoxPlace>
              )}
              {columnFirstIndex.map((col) => {
                return (
                  <BodyCol
                    numOfCols={columnFirstIndex.length}
                    width={col.width || null}
                    className={typeof col.className === 'function' ? col.className(item) : col.className}
                  >
                    {col.render(item)}
                  </BodyCol>);
              })}
            </FlexRow>
            {columnSecondIndex.length ? (
              <FlexRowSecond key={`second_${item[this.props.rowKey]}`}>
                {columnSecondIndex.map((col) => {
                  return (
                    <BodyCol
                      numOfCols={columnFirstIndex.length}
                      width={col.width || null}
                      className={col.className}
                    >
                      {col.render(item)}
                    </BodyCol>);
                })}
              </FlexRowSecond>
            ) : null}
            {columnThirdIndex.length ? (
              <FlexRowSecond key={`third_${item[this.props.rowKey]}`}>
                {columnThirdIndex.map((col) => {
                  return (
                    <BodyCol
                      numOfCols={columnFirstIndex.length}
                      width={col.width || null}
                      className={col.className}
                    >
                      {col.render(item)}
                    </BodyCol>);
                })}
              </FlexRowSecond>
            ) : null}
          </FlexWrapper>
        );
      }) : this.props.NoDataComponent}
    </Body>);
  };

  render () {
    return (
      <Container>
        {this.renderHeader()}
        {this.renderBody()}
      </Container>
    );
  }
}

FlexTable.propTypes = {
  rowKey: PropTypes.string.isRequired,
  dataSource: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  NoDataComponent: PropTypes.element.isRequired,
  hideCheckbox: PropTypes.bool,
  selectRow: PropTypes.func,
  onPressRow: PropTypes.func,
  selectedRows: PropTypes.array,
  blockedRows: PropTypes.array,
  hideHeader: PropTypes.bool,
  keyItem: PropTypes.string,
  justifyContent: PropTypes.string,
  isLoading: PropTypes.bool
};
FlexTable.defaultProps = {
  rowKey: 'id',
  dataSource: [],
  columns: [],
  hideCheckbox: false,
  selectRow: () => null,
  onPressRow: () => null,
  selectedRows: [],
  blockedRows: [],
  hideHeader: false,
  justifyContent: 'start',
  isLoading: false
};

export default FlexTable;

const Container = styled.div``;
const CheckBoxPlace = styled.div`
  margin: 0 8px 0 0;
  width: 18px;
  height: 18px;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 11px 8px;
  border-radius: 4px;
  background: ${styles.colors.mostlyWhite};

  ${CheckBoxPlace} {
    margin-top: 0;
  }
`;
const HeaderCol = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justifyContent === 'end' ? 'flex-end' : 'flex-start'};
  font-family: ${styles.fonts.family.primaryMedium};
  font-size: 14px;
  line-height: 16px;
  color: ${styles.colors.silver};
  margin: 0 8px;
  ${props => {
    if (props.width) {
      return `width: ${props.width.default}; min-width: ${props.width.minWidth}; max-width: ${props.width.maxWidth};`;
    } else {
      return `width: calc(100% / ${props.numOfCols})`;
    }
  }};
`;

const Body = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 16px 0;

`;
const FlexRowSecond = styled(FlexRow)`
  padding: 0;
`;
const BodyCol = styled.div`
  margin: 0 8px;
  ${props => {
    if (props.width) {
      return `width: ${props.width.default}; min-width: ${props.width.minWidth}; max-width: ${props.width.maxWidth};`;
    } else {
      return `width: calc(100% / ${props.numOfCols})`;
    }
  }};
`;
const FlexWrapper = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${styles.borders.color};
  width: 100%;
  position: relative;
`;
const BlockedBg = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: white;
  opacity: 0.7;
`;
const Text = styled(H5)`
  color: ${styles.colors.dark};
  font-family: ${styles.fonts.family.primaryBook};
  margin: 0 0 0 10px;
`;
const RowLoader = styled.div`
  padding: 100px 20px;
  justify-content: center;
  align-items: center;
  display: flex;
`;
