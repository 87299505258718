function scorePassword (pass) {
  let score = 0;
  if (!pass) return score;

  let variations = {
    count: pass.length,
    uppercase: pass.replace(/[^A-ZА-Я]/gm, '').length,
    lowercase: pass.replace(/[^a-zа-я]/gm, '').length,
    number: pass.replace(/[^0-9]/gm, '').toString().length,
    symbols: pass.replace(/[a-zа-яA-ZА-Я0-9]/gm, '').length
  };

  let variationCount = 0;
  for (let check in variations) {
    if (check !== 'count') {
      variationCount += variations[check] ? 1 : 0;
    }
  }
  if (variations.count && variations.count > 7) {
    variationCount++;
  }
  const count = variations.count * 4;
  const uppercase = variations.uppercase ? (variations.count - variations.uppercase) * 2 : 0;
  const lowercase = variations.lowercase ? (variations.count - variations.lowercase) * 2 : 0;
  const numbers = variations.count === variations.number ? variations.number : variations.number * 4;
  const symbols = variations.symbols * 6;
  const variation = variationCount * 2;

  let middleNumbersSymbols = 0;
  if (variations.count > 1) {
    for (let i = 1; i < variations.count - 1; i++) {
      if (pass[i].replace(/[^0-9]/gm, '') || pass[i].replace(/[a-zа-яA-ZА-Я0-9]/gm, '')) {
        middleNumbersSymbols++;
      }
    }
  }

  let repeatCharacters = 0;
  let consecutiveCharacters = 0;
  let sequentialNumbers = 0;
  if (variations.count > 1) {
    for (let i = 1; i < variations.count; i++) {
      if (
        (pass[i].replace(/[^A-ZА-Я]/gm, '') &&
          (pass[i].replace(/[^A-ZА-Я]/gm, '') === pass[i - 1].replace(/[^A-ZА-Я]/gm, ''))) ||
        (pass[i].replace(/[^a-zа-я]/gm, '') &&
          (pass[i].replace(/[^a-zа-я]/gm, '') === pass[i - 1].replace(/[^a-zа-я]/gm, ''))) ||
        (pass[i].replace(/[^0-9]/gm, '') &&
          (pass[i].replace(/[^0-9]/gm, '') === pass[i - 1].replace(/[^0-9]/gm, ''))) ||
        (pass[i].replace(/[a-zа-яA-ZА-Я0-9]/gm, '') &&
          (pass[i].replace(/[a-zа-яA-ZА-Я0-9]/gm, '') === pass[i - 1].replace(/[a-zа-яA-ZА-Я0-9]/gm, '')))
      ) {
        repeatCharacters++;
      }

      if (
        (pass[i].replace(/[^A-ZА-Я]/gm, '') && pass[i - 1].replace(/[^A-ZА-Я]/gm, '')) ||
        (pass[i].replace(/[^a-zа-я]/gm, '') && pass[i - 1].replace(/[^a-zа-я]/gm, '')) ||
        (pass[i].replace(/[^0-9]/gm, '') && pass[i - 1].replace(/[^0-9]/gm, '')) ||
        (pass[i].replace(/[a-zа-яA-ZА-Я0-9]/gm, '') && pass[i - 1].replace(/[a-zа-яA-ZА-Я0-9]/gm, ''))
      ) {
        consecutiveCharacters++;
      }

      if (pass[i].replace(/[^0-9]/gm, '') && pass[i - 1].replace(/[^0-9]/gm, '') &&
        ((Number(pass[i]) - 1) === Number(pass[i - 1]))) {
        sequentialNumbers++;
      }
      if (pass[i].replace(/[^0-9]/gm, '') && pass[i - 1].replace(/[^0-9]/gm, '') &&
        (Number(pass[i]) === (Number(pass[i - 1]) - 1))) {
        sequentialNumbers++;
      }
    }
  }

  if (sequentialNumbers > 2) {
    sequentialNumbers = sequentialNumbers - 1;
  }
  if (repeatCharacters > 2) {
    repeatCharacters = repeatCharacters - 1;
  }

  score = count + uppercase + lowercase + numbers +
    symbols + variation + middleNumbersSymbols * 2 - repeatCharacters * 3 -
    consecutiveCharacters * 2 - sequentialNumbers * 3;
  if ((!variations.uppercase || !variations.lowercase || !variations.number || (variations.count < 8)) && score >= 60) {
    score = 59;
  }

  return parseInt(score);
}

export default function getPasswordStrength (pass) {
  let score = scorePassword(pass);
  if (score > 80)
    return 'strong';
  if (score > 60)
    return 'medium';
  if (score >= 30)
    return 'low';
  return '';
}
