import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

class Content extends Component {
    constructor (props) {
        super (props);
        this.state = {};
    }

    render () {
        return (
            <Container className={'container-page'} {...this.props}>
                {this.props.children}
            </Container>
        );
    }
}
export default Content;

Content.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.element
    ]).isRequired
};

const Container = styled.div`
    display: block;
    width: 100%;
    padding: 0;
    margin: 0 auto;
`;
