import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';

const render = Component => {
  ReactDOM.render(
    <CookiesProvider>
      <BrowserRouter>
        <Provider store={store}>
          <React.Fragment/>
          <Component/>
        </Provider>
      </BrowserRouter>
    </CookiesProvider>
    ,
    document.getElementById('root')
  );
};

render(App);
