import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const DonationActivityMenu = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="m22 11c0-.6-.4-1-1-1h-5c-.6 0-1 .4-1 1s.4 1 1 1h2.8l-4 4.7-4.3-2.6c-.4-.3-1-.2-1.3.2l-5 6c-.4.4-.3 1.1.1 1.4.4.4 1.1.3 1.4-.1l4.5-5.3 4.3 2.6c.4.2 1 .2 1.3-.2l4.2-5v2.3c0 .6.4 1 1 1s1-.4 1-1z"/>
            <path d="m5 2c.5 0 .8.4.8.8v1.2c.3.1.5.1.7.2.3.2.4.2.5.3l.1.1c1.1.8 0 2.3-.9 1.5-.6-.5-2.1-.7-2.1.3 0 .2.1.4.3.5s.4.2.7.3c.3 0 .6.1.9.2.4.1.7.2 1 .4.3.1.5.4.7.7s.3.7.3 1.2c0 .7-.2 1.3-.6 1.8-.4.4-.9.7-1.6.9v1.3c0 .5-.4.8-.9.8s-.8-.4-.8-.8v-1.3c-.4-.1-.7-.2-1-.4s-.5-.4-.7-.5l-.2-.2c-.8-1 .5-2.1 1.2-1.3.1.1.2.2.3.3.3.3.8.4 1.1.4.7 0 1.1-.3 1.1-.9 0-.2-.1-.4-.4-.5-.2-.1-.5-.2-.9-.3s-.8-.2-1.1-.4c-.4-.2-.7-.4-1-.8-.3-.3-.4-.8-.4-1.4s.2-1.1.5-1.5c.4-.5.9-.8 1.5-.9v-1.2c0-.4.4-.8.9-.8z"/>
        </Icon>
    );

export default withTheme(DonationActivityMenu);
