import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const AddVolunteersIcon = props => (
    <Icon {...props} viewBox={'0 0 24 24'}>
        <path d="M21,6h-1V5c0-0.6-0.4-1-1-1s-1,0.4-1,1v1h-1c-0.6,0-1,0.4-1,1s0.4,1,1,1h1v1c0,0.6,0.4,1,1,1s1-0.4,1-1V8h1
	c0.6,0,1-0.4,1-1S21.6,6,21,6z"/>
        <path d="M10,11c2.2,0,4-1.8,4-4s-1.8-4-4-4S6,4.8,6,7S7.8,11,10,11z M10,5c1.1,0,2,0.9,2,2s-0.9,2-2,2S8,8.1,8,7
	S8.9,5,10,5z"/>
        <path d="M10,13c-3.9,0-7,3.1-7,7c0,0.6,0.4,1,1,1s1-0.4,1-1c0-2.8,2.2-5,5-5s5,2.2,5,5c0,0.6,0.4,1,1,1
	c0.6,0,1-0.4,1-1C17,16.1,13.9,13,10,13z"/>
    </Icon>
);

export default withTheme(AddVolunteersIcon);
