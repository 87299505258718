import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const CheckCircleSolidIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <rect fillOpacity="0" width="24" height="24"/>
            <path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.3,9.6l-4.6,6c-0.2,0.2-0.5,0.4-0.8,0.4
	c-0.3,0-0.6-0.1-0.8-0.4l-2.4-3.1c-0.3-0.4-0.3-1.1,0.2-1.4s1.1-0.3,1.4,0.2l1.6,2.1l3.8-5c0.3-0.4,1-0.5,1.4-0.2
	C16.6,8.5,16.6,9.1,16.3,9.6L16.3,9.6z"/>
        </Icon>
    );

export default withTheme(CheckCircleSolidIcon);
