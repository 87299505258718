import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import styles from '../../constants/styles';
import Avatar from '../Avatar';
import { H5, H6 } from '../Typography';
import { PdfOutlineIcon, PngOutlineIcon, JpgOutlineIcon, TrashIcon, CloudDownloadIcon } from '../Icons/General';
import { Button } from '../Buttons';

const ShortFile = (props) => {

  let size = props.imageSize || 36;
  let image = <Avatar size={size} src={props.image || ''} firstName={props.title || ''} className="short-file-avatar" />;

  if ((props.typeFile && props.typeFile === 'application/pdf')
    || (props.variant && (props.variant === 'client')) || props.isOnlyIcon) {
    if (props.typeFile === 'application/pdf') {
      image = <PdfOutlineIcon height={size} width={size} defaultFill={'#FF0203'}/>;
    }
    if (props.typeFile === 'image/png') {
      image = <PngOutlineIcon height={size} width={size} defaultFill={'#F87C3F'}/>;
    }
    if (props.typeFile === 'image/jpg' || props.typeFile === 'image/jpeg') {
      image = <JpgOutlineIcon height={size} width={size} defaultFill={'#028AFF'}/>;
    }
  }

  return (
    <Container data-testid={props.dataTestId} className={props.variant} onClick={props.openFile}>
      <Row isPadding={props.removeFun}>
        <ImagePlace isPointer={props.onClick || props.openFile} imageSize={props.imageSize} onClick={props.onClick}>
          {image}
        </ImagePlace>
        <ProfileInfo isStart={props.removeFun}>
          <TextTitle isPointer={props.onClick || props.openFile} onClick={props.onClick}>{props.title}</TextTitle>
          {props.subtitle ? (
            <Subtitle
              isPointer={props.onClick || props.openFile}
              className={'short-profile-subtitle'}
              isError={props.isTooBigFile}
              onClick={props.onClick}
            >
              {props.subtitle}
            </Subtitle>
          ) : null}
        </ProfileInfo>
      </Row>
      {props.removeFun ? (
        <Button
          variant={'outline-light'}
          onClick={() => props.removeFun()}
          dataTestId={`button-remove-${props.dataTestId}`}
          Icon={<TrashIcon/>}
        />
      ) : null}
      {props.openFile ? (
        <Open>
          <CloudDownloadIcon
            height={16}
            width={16}
            defaultFill={styles.colors.dark}
            dataTestId={'svg-button-download-file'}
          />
        </Open>
      ) : null}
    </Container>
  );
};

export default ShortFile;

ShortFile.propTypes = {
  image: PropTypes.string,
  typeFile: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  imageSize: PropTypes.number,
  dataTestId: PropTypes.string,
  isTooBigFile: PropTypes.bool,
  isOnlyIcon: PropTypes.bool
};

ShortFile.defaultProps = {
  image: '',
  typeFile: '',
  title: '',
  subtitle: '',
  imageSize: null,
  dataTestId: '',
  isTooBigFile: false,
  isOnlyIcon: false
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  .btn.btn-md.btn-content-icon {
    border-radius: 6px;
    width: 28px;
    min-width: 28px;
    height: 28px;
    min-height: 28px;
    max-height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${styles.borders.input.color.active};
    margin-top: 4px;
  }
  &.plate {
    background: ${styles.colors.mostlyWhite};
    border-radius: 12px;
    padding: 8px;
    display: flex;
    flex: inherit;
    flex-direction: row;
    align-items: flex-start;
    margin: 4px;
    cursor: pointer;
  }
`;
const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  padding-right: ${props => props.isPadding ? '16px' : '0'};
  .short-file-avatar {
    border-radius: 6px;
  }
`;
const ImagePlace = styled.div`
  margin-right: ${props => props.imageSize && props.imageSize > 64 ? '24' : '12'}px;
  height: ${props => props.imageSize ? props.imageSize : 36}px;
  position: relative;
  cursor: ${props => props.isPointer ? 'pointer' : 'default'};
`;
const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${props => props.isStart ? '0' : 'auto 0'};
  .btn.btn-md  {
    border: 1px solid ${styles.borders.input.color.active};
    width: 36px;
    padding: 10px 7px;
    margin: 8px 0 auto 0;
    .btn-icon svg {
      width: 20px;
      height: 20px;
    }
  }
`;
const TextTitle = styled(H5)`
  color: ${styles.colors.dark};
  font-family: ${styles.fonts.family.primaryBook};
  margin: 0;
  word-break: break-word;
  cursor: ${props => props.isPointer ? 'pointer' : 'default'};
`;
const Subtitle = styled(H6)`
  color: ${props => props.isError ? styles.colors.system.error :  styles.colors.medium};
  font-family: ${styles.fonts.family.primaryBook};
  margin: 0;
  word-break: break-word;
  cursor: ${props => props.isPointer ? 'pointer' : 'default'};
`;
const Open = styled.div`
  cursor: pointer;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  height: 20px;
  margin: 8px 6px 8px 16px;
`;
