import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const MinusIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M19,13H5c-0.6,0-1-0.4-1-1s0.4-1,1-1h14c0.6,0,1,0.4,1,1S19.6,13,19,13z"/>
        </Icon>
    );

export default withTheme(MinusIcon);
