import React from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import styles from '../../../constants/styles';
import { getDataTime, getDataTimeForShift } from '../../../_helpers';
import { InfoCircleIcon } from '../../../components/Icons/General';
import { H5, H6 } from '../../Typography';
import { Checkbox } from '../../Fields';
import { Button } from '../../Buttons';
import { SwitchButton } from '../../Elements';

const Shift = (props) => {
  const {
    item,
    eventTimeZone,
    eventStartTime,
    eventEndTime,
    counter,
    isSpots,
    isBackground,
    size,
    isNotBorder,
    onClick,
    isSelected = false,
    type = null,
    isReserved = false,
    isAttending = false,
    isShowSelectItem = false
  } = props;

  const dataDateTime = getDataTimeForShift(item.startTime, item.endTime, eventTimeZone, true);

  const eventDate = getDataTime(item.startTime, item.endTime, eventTimeZone);
  const startTimeEvent = getDataTime(parseInt(eventStartTime, 10), null, eventTimeZone);
  const endTimeEvent = getDataTime(parseInt(eventEndTime, 10), null, eventTimeZone);

  let shiftDate = eventDate.timeString;
  if (startTimeEvent.dateString !== endTimeEvent.dateString) {
    shiftDate = dataDateTime.dateString;
  }

  let classBlock = 'shift-item';

  if (isSpots) {
    classBlock = classBlock + ' border';
  }
  if (isBackground) {
    classBlock = classBlock + ' background';
  }
  if (size && size === 'full') {
    classBlock = classBlock + ' full-size';
  }
  if (isNotBorder) {
    classBlock = classBlock + ' full-size border-none';
  }

  let disabled = false;
  if (((item.spots - item.spotsTaken - item.spotsReserved) <= 0) && !isSelected && !isReserved) {
    disabled = true;
  }

  let count = item.spotsTaken;
  if (item.spotsReserved) {
    count = count + item.spotsReserved;
  }


  if (type && type === 'details') {
    return (
      <ShiftItemDetails className={classBlock}>
        <DataDetails type={type}>
          <TopDetails>
            <Title>{item.name || `Shift ${counter}`}</Title>
            {item.description ? (
              <Desc>{item.description}</Desc>
            ) : null}
          </TopDetails>
          <BlockRow isShowSelectItem={isShowSelectItem}>
            <ShiftDates>{shiftDate}</ShiftDates>
            <ShiftSpots isShowSelectItem={isShowSelectItem}>
              <ShiftSpotsNumber>
                {props.isOpportunity ? (
                  `${item.spots} Spot${item.spots === 1 ? '' : 's'}`
                ) : (
                  `${count || 0}/${item.spots} Spot${item.spots === 1 ? '' : 's'} Taken`
                )}
              </ShiftSpotsNumber>
            </ShiftSpots>
            {isShowSelectItem ? (
              <SwitchButton
                on={item.isAttending}
                onChange={() => props.onSelectShift(!item.isAttending)}
                offText={'Not going'}
                onText={'I’m going'}
              />
            ) : null}
          </BlockRow>
          {isReserved && !isAttending ? (
            <Reserved>You have a spot reserved for you.</Reserved>
          ) : null}
          {item.hasAttendanceSameTime && !props.isOpportunity ? (
            <FullBlock>
              <InfoCircleIcon width={20} height={20} defaultFill={styles.colors.system.alert}/>
              <Span>You signed up for multiple shifts at the same time.</Span>
            </FullBlock>
          ) : null}
        </DataDetails>
      </ShiftItemDetails>
    );
  }

  return (
    <ShiftItem className={classBlock} type={type}>
      <Checkbox
        checked={isSelected}
        onChange={() => onClick(item.eventShiftId, !isSelected)}
        style={{ margin: '2px 0 0 2px' }}
        disabled={disabled}
      />
      <Data>
        <Top>
          <ShiftTitle disabled={disabled}>{item.name || `Shift ${counter}`}</ShiftTitle>
          {item.description ? (
            <ShiftDesc disabled={disabled}>{item.description}</ShiftDesc>
          ) : null}
        </Top>
        <BlockRow>
          <ShiftDates disabled={disabled}>{shiftDate}</ShiftDates>
          <ShiftSpots>
            <ShiftSpotsNumber disabled={disabled}>{`${count || 0}/${item.spots}`}</ShiftSpotsNumber>
          </ShiftSpots>
        </BlockRow>
        {isReserved && !isAttending ? (
          <div>
            <Reserved>You have a spot reserved for you.</Reserved>
            <Button
              size="sm"
              variant="info"
              value="Claim Spot"
              onClick={() => props.claimSpot()}
              dataTestId={'claim-spot-button'}
              style={{ marginTop: 4 }}
            />
          </div>
        ) : null}
        {item.hasAttendanceSameTime ? (
          <FullBlock>
            <InfoCircleIcon width={20} height={20} defaultFill={styles.colors.system.alert}/>
            <Span>You signed up for multiple shifts at the same time.</Span>
          </FullBlock>
        ) : null}
      </Data>

    </ShiftItem>
  );
};

export default withTheme(Shift);

Shift.propTypes = {
  item: PropTypes.object.isRequired,
  isAccordion: PropTypes.bool,
  eventTimeZone: PropTypes.string,
  eventStartTime: PropTypes.number,
  eventEndTime: PropTypes.number,
  counter: PropTypes.number,
  isSpots: PropTypes.bool,
  isBackground: PropTypes.bool,
  size: PropTypes.string,
  isNotBorder: PropTypes.bool,
  onClick: PropTypes.func,
  claimSpot: PropTypes.func,
  isSelected: PropTypes.bool,
  isAttending: PropTypes.bool,
  type: PropTypes.string,
};

Shift.defaultProps = {
  isAccordion: false,
  isSelected: false,
  eventTimeZone: null,
  eventStartTime: 0,
  eventEndTime: 0,
  counter: 0,
  isSpots: false,
  isBackground: false,
  size: null,
  isNotBorder: false,
  isAttending: false,
  onClick: () => null,
  claimSpot: () => null,
  type: null,
};

const ShiftItem = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  width: calc(50% - 10px);
  border: 1px solid ${styles.borders.color};
  border-radius: 8px;
  padding: ${props => props.type ? '8px 16px 16px 16px' : '8px 12px 12px 12px'};
  margin: 4px;
  position: relative;

  &.full-size {
    width: calc(100% - 10px);
  }

  @media screen and (max-width: 1280px) {
    width: calc(100% - 10px);
  }
`;
const ShiftItemDetails = styled(ShiftItem)`
  padding: 10px 16px 16px 16px;
`;
const Data = styled.div`
  padding-top: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
  flex: 1;
`;
const DataDetails = styled(Data)`
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 0;
  flex: 1;
`;
const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 100%;
`;
const TopDetails = styled(Top)`
  margin-bottom: 4px;
  width: 100%;
`;
const ShiftTitle = styled(H5)`
  color: ${props => props.disabled ? styles.colors.silver : styles.colors.dark};
  margin: 0 0 8px;
  font-family: ${styles.fonts.family.primaryMedium};
  word-break: break-word;
`;
const ShiftDesc = styled(H6)`
  font-family: ${styles.fonts.family.primaryBook};
  color: ${props => props.disabled ? styles.colors.silver : styles.colors.medium};
  margin: 0 0 8px;
  word-break: break-word;
`;
const Title = styled(ShiftTitle)`
  font-size: 16px;
  line-height: 20px;
`;
const Desc = styled(ShiftDesc)`
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 8px;
`;

const ShiftDates = styled(H6)`
  display: flex;
  flex-wrap: wrap;
  padding: 4px 8px;
  margin: 0;
  color: ${styles.colors.dark};
  border-radius: 4px;
  font-family: ${styles.fonts.family.primaryMedium};
  background: ${props => props.disabled ? styles.colors.mostlyWhite : styles.colors.secondary + '1a'};
`;
const ShiftSpots = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  padding-left: 8px;
  padding-right: ${props => props.isShowSelectItem ? '16px' : 0};
  margin: ${props => props.isShowSelectItem ? '0 auto 0 16px' : 0};
`;
const ShiftSpotsNumber = styled(H6)`
  margin: 0;
  color: ${props => props.disabled ? styles.colors.silver : styles.colors.dark};
  font-family: ${styles.fonts.family.primaryMedium};
`;
const BlockRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${props => props.isShowSelectItem ? 'center' : 'flex-end'};
  justify-content: space-between;
  width: 100%;
`;
const Reserved = styled(H6)`
  color: ${styles.colors.medium};
  margin: 8px 0 0;
  font-family: ${styles.fonts.family.primaryBook};
`;
const FullBlock = styled.div`
  background: ${styles.colors.system.alert}14;
  border: 1px solid ${styles.colors.system.alert}1f;
  border-radius: 8px;
  padding: 4px 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 8px 0 0;
  width: 100%;

  svg {
    margin: 0 0 auto;
  }
`;
const Span = styled(H6)`
  color: ${styles.colors.dark};
  margin: 0 0 0 8px;
  font-family: ${styles.fonts.family.primaryMedium};
`;
const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: ${props => props.isShowSelectItem ? 'center' : 'flex-end'};
  width: 100%;
`;
