import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import styles from '../../../constants/styles';
import { H4, H5, H6 } from '../../../components/Typography';
import { getPasswordStrength } from '../../../_helpers';
import { CheckIcon } from '../../../components/Icons/General';

const renderProgress = (type) => {
  switch (type) {
    case 'low':
      return <StrengthLow/>;
    case 'medium':
      return <StrengthMedium/>;
    case 'strong':
      return <StrengthStrong/>;
    default:
      return <Strength/>;
  }
};

const PasswordStrength = (props) => {
  const strength = getPasswordStrength(props.password);
  const { size } = props;

  let isNumber = false;
  if (props.password.replace(/[^0-9]/gm, '').length > 0) {
    isNumber = true;
  }
  let isUpperLowercase = false;
  if ((props.password.replace(/[^A-ZА-Я]/gm, '').length > 0) && (props.password.replace(/[^a-zа-я]/gm, '').length > 0)) {
    isUpperLowercase = true;
  }
  let isLengthGood = false;
  if (props.password.length > 7) {
    isLengthGood = true;
  }

  return (
    <Wrapper size={size}>
      {props.isShow ? (
        <Container>
          <Title>Your password must contain:</Title>
          <Item>
            <CheckIcon defaultFill={isLengthGood ? styles.colors.system.success : styles.colors.medium} height={20} width={20}/>
            <Text style={{ color: isLengthGood ? styles.colors.system.success : styles.colors.medium }}>8 or more characters</Text>
          </Item>
          <Item>
            <CheckIcon defaultFill={isUpperLowercase ? styles.colors.system.success : styles.colors.medium} height={20} width={20}/>
            <Text style={{ color: isUpperLowercase ? styles.colors.system.success : styles.colors.medium }}>Upper & lowercase letters</Text>
          </Item>
          <Item>
            <CheckIcon defaultFill={isNumber ? styles.colors.system.success : styles.colors.medium} height={20} width={20}/>
            <Text style={{ color: isNumber ? styles.colors.system.success : styles.colors.medium }}>At least one number</Text>
          </Item>
          <Title style={{ marginTop: '10px' }}>Strength:</Title>
          <Progress>{renderProgress(strength)}</Progress>
          <Desc style={{ color: styles.colors.medium }}>Avoid using a password that you use with other websites or that might be easy for someone else to guess.</Desc>
        </Container>
      ) : null}
    </Wrapper>
  );
};
export default PasswordStrength;

PasswordStrength.propTypes = {
  password: PropTypes.string
};

PasswordStrength.defaultProps = {
  password: null
};

const Wrapper = styled.div`
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin: 0;
  display: flex;
  z-index: 99;
  position: relative;
  max-width: ${props => (props.size && props.size === 'full') ? '100%' : '360px'};
  @media screen and (max-width: 700px) {
    max-width: 100%;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 8px;
  background: white;
  box-shadow: 0 0 1px ${styles.colors.dark}1f, 0 4px 16px ${styles.colors.dark}1f;
  padding: 12px 16px;
  z-index: 99;
`;
const Title = styled(H4)`
  margin-top: 0;
  margin-bottom: 10px;
  color: ${styles.colors.dark};
  font-family: ${styles.fonts.family.primaryMedium};
`;
const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 4px;
`;
const Text = styled(H5)`
  margin-left: 8px;
  color: ${styles.colors.dark};
  font-family: ${styles.fonts.family.primaryBook};
  margin-bottom: 0;
`;
const Desc = styled(H6)`
  text-align: left;
  color: ${styles.colors.dark};
  font-family: ${styles.fonts.family.primaryBook};
  margin-bottom: 0;
`;
const Progress = styled.div`
  height: 4px;
  width: 100%;
  border-radius: 2px;
  background ${styles.colors.grey};
  margin-bottom: 12px;
  position: relative;
  overflow: hidden;
`;
const Strength = styled.div`
  height: 4px;
  width: 10% !important;
  border-radius: 2px;
  background: linear-gradient(90deg, rgb(247, 61, 52), rgb(247, 61, 52));
`;
const StrengthLow = styled(Strength)`
  width: 40% !important;
  background: linear-gradient(90deg, #F73D34, #F6921E);
`;
const StrengthMedium = styled(Strength)`
  width: 70% !important;
  background: linear-gradient(90deg, #f6921e, #ffad0d);
`;
const StrengthStrong = styled(Strength)`
  width: 100% !important;
  background: linear-gradient(90deg, #ffad0d, #00D000);
`;
