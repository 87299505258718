import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const DownloadIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M19,22H5c-1.7,0-3-1.3-3-3v-4c0-0.6,0.4-1,1-1s1,0.4,1,1v4c0,0.6,0.4,1,1,1h14c0.6,0,1-0.4,1-1v-4
		c0-0.6,0.4-1,1-1s1,0.4,1,1v4C22,20.7,20.7,22,19,22z"/>
            <path d="M12,16c-0.3,0-0.5-0.1-0.7-0.3l-5-5c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l4.3,4.3l4.3-4.3c0.4-0.4,1-0.4,1.4,0
		s0.4,1,0,1.4l-5,5C12.5,15.9,12.3,16,12,16z"/>
            <path d="M12,16c-0.6,0-1-0.4-1-1V3c0-0.6,0.4-1,1-1s1,0.4,1,1v12C13,15.6,12.6,16,12,16z"/>
        </Icon>
    );

export default withTheme(DownloadIcon);
