import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const TypeHighIcon = props => (
    <Icon {...props} viewBox={'0 0 24 24'}>
        <path fill="#3ba5ff" d="M18,2c1.1,0,2,0.9,2,2v16c0,1.1-0.9,2-2,2s-2-0.9-2-2V4
	C16,2.9,16.9,2,18,2z"/>
        <path fill="#3ba5ff" d="M12,8c1.1,0,2,0.9,2,2v10c0,1.1-0.9,2-2,2s-2-0.9-2-2
	V10C10,8.9,10.9,8,12,8z"/>
        <path fill="#3ba5ff" d="M6,14c1.1,0,2,0.9,2,2v4c0,1.1-0.9,2-2,2s-2-0.9-2-2v-4
	C4,14.9,4.9,14,6,14z"/>
    </Icon>
);

export default withTheme(TypeHighIcon);
