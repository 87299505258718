import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import styles from '../../constants/styles';
import { POINT_DASH_URL } from '../../constants/links';
import { INPUT_SIZE, INPUT_TYPE } from '../../constants';
import FireBaseTools from '../../api/firebase';
import { Modal } from '../../components/Modal';
import { H2, H5 } from '../../components/Typography';
import logo from '../../assets/images/svg/point-logo.svg';
import { Input } from '../../components/Fields';
import ErrorMessage from '../../components/ErrorMessage';
import { Button } from '../../components/Buttons';
import { isEmpty } from 'lodash';
import { getUserEmailsStatus } from '../../actions/accountActions';
import { getUser } from '../../actions/profileActions';
import { isValidEmail } from '../../_helpers';
import { loginGoogleAuthProvider, loginAppleAuthProvider } from './components/providers';
import validateUserProfile from './validate/validateUserProfile';

import googleIcon from '../../assets/images/svg/google.svg';
import appleIcon from '../../assets/images/svg/apple.svg';
import oktaIcon from '../../assets/images/svg/okta_logo.svg';

class SignIn extends Component {
  constructor (props) {
    super(props);

    this.state = {
      isAdminTab: true,
      login: '',
      password: '',
      errors: {},
      loading: false,
      passError: false,
      isAccount: false,
      loadingGoogle: false,
      loadingApple: false
    };
  }

  clearData = () => {
    this.setState({
      login: '',
      password: '',
      errors: {},
      loading: false,
      passError: false
    });
  };

  loginToPOINT = async () => {
    let profile = await getUser();
    const data = await getUserEmailsStatus();

    if (profile && profile.id) {
      profile.isCompleteProfile = true;
      const errors = validateUserProfile(profile);
      if (errors && Object.keys(errors).length) {
        profile.isCompleteProfile = false;
      }
      profile.isPrimaryEmailVerified = (data && data.isPrimaryEmailVerified) || false;
      localStorage.setItem('POINT.profileData', JSON.stringify(profile));

      this.clearData();
      this.props.onClickConfirm();
      return true;
    } else {
      localStorage.removeItem('POINT.profileData');
      this.setState({
        loading: false,
        passError: false
      });
      return false;
    }
  };

  login = async () => {
    const errors = {};

    this.setState({ loading: true });

    if (!this.state.login) {
      errors.login = 'Whoops! Login is required.';
    } else if (!isValidEmail(this.state.login)) {
      errors.login = 'Please, enter a valid Login.';
    }
    if (!this.state.password) {
      errors.password = 'Whoops! Password is required.';
    }

    if (isEmpty(errors)) {
      const fireAcc = await FireBaseTools.loginUser({
        email: this.state.login,
        password: this.state.password
      });
      if (fireAcc.errorCode === 'auth/wrong-password' || fireAcc.errorCode === 'auth/user-not-found') {
        errors.errorMessage = 'The password or email is invalid - try again!';
        this.setState({
          errors: errors,
          loading: false,
          passError: true
        });
        return false;
      } else if (fireAcc.errorCode) {
        errors.errorMessage = fireAcc.errorMessage;
        this.setState({
          errors: errors,
          loading: false,
          passError: true
        });
        return false;
      }

      await this.loginToPOINT();
    } else {
      this.setState({
        errors,
        loading: false,
        passError: false
      });
    }
  };

  render () {
    const { passError, login, password, errors } = this.state;

    return (
      <Modal
        width={styles.modal.size.extraSmall}
        visible={this.props.visible}
        onClose={() => {
          this.clearData();
          this.props.closeForm();
        }}
        buttons={null}
      >
        <Content>
          <Image src={logo} alt="Point Logo" className="logo-desktop"/>
          <Title>To sign up to volunteer log into your POINT account</Title>
          <Row hide={this.state.isAccount}>
            <Input
              size={INPUT_SIZE.NORMAL}
              type={INPUT_TYPE.TEXT}
              value={login}
              label={'Email *'}
              placeholder={'Enter your email'}
              onChange={(e) => {
                this.setState({
                  login: e.target.value.replace(/ /g, '').toLowerCase(),
                  passError: false,
                  errors: {}
                });
              }}
              dataTestId={'login-field'}
            />
            {errors && errors.login ? (
              <ErrorMessage text={errors.login} dataTestId={'login-field-error'}/>
            ) : null}
          </Row>
          <PasswordRow>
            <Input
              size={INPUT_SIZE.NORMAL}
              type={INPUT_TYPE.PASSWORD}
              value={password}
              label={'Password *'}
              placeholder={'Enter your password'}
              onChange={(e) => {
                this.setState({
                  password: e.target.value,
                  passError: false,
                  errors: {}
                });
              }}
              dataTestId={'password-field'}
            />
            {errors && errors.password ? (
              <ErrorMessage text={errors.password} dataTestId={'password-field-error'}/>
            ) : null}
          </PasswordRow>
          {passError && (
            <ErrorWrap>
              <ErrorMessage text={this.state.errors.errorMessage} dataTestId={'fields-error'}/>
            </ErrorWrap>
          )}
          <ForgetRow>
            <CustomLink data-testid={'forgot-password'} href={`${POINT_DASH_URL}/forgot-password`}>Forgot Password?</CustomLink>
          </ForgetRow>
          <Buttons data-testid={'login-button'}>
            <Button
              size={'lg'}
              value={'Log In'}
              onClick={this.login}
              variant={'primary'}
              block={true}
              dataTestId={'sign-in-button'}
              isLoading={this.state.loading}
            />
            <Separator>
              <SeparatorLine/>
              <SeparatorText>OR</SeparatorText>
            </Separator>
            <Button
                size={'lg'}
                value={'Continue with Google'}
                onClick={() => loginGoogleAuthProvider({
                  setInfo: (e) => this.setState(e),
                  getAccountLogin: getUser,
                  history: this.props.history,
                  onClickConfirm: this.props.onClickConfirm
                })}
                variant={'outline-light'}
                block={true}
                Icon={<img src={googleIcon} alt={'google'} width={20} height={20}/>}
                dataTestId={'continue-google-button'}
                isLoading={this.state.loadingGoogle}
            />
            <Button
              size={'lg'}
              value={'Continue with Apple'}
              onClick={() => loginAppleAuthProvider({
                setInfo: (e) => this.setState(e),
                getAccountLogin: getUser,
                history: this.props.history,
                onClickConfirm: this.props.onClickConfirm
              })}
              variant={'outline-light'}
              block={true}
              Icon={<img src={appleIcon} alt={'apple'} width={20} height={20}/>}
              dataTestId={'continue-apple-button'}
              isLoading={this.state.loadingApple}
            />
            <Button
                size={'lg'}
                value={'Continue with Okta'}
                onClick={() => this.props.setInfo({ showSignIn: false, showSignInOkta: true, fromPopup: 'signIn' })}
                variant={'outline-light'}
                block={true}
                Icon={<img src={oktaIcon} alt={'okta'} width={20} height={20}/>}
                dataTestId={'continue-okta-button'}
            />
          </Buttons>
        </Content>
        <Footer>
          Don’t have an account? <CustomLink onClick={() => this.props.openSignUp()} data-testid={'signup-button'}>Create one</CustomLink>
        </Footer>
      </Modal>
    );
  }
}

SignIn.propTypes = {
  onClickConfirm: PropTypes.func.isRequired,
  openSignUp: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  location: PropTypes.object
};

SignIn.defaultProps = {
  visible: false,
  location: null
};

const mapStateToProps = () => ({});
export default connect(mapStateToProps, {})(SignIn);

const Content = styled.div`
  padding: 8px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Image = styled.img`
  height: 25px;
`;
const Title = styled(H2)`
  margin: 30px 0 32px;
  text-align: center;
`;
const CustomLink = styled.a`
  color: ${styles.colors.primary};
  font-family: ${styles.fonts.family.primaryBook};
  font-size: 14px;
  line-height: 18px;

  &:hover {
    color: ${styles.colors.secondary};
  }
`;
const Row = styled.div`
  margin-bottom: 24px;
  display: ${props => props.hide ? 'none' : 'block'};
  width: 100%;
`;
const RowFlex = styled(Row)`
  display: flex;
  flex: none;
  flex-direction: row-reverse;
`;
const Buttons = styled(Row)`
  margin-bottom: 24px;

  .btn {
    width: 100%;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;
const PasswordRow = styled.div`
  margin-bottom: 16px;
  width: 100%;
`;
const ForgetRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
  text-align: right;
  width: 100%;

  &:hover {
    color: ${styles.colors.secondary};
  }
`;
const Footer = styled(RowFlex)`
  width: 100%;
  color: ${styles.colors.medium};
  font-family: ${styles.fonts.family.primaryBook};
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;

  a {
    color: ${styles.colors.primary};
    font-family: ${styles.fonts.family.primaryBook};
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
    margin-left: 4px;
  }
`;
const ErrorWrap = styled.div`
  margin-bottom: 8px;
  margin-top: -8px;
`;
const Separator = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 16px;
  position: relative;
`;
const SeparatorLine = styled.div`
  position: absolute;
  width: 100%;
  height: 1px;
  top: calc(50% + 1px);
  background: ${styles.colors.grey};
  z-index: 1;
`;
const SeparatorText = styled(H5)`
  display: inline-block;
  position: relative;
  font-family: ${styles.fonts.family.primaryBook};
  color: ${styles.colors.medium};
  background: white;
  padding: 0 8px;
  margin: 0;
  z-index: 2;
`;

