import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const PngOutlineIcon = props => (
  <Icon {...props} viewBox={'0 0 30 38'}>
    <path d="m25 37.6h-20c-2.6 0-4.8-2.1-4.8-4.8v-28c0-2.6 2.1-4.8 4.8-4.8h12.8c1.3 0 2.5.5 3.4 1.4l7.2 7.2c.9.9 1.4 2.1 1.4 3.4v20.8c0 2.7-2.2 4.8-4.8 4.8zm-20-36c-1.8 0-3.2 1.5-3.2 3.2v28c0 1.8 1.5 3.2 3.2 3.2h20c1.8 0 3.2-1.5 3.2-3.2v-20.8c0-.9-.3-1.7-1-2.3l-7.2-7.2c-.6-.6-1.4-1-2.3-1h-12.7z"/>
    <path d="m6.4 20.2v2.7h-1.3v-7.1h2.6c1.4 0 2.3.9 2.3 2.2s-.9 2.2-2.3 2.2zm1.1-1.2c.7 0 1.1-.4 1.1-1s-.4-1-1.1-1h-1.1v2z"/>
    <path d="m15.7 22.9-3.2-5v5h-1.4v-7.1h1.7l2.9 4.7v-4.7h1.4v7.1z"/>
    <path d="m24.1 22.9-.1-.8c-.3.5-1 1-2.1 1-1.9 0-3.5-1.4-3.5-3.7s1.7-3.7 3.6-3.7 2.9 1.1 3.2 2.2l-1.3.5c-.2-.6-.8-1.4-1.9-1.4s-2.2.7-2.2 2.4c0 1.6 1 2.4 2.2 2.4 1.3 0 1.8-.9 1.9-1.4h-2.2v-1.2h3.5v3.7z"/>
  </Icon>
);

export default withTheme(PngOutlineIcon);
