import { initializeApp } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  FacebookAuthProvider,
  getAuth,
  OAuthProvider,
  GithubAuthProvider,
  GoogleAuthProvider,
  linkWithCredential,
  linkWithPopup,
  onAuthStateChanged,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  TwitterAuthProvider,
  unlink,
  updatePassword,
  updateProfile
} from 'firebase/auth';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import config from './firebaseConfig';
import { AUTH_PROVIDERS } from '../constants';
import { message } from 'antd';

export const firebaseApp = initializeApp(config);
export const firebaseAuth = getAuth();

const FireBaseTools = {
  /**
   * Return an instance of a firebase auth provider based on the provider string.
   *
   * @param provider
   * @returns {firebase.auth.AuthProvider}
   */
  getProvider: (provider) => {
    switch (provider) {
      case AUTH_PROVIDERS.EMAIL:
        return new EmailAuthProvider();
      case AUTH_PROVIDERS.FACEBOOK:
        return new FacebookAuthProvider();
      case AUTH_PROVIDERS.GITHUB:
        return new GithubAuthProvider();
      case AUTH_PROVIDERS.GOOGLE:
        return new GoogleAuthProvider();
      case AUTH_PROVIDERS.APPLE:
        return new OAuthProvider(AUTH_PROVIDERS.APPLE);
      case AUTH_PROVIDERS.TWITTER:
        return new TwitterAuthProvider();
      default:
        throw new Error('Provider is not supported!!!');
    }
  },

  loginWithPassword: ({ email, password }) => {
    const newCredential = EmailAuthProvider.credential(email, password);

    return linkWithCredential(firebaseAuth.currentUser, newCredential).then((uc) => uc).catch(error => {
      return ({
        errorCode: error.code,
        errorMessage: error.message
      });
    });

  },

  verifyPassword: async (password) => {
    let user = firebaseAuth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, password);
    if (credential) {
      reauthenticateWithCredential(user, credential).then(() => {
        return true;
      }).catch((e) => {
        return 'error'
      });
    } else {
      return false;
    }
  },

  /**
   * Login with provider => p is provider "email", "facebook", "github", "google", or "twitter"
   * Uses Popup therefore provider must be an OAuth provider. EmailAuthProvider will throw an error
   *
   * @returns {any|!firebase.Thenable.<*>|firebase.Thenable<any>}
   */
  loginWithProvider: (p) => {
    const provider = FireBaseTools.getProvider(p);
    if (p === AUTH_PROVIDERS.GOOGLE) {
      provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
      provider.addScope('https://www.googleapis.com/auth/userinfo.email');
    }
    if (p === AUTH_PROVIDERS.APPLE) {
      provider.addScope('email');
      provider.addScope('name');
    }
    return signInWithPopup(firebaseAuth, provider).then((uc) => uc).catch(error => ({
      errorCode: error.code,
      errorMessage: error.message
    }));
  },

  linkProvider: (p) => {
    const provider = FireBaseTools.getProvider(p);
    return linkWithPopup(firebaseAuth.currentUser, provider).then((uc) => uc).catch(error => {
      return ({
        errorCode: error.code,
        errorMessage: error.message
      });
    });
  },

  /**
   * Register a user with email and password
   *
   * @param user
   * @returns {any|!firebase.Thenable.<*>|firebase.Thenable<any>}
   */
  registerUser: user => createUserWithEmailAndPassword(firebaseAuth, user.email, user.password)
    .then(userInfo => userInfo)
    .catch(error => ({
      errorCode: error.code,
      errorMessage: error.message
    })),

  /**
   * Sign the user out
   *
   * @returns {!firebase.Promise.<*>|firebase.Thenable<any>|firebase.Promise<any>|!firebase.Thenable.<*>}
   */
  logoutUser: () => signOut(firebaseAuth).then(() => ({
    success: 1,
    message: 'logout'
  })),

  /**
   * Get User Session Token
   * @returns any
   */
  getUserSessionToken: () => new Promise((resolve, reject) => {
    const unsub = onAuthStateChanged(firebaseAuth, async (user) => {
      unsub();
      if (user) {
        resolve(await user.getIdToken(true));
      }
    }, (error) => {
      reject(error);
    });
  }),

  /**
   * Retrieve the current user (Promise)
   * @returns {Promise}
   */
  fetchUser: () => new Promise((resolve, reject) => {
    const unsub = onAuthStateChanged(firebaseAuth, (user) => {
      unsub();
      resolve(user);
    }, (error) => {
      reject(error);
    });
  }),

  /**
   * Log the user in using email and password
   *
   * @param user
   * @returns {any|!firebase.Thenable.<*>|firebase.Thenable<any>}
   */
  loginUser: user => signInWithEmailAndPassword(firebaseAuth, user.email, user.password)
    .then(userInfo => userInfo)
    .catch(error => ({
      errorCode: error.code,
      errorMessage: error.message
    })),


  /**
   * Log the user in using token
   *
   * @param token
   * @returns {any|!firebase.Thenable.<*>|firebase.Thenable<any>}
   */
  loginUserWithToken: token => signInWithCustomToken(firebaseAuth, token)
    .then(userInfo => userInfo)
    .catch(error => ({
      errorCode: error.code,
      errorMessage: error.message
    })),

  /**
   * Update a user's profile data
   *
   * @param u
   * @returns {!firebase.Promise.<*>|firebase.Thenable<any>|firebase.Promise<any>|!firebase.Thenable.<*>}
   */
  updateUserProfile: u => updateProfile(firebaseAuth.currentUser, u).then(() => firebaseAuth.currentUser, error => ({
    errorCode: error.code,
    errorMessage: error.message
  })),

  /**
   * Reset the password given the specified email
   *
   * @param email {string}
   * @returns {!firebase.Promise.<*>|firebase.Thenable<any>|firebase.Promise<any>|!firebase.Thenable.<*>}
   */
  resetPasswordEmail: email => sendPasswordResetEmail(firebaseAuth, email).then(() => true, (error) => ({
    errorCode: error.code,
    errorMessage: error.message
  })),

  /**
   * Update the user's password with the given password
   *
   * @param newPassword {string}
   * @returns {!firebase.Promise.<*>|firebase.Thenable<any>|firebase.Promise<any>|!firebase.Thenable.<*>}
   */
  changePassword: newPassword => updatePassword(firebaseAuth.currentUser, newPassword).then(user => {
    message.success('Password updated successfully.', 6);
  }, error => {
    message.error(error.message, 6);
  }),

  /**
   * Send an account email verification message for the currently logged in user
   *
   * @returns {!firebase.Promise.<*>|firebase.Thenable<any>|firebase.Promise<any>|!firebase.Thenable.<*>}
   */
  sendEmailVerification: () => sendEmailVerification(firebaseAuth.currentUser).then(() => ({
    message: 'Email sent'
  }), error => ({
    errorCode: error.code,
    errorMessage: error.message
  })),

  /**
   * Upload from a Blob or File
   *
   */
  uploadFile: async ({ file, filePath, fileName }) => {
    const storage = getStorage(firebaseApp);
    const storageRef = ref(storage, `${filePath}/${fileName}`);

    // Upload the file and metadata
    const uploadFile = await uploadBytesResumable(storageRef, file);

    return getDownloadURL(uploadFile.ref);
  },

  /**
   * Unlink an auth provider from a user account
   *
   */
  unlinkProvider: async (providerId) => unlink(firebaseAuth.currentUser, providerId).then(() => {
    // Auth provider unlinked from account
    // ...
  }).catch((error) => {
    // An error happened
    // ...
  })

};

export default FireBaseTools;
