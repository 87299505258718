import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const AccessMenu = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M7.5,22c-1.6,0-3.3-0.6-4.6-1.9c-2.6-2.6-2.5-6.8,0.1-9.3c2.3-2.2,5.8-2.4,8.3-0.6l9-9c0.4-0.4,1-0.4,1.4,0
	s0.4,1,0,1.4L20.4,4l2.3,2.3c0.4,0.4,0.4,1,0,1.4l-3.5,3.5c-0.4,0.4-1,0.4-1.4,0l-2.3-2.3l-2.7,2.7c1.9,2.5,1.7,6.1-0.6,8.4
	C10.9,21.4,9.2,22,7.5,22z M7.6,11.1c-1.1,0-2.3,0.4-3.1,1.3c-1.8,1.7-1.8,4.6-0.1,6.4c1.8,1.8,4.7,1.8,6.4,0c1.7-1.8,1.7-4.6,0-6.4
	C9.8,11.5,8.7,11.1,7.6,11.1z M16.9,7.5l1.6,1.6L20.6,7L19,5.4L16.9,7.5z"/>
        </Icon>
    );

export default withTheme(AccessMenu);
