import React from 'react';
import { withTheme } from 'styled-components';
import Icon from './components/Icon';

const EventTemplatesMenu = props => (
        <Icon {...props}>
            <path d="M19.5,10v2H13v-2H19.5z M16.2,14c0.8,0,1.5,0.7,1.5,1.5
	c0,0.8-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5C14.7,14.7,15.4,14,16.2,14z M7.5,4.7H4.7c-0.1,0-0.2,0.1-0.2,0.2v6.2
	c0,0.1,0.1,0.2,0.2,0.2h4.7c0.1,0,0.2-0.1,0.2-0.2V6.7L7.5,4.7z M7.8,2.7c0.4,0,0.7,0.1,1,0.4l2.3,2.3c0.3,0.3,0.4,0.6,0.4,1v4.7
	c0,1.2-1,2.2-2.2,2.2H4.7c-1.2,0-2.2-1-2.2-2.2V4.9c0-1.2,1-2.2,2.2-2.2H7.8z M16.5,2.5c0.6,0,1,0.4,1,1v2h1c1.6,0,2.9,1.2,3,2.8
	l0,0.2v10c0,1.7-1.3,3-3,3h-12c-1.7,0-3-1.3-3-3V15h2v3.5c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1v-10c0-0.6-0.4-1-1-1H13v-2h2.5v-2
	C15.5,2.9,15.9,2.5,16.5,2.5z"/>
        </Icon>
    );

export default withTheme(EventTemplatesMenu);
