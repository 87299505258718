import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const LockIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M7,22h10c1.7,0,3-1.3,3-3v-6c0-1.7-1.3-3-3-3h-1V6c0-2.2-1.8-4-4-4S8,3.8,8,6v4H7c-1.7,0-3,1.3-3,3v6C4,20.7,5.3,22,7,22zM10,6c0-1.1,0.9-2,2-2s2,0.9,2,2v4h-4V6z"/>
        </Icon>
    );

export default withTheme(LockIcon);
