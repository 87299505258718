import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { CloseIcon, ProgramSolidIcon } from '../Icons/General';
import { H5 } from '../Typography';
import styles from '../../constants/styles';

const ProgramBadge = (props) => {

  let className = '';
  let size = 14;
  if (props.size && props.size === 'md') {
    className = 'pb-md';
    size = 18;
  }

  if (!props.to) {
    return (
      <Wrapper data-testid={`${props.dataTestId}-program-badge`} className={`program-badge ${className}`}>
        <Icon size="md">
          <ProgramSolidIcon width={size} height={size} defaultFill={props.color || styles.colors.primary}/>
        </Icon>
        <Text>{props.text}</Text>
        {props.onChange ? (
          <Close onClick={() => props.onChange()}>
            <CloseIcon defaultFill={styles.colors.silver} height={size} width={size}/>
          </Close>
        ) : null}
      </Wrapper>
    );
  }

  return (
    <Block data-testid={`${props.dataTestId}-program-badge`} className={`program-badge ${className}`}>
      <Icon>
        <ProgramSolidIcon width={size} height={size} defaultFill={props.color || styles.colors.primary}/>
      </Icon>
      <Text>{props.text}</Text>
    </Block>
  );
};
export default withTheme(ProgramBadge);

ProgramBadge.propTypes = {
  onChange: PropTypes.func,
  color: PropTypes.string,
  dataTestId: PropTypes.string,
  text: PropTypes.string
};

ProgramBadge.defaultProps = {
  dataTestId: '',
  color: null,
  onChange: null,
  text: ''
};

const Icon = styled.div`
  position: absolute;
  top: 4px;
  left: 5px;
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Close = styled(Icon)`
  left: inherit;
  right: 5px;
  cursor: pointer;
`;
const Block = styled.span`
  background: ${styles.colors.mostlyWhite};
  border: 1px solid ${styles.borders.input.color.default};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  border-radius: 4px;
  padding: 2px 8px 2px 22px;
  margin: 0;

  &.pb-md {
    padding: 8px 16px 8px 36px;
  }
`;
const Wrapper = styled.div`
  background: ${styles.colors.mostlyWhite};
  border: 1px solid ${styles.borders.input.color.default};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  border-radius: 4px;
  padding: 2px 26px 2px 22px;
  margin: 0;

  &.pb-md {
    padding: 8px 44px 8px 36px;

    ${Icon} {
      width: 18px;
      height: 18px;
      top: 8px;
      left: 11px;
    }

    ${Close} {
      left: inherit;
      right: 11px;
    }
  }
`;

const Text = styled(H5)`
  font-family: ${styles.fonts.family.primaryBook};
  margin: 0;
  color: ${styles.colors.dark};
`;
