import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../constants/styles';

const WaterSanitation = (props) => (
    <Container isActive={props.isActive}>
      <svg
          width="20px"
          height="20px"
          id="water_sanitation"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          enableBackground="new 0 0 24 24"
          xmlSpace="preserve"
      >
        <g id="mask">
          <path fill={props.isActive ? styles.colors.causes.waterSanitation : styles.colors.silver} d="M3.7,5.1C5,6.3,7,6.3,8.3,5.1c0.4-0.4,1-0.4,1.4,0
	c1.3,1.3,3.3,1.3,4.6,0c0.4-0.4,1-0.4,1.4,0c1.3,1.3,3.3,1.3,4.6,0c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4c-1.8,1.8-4.7,2-6.7,0.6
	c-1.8,1.3-4.2,1.3-6,0C8.1,7.7,7.1,8,6,8C4.7,8,3.3,7.5,2.3,6.5c-0.4-0.4-0.4-1,0-1.4C2.7,4.7,3.3,4.7,3.7,5.1z M3.7,10.5
	c1.3,1.3,3.3,1.3,4.6,0c0.4-0.4,1-0.4,1.4,0c1.3,1.3,3.3,1.3,4.6,0c0.4-0.4,1-0.4,1.4,0c1.3,1.3,3.3,1.3,4.6,0c0.4-0.4,1-0.4,1.4,0
	c0.4,0.4,0.4,1,0,1.4c-1.8,1.8-4.7,2-6.7,0.6c-1.8,1.3-4.2,1.3-6,0c-0.9,0.6-1.9,0.9-3,0.9c-1.4,0-2.7-0.5-3.7-1.5
	c-0.4-0.4-0.4-1,0-1.4C2.7,10.1,3.3,10.1,3.7,10.5z M3.7,16.3c1.2,1.2,3.4,1.2,4.6,0c0.4-0.4,1-0.4,1.4,0c1.2,1.2,3.4,1.2,4.6,0
	c0.4-0.4,1-0.4,1.4,0c1.3,1.3,3.3,1.3,4.6,0c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4c-1,1-2.3,1.5-3.7,1.5c-1.1,0-2.1-0.3-3-0.9
	c-1.7,1.2-4.3,1.2-6,0c-0.9,0.6-1.9,0.9-3,0.9c-1.4,0-2.7-0.5-3.7-1.5c-0.4-0.4-0.4-1,0-1.4C2.7,15.9,3.3,15.9,3.7,16.3z"/>
        </g>
      </svg>
    </Container>
);

export default WaterSanitation;

WaterSanitation.propTypes = {
  isActive: PropTypes.bool
};

WaterSanitation.defaultProps = {
  isActive: true
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: ${props => props.isActive ? `${styles.colors.causes.waterSanitation}1f` : styles.colors.mostlyWhite};
    border-radius: 20px;
`;