import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const GivingIcon = props => (
    <Icon {...props} viewBox={'0 0 24 24'}>
        <path d="M12,9H7.5C5.6,9,4,7.4,4,5.5S5.6,2,7.5,2c4.3,0,5.4,5.6,5.5,5.8c0.1,0.3,0,0.6-0.2,0.8C12.6,8.9,12.3,9,12,9z M7.5,4
		C6.7,4,6,4.7,6,5.5S6.7,7,7.5,7h3.2C10.2,5.8,9.2,4,7.5,4z" />
        <path d="M16.5,9H12c-0.3,0-0.6-0.1-0.8-0.4C11,8.4,11,8.1,11,7.8c0-0.2,1.2-5.8,5.5-5.8C18.4,2,20,3.6,20,5.5S18.4,9,16.5,9z
		 M13.3,7h3.2C17.3,7,18,6.3,18,5.5S17.3,4,16.5,4C14.8,4,13.8,5.8,13.3,7z" />
        <path d="M17,22H7c-1.7,0-3-1.3-3-3l0-5.2c-1.2-0.4-2-1.5-2-2.8l0-1c0-1.7,1.3-3,3-3h14c1.7,0,3,1.3,3,3v1c0,1.3-0.8,2.4-2,2.8V19
		C20,20.7,18.7,22,17,22z M5,9c-0.6,0-1,0.4-1,1l0,1c0,0.6,0.4,1,1,1c0.6,0,1,0.4,1,1l0,6c0,0.6,0.4,1,1,1h10c0.6,0,1-0.4,1-1l0-6
		c0-0.3,0.1-0.5,0.3-0.7S18.7,12,19,12c0.6,0,1-0.4,1-1v-1c0-0.6-0.4-1-1-1H5z" />
		<path d="M15,14H9c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1S15.6,14,15,14z" />
    </Icon>
);

export default withTheme(GivingIcon);
