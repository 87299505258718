import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const BookmarkIcon = props => (
    <Icon {...props} viewBox={'0 0 24 24'}>
        <path d="M20,2H4v19c0,0.4,0.2,0.7,0.5,0.9c0.3,0.2,0.7,0.1,1-0.1l6.4-4.6l6.4,4.6c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.3,0,0.5-0.1
	c0.3-0.2,0.5-0.5,0.5-0.9V2z"/>
    </Icon>
);

export default withTheme(BookmarkIcon);
