import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const EyeIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M21.9,11.5c-0.6-1.1-4.2-6.7-10.1-6.5C6.2,5.1,3,10,2.1,11.5c-0.2,0.3-0.2,0.7,0,1c0.6,1.1,4,6.5,9.9,6.5h0.2
	c5.5-0.1,8.7-5,9.6-6.5C22,12.2,22,11.8,21.9,11.5z M12.2,17c-4.3,0.1-7.1-3.6-8-5c1-1.6,3.6-4.9,7.6-5c4.3-0.1,7.1,3.6,8,5
	C18.8,13.6,16.2,16.9,12.2,17z" />
	        <path d="M12,8.5c-1.9,0-3.5,1.6-3.5,3.5s1.6,3.5,3.5,3.5s3.5-1.6,3.5-3.5S13.9,8.5,12,8.5z M12,13.5c-0.8,0-1.5-0.7-1.5-1.5
	s0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5S12.8,13.5,12,13.5z" />
        </Icon>
    );

export default withTheme(EyeIcon);
