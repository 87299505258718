import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const CoHostEventRemovedIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M9.3,22H3c-1.7,0-3-1.3-3-3V5c0-1.7,1.3-3,3-3h14c1.7,0,3,1.3,3,3v2c0,0.6-0.4,1-1,1s-1-0.4-1-1V5c0-0.6-0.4-1-1-1H3C2.4,4,2,4.4,2,5v14c0,0.6,0.4,1,1,1h6.3c0.6,0,1,0.4,1,1S9.9,22,9.3,22z"/>
            <path d="M14,6c-0.6,0-1-0.4-1-1V1c0-0.6,0.4-1,1-1s1,0.4,1,1v4C15,5.6,14.6,6,14,6z"/>
            <path d="M6,6C5.4,6,5,5.6,5,5V1c0-0.6,0.4-1,1-1s1,0.4,1,1v4C7,5.6,6.6,6,6,6z"/>
            <path d="M11.3,10H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h10.3c0.6,0,1,0.4,1,1S11.9,10,11.3,10z"/>
            <path d="M3.5,14.9c0-1.8,1.3-3,2.9-3c1.7,0,2.5,1.1,2.6,1.9l-1.2,0.4c-0.1-0.4-0.4-1.1-1.4-1.1c-0.8,0-1.6,0.5-1.6,1.7c0,1.1,0.8,1.7,1.6,1.7c1,0,1.4-0.7,1.5-1.1l1.2,0.3c-0.2,0.8-0.9,2-2.7,2C4.8,17.8,3.5,16.6,3.5,14.9z"/>
            <path d="M23,14.7H11c-0.6,0-1-0.4-1-1s0.4-1,1-1h12c0.6,0,1,0.4,1,1S23.6,14.7,23,14.7z"/>
            <path d="M20.3,24h-6.7c-1.3,0-2.3-1-2.3-2.3v-8c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1v8c0,0.2,0.1,0.3,0.3,0.3h6.7c0.2,0,0.3-0.1,0.3-0.3v-8c0-0.6,0.4-1,1-1s1,0.4,1,1v8C22.7,23,21.6,24,20.3,24z M19.7,14.7c-0.6,0-1-0.4-1-1v-2.3c0-0.2-0.1-0.3-0.3-0.3h-2.7c-0.2,0-0.3,0.1-0.3,0.3v2.3c0,0.6-0.4,1-1,1s-1-0.4-1-1v-2.3c0-1.3,1-2.3,2.3-2.3h2.7c1.3,0,2.3,1,2.3,2.3v2.3C20.7,14.2,20.2,14.7,19.7,14.7z"/>
        </Icon>
    );

export default withTheme(CoHostEventRemovedIcon);
