export const PHONE_REGX = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export const LINK_REGX = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i;

export const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PUTCH: 'PUTCH',
  DELETE: 'DELETE'
};

export const LOCATION_DEFAULT = {
  lat: 39.9678558,
  lng: -83.0005317,
  address: 'Columbus, OH 43215, USA'
};

export const CITIES = {
  CINCINNATI: {
    label: 'Cincinnati',
    value: 'cincinnati',
    lat: 39.143262,
    lng: -84.509000,
    miles: 12
  },
  COLUMBUS: {
    label: 'Columbus',
    value: 'columbus',
    lat: 39.959381,
    lng: -83.006944,
    miles: 14
  },
  CLEVELAND: {
    label: 'Cleveland',
    value: 'cleveland',
    lat: 41.486238,
    lng: -81.684620,
    miles: 20
  },
  AUSTIN: {
    label: 'Austin',
    value: 'austin',
    lat: 30.314179,
    lng: -97.727460,
    miles: 20
  },
  DENVER: {
    label: 'Denver',
    value: 'denver',
    lat: 39.747890,
    lng: -104.933392,
    miles: 20
  },
  ATLANTA: {
    label: 'Atlanta',
    value: 'atlanta',
    lat: 33.753746,
    lng: -84.386330,
    miles: 20
  }
};

export const AUTH_PROVIDERS = {
  PASSWORD: 'password',
  APPLE: 'apple.com',
  GOOGLE: 'google.com',
  EMAIL: 'email',
  FACEBOOK: 'facebook.com',
  GITHUB: 'github',
  TWITTER: 'twitter.com'
};

export const REGISTRY_ITEM_STATUSES = {
  OPEN: 'open',
  CLOSED: 'closed'
};
export const REGISTRY_ITEMS_SORT = {
  URGENCY_ASC: 'urgency_asc',
  URGENCY_DESC: 'urgency_desc',
  PRICE_ASC: 'price_asc',
  PRICE_DESC: 'price_desc'
};
export const REGISTRY_ITEMS_FILTER_STATUSES = {
  ALL: 'all',
  COMPLETED: 'completed',
  IN_PROGRESS: 'in_progress'
};

export const DONATION_TYPES = {
  GIFTED: 'gifted',
  FUNDED: 'funded'
};
export const DONATION_URGENCY_TYPES = {
  STANDARD: 'standard',
  MEDIUM: 'medium',
  HIGH: 'high'
};

export const USER_GENDER = [
  { label: 'Male', value: 2 },
  { label: 'Female', value: 3 },
  { label: 'Non-Binary', value: 4 }
];

export const GENDER_CHOICES = {
  0: 'Gender',
  1: 'Prefer not to say',
  2: 'Male',
  3: 'Female',
  4: 'Non-Binary',
};
export const GENDER_CHOICES_SHORT = {
  0: 'G',
  1: 'N/A',
  2: 'M',
  3: 'F',
  4: 'N/B',
};

export const EVENT_STATUSES = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  CANCELLED: 'cancelled'
};

export const EVENT_TENSES = {
  PAST: 'past',
  UPCOMING: 'upcoming',
  ALL: 'all'
};

export const EVENTS_TABS = {
  PAST: 'past',
  UPCOMING: 'upcoming',
  ALL: 'all',
  DRAFTS: 'drafts'
};

export const DEFAULT_TIMEZONE = 'America/New_York';

export const GROUP_TYPES = {
  GROUP: 'group',
  GRADE: 'grade',
  CLUB: 'club'
};
export const GROUP_TYPES_OPTIONS = [
  { id: 'group', title: 'Group' },
  { id: 'grade', title: 'Grade' },
  { id: 'club', title: 'Club' }
];

export const GROUP_USER_SORT = {
  JOIN_TIME: 'join',
  HOURS: 'hours',
  NAME_ASC: 'nameasc',
  NAME_DESC: 'namedesc'
};

export const PERIODS_OF_TIME = [
  { value: 'oneday', label: 'Day' },
  { value: 'oneweek', label: 'Week' },
  { value: 'onemonth', label: 'Month' },
  { value: 'oneyear', label: 'Year' }
];

export const EVENT_SORT = {
  DATE_START_ASC: 'start-asc',
  DATE_START_DESC: 'start-desc',
  NAME_ASC: 'name-asc',
  NAME_DESC: 'name-desc',
};

export const ORGANIZATION_TYPES = {
  SCHOOL: 'school',
  RELIGION: 'religion',
  NONPROFIT: 'non_profit',
  LOCAL_GOVERNMENT: 'local_government',
  BUSINESS: 'business',
  OTHER: 'other',
  CLUB: 'club'
};

export const ORGANIZATION_TYPES_OPTION = [
  { label: 'School', value: ORGANIZATION_TYPES.SCHOOL },
  { label: 'Religious Congregation', value: ORGANIZATION_TYPES.RELIGION },
  { label: 'Nonprofit', value: ORGANIZATION_TYPES.NONPROFIT },
  { label: 'Local Government', value: ORGANIZATION_TYPES.LOCAL_GOVERNMENT },
  { label: 'Business', value: ORGANIZATION_TYPES.BUSINESS }
];

export const PROFILE_TYPES = {
  USER: 'user',
  SCHOOL: ORGANIZATION_TYPES.SCHOOL,
  RELIGION: ORGANIZATION_TYPES.RELIGION,
  NONPROFIT: ORGANIZATION_TYPES.NONPROFIT,
  LOCALGOVERNMENT: ORGANIZATION_TYPES.LOCAL_GOVERNMENT,
  BUSINESS: ORGANIZATION_TYPES.BUSINESS,
  OTHER: ORGANIZATION_TYPES.OTHER,
  CLUB: ORGANIZATION_TYPES.CLUB,
  SYSTEM: 'system'
};

export const NETWORK_TYPES = {
  OPEN: 'open',
  CLOSED: 'closed',
  SECURE: 'secure'
};

export const NETWORK_ACCESS = {
  OVERVIEW: 'overview',
  DETAILED_VIEW: 'detailed_view',
  LIMITED_ADMIN: 'limited_admin',
  FULL_ADMIN: 'full_admin'
};

export const NETWORK_ACCESS_INFO = [
  {
    access: NETWORK_ACCESS.OVERVIEW,
    title: 'Overview Access',
    desc: 'View overall aggregated statistics for individual organizations in your network. All user data is de-identified and hidden.'
  },
  {
    access: NETWORK_ACCESS.DETAILED_VIEW,
    title: 'Detailed View Access',
    desc: 'View overall statistics for individual organizations in your network, plus their groups, group statistics, and events. All user data is de-identified and hidden.'
  },
  {
    access: NETWORK_ACCESS.LIMITED_ADMIN,
    title: 'Limited Admin Access',
    desc: 'Editing and viewing access for individual organizations\' statistics, groups, and events. All user data is de-identified and hidden.'
  },
  {
    access: NETWORK_ACCESS.FULL_ADMIN,
    title: 'Full Admin Access',
    desc: 'Editing and viewing access for individual organizations\' statistics, groups, and events. See user data, and add or de-affiliate user accounts.'
  }
];

export const NETWORK_STATUS = {
  MEMBERS: 'members',
  PENDING: 'pending',
  DECLINED: 'declined'
};

export const NETWORK_STATUS_OPTIONS = [
  { label: 'Network Members', value: NETWORK_STATUS.MEMBERS },
  { label: 'Pending', value: NETWORK_STATUS.PENDING },
  { label: 'Declined', value: NETWORK_STATUS.DECLINED }
];

export const ERROR_CODES = {
  NO_DATA: 'no_data'
};

export const TRACK_EVENTS = {
  LOGIN: 'login',
  ORGANIZATION_LOGIN: 'org_login'
};

export const ORGANIZATION_JOIN_TYPES = {
  ON_APPROVAL: 'on_approval',
  OPEN: 'open'
};

export const ORGANIZATION_STATUSES = {
  PENDING: 'pending',
  APPROVED: 'approved'
};

export const ORGANIZATION_USER_TAGS = {
  STUDENT: 'student',
  EMPLOYEE: 'employee'
};

export const ORGANIZATION_USER_TYPES = {
  VOLUNTEER: 'volunteer',
  CLIENT: 'client',
  ADMIN: 'admin',
  OWNER: 'owner'
};

export const ORGANIZATION_USER_PAGES = {
  STUDENTS: 'students',
  MEMBERS: 'members',
  VOLUNTEERS: 'volunteers',
  EMPLOYEES: 'employees'
};

export const ORGANIZATION_USER_SORT = {
  NAME_ASC: 'nameasc',
  NAME_DESC: 'namedesc',
  TIME_ASC: 'timeasc',
  TIME_DESC: 'timedesc',
  AGE_ASC: 'ageasc',
  AGE_DESC: 'agedesc',
  TYPE_ASC: 'typeasc',
  TYPE_DESC: 'typedesc'
};

export const PROVIDER_STATUS = {
  CONNECTED: 'connected',
  NOT_CONNECTED: 'not_connected'
};

export const ORGANIZATION_USER_SORT_OPTIONS = [
  { label: 'Sort by Name (A-Z)', value: ORGANIZATION_USER_SORT.NAME_ASC },
  { label: 'Sort by Name (Z-A)', value: ORGANIZATION_USER_SORT.NAME_DESC },
  { label: 'Sort by Hours (Least to Most)', value: ORGANIZATION_USER_SORT.TIME_ASC },
  { label: 'Sort by Hours (Most to Least)', value: ORGANIZATION_USER_SORT.TIME_DESC },
  { label: 'Sort by Age (Youngest to Oldest)', value: ORGANIZATION_USER_SORT.AGE_ASC },
  { label: 'Sort by Age (Oldest to Youngest)', value: ORGANIZATION_USER_SORT.AGE_DESC },
  { label: 'Sort by Status (↓)', value: ORGANIZATION_USER_SORT.TYPE_ASC },
  { label: 'Sort by Status (↑)', value: ORGANIZATION_USER_SORT.TYPE_DESC }
];

export const ORGANIZATION_USER_RELATIONS = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
  JOIN_PENDING: 'join_pending',
  INVITE_PENDING: 'invite_pending',
  INVITE_SENT: 'invite_sent'
};

export const ORGANIZATION_USER_RELATIONS_OPTIONS = [
  { label: 'Filter by', value: 'all' },
  { label: 'Filter by Internal', value: ORGANIZATION_USER_RELATIONS.INTERNAL },
  { label: 'Filter by External', value: ORGANIZATION_USER_RELATIONS.EXTERNAL },
  { label: 'Filter by Pending Approval', value: ORGANIZATION_USER_RELATIONS.JOIN_PENDING },
  { label: 'Filter by Invite Pending', value: ORGANIZATION_USER_RELATIONS.INVITE_PENDING },
  { label: 'Filter by Invite Sent', value: ORGANIZATION_USER_RELATIONS.INVITE_SENT },
];

export const ORGANIZATION_USER_RELATIONS_OPTIONS_BAS = [
  { label: 'Filter by', value: 'all' },
  { label: 'Filter by Internal', value: ORGANIZATION_USER_RELATIONS.INTERNAL },
  { label: 'Filter by Pending Approval', value: ORGANIZATION_USER_RELATIONS.JOIN_PENDING },
  { label: 'Filter by Invite Pending', value: ORGANIZATION_USER_RELATIONS.INVITE_PENDING },
  { label: 'Filter by Invite Sent', value: ORGANIZATION_USER_RELATIONS.INVITE_SENT },
];

export const USER_STATUSES = {
  PENDING: 'pending',
  INVITED: 'invited',
  APPROVED: 'approved'
};

export const EVENT_HOSTING_STATUSES = {
  PENDING: 'pending',
  CONFIRMED: 'confirmed',
  REJECTED: 'rejected'
};

export const EVENT_HOSTING_VISIBILITY = {
  EVERYONE: 'everyone',
  ORGANIZATION: 'organization',
  GROUPS: 'groups',
  NONE: 'none'
};

export const HOUR_STATUSES = {
  PENDING: 'pending',
  CONFIRMED: 'confirmed',
  REJECTED: 'rejected'
};

export const ATTENDANCE_STATUSES = {
  PENDING: 'pending',
  VISITED: 'visited',
  SKIPPED: 'skipped',
  DISMISSED: 'dismissed'
};

export const TIMELINE_RECORD_TYPE = {
  UNDEFINED: 0,
  USER_SIGNED_UP: 1,
  USER_CHECK_IN: 2,
  USER_CHECK_OUT: 3,
  USER_CANCELED: 4,
  EVENT_STARTED: 100,
  EVENT_DELETED: 101,
  EVENT_CO_HOST_INVITED: 102,
  EVENT_CO_HOST_REMOVED: 103,
  EVENT_CO_HOST_CONFIRMED: 104,
  EVENT_CO_HOST_REJECTED: 105
};

export const DATE_FORMAT = 'ddd, MMM Do, YYYY';
export const TIME_FORMAT = 'hh:mm a';

export const DATA_WEEK = [
  { value: 'sun', label: 'Sunday' },
  { value: 'mon', label: 'Monday' },
  { value: 'tue', label: 'Tuesday' },
  { value: 'wed', label: 'Wednesday' },
  { value: 'thu', label: 'Thursday' },
  { value: 'fri', label: 'Friday' },
  { value: 'sat', label: 'Saturday' }
];

export const DATA_MONTH = [
  { label: '01', value: '01' },
  { label: '02', value: '02' },
  { label: '03', value: '03' },
  { label: '04', value: '04' },
  { label: '05', value: '05' },
  { label: '06', value: '06' },
  { label: '07', value: '07' },
  { label: '08', value: '08' },
  { label: '09', value: '09' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' },
];

export const DATA_YEAR = [
  { label: '18', value: '2018' },
  { label: '19', value: '2019' },
  { label: '20', value: '2020' },
  { label: '21', value: '2021' },
  { label: '22', value: '2022' },
  { label: '23', value: '2023' },
  { label: '24', value: '2024' },
  { label: '25', value: '2025' },
  { label: '26', value: '2026' },
  { label: '27', value: '2027' },
  { label: '28', value: '2028' },
  { label: '29', value: '2029' },
  { label: '30', value: '2030' },
  { label: '31', value: '2031' },
  { label: '32', value: '2032' },
  { label: '33', value: '2033' },
  { label: '34', value: '2034' },
  { label: '35', value: '2035' },
  { label: '36', value: '2036' },
  { label: '37', value: '2037' },
  { label: '38', value: '2038' },
  { label: '39', value: '2039' },
  { label: '40', value: '2040' },
];

export const DATA_STATE = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'District Of Columbia', value: 'DC' },
  { label: 'Federated States Of Micronesia', value: 'FM' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Guam', value: 'GU' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Palau', value: 'PW' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virgin Islands', value: 'VI' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' }
];

export const DATA_COUNTRY = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'land Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'AndorrA', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: 'Cote D\'Ivoire', code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: 'Korea, Democratic People\'S Republic of', code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: 'Lao People\'S Democratic Republic', code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montenegro', code: 'ME' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'RWANDA', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia', code: 'RS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' }
];

export const INPUT_SIZE = {
  SMALL: 'sm',
  NORMAL: 'nm'
};

export const SELECT_TYPE = {
  DEFAULT: 'default',
  BUTTON: 'btn'
};

export const INPUT_TYPE = {
  TEXT: 'text',
  PASSWORD: 'password',
  SEARCH: 'search',
  SEARCH_WITH_SELECT: 'search_with_select',
  PHONE: 'tel',
  EMAIL: 'email',
  USERNAME: 'username',
  NUMBER: 'number',
  WEBSITE: 'website',
  MONEY: 'money',
  TIME: 'time',
  DATETIME: 'date_time',
  ZIP: 'zip',
  EIN: 'ein',
  DURATION: 'duration',
  CVV: 'cvv',
  DATE_CARD: 'date_card',
  CARD: 'card'
};

export const DATE_TIME_LOOK = {
  DATE: 'date',
  TIME: 'time',
  ALL: 'all'
};

export const INPUT_STATE_OF_DATA = {
  BAD: 'bad',
  SUCCESS: 'success',
  DEFAULT: 'default'
};

export const MIN_YEARS_OLD_USER = 13;
