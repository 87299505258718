import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../constants/styles';

const CommunityDevelopment = (props) => (
    <Container isActive={props.isActive}>
      <svg
          width="20px"
          height="20px"
          id="community_development"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          enableBackground="new 0 0 24 24"
          xmlSpace="preserve"
      >
        <g id="mask">
          <path fill={props.isActive ? styles.colors.causes.communityDevelopment : styles.colors.silver} d="M10.5,1.5c0.9-0.5,2.1-0.5,3,0l7,4c0.1,0.1,0.3,0.2,0.4,0.3L12,11
	L3.1,5.8c0.1-0.1,0.2-0.2,0.4-0.3L10.5,1.5z M2.1,7.5l8.9,5.2v10.2c-0.2-0.1-0.3-0.1-0.5-0.2l-7-4C2.6,18.2,2,17.2,2,16.1v-8
	C2,7.9,2,7.7,2.1,7.5z M21.9,7.5C22,7.7,22,7.9,22,8.1v8c0,1.1-0.6,2.1-1.5,2.6l-7,4c-0.2,0.1-0.3,0.1-0.5,0.2V12.7L21.9,7.5z"/>
        </g>
      </svg>
    </Container>
);

export default CommunityDevelopment;

CommunityDevelopment.propTypes = {
  isActive: PropTypes.bool
};

CommunityDevelopment.defaultProps = {
  isActive: true
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: ${props => props.isActive ? `${styles.colors.causes.communityDevelopment}1f` : styles.colors.mostlyWhite};
    border-radius: 20px;
`;
