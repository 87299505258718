import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const CsvIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M22.7,7.3l-6-6C16.5,1.1,16.3,1,16,1H8C6.3,1,5,2.3,5,4v4h2V4c0-0.6,0.4-1,1-1h7.6L21,8.4V20
	c0,0.6-0.4,1-1,1H8c-0.6,0-1-0.4-1-1v-1H5v1c0,1.7,1.3,3,3,3h12c1.7,0,3-1.3,3-3V8C23,7.7,22.9,7.5,22.7,7.3z"/>
            <path d="M1,13.5C1,11.7,2.1,10,4.3,10c1.4,0,2.4,0.7,3,2l-1.2,0.5c-0.3-0.9-1-1.3-1.9-1.3c-1.5,0-2.1,1-2.1,2.3
	c0,1.4,0.6,2.4,2.1,2.4c0.9,0,1.5-0.3,1.9-1.3l1.1,0.5c-0.5,1.3-1.6,2-3,2C2.1,17.1,1,15.4,1,13.5z"/>
            <path d="M7.8,15L9,14.7c0.1,0.9,0.7,1.2,1.4,1.2c0.7,0,1.1-0.4,1.1-0.9c0-1.4-3.3-0.9-3.3-3.2c0-1.2,1-1.9,2.1-1.9
	c0.8,0,1.6,0.4,2.1,1.2l-1,0.6c-0.2-0.5-0.6-0.7-1.1-0.7c-0.5,0-0.9,0.3-0.9,0.7c0,1.3,3.3,0.9,3.3,3.1c0,1.3-1.1,2.1-2.4,2.1
	C9.2,17,8,16.4,7.8,15z"/>
            <path d="M19.5,10.1L16.8,17h-0.9l-2.7-6.9h1.4l1.8,4.9l1.8-4.9H19.5z"/>
        </Icon>
    );

export default withTheme(CsvIcon);
