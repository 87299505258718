import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H4, P2 } from '../Typography';
import EmptyImage from '../../assets/images/svg/empty-state/empty-data.svg';

const NoData = (props) => {
    const { image, title, desc, button, minHeight, height, className } = props;

    return (
        <NoDataSection
            height={height || null}
            minHeight={minHeight || 0}
            className={className}
        >
            <NoDataImage
                src={image || EmptyImage}
                alt={title || 'no data icon'}
            />
            {title && <NoDataTitle>{title}</NoDataTitle>}
            {desc && <NoDataDesc>{desc}</NoDataDesc>}
            {button || null}
        </NoDataSection>
    );
};
export default NoData;

NoData.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    desc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    button: PropTypes.shape(),
    minHeight: PropTypes.number
}

NoData.defaultProps = {
    image: null,
    title: null,
    desc: null,
    button: null,
    height: null,
    minHeight: 0
};

const NoDataSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: ${(props) => props.height ? props.height + 'px' : '100%'};
    min-height: ${(props) => props.minHeight ? props.minHeight + 'px' : 'calc(100vh - 350px)'};
    .btn {
        margin-top: 10px;
    }
`;
const NoDataImage = styled.img`
    height: 64px;
    width: 64px;
    margin-bottom: 20px;
`;
const NoDataTitle = styled(H4)`
    margin-bottom: 5px;
`;
const NoDataDesc = styled(P2)`
    margin-bottom: 15px;
`;
