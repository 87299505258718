import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ArrowRight = ({size, fill = '#a6aebc'}) => (
  <Svg
    size={size}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path id="arrow-right-a" d="M12,13.5857864 L16.2928932,9.29289322 C16.6834175,8.90236893 17.3165825,8.90236893 17.7071068,9.29289322 C18.0976311,9.68341751 18.0976311,10.3165825 17.7071068,10.7071068 L12.7071068,15.7071068 C12.3165825,16.0976311 11.6834175,16.0976311 11.2928932,15.7071068 L6.29289322,10.7071068 C5.90236893,10.3165825 5.90236893,9.68341751 6.29289322,9.29289322 C6.68341751,8.90236893 7.31658249,8.90236893 7.70710678,9.29289322 L12,13.5857864 Z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="arrow-right-b" fill="#fff">
        <use xlinkHref="#arrow-right-a" />
      </mask>
      <use fill="#051033" fillOpacity="0" fillRule="nonzero" transform="rotate(-90 12 12.5)" xlinkHref="#arrow-right-a" />
      <g fill={fill} mask="url(#arrow-right-b)">
        <polygon points="0 0 24 0 24 24 0 24" />
      </g>
    </g>
  </Svg>
);

const Svg = styled.svg`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  transform: rotate(-90deg);
  g {
    transition: .25s ease;
  }
`;

ArrowRight.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string
};

ArrowRight.defaultProps = {
  size: 24
};

export default ArrowRight;
