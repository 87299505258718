import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const ImageIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M19,22H5c-1.7,0-3-1.3-3-3V5c0-1.7,1.3-3,3-3h14c1.7,0,3,1.3,3,3v14C22,20.7,20.7,22,19,22z M5,4
	C4.4,4,4,4.4,4,5v14c0,0.6,0.4,1,1,1h14c0.6,0,1-0.4,1-1V5c0-0.6-0.4-1-1-1H5z"/>
            <path d="M8.5,11C7.1,11,6,9.9,6,8.5S7.1,6,8.5,6S11,7.1,11,8.5S9.9,11,8.5,11z M8.5,8C8.2,8,8,8.2,8,8.5S8.2,9,8.5,9
	S9,8.8,9,8.5S8.8,8,8.5,8z"/>
            <path d="M5,22c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l11-11c0.4-0.4,1-0.4,1.4,0l5,5c0.4,0.4,0.4,1,0,1.4
	s-1,0.4-1.4,0L16,11.4L5.7,21.7C5.5,21.9,5.3,22,5,22z"/>
        </Icon>
    );

export default withTheme(ImageIcon);
