import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../constants/styles';

const Energy = (props) => (
    <Container isActive={props.isActive}>
      <svg
          width="20px"
          height="20px"
          id="energy"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          enableBackground="new 0 0 24 24"
          xmlSpace="preserve"
      >
        <g id="mask">
          <path fill={props.isActive ? styles.colors.causes.energy : styles.colors.silver} d="M21.9,9.6C21.7,9.2,21.4,9,21,9h-7.9L14,2.1c0.1-0.4-0.2-0.9-0.6-1
	C13,0.9,12.5,1,12.2,1.4l-10,12c-0.2,0.3-0.3,0.7-0.1,1.1C2.3,14.8,2.6,15,3,15h7.9L10,21.9c-0.1,0.4,0.2,0.9,0.6,1
	C10.7,23,10.9,23,11,23c0.3,0,0.6-0.1,0.8-0.4l10-12C22,10.3,22.1,9.9,21.9,9.6"/>
        </g>
      </svg>
    </Container>
);

export default Energy;

Energy.propTypes = {
  isActive: PropTypes.bool
};

Energy.defaultProps = {
  isActive: true
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: ${props => props.isActive ? `${styles.colors.causes.energy}1f` : styles.colors.mostlyWhite};
    border-radius: 20px;
`;
