import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../constants/styles';

const Crisis = (props) => (
    <Container isActive={props.isActive}>
      <svg
          width="20px"
          height="20px"
          id="crisis"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          enableBackground="new 0 0 24 24"
          xmlSpace="preserve"
      >
        <g id="mask">
          <path fill={props.isActive ? styles.colors.causes.crisis : styles.colors.silver} d="M17,19c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1l3.6,0l-7.1-7.1l-4.3,4.3
	c-0.4,0.4-1,0.4-1.4,0L0.3,6.7c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l6.8,6.8l4.3-4.3c0.4-0.4,1-0.4,1.4,0l7.8,7.8l0-3.6
	c0-0.6,0.4-1,1-1s1,0.4,1,1v6l0,0c0,0,0,0,0,0.1l0-0.1c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1
	c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c-0.1,0.1-0.1,0.1-0.2,0.2l0.1-0.1c0,0-0.1,0.1-0.1,0.1
	c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0
	L23,19H17z"/>
        </g>
      </svg>
    </Container>
);

export default Crisis;

Crisis.propTypes = {
  isActive: PropTypes.bool
};

Crisis.defaultProps = {
  isActive: true
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: ${props => props.isActive ? `${styles.colors.causes.crisis}1f` : styles.colors.mostlyWhite};
    border-radius: 20px;
`;

