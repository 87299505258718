import React from 'react';
import styled from 'styled-components';
import { POINT_URL, POINT_BLOG, POINT_TERMS } from '../../constants/links';
import styles from '../../constants/styles';

const date = new Date();
const Footer = () => (
    <Container>
        <SpanLeft>
            <LinkToSite href={POINT_URL} target="_blank" rel="noopener noreferrer">pointapp.org</LinkToSite>
            <LinkToSite href={POINT_BLOG} target="_blank" rel="noopener noreferrer">Blog</LinkToSite>
        </SpanLeft>
        <SpanToSite>
            <LinkToSite href={POINT_TERMS} target="_blank" rel="noopener noreferrer">Terms of Service</LinkToSite>
            &copy; POINT
            {' '}
            {date.getFullYear()}
        </SpanToSite>
    </Container>
);
export default Footer;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  padding: 24px 32px;
  min-height: initial!important;
  border-top: 1px solid ${styles.borders.color};
  @media screen and (max-width: 700px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const LinkToSite = styled.a`
  color: ${styles.colors.silver};
  font-family: ${styles.fonts.family.primaryBook};
  font-size: 14px;
  line-height: 18px;
  margin-right: 32px;
  text-decoration: none;
  &:hover {
    color: ${styles.colors.primary};
  }
`;
const SpanLeft = styled.span`
  color: ${styles.colors.silver};
  font-family: ${styles.fonts.family.primaryBook};
  font-size: 14px;
  line-height: 18px;
  @media screen and (max-width: 700px) {
    a:last-of-type{
      margin: 0;
    }
  }
`;
const SpanToSite = styled(SpanLeft)`
  @media screen and (max-width: 700px) {
    padding-top: 10px;
    a:last-of-type{
      margin-right: 30px;
    }
  }
  @media screen and (max-width: 425px) {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    p, a,a:last-of-type{
      margin: 0 0 10px 0;
    }
  }
`;
