import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../constants/styles';

const Family = (props) => (
    <Container isActive={props.isActive}>
      <svg
          width="20px"
          height="20px"
          id="family"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          enableBackground="new 0 0 24 24"
          xmlSpace="preserve"
      >
        <g id="mask">
          <path fill={props.isActive ? styles.colors.causes.family : styles.colors.silver} d="M16,5c-1.5,0-2.9,0.5-4,1.3C10.9,5.5,9.5,5,8,5c-3.9,0-7,3.1-7,7
	c0,3.9,3.1,7,7,7c1.5,0,2.9-0.5,4-1.3c1.1,0.8,2.5,1.3,4,1.3c3.9,0,7-3.1,7-7C23,8.1,19.9,5,16,5 M12,15c-0.6-0.8-1-1.9-1-3
	c0-1.1,0.4-2.1,1-3c0.6,0.8,1,1.9,1,3C13,13.1,12.6,14.1,12,15 M3,12c0-2.8,2.2-5,5-5c0.9,0,1.8,0.3,2.5,0.7C9.6,8.9,9,10.4,9,12
	s0.6,3.1,1.5,4.3C9.8,16.7,8.9,17,8,17C5.2,17,3,14.8,3,12 M16,17c-0.9,0-1.8-0.3-2.5-0.7c0.9-1.2,1.5-2.7,1.5-4.3s-0.6-3.1-1.5-4.3
	C14.2,7.3,15.1,7,16,7c2.8,0,5,2.2,5,5S18.8,17,16,17"/>
        </g>
      </svg>
    </Container>
);

export default Family;

Family.propTypes = {
  isActive: PropTypes.bool
};

Family.defaultProps = {
  isActive: true
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: ${props => props.isActive ? `${styles.colors.causes.family}1f` : styles.colors.mostlyWhite};
    border-radius: 20px;
`;

