import buildRequest from './actions';
import { HTTP_METHODS } from '../constants';

export const getUser = async () => {
  return await buildRequest({
    method: HTTP_METHODS.GET,
    url: 'client/login',
    isAuth: true
  });
};

export const getOrganizationSearch = async (params) => {
  return await buildRequest({
    method: HTTP_METHODS.GET,
    url: 'client/organizations/search',
    isAuth: true,
    queryData: params
  });
};

export const requestOrganization = async (obj) => {
  return await buildRequest({
    method: HTTP_METHODS.POST,
    url: 'client/requests',
    isAuth: true,
    bodyData: obj,
  });
};

export const cancelRequestOrganization = async (obj) => {
  return await buildRequest({
    method: HTTP_METHODS.DELETE,
    url: `client/requests/${obj.organizationId}`,
    isAuth: true,
    messages: {
      success: 'Successful.'
    }
  });
};

export const acceptInviteOrganization = async (obj) => {
  return await buildRequest({
    method: HTTP_METHODS.POST,
    url: `client/invites/accept`,
    isAuth: true,
    bodyData: obj,
  });
};

export const declineInviteOrganization = async (obj) => {
  return await buildRequest({
    method: HTTP_METHODS.POST,
    url: 'client/invites/reject',
    isAuth: true,
    bodyData: obj,
    messages: {
      success: 'Successful.'
    }
  });
};

export const leaveOrganization = async (obj) => {
  return await buildRequest({
    method: HTTP_METHODS.DELETE,
    url: `client/organizations/${obj.organizationId}`,
    isAuth: true,
    messages: {
      success: 'Successful.'
    }
  });
};

export const getClientEmailsList = async () => {
  return await buildRequest({
    method: HTTP_METHODS.GET,
    url: 'client/emails',
    isAuth: true
  });
};

export const resetPassword = async (email) => {
  return await buildRequest({
    method: HTTP_METHODS.POST,
    url: 'reset-password',
    isAuth: false,
    bodyData: { email: email },
  });
};

export const updateClientProfile = async (profileData) => {
  return await buildRequest({
    method: HTTP_METHODS.PUT,
    url: `/client/user`,
    isAuth: true,
    bodyData: profileData,
    messages: {
      success: 'You have updated your profile successfully!'
    }
  });
};
