import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const FilterIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M17,13H7c-0.6,0-1-0.4-1-1s0.4-1,1-1h10c0.6,0,1,0.4,1,1S17.6,13,17,13z"/>
            <path d="M20,7H4C3.4,7,3,6.6,3,6s0.4-1,1-1h16c0.6,0,1,0.4,1,1S20.6,7,20,7z"/>
            <path d="M14,19h-4c-0.6,0-1-0.4-1-1s0.4-1,1-1h4c0.6,0,1,0.4,1,1S14.6,19,14,19z"/>
        </Icon>
    );

export default withTheme(FilterIcon);
