import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const NetworkClosedIcon = props => (
    <Icon {...props} viewBox={'0 0 24 24'}>
        <path d="m7 22h10c1.7 0 3-1.3 3-3v-6c0-1.7-1.3-3-3-3h-1v-4c0-2.2-1.8-4-4-4s-4 1.8-4 4v4h-1c-1.7 0-3 1.3-3 3v6c0 1.7 1.3 3 3 3zm3-16c0-1.1.9-2 2-2s2 .9 2 2v4h-4z" />
    </Icon>
);

export default withTheme(NetworkClosedIcon);
