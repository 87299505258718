import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../../../../constants/styles';
import IconBase from '../../../components/Icon';

const Icon = ({
                        width,
                        height,
                        isActive,
                        isHover,
                        defaultFill,
                        activeFill,
                        hoverFill,
                        children
                    }) => {
    const _defaultFill = defaultFill || styles.colors.medium;
    const _activeFill = activeFill || styles.colors.primary;
    const _hoverFill = hoverFill || styles.colors.primary;

    return <IconBase
        width={width}
        height={height}
        isActive={isActive}
        isHover={isHover}
        defaultFill={_defaultFill}
        activeFill={_activeFill}
        hoverFill={_hoverFill}
        viewBox={'0 0 24 24'}
    >
        {children}
    </IconBase>;
};

Icon.propTypes = {
    children: PropTypes.node,
    width: PropTypes.number,
    height: PropTypes.number,
    isActive: PropTypes.bool,
    isHover: PropTypes.bool,
    defaultFill: PropTypes.string,
    activeFill: PropTypes.string,
    hoverFill: PropTypes.string
};

Icon.defaultProps = {
    children: null,
    width: 24,
    height: 24,
    isActive: false,
    isHover: false,
    defaultFill: styles.colors.medium,
    activeFill: styles.colors.primary,
    hoverFill: styles.colors.primary,
};

export default Icon;
