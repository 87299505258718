import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from '../../../constants/styles';
import { Modal } from '../../../components/Modal';
import { H2 } from '../../../components/Typography';
import styled from 'styled-components';
import { Select } from '../../../components/Fields';
import { DATA_STATE, INPUT_SIZE, INPUT_TYPE, USER_GENDER } from '../../../constants';
import Input from '../../../components/Fields/Input';
import PhoneInput from '../../../components/Fields/PhoneInput';
import validateProfile from '../../Users/Profile/validate/clientProfile';
import ErrorMessage from '../../../components/ErrorMessage';
import FieldLabel from '../../../components/Fields/Label';
import { updateClientProfile } from '../../../actions/profileActions';
import moment from 'moment';
import { getAddress } from '../../../actions/eventsActions';
import Location from './Location';
import Causes from '../../../components/Causes';
import Button from '../../../components/Buttons/Button';
import TitleSection from '../../../components/Elements/TitleSection';
import { getCauses } from '../../../actions/causesActions';

class CompleteProfilePopup extends Component {
  constructor (props) {
    super(props);
    this.state = {
      profileData: null,
      gender: 1,
      birthday: '',
      phone: null,
      address: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zipCode: '',
        lat: 0,
        lng: 0
      },
      errors: {},
      allCauses: [],
      selectedCauses: [],
      loading: false,
      popup: false,
    };
  }

  async componentDidMount () {
    const profileData = JSON.parse(localStorage.getItem('POINT.profileData'));
    let allCauses = await getCauses();

    this.setState({
        profileData: profileData,
        birthday: (profileData && profileData.birthday) ? moment(profileData.birthday).format('MM/DD/YYYY') : '',
        gender: (profileData && profileData.gender) ? profileData.gender : 1,
        phone: (profileData && profileData.phone) ? profileData.phone : '',
        selectedCauses: (profileData && profileData.selectedCauses) ? profileData.causes : [],
        allCauses: allCauses.items,
        address: {
          state: (profileData && profileData.address) ? profileData.address?.state : '',
          addressLine1: (profileData && profileData.address) ? profileData.address?.addressLine1 : '',
          addressLine2: (profileData && profileData.address) ? profileData.address?.addressLine2 : '',
          city: (profileData && profileData.address) ? profileData.address?.city : '',
          zipCode: (profileData && profileData.address) ? profileData.address?.zipCode : '',
          lat: (profileData && profileData.address) ? profileData.address?.lat : '',
          lng: (profileData && profileData.address) ? profileData.address?.lng : ''
        },
      }
    );
  }

  onSubmit = async () => {
    const { gender, birthday, address, phone, selectedCauses } = this.state;
    const payload = {
      gender, address, phone,
      causes: selectedCauses,
      birthday: moment(birthday).valueOf() ? moment(birthday).valueOf() : birthday
    };
    const errors = validateProfile(payload);
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      this.setState({
        loading: true,
      });

      const result = await updateClientProfile(payload);
      if (result) {
        let profileDataStorage = await JSON.parse(localStorage.getItem('POINT.profileData'));
        profileDataStorage.isCompleteProfile = true;
        await localStorage.setItem('POINT.profileData', JSON.stringify({ ...profileDataStorage, ...payload }));
        this.props.setData({ showCompleteProfilePopUp: false });
      }
      this.setState({
        loading: false,
      });
    }
  };

  onPersonalInfoUpdate = (fieldName, value) => {
    const { errors } = this.state;
    delete errors[fieldName];
    this.setState({
      [fieldName]: value,
      errors: errors,
    });
  };
  onAddressInfoUpdate = (fieldName, value) => {
    const { errors } = this.state;
    delete errors[fieldName];
    this.setState({
      address: {
        ...this.state.address,
        [fieldName]: value,
      },
      errors: errors,
    });
  };
  setAddress = async (address) => {
    const { errors } = this.state;
    if ('address' in address) {
      delete errors.address;
      this.setState({ errors: errors });
      this.setState({
        address: {
          ...this.state.address,
          addressLine1: address.address
        }
      });
    } else if (address.lat || address.lng) {
      await this.updateAddress(address);
    }
  };
  updateAddress = async (data) => {
    if (!data) {
      return false;
    }
    const { errors } = this.state;
    const res = await getAddress(`${data.lat},${data.lng}`);
    let address = {};
    if (res && res.results[0].address_components) {

      res.results[0].address_components.forEach((element) => {
        const streetNumber = element.types.indexOf('street_number');
        if (streetNumber >= 0) address.addressLine1 = element.long_name;

        const street = element.types.indexOf('route');
        if (street >= 0) {
          address.addressLine1 = `${address.addressLine1 ? address.addressLine1 + ' ' : ''}${element.long_name}`;
        }

        const room = element.types.indexOf('room');
        if (room >= 0) {
          address.addressLine2 = element.long_name;
        }

        const state = element.types.indexOf('administrative_area_level_1');
        if (state >= 0) {
          address.state = element.short_name;
          delete errors.state;
        }

        const level_2 = element.types.indexOf('administrative_area_level_2');
        if (level_2 >= 0 && !address.addressLine1) {
          address.addressLine1 = element.long_name;
        }

        const level_3 = element.types.indexOf('administrative_area_level_3');
        if (level_3 >= 0 && !address.addressLine1) {
          address.addressLine1 = element.long_name;
        }

        const city = element.types.indexOf('locality');
        if (city >= 0) {
          address.city = element.short_name;
          delete errors.city;
        }

        const zipCode = element.types.indexOf('postal_code');
        if (zipCode >= 0) {
          address.zipCode = element.short_name;
          delete errors.zipCode;
        }
      });

      address.lat = data.lat;
      address.lng = data.lng;
    }

    this.setState({ address: { ...this.state.address, ...address }, errors });
  };
  setCauses = () => {
    const { errors } = this.state;
    delete errors.causes;

    this.setState({
      popup: false,
      errors: errors,
    });
  };

  setCausePopup = async (item) => {
    let { selectedCauses } = this.state;
    const index = selectedCauses.findIndex((el) => (el.id === item.id));
    if (index > -1) {
      selectedCauses.splice(index, 1);
    } else {
      selectedCauses.push(item);
    }
    await this.setState({
      selectedCauses: selectedCauses
    });
  };

  renderPopup = () => (
    <Modal
      title={'Choose Cause(s)'}
      width={styles.modal.size.extraLarge}
      visible={!!this.state.popup}
      onClose={() => this.setState({
        popup: false,
        errors: {},
        selectedCauses: [],
      })}
      buttons={[ {
        onPress: () => this.setCauses(),
        disabled: false,
        value: 'Apply',
        variant: 'primary',
        isLoading: false
      } ]}
    >
      <Causes
        className={'causes-edit-small'}
        causesState={'edit'}
        selectedCauses={this.state.selectedCauses}
        causes={this.state.allCauses}
        setCause={this.setCausePopup}
      />
    </Modal>
  );

  render () {
    const { gender, birthday, address, errors, loading, phone } = this.state;

    return (
      <Modal
        visible={this.props.visible}
        width={styles.modal.size.large}
        onClose={() => this.props.setData({ showCompleteProfilePopUp: false })}
        buttons={[
          {
            onPress: () => {
              this.props.setData({ showCompleteProfilePopUp: false });
            },
            value: 'Cancel',
            variant: 'outline-light',
            dataTestId: 'complete-profile-button',
          },
          {
            onPress: () => this.onSubmit(),
            value: 'Save',
            dataTestId: 'complete-profile-button',
            isLoading: loading
          }
        ]}
      >
        {this.renderPopup()}
        <Data>
          <Title>{'Complete Profile'}</Title>
          <Row>
            <Col>
              <FieldLabel text={'Gender'}/>
              <Select
                minWidth={220}
                optionsWidth={220}
                size={INPUT_SIZE.NORMAL}
                value={gender}
                options={USER_GENDER}
                onChange={value => this.onPersonalInfoUpdate('gender', value)}
                dataTestId={'data-gender'}
                placeholder={'Gender'}
              />
              {!!Object.keys(errors).length && errors.gender ? (
                <ErrorMessage text={errors.gender} dataTestId={'gender-field-error'}/>
              ) : null}
            </Col>
            <Col>
              <FieldLabel text={'Birthday'}/>
              <Input
                name={'birthday'}
                size={INPUT_SIZE.NORMAL}
                type={INPUT_TYPE.DATETIME}
                labelUnder={'Month / Day / Year'}
                value={birthday}
                placeholder={'MM/DD/YYYY'}
                onChange={(v) => this.onPersonalInfoUpdate('birthday', v)}
                dataTestId={'birthday-field'}
              />
              {!!Object.keys(errors).length && errors.birthday ? (
                <ErrorMessage text={errors.birthday} dataTestId={'birthday-field-error'}/>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col size="full">
              <FieldLabel text={'Address Line 1'}/>
              <Location
                isMap={false}
                size={INPUT_SIZE.NORMAL}
                hideLabel={true}
                address={address.addressLine1}
                openMap={() => null}
                updateParentState={(address) => this.setAddress(address)}
                dataTestId={'input-location'}
              />
              {!!Object.keys(errors).length && errors.address ? (
                <ErrorMessage text={errors.address} dataTestId={'address-field-error'}/>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col>
              <FieldLabel text={'City'}/>
              <Input
                size={INPUT_SIZE.NORMAL}
                type={INPUT_TYPE.TEXT}
                value={address.city}
                placeholder={'City'}
                onChange={e => this.onAddressInfoUpdate('city', e.target.value)}
                dataTestId={'data-city'}
              />

              {!!Object.keys(errors).length && errors.city ? (
                <ErrorMessage text={errors.city} dataTestId={'city-field-error'}/>
              ) : null}
            </Col>
            <Col>
              <FieldLabel text={'State'}/>
              <Select
                minWidth={220}
                optionsWidth={220}
                size={INPUT_SIZE.NORMAL}
                value={address.state}
                options={DATA_STATE}
                onChange={value => this.onAddressInfoUpdate('state', value)}
                dataTestId={'data-state'}
                placeholder={'State'}
              />
              {!!Object.keys(errors).length && errors.state ? (
                <ErrorMessage text={errors.state} dataTestId={'address-field-error'}/>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col>
              <FieldLabel text={'Zip code'}/>
              <Input
                placeholder={'Zip Code'}
                value={address.zipCode}
                size={INPUT_SIZE.NORMAL}
                type={INPUT_TYPE.ZIP}
                onChange={e => this.onAddressInfoUpdate('zipCode', e)}
                tabIndex="11"
                dataTestId={'org-zip-code'}
              />
              {!!Object.keys(errors).length && errors.zipCode ? (
                <ErrorMessage text={errors.zipCode} dataTestId={'zipcode-field-error'}/>
              ) : null}
            </Col>
            <Col>
              <FieldLabel text={'Phone'}/>
              <PhoneInput
                placeholder={'+_(___)-___-____'}
                phone={phone}
                onManualChange={(value, data, event, phone) => this.onPersonalInfoUpdate('phone', phone)}
                dataTestId={'manual-user-phone'}
              />
              {!!Object.keys(errors).length && errors.phone ? (
                <ErrorMessage text={errors.phone} dataTestId={'phone-field-error'}/>
              ) : null}
            </Col>
          </Row>
          <TitleSection title={'EVENT CAUSES'}/>
          <Row>
            <Col size="full">
              <Causes
                causesState={'view'}
                selectedCauses={this.state.selectedCauses}
              />
              <Button
                size={'lg'}
                variant={'outline-secondary'}
                value={'Choose Cause(s)'}
                block
                onClick={() => {
                  this.setState({ popup: !this.state.popup });
                }}
                dataTestId={'profile-button-choose-causes'}
              />
              {!!Object.keys(errors).length && errors.causes ? (
                <ErrorMessage text={errors.causes} dataTestId={'causes-field-error'}/>
              ) : null}
            </Col>
          </Row>
        </Data>
      </Modal>
    );
  }
}

CompleteProfilePopup.propTypes = {
  setData: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  visible: PropTypes.bool
};

CompleteProfilePopup.defaultTypes = {
  visible: false
};

export default CompleteProfilePopup;

const Data = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(H2)`
  margin-bottom: 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  align-content: center;
  width: 100%;
  margin: 12px auto;

  &:first-of-type {
    margin-top: 32px;
  }

  &:last-of-type {
    margin-bottom: 4px;
  }

  @media screen and (max-width: 700px) {
    flex-wrap: wrap;
  }
`;
const Col = styled.div`
  width: ${props => props.size === 'full' ? '100%' : 'calc(50% - 12px)'};
  @media screen and (max-width: 700px) {
    width: 100%;
    margin-bottom: 24px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;
