import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const CompassIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M7,18c-0.3,0-0.5-0.1-0.7-0.3C6.1,17.4,6,17,6.1,16.6L9,9.5C9.1,9.3,9.3,9.1,9.5,9l7.1-2.8c0.4-0.1,0.8-0.1,1.1,0.2
	C17.9,6.6,18,7,17.9,7.4L15,14.5c-0.1,0.3-0.3,0.5-0.6,0.6l-7.1,2.8C7.3,17.9,7.2,18,7,18z M10.6,10.6l-1.8,4.5l4.5-1.8l1.8-4.5
	L10.6,10.6z M14.1,14.1L14.1,14.1L14.1,14.1z"/>
            <path d="M12,23C5.9,23,1,18.1,1,12S5.9,1,12,1s11,4.9,11,11S18.1,23,12,23z M12,3c-5,0-9,4-9,9c0,5,4,9,9,9c5,0,9-4,9-9
	C21,7,17,3,12,3z"/>
        </Icon>
    );

export default withTheme(CompassIcon);
