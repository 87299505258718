import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { filter } from 'lodash';
import styles from '../../../constants/styles';
import { Button } from '../../Buttons';
import { ArrowDownIcon, PlusIcon } from '../../Icons/General';
import { H5, H6 } from '../../Typography';
import { INPUT_SIZE, INPUT_TYPE, SELECT_TYPE } from '../../../constants';
import ArrowRight from '../../../assets/icons/ArrowRight';
import ArrowLeft from '../../../assets/icons/ArrowLeft';
import Checkbox from '../Checkbox';
import NoData from '../../NoData';
import Input from '../Input';
import getOs from "../../../_helpers/os";

class Select extends Component {
    constructor (props) {
        super(props);
        this.state = {
            search: '',
            selectState: false,
            maxHeightOptionsModal: 200,
            offsetTop: 0,
            offsetLeft: 0,
            offsetRight: 0,
            selectWidth: 0,
            selectOptions: [],
            prevSelectOptions: new Map(),
            firstRender: true,
            extraOffset: 0,
        };
        this.selectRef = React.createRef();
        this.renderOptions = this.renderOptions.bind(this);
    }

    componentDidMount () {
        this.setState({ selectOptions: this.props.options });
        if(getOs() === 'Windows') {
            this.setState({
                // body scrollbar width
                extraOffset: window.innerWidth - document.body.clientWidth,
            })
        }
    }

    static getDerivedStateFromProps (nextProps, prevState) {
        if (
            Array.isArray(prevState.selectOptions) &&
            prevState.selectOptions.length === 1 &&
            Array.isArray(nextProps.options) &&
            nextProps.options.length > 1
        ) {
            return {
                selectOptions: nextProps.options
            }
        } else if (prevState.firstRender && nextProps.options.length) {
            return {
                selectOptions: nextProps.options,
                firstRender: false
            }
        }
        if (nextProps.isSearch) {
            return {
                selectOptions: nextProps.options
            }
        }
        return null;
    }

    renderOptions = () => {
        return (
            <SelectOptions
                size={this.props.size}
                optionsWidth={this.props.optionsWidth}
                maxHeightOptionsModal={this.state.maxHeightOptionsModal}
                offsetTop={this.state.offsetTop}
                offsetLeft={this.state.offsetLeft}
                offsetRight={this.state.offsetRight}
                selectWidth={this.state.selectWidth}
                isRightOffset={Number(this.state.offsetRight) - Number(this.state.offsetLeft) < 0}
            >
                {this.props.isSearch && (
                    <SearchForm>
                        <Input
                            type={INPUT_TYPE.SEARCH}
                            size={INPUT_SIZE.SMALL}
                            name="search"
                            placeholder={'Search'}
                            value={this.state.search}
                            onChange={(e) => {
                                this.props.onSearch(e.target.value);
                                this.setState({ search: e.target.value });
                            }}
                            dataTestId={'select-search-field'}
                        />
                    </SearchForm>
                )}
                {(this.state.selectOptions && Array.isArray(this.state.selectOptions) && this.state.selectOptions.length > 0)
                    ? (this.props.emptyButton ? (
                        <Button
                            size={this.props.emptyButton.size}
                            style={{ minHeight: '36px', margin: '6px 0 0' }}
                            value={this.props.emptyButton.title}
                            variant={this.props.emptyButton.variant}
                            onClick={this.props.emptyButton.onClick}
                            dataTestId={this.props.emptyButton.dataTestId}
                            block
                        />
                    ) : null) : null}
                {
                    this.state.selectOptions && Array.isArray(this.state.selectOptions) && this.state.selectOptions.length > 0 ?
                        this.state.selectOptions.map((item) => {
                            const isSelected = this.props.values && this.props.values.includes(item.value);
                            return (
                                <Option
                                    onClick={() => {
                                        if (item.value === 'back') {
                                            if (this.state.prevSelectOptions.has(item.parentId)) {
                                                this.setState({
                                                    selectOptions: this.state.prevSelectOptions.get(item.parentId)
                                                });
                                            } else {
                                                this.setState({
                                                    selectOptions: this.props.options
                                                });
                                            }
                                            return true;
                                        }

                                        if (item.children && Array.isArray(item.children) && item.children.length > 0) {
                                            return false;
                                        }

                                        if (this.props.type === SELECT_TYPE.BUTTON) {
                                            item.onClick();
                                        } else {
                                            this.props.onChange(item.value);
                                        }
                                        if (this.props.type !== SELECT_TYPE.MULTI) {
                                            this.setState({ selectState: false });
                                        }
                                    }}
                                    key={item.value}
                                    selected={(this.props.type === SELECT_TYPE.MULTI) ? isSelected : item.value === this.props.value}
                                    data-testid={this.props.type === SELECT_TYPE.BUTTON ? item.dataTestId : `${this.props.dataTestId}-${item.label}`}
                                    isSearch={this.props.isSearch && (!this.props.type || this.props.type && this.props.type !== SELECT_TYPE.MULTI)}
                                >
                                    <OptionBody onClick={() => {
                                        if (item.children && Array.isArray(item.children) && item.children.length > 0) {
                                            this.props.onChange(item.value);
                                        }
                                        return false;
                                    }}>
                                        {this.props.type === SELECT_TYPE.MULTI && item.value !== 'back' && !item.hideCheckbox ? (
                                            <OptionButton fill={isSelected ? styles.colors.system.success : null}>
                                                <Checkbox onChange={() => null} checked={isSelected}/>
                                            </OptionButton>
                                        ) : null}
                                        {item.value === 'back' ? (
                                            <OptionButton fill={isSelected ? styles.colors.system.success : null}>
                                                <ArrowLeft size={16} />
                                            </OptionButton>
                                        ) : null}
                                        <OptionIcon>{item.Icon || null}</OptionIcon>
                                        {item.image ? (<OptionImage src={item.image} alt={item.label} />) : null}
                                        {item.label}
                                        {this.props.isSearch && (!this.props.type || this.props.type && this.props.type !== SELECT_TYPE.MULTI) ? (
                                            <Plus data-testid="button-add-item">
                                                <PlusIcon defaultFill={styles.colors.medium} width={14} height={14} />
                                            </Plus>
                                        ) : null}
                                    </OptionBody>

                                    {item.children && Array.isArray(item.children) && item.children.length > 0 ? (
                                        <RightArrow onClick={() => {
                                            this.state.prevSelectOptions.set(item.value, this.state.selectOptions);
                                            this.setState({
                                                prevSelectOptions: this.state.prevSelectOptions,
                                                selectOptions: item.children
                                            });
                                        }}>
                                            <ArrowRight size={16} />
                                        </RightArrow>
                                    ) : null}

                                </Option>
                            );
                        })  : (
                            <NoData
                                desc={'No data to display.'}
                                minHeight={200}
                                button={this.props.emptyButton ?
                                    <Button
                                        value={this.props.emptyButton.title}
                                        variant={this.props.emptyButton.variant}
                                        onClick={this.props.emptyButton.onClick}
                                        dataTestId={this.props.emptyButton.dataTestId}
                                        size={this.props.emptyButton.size}
                                    />
                                    : null}
                            />
                        )
                }
            </SelectOptions>
        );
    };

    triggerSelect = () => {
        let offsetTop = this.selectRef.current.getBoundingClientRect().top;
        let offsetLeft = this.selectRef.current.getBoundingClientRect().left;
        let offsetRight = this.selectRef.current.getBoundingClientRect().right + this.state.extraOffset;
        let offsetHeight = this.selectRef.current.offsetHeight;
        let innerHeight = window.innerHeight;
        let innerWidth = window.innerWidth;
        let maxHeightOptionsModal = innerHeight - (offsetTop + offsetHeight) - 32;

        this.setState({
            selectState: !this.state.selectState,
            maxHeightOptionsModal: maxHeightOptionsModal,
            selectWidth: this.selectRef.current.getBoundingClientRect().width,
            offsetTop: offsetTop + offsetHeight,
            offsetLeft: offsetLeft,
            offsetRight: innerWidth - offsetRight
        });
    };

    render () {
        const { label, disabled, type, size, dataTestId, minWidth, colorScheme, hideArrow } = this.props;

        const selectedOption = filter(this.props.options, (o) => o.value === this.props.value);

        return (
            <SelectPlace
                size={size}
                disabled={disabled}
                isActive={this.state.selectState}
                ref={this.selectRef}
                minWidth={minWidth}
                className={this.props.className}
                colorScheme={colorScheme}
                type={type}
            >
                {this.state.selectState ? <SelectBg onClick={() => this.setState({ selectState: false })}/> : null}
                {label ? <Label size={size}>{label}</Label> : null}
                <SelectBody size={size} type={type} onClick={() => this.triggerSelect()}>
                    <SelectedOption size={size} data-testid={dataTestId} colorScheme={colorScheme}>
                        {selectedOption && Array.isArray(selectedOption) && selectedOption.length > 0 ?
                            selectedOption[0].label :
                            this.props.placeholder || 'Select'}
                    </SelectedOption>
                    {!hideArrow ? (
                        <ArrowPlace
                            isActive={this.state.selectState}
                            data-testid={(this.state.selectState ? 'button-hide-list-options-' : 'button-show-list-options-')+dataTestId}
                        >
                            <ArrowDownIcon defaultFill={colorScheme ? 'white' : styles.colors.silver} width={20} height={20}/>
                        </ArrowPlace>
                    ) : null}
                </SelectBody>
                {this.state.selectState ? this.renderOptions() : null}
            </SelectPlace>
        );
    }
}

Select.propTypes = {
    type: PropTypes.string,
    size: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.array.isRequired,
    value: PropTypes.string,
    values: PropTypes.array,
    onChange: PropTypes.func,
    onSearch: PropTypes.func,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    isSearch: PropTypes.bool,
    hideArrow: PropTypes.bool,
    minWidth: PropTypes.number,
    emptyButton: PropTypes.object
};

Select.defaultProps = {
    type: SELECT_TYPE.DEFAULT,
    size: INPUT_SIZE.NORMAL,
    label: null,
    value: '',
    values: null,
    onChange: () => null,
    onSearch: () => null,
    emptyButton: null,
    placeholder: '',
    className: '',
    isSearch: false,
    minWidth: 150,
    hideArrow: false
};

export default Select;

const SelectPlace = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  min-width: ${props => props.minWidth}px;
  height: ${props => {
    switch (props.size) {
        case INPUT_SIZE.EXTRA_SMALL:
            return '24px';
        case INPUT_SIZE.SMALL:
            return '38px';
        case INPUT_SIZE.NORMAL:
            return '48px';
        default:
            return '48px';
    }
}};
  background: ${props => props.colorScheme ? props.colorScheme : 'white'};
  border: 1px solid ${props => {
    if (props.isActive) {
        return props.colorScheme ? props.colorScheme : styles.borders.input.color.active;
    } else {
        return props.colorScheme ? props.colorScheme : styles.borders.input.color.default;
    }
}
};
  border-radius: ${styles.borders.input.radius};
  cursor: ${props => props.disabled ? 'not-allowed' : 'default'};
  padding: ${props => {
    if (props.type === SELECT_TYPE.BUTTON) {
        return 0;
    } else {
        switch (props.size) {
            case INPUT_SIZE.EXTRA_SMALL:
                return '2px 8px';
            case INPUT_SIZE.SMALL:
                return '7px 12px';
            case INPUT_SIZE.NORMAL:
                return '13px 16px';
            default:
                return '13px 16px';
        }
    }
}};
`;
const Label = styled(H6)`
  display: inline-block;
  position: absolute;
  color: ${styles.colors.silver};
  font-family: ${styles.fonts.family.primaryBook};
  padding: 0 4px;
  background: white;
  bottom: ${props => {
    switch (props.size) {
        case INPUT_SIZE.EXTRA_SMALL:
            return '24px';
        case INPUT_SIZE.SMALL:
            return '28px';
        case INPUT_SIZE.NORMAL:
            return '38px';
        default:
            return '38px';
    }
}};
  margin: 0;
  left: ${props => {
    switch (props.size) {
        case INPUT_SIZE.EXTRA_SMALL:
            return '4px';
        case INPUT_SIZE.SMALL:
            return '8px';
        case INPUT_SIZE.NORMAL:
            return '12px';
        default:
            return '12px';
    }
}};
`;
const SelectBody = styled.div`
  display: flex;
  position: relative;
  padding: ${props => props.type === SELECT_TYPE.BUTTON ? (props.size === INPUT_SIZE.EXTRA_SMALL ? '2px 8px' : '8px 14px') : 0};
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
`;
const SelectedOption = styled.div`
  flex: 1;
  color: ${props => props.colorScheme ? 'white' : styles.colors.medium};
  font-family: ${props => props.size === INPUT_SIZE.EXTRA_SMALL ? styles.fonts.family.primaryMedium : styles.fonts.family.primaryBook};
  font-size: ${props => props.size === INPUT_SIZE.EXTRA_SMALL ? 12 : 14}px;
  line-height: ${props => props.size === INPUT_SIZE.EXTRA_SMALL ? 16 : 18}px;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  white-space: nowrap;
  @media screen and (max-width: 425px) {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
`;
const ArrowPlace = styled.div`
  position: relative;
  right: -4px;

  svg {
    position: relative;
    transform: ${props => props.isActive ? 'rotate(180deg)' : 0};
  }
`;
const OptionImage = styled.img`
  width: 32px;
  height: 32px;
  max-width: 32px;
  min-width: 32px;
  border-radius: 50%;
  margin-right: 8px;
  position: relative;
`;
const SelectOptions = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: white;
  width: ${props => (!!Number(props.optionsWidth) && Number(props.selectWidth) < Number(props.optionsWidth)) ? props.optionsWidth : props.selectWidth}px;
  position: fixed;
  max-height: ${props => props.maxHeightOptionsModal < 300 ? props.maxHeightOptionsModal : 300}px;
  min-width: ${props => (!!Number(props.optionsWidth) && Number(props.selectWidth) < Number(props.optionsWidth)) ? props.optionsWidth : props.selectWidth}px;
  max-width: 700px;
  overflow-y: scroll;
  top: ${props => props.offsetTop}px;
  ${props => props.isRightOffset ? `right: ${props.offsetRight}px;` : `left: ${props.offsetLeft}px;`}
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 0 1px 0 ${styles.colors.dark}1f, 0 4px 16px 0 ${styles.colors.dark}1f;
  z-index: 99;
`;
const SearchForm = styled.div`
  width: 100%;
  margin-bottom: 4px;
`;
const Option = styled(H5)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: ${props => props.isSearch ? '9px 48px 9px 12px' : '9px 12px'};
  position: relative;
  margin: 0;
  color: ${styles.colors.dark};
  font-family: ${styles.fonts.family.primaryMedium};
  border-radius: 4px;
  background: ${props => props.selected ? styles.colors.mostlyWhite : 'white'};

  > svg {
    g {
      fill: ${styles.colors.dark};
    }

    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    margin-right: 8px;
  }

  &:hover {
    background: ${styles.colors.mostlyWhite};
  }
`;
const OptionBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  word-break: break-word;
  flex: 1;
  text-align: left;
`;
const RightArrow = styled.div`
  padding-top: 2px;
`;
const OptionIcon = styled.div`
  display: flex;

  svg {
    g {
      fill: ${styles.colors.dark};
    }

    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    margin-right: 8px;
  }
`;
const OptionButton = styled.div`
  display: flex;
  margin-bottom: -3px;
  margin-right: 12px;
`;
const SelectBg = styled.div`
  display: block;
  background: transparent;
  z-index: 1;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
`;
const Plus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 13px;
  right: 12px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid ${styles.borders.input.color.default};
`;
