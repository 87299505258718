import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const LinkedinIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M18.9,4c0.3,0,0.6,0.1,0.8,0.3S20,4.8,20,5.1v13.7c0,0.3-0.1,0.6-0.3,0.8S19.2,20,18.9,20H5.1
	c-0.3,0-0.6-0.1-0.8-0.3S4,19.2,4,18.9V5.1c0-0.3,0.1-0.6,0.3-0.8S4.8,4,5.1,4H18.9z M7.6,9c0.4,0,0.7-0.1,1-0.4S9,8,9,7.7
	c0-0.4-0.1-0.7-0.4-1C8.4,6.4,8,6.3,7.6,6.3s-0.7,0.1-1,0.4C6.4,7,6.3,7.3,6.3,7.7s0.1,0.7,0.4,1C6.9,8.9,7.3,9,7.6,9z M8.8,17.7
	L8.8,17.7v-7.6H6.5v7.6H8.8z M17.7,17.7v-4.2c0-1.2-0.2-2.1-0.5-2.6c-0.5-0.7-1.2-1-2.3-1c-0.5,0-1,0.1-1.5,0.4
	c-0.4,0.2-0.6,0.5-0.8,0.8l0,0v-1h-2.3v7.6h2.4v-3.8c0-0.6,0.1-1,0.2-1.4c0.2-0.4,0.6-0.6,1.2-0.6c0.5,0,0.9,0.2,1.1,0.7
	c0.1,0.3,0.2,0.7,0.2,1.4v3.7H17.7z"/>
        </Icon>
    );

export default withTheme(LinkedinIcon);
