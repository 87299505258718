import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const CheckCircleIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,20c-4.4,0-8-3.6-8-8s3.6-8,8-8
                s8,3.6,8,8S16.4,20,12,20z"/>
            <path d="M14.7,8.4l-3.8,5l-1.6-2.1c-0.3-0.4-1-0.5-1.4-0.2s-0.5,1-0.2,1.4l2.4,3.1c0.2,0.2,0.5,0.4,0.8,0.4
	c0.3,0,0.6-0.1,0.8-0.4l4.6-6c0.3-0.4,0.3-1.1-0.2-1.4C15.7,7.9,15,7.9,14.7,8.4L14.7,8.4z"/>
        </Icon>
    );

export default withTheme(CheckCircleIcon);
