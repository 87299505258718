import React from 'react';
import PropTypes from 'prop-types';
import PhoneInput2 from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import './styles.scss';

const PhoneInput = (props) => {
  return (
    <PhoneInput2
      {...props}
      country={'us'}
      specialLabel={''}
      placeholder={props.placeholder}
      value={props.phone ? props.phone.toString() : ''}
      onChange={props.onManualChange}
      inputProps={{
        'data-testid': props.dataTestId
      }}
    />
  );
}

PhoneInput.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  phone: PropTypes.string,
  onManualChange: PropTypes.func
};

PhoneInput.defaultTypes = {
  phone: null,
  onManualChange: () => null
};

export default PhoneInput;
