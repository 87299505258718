import React from 'react';
import { withTheme } from 'styled-components';
import Icon from './components/Icon';

const StatsMenu = props => (
        <Icon {...props}>
            <path d="M7,20v-6c0-0.6-0.4-1-1-1s-1,0.4-1,1v6c0,0.6,0.4,1,1,1S7,20.6,7,20z"/>
            <path d="M18,9c-0.6,0-1,0.4-1,1v10c0,0.6,0.4,1,1,1s1-0.4,1-1V10C19,9.4,18.6,9,18,9z"/>
            <path d="M13,20V4c0-0.6-0.4-1-1-1s-1,0.4-1,1v16c0,0.6,0.4,1,1,1S13,20.6,13,20z"/>
        </Icon>
    );

export default withTheme(StatsMenu);
