import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const KeyIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M7,5c-3.9,0-7,3.1-7,7s3.1,7,7,7c3.2,0,5.8-2.1,6.7-5H18v3h4v-3h2
	v-4H13.7C12.8,7.1,10.2,5,7,5 M7,9c1.7,0,3,1.3,3,3s-1.3,3-3,3s-3-1.3-3-3S5.3,9,7,9"/>
        </Icon>
    );

export default withTheme(KeyIcon);
