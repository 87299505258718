import buildRequest from './actions';
import { HTTP_METHODS } from '../constants';

export const getCauses = async () => {
  return await buildRequest({
    method: HTTP_METHODS.GET,
    url: 'admin/causes',
    isAuth: false,
  });
};
