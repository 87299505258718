import axios from 'axios';
import buildRequest from './actions';
import { HTTP_METHODS } from '../constants';
import { GET_EVENTS_LIST } from './types';

export const getEventsListShare = (params) => async (dispatch) => {
  return await buildRequest({
    method: HTTP_METHODS.GET,
    url: `charities/events`,
    isAuth: false,
    queryData: params,
    dispatchSuccess: (data) => dispatch({
      type: GET_EVENTS_LIST,
      payload: data
    }),
  });
};

export const getEventShare = async (eventId, params) => {
  return await buildRequest({
    method: HTTP_METHODS.GET,
    url: `events/${eventId}/shareable`,
    isAuth: false,
    showNotifications: false,
    queryData: params
  });
};

export const getClientEvent = async (eventId, params) => {
  return await buildRequest({
    method: HTTP_METHODS.GET,
    url: `client/events/${eventId}`,
    isAuth: true,
    queryData: params,
    showNotifications: false
  });
};

export const getTimeZone = async (lat, lng, time) => {
  try {
    const response = await axios.get(`https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${time}&key=${process.env.REACT_APP_MAP_API_KEY}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAddress = async (address) => {
  try {
    const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?language=en&address=${address}&key=${process.env.REACT_APP_MAP_API_KEY}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const shiftSubscription = async ({ eventId, shifts, accessKey }) => {
  return await buildRequest({
    method: HTTP_METHODS.PUT,
    url: `client/events/${eventId}/attendance`,
    isAuth: true,
    bodyData: {
      shifts: shifts,
      accessKey: accessKey
    },
    showNotifications: false
  });
};

export const getEventReservations = async ({ eventId, params }) => {
  return await buildRequest({
      method: HTTP_METHODS.GET,
    url: `client/events/${eventId}/reservations`,
    isAuth: true,
    queryData: params,
    showNotifications: false,
  });
};

export const getClientEvents = async (opts = null, limit = 24, skip = 0) => {
  const defaults = { upcoming: true };
  if (opts && (opts.past || (opts.endTime || opts.startTime))) {
    delete opts.upcoming;
  }
  return await buildRequest({
    method: HTTP_METHODS.GET,
    url: `client/events`,
    isAuth: true,
    queryData: opts || defaults
  });
};

export const setSignedUpOpportunityShifts = async (opportunityId, data) => {
  return await buildRequest({
    method: HTTP_METHODS.PUT,
    url: `client/integrations/vm/events/${opportunityId}/attendance`,
    isAuth: true,
    bodyData: data,
    showNotifications: false
  });
};
