import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const ArrowTopRightIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M17,6L17,6C17,6,17.1,6,17,6L17,6c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0
	c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0.1,0,0.1,0.1,0.2,0.2l-0.1-0.1c0,0,0.1,0.1,0.1,0.1
	c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1
	c0,0,0,0,0,0.1c0,0,0,0,0,0v10c0,0.6-0.4,1-1,1c-0.6,0-1-0.4-1-1l0-7.6l-8.3,8.3C7.5,17.9,7.3,18,7,18s-0.5-0.1-0.7-0.3
	c-0.4-0.4-0.4-1,0-1.4L14.6,8L7,8C6.4,8,6,7.6,6,7s0.4-1,1-1H17z"/>
        </Icon>
    );

export default withTheme(ArrowTopRightIcon);
