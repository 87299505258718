import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ContentAccount = (props) => (
    <Container size={props.size}>
        {props.children}
    </Container>
);
export default ContentAccount;

ContentAccount.propTypes = {
    size: PropTypes.string,
    children: PropTypes.element.isRequired
};

const Container = styled.div`
  width: 100%;
  max-width: ${props => (props.size && props.size === 'bg') ? 'calc(100% - 270px)' : '1110px'};
  padding: 30px;
  margin: 0 auto;
  flex: 1;
  @media screen and (max-width: 1024px) {
    max-width: 100%;
  }
`;
