import React, {Component} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {now} from 'moment';
import styles from '../../constants/styles';
import {EVENT_STATUSES} from '../../constants';
import {EVENT_LINK} from '../../constants/links';
import {getDataTime, getImageURI} from '../../_helpers';
import {EventPrivacy} from '../../components/Events';
import {ProgressDonut} from '../../components/Elements';
import {H3, H4, H5, H6} from '../Typography';
import {Shift} from '../Events';
import {DotsHorizontalIcon, EventIcon, LocationSolidIcon, TimeIcon} from '../Icons/General';
import Badge from '../Badge';
import ImageLoader from '../ImageLoader';
import CauseItem from '../CauseItem';
import ShortProfile from '../Elements/ShortProfile';
import defaultEvent from '../../assets/images/default-event.png';

const moment = require('moment-timezone');

class ItemEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopUpApprove: false,
            showPopUpReject: false,
            isLoadingApproveBtn: false,
            isLoadingRejectBtn: false
        };
        this.showShifts = this.showShifts.bind(this);
    }

    showShifts = ({eventShifts, eventStartTime, eventEndTime, eventTimeZone}) => {
        return (
            <Shifts>
                {eventShifts.map((item, index) => (
                    <Shift
                        key={index}
                        item={item}
                        eventStartTime={eventStartTime}
                        eventEndTime={eventEndTime}
                        eventTimeZone={eventTimeZone}
                        counter={index + 1}
                        isSpots={true}
                    />
                ))}
            </Shifts>
        );
    };

    render() {
        if (this.props.type && this.props.type === 'skeleton') return (
            <Container style={this.props.style}>
                <Animation style={this.props.style} className="animation-opacity">
                    <EventBlockEmpty>
                        <ImageBlock>
                            <ImageEmpty/>
                        </ImageBlock>
                        <Content>
                            <HeaderEventItemEmpty>
                                <BlockTitleWithAddress>
                                    <TitleEmpty/>
                                    <AddressEmpty>
                                        <IconDate/>
                                        <DateEmpty/>
                                    </AddressEmpty>
                                </BlockTitleWithAddress>
                                <StatusEmpty>
                                    <Status/>
                                    <IconStatus/>
                                </StatusEmpty>
                            </HeaderEventItemEmpty>
                            <BodyEventItem>
                                <ContentBlock>
                                    <BlockDate>
                                        <DateTime>
                                            <IconDate/>
                                            <DateEmpty/>
                                        </DateTime>
                                        <DateTime>
                                            <IconDate/>
                                            <DateEmpty/>
                                        </DateTime>
                                    </BlockDate>
                                    <BlockActionsEmpty>
                                        <DataProgressEmpty>
                                            <IconSpots/>
                                            <SpotsNumber/>
                                            <SpotsTaken/>
                                        </DataProgressEmpty>
                                        <Buttons>
                                            <ViewAction/>
                                            <EditAction/>
                                            <VolunteersAction/>
                                        </Buttons>
                                    </BlockActionsEmpty>
                                </ContentBlock>
                            </BodyEventItem>
                        </Content>
                    </EventBlockEmpty>
                    <ShiftBlockScreen>
                        <LabelEmpty/>
                        <Shifts>
                            <ShiftItem>
                                <LeftCol>
                                    <TitleShift/>
                                    <DateShift/>
                                </LeftCol>
                                <RightCol>
                                    <SpotsShiftCount/>
                                    <SpotsShift/>
                                </RightCol>
                            </ShiftItem>
                            <ShiftItem>
                                <LeftCol>
                                    <TitleShift/>
                                    <DateShift/>
                                </LeftCol>
                                <RightCol>
                                    <SpotsShiftCount/>
                                    <SpotsShift/>
                                </RightCol>
                            </ShiftItem>
                        </Shifts>
                    </ShiftBlockScreen>
                </Animation>
            </Container>
        );

        const {
            id: eventId,
            title: eventTitle,
            startTime: eventStartTime,
            endTime: eventEndTime,
            shifts: eventShifts,
            images,
            organization,
            timezone: eventTimeZone,
            address: eventAddress,
            spotsTaken: eventSpotsTaken,
            spots: eventSpots,
            host,
            eventGender,
            coHosts,
            status: eventStatus,
            isVirtual,
            spotsReserved,
            causes,
            topUsers
        } = this.props.item;

        let eventImage = defaultEvent;

        if ((images && images.length > 0 && typeof (images[0]) === 'string') || this.props.item.image) {
            eventImage = getImageURI(this.props.item.image || images[0]);
        }
        const isShifts = (eventShifts && eventShifts.length > 1);
        const dataDateTime = getDataTime(eventStartTime, eventEndTime, eventTimeZone, true);
        const isCoHost = coHosts && coHosts.length > 0;
        const nowTimestamp = now();
        const isStarted = (eventStartTime <= nowTimestamp && nowTimestamp < eventEndTime);

        if (this.props.type && this.props.type === 'mobile') {
            const event = this.props.item;
            let cause = {};
            if (causes) {
                causes.forEach((el) => {
                    if (el.isPrimary) {
                        cause = el;
                    }
                });
            }
            if (this.props.item.primaryCause) {
                cause = this.props.item.primaryCause;
            }

            let count = this.props.item.spotsFree || 0;

            return (
                <Wrapper style={this.props.style} block={this.props.block} onClick={this.props.onClick}>
                    <ImageBlockMobile>
                        <Image src={eventImage || defaultEvent} alt={eventTitle}/>
                        <Date>
                            <View>
                                <Number>{moment.tz(eventStartTime, eventTimeZone).format('D')}</Number>
                                <Month>{moment.tz(eventStartTime, eventTimeZone).format('MMM')}</Month>
                            </View>
                            {eventEndTime && (moment.tz(eventStartTime, eventTimeZone).format('D MMM') !== moment.tz(eventEndTime, eventTimeZone).format('D MMM')) ? (
                                <BlockRow>
                                    <Line>-</Line>
                                    <View>
                                        <Number>{moment.tz(eventEndTime, eventTimeZone).format('D')}</Number>
                                        <Month>{moment.tz(eventEndTime, eventTimeZone).format('MMM')}</Month>
                                    </View>
                                </BlockRow>
                            ) : null}
                        </Date>
                        <PrimaryCause>
                            <CauseItem
                                key={`cause_${cause.id}`}
                                item={cause}
                                type={'icon'}
                            />
                        </PrimaryCause>
                        {this.props.list ? (
                            <DataColumn>
                                {this.props.item.isVirtual ? (
                                    <Virtual>Virtual</Virtual>
                                ) : (
                                    <AddressLine>
                                        <LocationSolidIcon height={14} width={14} defaultFill={styles.colors.dark}/>
                                        <Address>{this.props.item.address}</Address>
                                    </AddressLine>
                                )}
                                <DataRow>
                                    <Time>
                                        <TimeIcon height={14} width={14} defaultFill={styles.colors.dark}/>
                                        {moment.tz(eventStartTime, eventTimeZone).format('h:mm A')}
                                    </Time>
                                    <SpotsCount>{count > 0 ? `${count} Spot${count > 1 ? 's' : ''} Left` : `Full`}</SpotsCount>
                                </DataRow>
                            </DataColumn>
                        ) : null}
                    </ImageBlockMobile>
                    <Data>
                        <TitleEvent>{eventTitle}</TitleEvent>
                        <Details>
                            <ShortProfile
                                title={organization.organizationName || organization.name}
                                image={organization.logo}
                                dataTestId="creating-organization"
                                onClick={() => null}
                                imageSize={32}
                            />
                            <Members>
                                {topUsers && topUsers.length > 0 ? topUsers.map((u, i) => {
                                    if (i > 3) return null;
                                    if (i === 3) {
                                        return (
                                            <BlockIconMore key={`event_${event.id.toString()}_${u.userId}_${i}`}>
                                                <DotsHorizontalIcon height={20} width={20}
                                                                    defaultFill={styles.colors.medium}/>
                                            </BlockIconMore>
                                        );
                                    } else {
                                        if (u.userAvatar) {
                                            return <Avatar key={`event_${event.id.toString()}_${u.userId}_${i}`}
                                                           src={u.userAvatar}/>
                                        } else {
                                            return (
                                                <BlockIcon key={`event_${event.id.toString()}_${u.userId}_${i}`}>
                                                    {u.userFirstName !== null ? u.userFirstName.charAt(0) : ''}
                                                    {u.userLastName !== null ? u.userLastName.charAt(0) : ''}
                                                </BlockIcon>
                                            );
                                        }
                                    }

                                }) : ''}
                            </Members>
                        </Details>
                    </Data>
                </Wrapper>
            );
        }

        return (
            <Container style={this.props.style}>
                <EventBlock>
                    <ImageBlock>
                        <Img src={eventImage || defaultEvent}
                             onClick={() => this.props.history.push(EVENT_LINK + eventId)} alt={eventTitle}/>
                    </ImageBlock>
                    <Content>
                        <HeaderEventItem data-testid={this.props.item.title + '-parentElement'}>
                            <TitleWithAddress>
                                <Title
                                    data-testid={this.props.item.title}
                                    onClick={() => this.props.history.push(EVENT_LINK + eventId)}
                                >
                                    {eventTitle || 'Event Name'}
                                </Title>
                                <DataAddress>
                                    <IconAddress>
                                        <LocationSolidIcon height={16} width={16} defaultFill={styles.colors.silver}/>
                                    </IconAddress>
                                    <Location textColor="medium">{eventAddress || 'Location'}</Location>
                                </DataAddress>
                            </TitleWithAddress>
                            <Badges>
                                {isVirtual ? <Badge dataTestId={'virtual-badge'} variant={'virtual'}
                                                    style={{margin: 4}}/> : null}
                                {isCoHost ?
                                    <Badge dataTestId={'cohost-badge'} variant={'cohost'} style={{margin: 4}}/> : null}
                                {eventStatus === EVENT_STATUSES.DRAFT ?
                                    <Badge dataTestId={'draft-badge'} variant={'draft'} style={{margin: 4}}/> : null}
                                {isStarted ? <Badge variant={'started'} style={{margin: 4}}/> : null}
                                <EventPrivacy
                                    host={host}
                                    coHosts={coHosts}
                                    eventGender={eventGender}
                                    style={{margin: 4}}
                                />
                            </Badges>
                        </HeaderEventItem>
                        <BodyEventItem>
                            <BodyLeft>
                                <BlockDate>
                                    <DateTime>
                                        <IconTime><EventIcon height={16} width={16}
                                                             defaultFill={styles.colors.dark}/></IconTime>
                                        <Par textColor="medium"
                                             data-testid={`eventDate_${this.props.indexItem}`}>{dataDateTime.dateString}</Par>
                                    </DateTime>
                                    <DateTime>
                                        <IconTime><TimeIcon height={16} width={16}
                                                            defaultFill={styles.colors.dark}/></IconTime>
                                        <Par textColor="medium"
                                             data-testid={`eventTime_${this.props.indexItem}`}>{dataDateTime.timeString}</Par>
                                    </DateTime>
                                </BlockDate>
                            </BodyLeft>
                            <BodyRight>
                                <DataProgress>
                                    <Spots><ProgressDonut percent={(eventSpotsTaken / eventSpots) * 100}/></Spots>
                                    <SpotsData textColor="medium">{`${eventSpotsTaken}/${eventSpots}`}</SpotsData>
                                    <Span>{`Spot${eventSpots > 1 ? 's' : ''} Taken`}</Span>
                                </DataProgress>
                                <BlockSpotsReserved>
                                    <SpotsData textColor="medium">{spotsReserved}</SpotsData>
                                    <Span>Reserved</Span>
                                </BlockSpotsReserved>
                            </BodyRight>
                        </BodyEventItem>
                    </Content>
                </EventBlock>
                {isShifts ? (
                    <ShiftBlockScreen>
                        <Label>SHIFT DATES:</Label>
                        {this.showShifts({eventShifts, eventStartTime, eventEndTime, eventTimeZone})}
                    </ShiftBlockScreen>
                ) : null}
            </Container>
        )
    }
}

export default ItemEvent;

ItemEvent.propTypes = {
    item: PropTypes.shape().isRequired,
    history: PropTypes.shape(),
    indexItem: PropTypes.number,
    type: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
    block: PropTypes.bool,
    list: PropTypes.bool
}

ItemEvent.defaultProps = {
    history: null,
    indexItem: 0,
    type: null,
    style: null,
    onClick: () => null,
    block: false,
    list: false
}

const TitleWithAddress = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 40px;
  @media screen and (max-width: 575px) {
    padding-right: 0;
  }
`;
const Badges = styled.div`
  display: flex;
  margin: -5px -5px -5px auto;
  @media screen and (max-width: 575px) {
    justify-content: flex-end;
    margin-bottom: 5px;
    flex-wrap: wrap;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  padding: 32px;
  margin-bottom: 20px;
  border-radius: 20px;
  border: 1px solid ${styles.borders.color};
  cursor: pointer;
`;
const EventBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding: 0;
  min-height: 100px;
  width: 100%;
  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
`;
const Title = styled(H3)`
  margin: 0;
  padding-right: 10px;
  cursor: pointer;
  overflow: hidden;
  color: ${styles.colors.dark};
  word-break: break-word;
  font-family: ${styles.fonts.family.primaryMedium};
  display: flex;
  align-items: flex-start;
`;
const ContentBlock = styled.div`
  display: flex;
  width: 100%;
  @media screen and (max-width: 1480px) {
    flex-wrap: wrap;
  }
`;
const BodyEventItem = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
`;
const BodyLeft = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const BodyRight = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 1199px) {
    width: 100%;
    margin: 3px 0 5px;
  }
`;
const BlockDate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-right: 20px;
`;
const DateTime = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 8px;
`;
const IconTime = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  margin-top: 1px;
`;
const Span = styled(H6)`
  margin: 0;
  font-family: ${styles.fonts.family.primaryMedium};
  color: ${styles.colors.silver};
`;
const IconAddress = styled.div`
  margin: 2px 8px 0 0;
  width: 16px;
  height: 16px;
`;
const Location = styled(H5)`
  margin: 0;
  font-family: ${styles.fonts.family.primaryBook};
  color: ${styles.colors.silver};
`;
const Par = styled(H5)`
  margin: 0;
  font-family: ${styles.fonts.family.primaryBook};
`;
const BlockSpotsReserved = styled.div`
  margin-right: 22px;
  @media screen and (max-width: 575px) {
    margin: 5px 22px 5px 0;
  }
  @media screen and (max-width: 375px) {
    margin: 5px 0;
  }
`;
const SpotsData = styled(H4)`
  margin: 0;
  font-family: ${styles.fonts.family.primaryMedium};
`;
const DataAddress = styled.div`
  padding: 0;
  display: flex;
  align-items: flex-start;
  margin-top: 2px;
`;
const Spots = styled.div`
  position: absolute;
  left: 0;
  top: 4px;
`;
const DataProgress = styled.div`
  position: relative;
  padding: 0 0 0 40px;
  border-right: none;
  margin-right: 16px;
  @media screen and (max-width: 575px) {
    margin: 5px 16px 5px 0;
  }
`;
const Buttons = styled.div`
  padding: 0;
  border-right: none;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  margin: 0 0 0 auto;

  & > div {
    padding: 0;
  }

  .btn {
    margin-left: 10px;
  }

  @media (min-width: 1481px) and (max-width: 1605px) {
    padding-left: 10px;
  }
  @media screen and (max-width: 1480px) {
    width: auto;
    min-width: initial;
    padding-left: 0;
  }
  @media screen and (max-width: 575px) {
    margin: 0 -5px 0 auto;
    flex-wrap: wrap;
    .btn {
      margin: 5px;
    }
  }
`;
const ImageBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  width: 160px;
  height: 90px;
  @media screen and (max-width: 991px) {
    display: none;
    margin: 0 auto;
    padding: 30px;
    width: 100%;
    height: 250px;
  }
`;
const Img = styled(ImageLoader)`
  height: auto;
  width: auto;
  max-height: 100%;
  max-width: 100%;
  border-radius: 12px;
  cursor: pointer;
  overflow: hidden;
`;
const HeaderEventItem = styled.div`
  padding: 0;
  position: relative;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  @media screen and (max-width: 575px) {
    flex-wrap: wrap-reverse;
    justify-content: flex-start;
    align-items: flex-end;
  }
`;
const Content = styled.div`
  position: relative;
  top: -6px;
  margin-bottom: -14px;
  width: calc(100% - 160px);
  padding-left: 30px;
  @media screen and (max-width: 991px) {
    width: 100%;
    padding-left: 0;
  }
`;
const EventBlockEmpty = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
`;

const ShiftItem = styled.div`
  position: relative;
  padding: 8px 16px;
  margin: 4px;
  line-height: 32px;
  min-height: 32px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  border: 1px solid ${styles.borders.color};
  border-radius: 8px;
  width: calc(50% - 8px);
  justify-content: space-between;
  @media screen and (max-width: 1280px) {
    width: calc(100% - 10px);
  }
`;
const Label = styled(H6)`
  display: block;
  align-items: center;
  font-family: ${styles.fonts.family.primaryMedium};
  color: ${styles.colors.silver};
  margin: 0 0 8px;
`;
const Shifts = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  flex-wrap: wrap;
  margin: -5px;
`;
const ShiftBlockScreen = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 0;
  margin: 15px 0 0;
  width: 100%;
`;
const ImageEmpty = styled.div`
  background: ${props => props.theme.colors.background.skeleton};
  width: 100%;
  height: 95px;
  border-radius: 10px;
`;
const HeaderEventItemEmpty = styled(HeaderEventItem)`
  border: none;
`;
const BlockTitleWithAddress = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  flex-direction: column;
  margin-right: 15px;
`;
const TitleEmpty = styled.div`
  background: ${props => props.theme.colors.background.skeleton};
  width: 180px;
  height: 10px;
  border-radius: 10px;
  margin-bottom: 6px;
`;
const StatusEmpty = styled.div`
  margin: 0 0 10px auto;
  display: flex;
  align-items: center;
`;
const IconStatus = styled.div`
  background: ${props => props.theme.colors.background.skeleton};
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-left: 16px;
`;
const Status = styled.div`
  background: ${props => props.theme.colors.background.skeleton};
  width: 70px;
  height: 12px;
  border-radius: 5px;
`;
const IconDate = styled(IconTime)`
  width: 16px;
  height: 16px;
  background: ${props => props.theme.colors.background.skeleton};
  border-radius: 50%;
`;
const DateEmpty = styled(Status)`
  height: 8px;
  width: 121px;
  margin: 5px 0;
`;
const AddressEmpty = styled(DateTime)`

  ${IconDate} {
    height: 14px;
    width: 14px;
  }

  ${DateEmpty} {
    height: 6px;
    width: 135px;
  }
`;
const IconSpots = styled(Spots)`
  width: 35px;
  height: 35px;
  top: 0;
  left: 0;
  background: ${props => props.theme.colors.background.skeleton};
  border-radius: 50%;
`;
const DataProgressEmpty = styled(DataProgress)`
  margin-right: 24px;
  padding-bottom: 0;
`;
const SpotsTaken = styled(Status)`
  width: 76px;
  height: 8px;
  @media screen and (max-width: 350px) {
    width: 56px;
  }
`;
const BlockActionsEmpty = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  margin: 0 0 0 auto;
  flex-wrap: wrap;
  @media screen and (max-width: 1130px) {
    width: 100%;
    margin: 3px 0 5px;
    justify-content: space-between;
  }
`;
const SpotsNumber = styled(Status)`
  width: 32px;
  height: 8px;
  margin: 6px 0 10px;
`;
const ViewAction = styled.div`
  width: 44px;
  height: 36px;
  margin: 5px;
  background: ${props => props.theme.colors.background.skeleton};
  border-radius: 4px;
`;
const VolunteersAction = styled(ViewAction)`
  width: 118px;
  @media (max-width: 1605px) and (min-width: 1481px) {
    width: 111px;
  }
`;
const EditAction = styled(ViewAction)`
  width: 44px;
`;
const LabelEmpty = styled(Label)`
  width: 70px;
  height: 8px;
  border-radius: 4px;
  background: ${props => props.theme.colors.background.skeleton};
  margin-bottom: 10px;
`;

const TitleShift = styled(Status)`
  height: 6px;
  margin: 5px 0;
  width: 160px;
  background: ${props => props.theme.colors.background.skeleton};
  @media screen and (max-width: 425px) {
    width: 100px;
  }
`;
const DateShift = styled(Status)`
  height: 8px;
  margin: 5px 0;
  width: 120px;
  background: ${props => props.theme.colors.background.skeleton};
  @media screen and (max-width: 425px) {
    width: 80px;
  }
`;

const LeftCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const RightCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;
const SpotsShiftCount = styled.div`
  width: 60px;
  height: 6px;
  border-radius: 4px;
  margin: 5px 0;
  background: ${props => props.theme.colors.background.skeleton};
  @media screen and (max-width: 425px) {
    width: 30px;
  }
`;
const SpotsShift = styled(SpotsShiftCount)`
  width: 100px;
  height: 6px;
  @media screen and (max-width: 425px) {
    width: 50px;
  }
`;
const Animation = styled(Container)`
  width: 100%;
  flex-direction: row;
  padding: 0;
  border: none;
  margin: 0;
  flex-wrap: wrap;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: ${props => props.block ? '100%' : 'calc(100%/3 - 32px)'};
  margin: ${props => props.block ? '0 0 20px' : '16px'};
  border-radius: 20px;
  @media screen and (max-width: 980px) {
    width: ${props => props.block ? '100%' : 'calc(100%/2 - 32px)'};
  }
  @media screen and (max-width: 575px) {
    width: ${props => props.block ? '100%' : 'calc(100% - 32px)'};
  }
`;
const ImageBlockMobile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 185px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  @media screen and (max-width: 575px) {
    height: auto;
  }
`;
const Image = styled(ImageLoader)`
  object-fit: cover;
  width: 100%;
  min-height: 100%;
  align-self: center;
`;
const Date = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  border-radius: 16px;
  background-color: white;
  padding: 3px 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;
const PrimaryCause = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  border-radius: 16px;
  background-color: white;

  div {
    width: 44px;
    height: 44px;
    border-radius: 16px;
    margin: 0;
  }
`;
const Number = styled(H3)`
  margin: 0;
  text-align: center;
`;
const Month = styled.span`
  margin: 0;
  text-align: center;
  font-size: 10px;
  line-height: 16px;
  margin-top: -3px;
  color: ${styles.colors.dark};
  font-family: ${styles.fonts.family.primaryBold};
  text-transform: uppercase;
`;
const View = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const BlockRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const Line = styled.div`
  margin: 0 10px 1px;
  font-size: 12px;
  line-height: 16px;
`;
const Data = styled.div`
  padding: 36px 24px 24px;
  border: 1px solid ${styles.borders.color};
  border-top-width: 0;
  border-radius: 0 0 20px 20px;
  margin-top: -20px;
`;
const TitleEvent = styled(H4)`
  margin: 0 0 16px;
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const Details = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  @media screen and (max-width: 450px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const Members = styled.div`
  padding: 0 9px 0 0;
  display: flex;
  flex-direction: row;
  margin: 0 0 0 12px;
  @media screen and (max-width: 450px) {
    margin: 12px 0 0;
  }
`;
const Avatar = styled.img`
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid white;
  height: 36px;
  width: 36px;
  margin-right: -9px;
`;
const BlockIcon = styled.span`
  border: 2px solid #fff;
  background-color: ${styles.colors.secondary};
  border-radius: 50%;
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: ${styles.fonts.family.primaryBold};
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  text-align: center;
  height: 36px;
  width: 36px;
  margin-right: -9px;
`;
const BlockIconMore = styled(BlockIcon)`
  background-color: ${styles.colors.light};
`;
const DataColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: calc(100% - 16px);
  position: absolute;
  left: 8px;
  bottom: 8px;
`;
const DataRow = styled.div`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
`;
const AddressLine = styled.span`
  background: white;
  border-radius: 18px;
  padding: 4px 8px 4px 6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 4px;
  max-width: 100%;

  svg {
    margin-right: 4px;
  }
`;
const Address = styled.span`
  color: ${styles.colors.dark};
  font-size: 12px;
  line-height: 16px;
  font-family: ${styles.fonts.family.primaryBold};
  max-width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;
const Virtual = styled(AddressLine)`
  background: #00ABBA;
  color: white;
  font-size: 12px;
  line-height: 16px;
  font-family: ${styles.fonts.family.primaryBold};
`;
const Time = styled.span`
  background: white;
  border-radius: 18px;
  padding: 4px 8px 4px 6px;
  color: ${styles.colors.dark};
  font-size: 12px;
  line-height: 16px;
  font-family: ${styles.fonts.family.primaryBold};
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 4px;
  max-width: 100%;

  svg {
    margin-right: 5px;
  }
`;
const SpotsCount = styled(Time)`
  background: ${styles.colors.dark};
  color: white;
  padding: 4px 8px;
`;
