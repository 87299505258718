import moment from 'moment';
import { getIsZipCode } from '../../../_helpers';
import { MIN_YEARS_OLD_USER } from '../../../constants';

const validateUserProfile = (values) => {
  const errors = {};

  const birthday = values.birthday ? moment(values.birthday, 'x')._d : null;

  if (!moment(birthday).valueOf()) {
    errors.birthday = 'Whoops! Birthday is required.';
    return errors;
  } else {
    const year = (moment().format('YYYY') - MIN_YEARS_OLD_USER).toString();
    const today = moment().format('MMMM Do');
    const day = moment(`${today} ${year}`, 'MMMM Do YYYY');
    const birthdayUser = moment(birthday).format('x');

    if (Number(birthdayUser) > Number(moment(day).format('x'))) {
      errors.birthday = `Whoops! You must be at least ${MIN_YEARS_OLD_USER} years old.`;
      return errors;
    }
  }

  if (!values.gender) {
    errors.gender = 'Whoops! Please select Gender.';
    return errors;
  }

  if (values.causes.length < 1) {
    errors.causes = 'Whoops! Please select at least one cause.';
    return errors;
  }

  if (!values.address || (values.address && !values.address.addressLine1)) {
    errors.addressLine1 = 'Whoops! Address Line 1 is required.';
    return errors;
  }

  if (values.address && !values.address.city) {
    errors.city = 'Whoops! City is required.';
    return errors;
  }
  if (values.address && !values.address.state) {
    errors.state = 'Whoops! State is required.';
    return errors;
  }
  if (values.address && !values.address.zipCode) {
    errors.zipCode = 'Whoops! Zip Code is required.';
    return errors;
  } else {
    if (values.address && !getIsZipCode(values.address.zipCode)) {
      errors.zipCode = 'Whoops! Please enter a valid Zip Code.';
      return errors;
    }
  }

  if (!values.phone) {
    errors.phone = 'Whoops! Phone is required.';
    return errors;
  }

  return errors;
};

export default validateUserProfile;
