import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const BackgroundCheckIcon = props => (
    <Icon {...props} viewBox={'0 0 24 24'}>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.3849 4.24396C12.1386 4.14125 11.8614 4.14125 11.6151 4.24396L6.61513 6.3289C6.24262 6.48423 6 6.84827 6 7.25187V9.91383C6 11.6054 6.40332 13.2727 7.1765 14.7772C7.90629 16.1974 8.94633 17.4351 10.2195 18.3986L11.47 19.3449C11.7776 19.5776 12.2031 19.5747 12.5074 19.3378L14.3732 17.8855C15.5682 16.9554 16.5209 15.7503 17.1501 14.3728C17.7101 13.1467 18 11.8145 18 10.4666V7.25187C18 6.84827 17.7574 6.48423 17.3849 6.3289L12.3849 4.24396ZM10.8454 2.39802C11.5843 2.0899 12.4157 2.0899 13.1546 2.39802L18.1546 4.48296C19.2721 4.94896 20 6.04107 20 7.25187V10.4666C20 12.1013 19.6485 13.7169 18.9693 15.2038C18.2062 16.8743 17.0509 18.3357 15.6017 19.4638L13.7359 20.9161C12.7171 21.7091 11.2926 21.7188 10.2631 20.9397L9.01265 19.9934C7.50013 18.8488 6.2646 17.3784 5.39763 15.6914C4.47913 13.904 4 11.9234 4 9.91383V7.25187C4 6.04107 4.72786 4.94896 5.84539 4.48296L10.8454 2.39802Z"/>
      <path d="M8.5 8.87178V10.4569C8.5 11.3822 8.72061 12.2942 9.14353 13.1172C9.54272 13.894 10.1116 14.571 10.808 15.098L11.6758 15.7547C11.867 15.8994 12.1316 15.8976 12.3208 15.7503L13.4937 14.8373C14.1548 14.3228 14.6818 13.6562 15.0298 12.8942C15.3397 12.2159 15.5 11.479 15.5 10.7333V8.91176C15.5 8.63786 15.3354 8.39082 15.0826 8.28541L12.7697 7.32099C12.2771 7.11558 11.7229 7.11558 11.2303 7.32099L8.88055 8.30079C8.65009 8.39689 8.5 8.6221 8.5 8.87178Z"/>
    </Icon>
);

export default withTheme(BackgroundCheckIcon);
