import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../constants/styles';
import {H5, H6} from '../Typography';
import {BookmarkIcon, CheckIcon} from '../../components/Icons/General';
import Animals from '../../assets/icons/causes/Animals';
import Poverty from '../../assets/icons/causes/Poverty';
import Food from '../../assets/icons/causes/Food';
import WaterSanitation from '../../assets/icons/causes/WaterSanitation';
import Health from '../../assets/icons/causes/Health';
import Education from '../../assets/icons/causes/Education';
import Equality from '../../assets/icons/causes/Equality';
import Energy from '../../assets/icons/causes/Energy';
import ArtCulture from '../../assets/icons/causes/ArtCulture';
import Climate from '../../assets/icons/causes/Climate';
import CommunityDevelopment from '../../assets/icons/causes/CommunityDevelopment';
import Conservation from '../../assets/icons/causes/Conservation';
import PeaceJustice from '../../assets/icons/causes/PeaceJustice';
import Family from '../../assets/icons/causes/Family';
import Crisis from '../../assets/icons/causes/Crisis';
import Refugees from '../../assets/icons/causes/Refugees';
import Homless from '../../assets/icons/causes/Homless';
import Consumption from '../../assets/icons/causes/Consumption';
import Jobs from '../../assets/icons/causes/Jobs';
import Religious from '../../assets/icons/causes/Religious';

const renderIcon = (causeId, isActive) => {
    switch (causeId) {
        case 1:
            return (<Animals isActive={isActive} />);
        case 2:
            return (<Poverty isActive={isActive} />);
        case 3:
            return (<Food isActive={isActive}  />);
        case 4:
            return (<WaterSanitation isActive={isActive}  />);
        case 5:
            return (<Health isActive={isActive}  />);
        case 6:
            return (<Education isActive={isActive}  />);
        case 7:
            return (<Equality isActive={isActive}  />);
        case 8:
            return (<Energy isActive={isActive}  />);
        case 9:
            return (<ArtCulture isActive={isActive}  />);
        case 10:
            return (<Climate isActive={isActive}  />);
        case 11:
            return (<CommunityDevelopment isActive={isActive}  />);
        case 13:
            return (<Conservation isActive={isActive}  />);
        case 14:
            return (<PeaceJustice isActive={isActive}  />);
        case 15:
            return (<Family isActive={isActive}  />);
        case 16:
            return (<Crisis isActive={isActive}  />);
        case 17:
            return (<Refugees isActive={isActive}  />);
        case 18:
            return (<Homless isActive={isActive}  />);
        case 19:
            return (<Consumption isActive={isActive}  />);
        case 20:
            return (<Jobs isActive={isActive}  />);
        case 21:
            return (<Religious isActive={isActive}  />);
        default:
            return (<Animals isActive={isActive}  />);
    }
};

const CauseItem = (props) => {
    const {
        type,
        item,
        isActive,
        setCause,
        isPrimary,
        showPrimary
    } = props;

    if (type && type === 'icon') {
        return (<WrapperOnlyIcon>
            {renderIcon((item.causeId || item.id), true)}
        </WrapperOnlyIcon>);
    }

    if (type && type === 'edit') {
        let indexCause = item.title.replace(/[^a-zA-Z]+/g,'');
        indexCause = indexCause.charAt(0).toLowerCase() + indexCause.slice(1);
        return (
            <WrapperEdit
                data-cause-name={indexCause}
                data-item-selected={isActive}
                color={styles.colors.causes[indexCause]}
                isActive={isActive}
            >
                <ActiveZone
                    onClick={() => setCause({
                        id: item.id,
                        isPrimary: isPrimary,
                        title: item.title,
                        image: item.image,
                        description: item.description
                    }, isActive ? 'remove' : 'add')}
                    data-testid={`cause-${indexCause}-item`}
                >
                    <CheckPlace>
                        {isActive ?
                            <CheckIcon
                                width={16}
                                heigth={16}
                                defaultFill={styles.colors.primary}
                            /> : null}
                    </CheckPlace>
                    <CauseIconPlace>
                        {renderIcon((item.id), isActive)}
                    </CauseIconPlace>
                    <CauseNameOnEdit>{item.title}</CauseNameOnEdit>
                </ActiveZone>
                {showPrimary ? (
                    <PrimaryIconPlace
                        isPrimary={isPrimary}
                        onClick={() => props.setPrimary(item.id)}
                        data-testid={`cause-${indexCause}-primary-item`}
                    >
                        <BookmarkIcon width={14} heigth={14} defaultFill={'white'} />
                    </PrimaryIconPlace>
                ) : null}
            </WrapperEdit>
        );
    }

    return (
        <Wrapper
            data-cause-name={(item.title || '').toLowerCase()}
        >
            <CauseIconPlace>
                {isPrimary ? (<Status><BookmarkIcon width={12} heigth={12} defaultFill={'white'} /></Status>) : null}
                {renderIcon((item.id), isActive)}
            </CauseIconPlace>
            <CauseName>{item.title}</CauseName>
        </Wrapper>
    );
};

export default CauseItem;

CauseItem.propTypes = {
    type: PropTypes.string,
    item: PropTypes.shape().isRequired,
    styles: PropTypes.string,
    isActive: PropTypes.bool,
    isPrimary: PropTypes.bool,
    isForm: PropTypes.bool,
    setCause: PropTypes.func,
    setPrimary: PropTypes.func,
    showPrimary: PropTypes.string
};

CauseItem.defaultProps = {
    type: '',
    styles: 'normal',
    isPrimary: false,
    isActive: false,
    isForm: false,
    showPrimary: '',
    setCause: () => null,
    setPrimary: () => null
};

const PrimaryIconPlace = styled.div`
    display: ${props => props.isPrimary ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 2;
    top: 12px;
    right: 12px;
    width: 24px;
    height: 24px;
    background: ${props => props.isPrimary ? styles.colors.system.alert : styles.colors.grey};
    border-radius: 50%;
    transition: all 0.5s ease;
    &:hover {
        background: ${props => props.isPrimary ? styles.colors.system.alert : styles.colors.silver};    
    }
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 72px;
    margin: 8px;
`;
const WrapperOnlyIcon = styled(Wrapper)`
    width: 36px;
    margin: 4px;
    div {
        width: 36px;
        height: 36px;
        border-radius: 12px;
    }
`;
const WrapperEdit = styled(Wrapper)`
    position: relative;
    width: 156px;
    height: 164px;
    border-radius: 20px;
    cursor: pointer;
    justify-content: center;
    border: 1px solid ${props => props.isActive ? `${props.color}80` : styles.colors.light};
    padding: 24px 32px;
    margin: 12px;
    &:hover {
        ${PrimaryIconPlace} {
            display: ${props => props.isActive ? 'flex!important' : 'none!important'};
        }
    }
`;
const ActiveZone = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 156px;
    height: 164px;
    border-radius: 20px;
`;
const CauseIconPlace = styled.div`
    position: relative;
`;
const CheckPlace = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 12px;
    left: 12px;
    width: 24px;
    height: 24px;
    border: 1px solid ${styles.colors.light};
    border-radius: 50%;
`;
const Status = styled.div`
    background: ${styles.colors.system.alert};
    width: 24px;
    height: 24px;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    border: 2px solid white;
    border-radius: 50%;
    top: -8px;
    right: -8px;
`;
const CauseName = styled(H6)`
    color: ${styles.colors.dark};
    font-family: ${styles.fonts.family.primaryBook};
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: center;
    text-transform: capitalize;
`;
const CauseNameOnEdit = styled(H5)`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    color: ${styles.colors.dark};
    font-family: ${styles.fonts.family.primaryBook};
    margin-top: 16px;
    margin-bottom: 0;
    text-align: center;
    text-transform: capitalize;
    min-height: 36px;
`;
