import styled from 'styled-components';

export const P1 = styled.p`
  font-size: 16px;
  line-height: 24px;
`;

export const P2 = styled.p`
  font-size: 14px;
  line-height: 22px;
`;

export const P3 = styled.p`
  font-size: 12px;
  line-height: 18px;
`;

