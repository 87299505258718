import React from "react";
import styled from 'styled-components';
import { DatePicker, TimePicker } from 'antd';
import { DATE_TIME_LOOK, INPUT_SIZE } from '../../../constants';
import styles from '../../../constants/styles';
import { H6 } from '../../Typography';
import { EventIcon, TimeIcon } from '../../Icons/General';

const momentZone = require('moment-timezone');

const DateTimePicker = (props) => {
    const dateFormat = 'M/DD/yyyy';
    const timeFormat = 'hh:mm A';

    let { dateTime, timezone, label, disabled, size, show } = props;

    timezone = timezone || momentZone.tz.guess();

    const dateTimeValue = dateTime ? momentZone.tz(dateTime, timezone) : null;

    let width = window.innerWidth;

    if (show !== DATE_TIME_LOOK.ALL) {
        return (
            <InputPlace
                size={size}
                disabled={disabled}
                data-testid={props.dataTestId}
            >
                {label ? <Label size={size}>{label}</Label> : null}
                <Picker>
                    {show === DATE_TIME_LOOK.DATE ? (
                        <DatePicker
                            defaultValue={dateTimeValue}
                            value={dateTimeValue}
                            format={dateFormat}
                            bordered={false}
                            className={'point-date-picker'}
                            suffixIcon={null}
                            allowClear={false}
                            inputReadOnly={width < 992}
                            onChange={(t) => props.updateDateTime({
                              dateTime: momentZone.tz(t.valueOf() , 'UTC')
                            })}
                        />
                    ) : null}
                    {show === DATE_TIME_LOOK.TIME ? (
                        <TimePicker
                            use12Hours
                            minuteStep={15}
                            defaultValue={dateTimeValue}
                            value={dateTimeValue}
                            format={timeFormat}
                            bordered={false}
                            className={'point-time-picker'}
                            suffixIcon={null}
                            allowClear={false}
                            inputReadOnly={width < 992}
                            onChange={(t) => props.updateDateTime({
                              dateTime: momentZone.tz(t.valueOf() , 'UTC')
                            })}
                        />
                    ) : null}
                </Picker>
                <IconPlace size={size}>
                    {(show === DATE_TIME_LOOK.DATE) ? (
                        <EventIcon
                            defaultFill={styles.colors.silver}
                            hoverFill={styles.colors.dark}
                            height={20}
                            width={20}
                            dataTestId={'search-input-icon'}
                        />) : null}
                    {(show === DATE_TIME_LOOK.TIME) ? (
                        <TimeIcon
                            defaultFill={styles.colors.silver}
                            hoverFill={styles.colors.dark}
                            height={20}
                            width={20}
                            dataTestId={'search-input-icon'}
                        />) : null}
                </IconPlace>
            </InputPlace>
        );
    } else {
        return (
            <Container data-testid={props.dataTestId}>
                <InputPlace
                    size={size}
                    disabled={disabled}
                >
                    {label ? <Label size={size}>{label}</Label> : null}
                    <Picker>
                        <DatePicker
                            defaultValue={dateTimeValue}
                            value={dateTimeValue}
                            format={dateFormat}
                            bordered={false}
                            className={'point-date-picker'}
                            suffixIcon={null}
                            allowClear={false}
                            inputReadOnly={width < 992}
                            onChange={(t) => props.updateDateTime({
                                dateTime: momentZone.tz(t.valueOf() , 'UTC')
                            })}
                        />
                    </Picker>
                    <IconPlace size={size}>
                        <EventIcon
                            defaultFill={styles.colors.silver}
                            hoverFill={styles.colors.dark}
                            height={20}
                            width={20}
                            dataTestId={'date-icon'}
                        />
                    </IconPlace>
                </InputPlace>
                <InputPlace
                    size={size}
                    disabled={disabled}
                >
                    {label ? <Label size={size}>{label}</Label> : null}
                    <Picker>
                        <TimePicker
                            use12Hours
                            minuteStep={15}
                            defaultValue={dateTimeValue}
                            value={dateTimeValue}
                            format={timeFormat}
                            bordered={false}
                            className={'point-time-picker'}
                            suffixIcon={null}
                            allowClear={false}
                            inputReadOnly={width < 992}
                            onChange={(t) => props.updateDateTime({
                                dateTime: momentZone.tz(t.valueOf() , 'UTC')
                            })}
                        />
                    </Picker>
                    <IconPlace size={size}>
                        <TimeIcon
                            defaultFill={styles.colors.silver}
                            hoverFill={styles.colors.dark}
                            height={20}
                            width={20}
                            dataTestId={'time-icon'}
                        />
                    </IconPlace>
                </InputPlace>
            </Container>
        );
    }
}

export default DateTimePicker;

const Container = styled.div`
    display: flex;
    flex-direction: row;
`;
const InputPlace = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    height: ${props =>  {
        switch (props.size) {
            case INPUT_SIZE.SMALL:
                return '36px';
            case INPUT_SIZE.NORMAL:
                return '48px';
            default:
                return '48px';
        }
    }};
    border: 1px solid ${styles.borders.input.color.default};
    border-radius: ${styles.borders.input.radius};
    cursor: ${props => props.disabled ? 'not-allowed': 'default'};
    padding: ${props =>  {
        switch (props.size) {
            case INPUT_SIZE.SMALL:
                return '7px 12px';
            case INPUT_SIZE.NORMAL:
                return '13px 16px';
            default:
                return '13px 16px';
        }
    }};
`;
const Label = styled(H6)`
    display: inline-block;
    position: absolute;
    color: ${styles.colors.silver};
    font-family: ${styles.fonts.family.primaryBook};
    padding: 0 4px;
    background: white;
    bottom: ${props =>  {
        switch (props.size) {
            case INPUT_SIZE.SMALL:
                return '26px';
            case INPUT_SIZE.NORMAL:
                return '38px';
            default:
                return '38px';
        }
    }};
    margin: 0;
    left: ${props =>  {
        switch (props.size) {
            case INPUT_SIZE.SMALL:
                return '8px';
            case INPUT_SIZE.NORMAL:
                return '12px';
            default:
                return '12px';
        }
    }};
`;
const Picker = styled.div`
    display: flex;
    position: relative;
    z-index: 2;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    cursor: default;
    .point-date-picker {
        border: 0;
        width: 100%;
        padding: 0;
        font-size: 14px;
        line-height: 22px;
        color: ${styles.colors.medium};
        input {
            color: ${styles.colors.medium};
            font-size: 14px;
            line-height: 22px;    
        }
    }
    @media screen and (max-width: 991px) {
        .point-date-picker {
            font-size: 16px;
            input {
                font-size: 16px;
            }
        }
    }
`;
const IconPlace = styled.div`
    display: block;
    position: relative;
    right: -4px;
`;
