import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const TimeShiftIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M6.5,11h3.8V7.2c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v5.2H6.5c-0.4,0-0.8-0.3-0.8-0.8S6.1,11,6.5,11z"/>
            <path d="M16,15.5h7v-2h-7v-2l-3,3l3,3V15.5z"/>
            <path d="M20,18.5h-7v2h7v2l3-3l-3-3V18.5z"/>
            <path d="M11,19.5c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8c0.9,0,1.3,0,2,0c0-5.5-4.5-10-10-10C5.5,1.5,1,6,1,11.5s4.5,10,10,10
		c0,0,0,0,0,0V19.5z"/>
        </Icon>
    );

export default withTheme(TimeShiftIcon);
