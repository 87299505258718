import React from 'react';
import { withTheme } from 'styled-components';
import Icon from './components/Icon';

const SettingsMenu = props => (
  <Icon {...props}>
    <path d="M12,10c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S13.1,10,12,10z M12,8c2.2,0,4,1.8,4,4s-1.8,4-4,4s-4-1.8-4-4S9.8,8,12,8z
	 M12,2c-0.6,0-1,0.4-1,1v0.2c0,1.1-0.6,2-1.6,2.4c-0.1,0-0.2,0.1-0.3,0.1C8.2,6,7.1,5.8,6.4,5.1C5.9,4.6,5.3,4.6,4.9,5
	c-0.4,0.4-0.4,1,0,1.4c0.8,0.8,1,2,0.6,3c-0.4,1-1.3,1.6-2.4,1.7H3c-0.6,0-1,0.4-1,1s0.4,1,1,1h0.2c1.1,0,2,0.6,2.4,1.6
	c0.4,1,0.2,2.1-0.5,2.9c-0.3,0.3-0.4,0.5-0.4,0.8c0,0.2,0.1,0.4,0.2,0.6L5,19.1c0,0,0,0,0,0c0.4,0.4,1,0.4,1.4,0
	c0.6-0.5,1.2-0.8,1.9-0.8c0.4,0,0.7,0.1,1.1,0.2c1,0.4,1.6,1.3,1.7,2.4l0,0.1c0,0,0,0,0,0c0,0.6,0.4,1,1,1c0.6,0,1-0.4,1-1v-0.2
	c0-1.1,0.6-2,1.6-2.4c1-0.4,2.1-0.2,2.9,0.5c0.2,0.2,0.4,0.3,0.7,0.4l0.1,0c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7
	c0-0.3-0.1-0.5-0.3-0.7c-0.8-0.8-1-2-0.6-3c0.4-1,1.4-1.6,2.4-1.6c0.6,0,1.1-0.4,1.1-1s-0.4-1-1-1h-0.2c-1.1,0-2-0.6-2.4-1.6
	c0-0.1-0.1-0.2-0.1-0.3c-0.3-0.9-0.1-2,0.6-2.7c0.5-0.5,0.5-1.1,0.1-1.5c0,0,0,0,0,0c-0.2-0.2-0.4-0.3-0.7-0.3h0
	c-0.3,0-0.5,0.1-0.7,0.3c-0.8,0.8-2,1-3,0.6c-1-0.4-1.6-1.4-1.6-2.4C13,2.4,12.6,2,12,2z M12,0c1.7,0,3,1.3,3,3
	c0,0.3,0.2,0.6,0.4,0.7c0.3,0.1,0.5,0.1,0.7-0.1c0.6-0.6,1.4-0.9,2.2-0.9h0c0.8,0,1.5,0.3,2.1,0.9l0,0v0c1.2,1.2,1.2,3.1,0,4.2
	c-0.2,0.2-0.3,0.5-0.2,0.8c0,0.1,0.1,0.1,0.1,0.2C20.4,8.9,20.6,9,20.8,9H21c1.7,0,3,1.3,3,3s-1.3,3-3,3c-0.3,0-0.6,0.2-0.7,0.4
	c0,0,0,0,0,0c-0.1,0.2-0.1,0.5,0.1,0.7c1.2,1.2,1.2,3.1,0.1,4.3c-1.2,1.2-3.1,1.2-4.2,0c-0.2-0.2-0.5-0.3-0.8-0.2
	c-0.2,0.1-0.4,0.3-0.4,0.6V21c0,1.7-1.3,3-3,3c-1.7,0-3-1.3-3-3c0-0.3-0.2-0.6-0.4-0.7c-0.3-0.1-0.6-0.1-0.8,0.1
	c-1.2,1.2-3.1,1.2-4.3,0.1l0,0c-0.6-0.6-0.9-1.3-0.9-2.1c0-0.8,0.3-1.6,0.9-2.1c0.2-0.2,0.3-0.5,0.2-0.8c-0.1-0.2-0.3-0.4-0.6-0.4H3
	c-1.7,0-3-1.3-3-3s1.3-3,3-3c0.3,0,0.6-0.2,0.7-0.4c0.1-0.3,0.1-0.6-0.1-0.8C2.3,6.7,2.3,4.8,3.5,3.6c1.2-1.2,3.1-1.2,4.2,0
	C8,3.8,8.3,3.9,8.5,3.8c0.1,0,0.1,0,0.2-0.1C8.9,3.6,9,3.4,9,3.2V3C9,1.3,10.3,0,12,0z"/>
  </Icon>
);

export default withTheme(SettingsMenu);
