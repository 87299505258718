import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const DismissIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M12,2C6.5,2,2,6.5,2,12c0,5.5,4.5,10,10,10c5.5,0,10-4.5,10-10C22,6.5,17.5,2,12,2 M20,12
			c0,1.8-0.6,3.5-1.7,4.9L7.1,5.7C8.5,4.6,10.2,4,12,4C16.4,4,20,7.6,20,12 M4,12c0-1.8,0.6-3.5,1.7-4.9l11.2,11.2
			C15.5,19.4,13.8,20,12,20C7.6,20,4,16.4,4,12"/>
        </Icon>
    );

export default withTheme(DismissIcon);
