import React from 'react';
import { withTheme } from 'styled-components';
import Icon from './components/Icon';

const NotificationMenu = props => (
        <Icon {...props}>
            <path d="M11.1,20.5c0.1,0.1,0.2,0.3,0.4,0.4c0.5,0.3,1.1,0.1,1.4-0.4c0.3-0.5,0.9-0.6,1.4-0.4
	c0.5,0.3,0.6,0.9,0.4,1.4C14,22.5,13,23,12,23c-0.5,0-1-0.1-1.5-0.4C10,22.3,9.7,22,9.4,21.5c-0.3-0.5-0.1-1.1,0.4-1.4
	C10.2,19.9,10.9,20,11.1,20.5z M12,1c3.9,0,7,3.1,7,7v5c0,2.4,1.1,2.9,1.4,3.1c0.4,0.2,0.6,0.7,0.5,1.1c-0.1,0.5-0.5,0.8-1,0.8H4
	c-0.5,0-0.9-0.3-1-0.8c-0.1-0.5,0.1-0.9,0.5-1.1C3.9,15.9,5,15.4,5,13V8C5,4.1,8.1,1,12,1z M12,3C9.2,3,7,5.2,7,8v5
	c0,1.3-0.3,2.3-0.6,3h11.3c-0.4-0.7-0.6-1.7-0.6-3V8C17,5.2,14.8,3,12,3z"/>
        </Icon>
    );

export default withTheme(NotificationMenu);
