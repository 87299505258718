import { combineReducers } from 'redux';
import eventsReducer from './eventsReducer';
import accountReducer from './accountReducer';

const rootReducer = combineReducers({
  events: eventsReducer,
  account: accountReducer,
});

export default rootReducer;
