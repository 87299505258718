import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const EventIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M18,7.5H6c-0.6,0-1,0.4-1,1v2h14v-2C19,7.9,18.6,7.5,18,7.5z M19,12.5H5v6c0,0.6,0.4,1,1,1h12
	c0.6,0,1-0.4,1-1V12.5z M16,2.5c0.6,0,1,0.4,1,1v2h1c1.6,0,2.9,1.2,3,2.8v10.2c0,1.7-1.3,3-3,3H6c-1.7,0-3-1.3-3-3v-10
	c0-1.7,1.3-3,3-3h1v-2c0-0.6,0.4-1,1-1s1,0.4,1,1v2h6v-2C15,2.9,15.4,2.5,16,2.5z"/>
        </Icon>
    );

export default withTheme(EventIcon);
