import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import styles from '../../constants/styles';
import Hint from '../Hint';
import Avatar from '../Avatar';
import { H5, H6 } from '../Typography';
import { StarSolidIcon } from '../Icons/General';

import imageVerificationSign from '../../assets/icons/svg/verification-check.svg';

const ShortProfile = (props) => {
  const avatar = <Avatar size={props.imageSize || 36} src={props.image || ''} firstName={props.title || ''} />

  return (
    <Container
      onClick={() => props.onClick()}
      data-testid={props.dataTestId}
      isLive={props.isLive}
    >
      <ImagePlace>
        {props.hint ? <Hint shortValue={avatar} value={props.hint} /> : avatar}
        {props.isSign ? (
          <VerificationSign>
            <VerificationSignImg src={imageVerificationSign} alt={'Verification Sign'} />
          </VerificationSign> ) : null}
      </ImagePlace>
      <ProfileInfo data-full-name={props.title}>
        <ProfileTitle>
          {props.title}
          {props.isLeader ? <StarSolidIcon defaultFill={styles.colors.primary} height={18} width={18} /> : null}
        </ProfileTitle>
        {props.subtitle ? (
          <ProfileSubtitle>{props.subtitle}</ProfileSubtitle>
          ) : null}
      </ProfileInfo>
    </Container>
  );
}

export default ShortProfile;

ShortProfile.propTypes = {
  onClick: PropTypes.func,
  image: PropTypes.string,
  imageSize: PropTypes.number,
  hint: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isSign: PropTypes.bool,
  isLive: PropTypes.bool,
  isLeader: PropTypes.bool,
  dataTestId: PropTypes.string,
  joinStatus: PropTypes.string
};
ShortProfile.defaultProps = {
  onClick: () => null,
  image: '',
  imageSize: 36,
  hint: '',
  title: '',
  subtitle: '',
  isSign: false,
  isLive: true,
  isLeader: false,
  dataTestId: '',
  joinStatus: null
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  cursor: ${props => props.isLive ? 'pointer' : 'default'};
  filter: ${props => props.isLive ? 'none' : 'blur(1px) opacity(0.6)'};
`;
const ImagePlace = styled.div`
  margin-right: 12px;
  position: relative;
  cursor: pointer;
`;
const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
`;
const ProfileTitle = styled(H5)`
  color: ${styles.colors.dark};
  font-family: ${styles.fonts.family.primaryBook};
  margin: 0;
  word-break: break-word;
  .badge {
    margin-left: 8px;
  }
`;
const ProfileSubtitle = styled(H6)`
  color: ${styles.colors.silver};
  font-family: ${styles.fonts.family.primaryBook};
  word-break: break-word;
  margin: 0;
`;
const VerificationSign = styled.div`
  position: absolute;
  right: -2px;
  bottom: -2px;
  border: 2px solid white;
  border-radius: 50%;
  background: white;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
const VerificationSignImg = styled.img`
  width: 10px;
  height: 10px;
`;
