import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../constants/styles';

const Food = (props) => (
    <Container isActive={props.isActive}>
      <svg
        width="20px"
        height="20px"
        id="food"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        enableBackground="new 0 0 24 24"
        xmlSpace="preserve"
      >
        <g id="mask">
          <path fill={props.isActive ? styles.colors.causes.food : styles.colors.silver} d="M22,8.4c0-2.3-6.3-2.9-10-2.9S2,6.1,2,8.4v0.1c0,5.5,4.5,10,10,10
    C17.5,18.5,22,14,22,8.4L22,8.4z M3.2,8.4C3.4,7.9,6.6,6.8,12,6.8c5.4,0,8.5,1.1,8.7,1.6c-0.2,0.6-3.4,1.6-8.7,1.6
    C6.6,10.1,3.4,9,3.2,8.4z"/>
        </g>
      </svg>
    </Container>
);

export default Food;

Food.propTypes = {
  isActive: PropTypes.bool
};

Food.defaultProps = {
  isActive: true
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: ${props => props.isActive ? `${styles.colors.causes.food}1f` : styles.colors.mostlyWhite};
    border-radius: 20px;
`;