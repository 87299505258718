import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const CalendarIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M19,3h-2V2c0-0.6-0.4-1-1-1s-1,0.4-1,1v1H9V2c0-0.6-0.4-1-1-1S7,1.4,7,2v1H5C3.3,3,2,4.3,2,6v14
	c0,1.7,1.3,3,3,3h14c1.7,0,3-1.3,3-3V6C22,4.3,20.7,3,19,3z M20,20c0,0.6-0.4,1-1,1H5c-0.6,0-1-0.4-1-1v-9h16V20z M20,9H4V6
	c0-0.6,0.4-1,1-1h2v1c0,0.6,0.4,1,1,1s1-0.4,1-1V5h6v1c0,0.6,0.4,1,1,1s1-0.4,1-1V5h2c0.6,0,1,0.4,1,1V9"/>
        </Icon>
    );

export default withTheme(CalendarIcon);
