import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import styles from '../../../constants/styles';
import moment from 'moment-timezone';
import { H4, P2 } from '../../Typography';
import { ArrowLeftIcon, ArrowRightIcon } from '../../Icons/General';
import PropTypes from 'prop-types';
import Hint from '../../Hint';

const eventsServerURL = process.env.REACT_APP_EVENTS_SERVER;
const Calendar = (props) => {
  if (!props.event) {
    return false;
  }
  let events = props.event.repeats || [];
  const monthToday = +moment.tz(props.event.startTime, props.event.timezone).format('M');
  const yearToday = +moment.tz(props.event.startTime, props.event.timezone).format('YYYY');
  const [month, setMonth] = useState(monthToday);
  const [year, setYear] = useState(yearToday);
  const [daysOfPage, setDays] = useState([]);

  const renderElement = (data, isMonth) => ({
    day: data.format('D'),
    date: data.format('L'),
    isMonth: isMonth
  });

  const updatePageCalendar = () => {
    let daysPage = [];
    let currentMonth = moment(`${month}-${year}`, "M-YYYY");
    let countDaysMonth = +currentMonth.clone().daysInMonth();

    let startMonthElement = currentMonth.clone().startOf('month');
    let endMonthElement = currentMonth.clone().endOf('month');

    let startOfMonth = +startMonthElement.clone().format('e');
    let endOfMonth = +endMonthElement.clone().format('e');

    for (let i=(0 - startOfMonth); i < (countDaysMonth + (6 - endOfMonth)); i++) {
      let element = startMonthElement.clone().add(i, 'days');
      daysPage.push((renderElement(element, i < countDaysMonth && i >= 0)));
    }
    setDays(daysPage);
  }

  useEffect(() => {
    updatePageCalendar();
  }, [month]);

  const prevMonth = () => {
    if (month === 1) {
      setMonth(12);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  }

  const nextMonth = () => {
    if (month === 12) {
      setMonth(1);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  }

  const linkToEventDetails = (history, eventId = '', organizationId) => {
    window.open(`${eventsServerURL}/${eventId}`, '_self');
    props.updateData(eventId);
  };

  const setToday = () => {
    setMonth(+moment.tz(props.event.timezone).startOf('days').format('M'));
    setYear(+moment.tz(props.event.timezone).startOf('days').format('YYYY'));
  }

  const listDays = () => {
    let onGoingEvents = [];

    if (events && events.length) {
      events.forEach((elem) => {
        let data = {
          startTime: moment.tz(elem.startTime, props.event.timezone).startOf('days').format('L'),
          eventId: elem.eventId
        }
        if ('isHosted' in elem) {
          data.isHosted = elem.isHosted;
        }
        onGoingEvents.push(data);
      });
    }

    return daysOfPage.map((item) => {
      let today = moment.tz(props.event.timezone).startOf('days').format('L');
      let borderColor = styles.borders.input.color.default;
      let color = styles.colors.silver;
      let dotColor = null;
      let indexEvent = onGoingEvents.findIndex(e => e.startTime === item.date);

      if (item.isMonth) {
        if (today === item.date) {
          borderColor = styles.colors.primary;
        }

        if (today === item.date || moment(item.date).diff(moment(today)) > 0) {
          color = styles.colors.medium;
        }

        if (item.date === moment.tz(props.event.startTime, props.event.timezone).format('L')) {
          dotColor = styles.colors.system.success;
          borderColor = styles.colors.system.success+'66';
        }
        if (indexEvent > -1) {
          dotColor = styles.colors.primary;
          borderColor = styles.colors.primary+'66';
        }
      } else {
        borderColor = 'transparent';
      }

      let isOnPress = false;

      if (indexEvent > - 1 && props.history
        && (('isHosted' in onGoingEvents[indexEvent]) && onGoingEvents[indexEvent].isHosted
          || !('isHosted' in onGoingEvents[indexEvent]))) {
        isOnPress = true;
      }

      return (
        <Day
          borderColor={borderColor}
          isMonth={item.isMonth}
          dotColor={dotColor}
          color={color}
          cursor={isOnPress ? 'pointer' : 'default'}
          onClick={() => {
            if (isOnPress) {
              linkToEventDetails(props.history, onGoingEvents[indexEvent].eventId, props.event.organization.id);
            }
            return null;
          }}
        >
          {dotColor ? (
            <Dot color={dotColor} />
          ) : null}
          <Text color={color}>{item.day}</Text>
        </Day>
      )
    });
  }

  let momentName = moment(month, 'M').format('MMMM');

  return (
    <Wrapper>
      <Container>
        <Navigation>
          <Text color={styles.colors.dark}>{`${momentName} ${year}`}</Text>
          <Arrows>
            <Hint
              variant={'dark'}
              value={'Previous month'}
              shortValue={(
                <Btn onClick={() => prevMonth()} data-testid={'btn-calendar-today-previous-month'}>
                  <ArrowLeftIcon height={20} width={20} defaultFill={styles.colors.dark} />
                </Btn>
              )}
              style={{ margin: '4px', borderRadius: '8px'}}
              dataTestId={'hint-calendar-previous-month'}
            />
            <Btn onClick={() => setToday()} isMargin data-testid={'btn-calendar-today'}>{'Today'}</Btn>
            <Hint
              variant={'dark'}
              value={'Next month'}
              shortValue={(
                <Btn onClick={() => nextMonth()} data-testid={'btn-calendar-today-next-month'}>
                  <ArrowRightIcon height={20} width={20} defaultFill={styles.colors.dark} />
                </Btn>
              )}
              style={{ margin: '4px', borderRadius: '8px' }}
              dataTestId={'hint-calendar-next-month'}
            />
          </Arrows>
        </Navigation>
        <List>
          <Day><Text>{'S'}</Text></Day>
          <Day><Text>{'M'}</Text></Day>
          <Day><Text>{'T'}</Text></Day>
          <Day><Text>{'W'}</Text></Day>
          <Day><Text>{'T'}</Text></Day>
          <Day><Text>{'F'}</Text></Day>
          <Day><Text>{'S'}</Text></Day>
        </List>
        <List>
          {listDays()}
        </List>
      </Container>
      <Row>
        <RowBlock>
          <Dot color={styles.colors.system.success} />
          <Label>{'Current Event'}</Label>
        </RowBlock>
        <RowBlock>
          <Dot color={styles.colors.primary} />
          <Label>{'Ongoing Event'}</Label>
        </RowBlock>
      </Row>
    </Wrapper>

  );
}

Calendar.propTypes = {
  history: PropTypes.shape(),
  event: PropTypes.shape(),
  repeatsEventsForWatch: PropTypes.shape(),
  updateData: PropTypes.func,
};

Calendar.defaultProps = {
  history: null,
  repeatsEventsForWatch: null,
  event: null,
  updateData: () => null
};

export default Calendar;

const Wrapper = styled.div`
  max-width: 100%;
  width: 672px;
`;
const Container = styled.div`
  padding: 24px;
  border: 1px solid ${styles.borders.input.color.default};
  border-radius: 16px;
  margin: 0 0 24px;
  @media screen and (max-width: 425px) {
    padding: 16px;
  }
`;

const Btn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 36px;
  margin: ${props => props.isMargin ? '4px' : '0'};
  font-size: 14px;
  line-height: 18px;
  color: ${styles.colors.dark};
  font-family: ${styles.fonts.family.primaryMedium};
  padding: 0 8px;
  height: 36px;
  border-radius: 8px;
  background: transparent;
  transition: 0.5s;
  &:hover {
    background: ${styles.colors.medium}1a;
    transition: 0.5s;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Label = styled(P2)`
  color: ${styles.colors.medium};
  line-height: 18px;
  font-family: ${styles.fonts.family.primaryBook};
  margin: 0 0 0 4px;
`;
const RowBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  &:first-of-type {
    margin: 0 24px 0 0;
  }
`;
const List = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: -4px;
`;
const Text = styled(H4)`
  margin: 0;
  color: ${props => props.color ? props.color : styles.colors.medium};
  font-family: ${styles.fonts.family.primaryMedium};
  @media screen and (max-width: 575px) {
    font-size: 12px;
    line-height: 16px;
  }
`;
const Dot = styled.div`
  margin: 0 4px 0 0;
  background: ${props => props.color ? props.color : 'transparent'};
  width: 8px;
  min-width: 8px;
  height: 8px;
  border-radius: 50%;
`;

const Day = styled.div`
  padding: 10px;
  width: calc((100%/7) - 8px);
  height: 48px;
  border: 1px solid ${props => props.borderColor ? props.borderColor : 'transparent'};
  border-radius: 36px;
  margin: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: ${props => props.cursor ? props.cursor : 'default'};
  &:hover {
    background: ${props => props.isMonth ? props.dotColor || styles.colors.light : 'transparent'};
    ${Text} {
      color: ${props => props.isMonth ? (props.dotColor ? '#fff' : styles.colors.medium) : props.color};
    }
    ${Dot} {
      background: #fff;
    }
  }
  @media screen and (max-width: 575px) {
    width: calc((100%/7) - 4px);
    height: 36px;
    margin: 2px;
  }
  @media screen and (max-width: 375px) {
    ${Dot} {
      width: 6px;
      min-width: 6px;
      height: 6px;

      position: absolute;
      margin: 0;
      bottom: 3px;
      left: calc(50% - 3px);
    }
  }
`;
const Arrows = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: -4px -4px -4px auto;
  padding-left: 12px;
`;
const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 16px;
  ${Text} {
    margin: 8px 0;
  }
`;
