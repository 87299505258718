import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const CheckboxIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
          <path d="M22,4.6c-0.8-0.8-2-0.8-2.8,0L8.6,15.2l-3.8-3.6c-0.8-0.8-2-0.8-2.8,0s-0.8,2,0,2.8l5,5c0.8,0.8,2,0.8,2.8,0l12-12
	C22.8,6.6,22.8,5.4,22,4.6z"/>
        </Icon>
    );

export default withTheme(CheckboxIcon);
