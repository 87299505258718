import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const NonprofitIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M7.2,6C6.4,6,5.5,6.3,4.9,6.9c-1.3,1.3-1.3,3.3,0,4.6l7.1,7.1l7.1-7.1c1.3-1.3,1.3-3.3,0-4.6
	c-1.3-1.2-3.3-1.2-4.6,0l-1.8,1.8c-0.4,0.4-1,0.4-1.4,0c0,0,0,0,0,0L9.5,6.9C8.9,6.3,8.1,6,7.2,6 M12,21c-0.3,0-0.5-0.1-0.7-0.3
	l-7.8-7.8c-2-2-2-5.3,0-7.4c2-2,5.4-2,7.4,0L12,6.6l1.1-1.1c2-2,5.4-2,7.4,0c2,2.1,2,5.4,0,7.4l-7.8,7.8C12.5,20.9,12.3,21,12,21"/>
        </Icon>
    );

export default withTheme(NonprofitIcon);
