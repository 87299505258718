import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const SortIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M8.5,20.5v-13l1.2,1.6c0.3,0.4,1,0.5,1.4,0.2c0.3-0.2,0.4-0.5,0.4-0.8c0-0.2-0.1-0.4-0.2-0.6l-3-4C8,3.5,7.3,3.4,6.9,3.7
			C6.8,3.8,6.8,3.8,6.7,3.9l-3,3.9C3.4,8.2,3.4,8.8,3.9,9.2S5,9.4,5.3,9l0,0l1.2-1.6v13.1c0,0.6,0.4,1,1,1
			C8.1,21.5,8.5,21.1,8.5,20.5z"/>
            <path d="M15.5,4.5v13l-1.2-1.6c-0.3-0.4-1-0.5-1.4-0.2c-0.4,0.3-0.5,1-0.2,1.4l3,4c0.3,0.4,1,0.5,1.4,0.2c0.1-0.1,0.1-0.1,0.2-0.2
			l3-3.9c0.1-0.2,0.2-0.4,0.2-0.6c0-0.3-0.1-0.6-0.4-0.8c-0.4-0.3-1.1-0.3-1.4,0.2l-1.2,1.6V4.5c0-0.6-0.4-1-1-1S15.5,3.9,15.5,4.5z
			"/>
        </Icon>
    );

export default withTheme(SortIcon);
