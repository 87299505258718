import React from 'react';
import { withTheme } from 'styled-components';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Badge from './Badge';
import { DraftIcon, VirtualIcon, FundIcon, GiftIcon } from '../Icons/General';
import icon_urgency_high from '../../assets/images/svg/types/high.svg';
import icon_urgency_medium from '../../assets/images/svg/types/medium.svg';
import icon_urgency_standard from '../../assets/images/svg/types/standard.svg';

const Index = props => {
    const { variant = 'internal' } = props;
    switch (variant) {
        case 'internal':
            return null;
        case 'cohost':
            return <Badge {...props} variant={variant} value={'CO-HOST'} />;
        case 'virtual':
            return <Badge {...props} variant={variant} value={'VIRTUAL'} Icon={<VirtualIcon width={14} height={14}/>} />;
        case 'draft':
            return <Badge {...props} variant={variant} value={'DRAFT'} Icon={<DraftIcon width={14} height={14}/>} />;
        case 'pending':
            return <Badge {...props} variant={variant} value={'PENDING'} />;
        case 'started':
            return <Badge {...props} variant={variant} value={'STARTED'} />;
        case 'joined':
            return <Badge {...props} variant={'confirmed'} value={'JOINED'} />;
        case 'confirmed':
            return <Badge {...props} variant={variant} value={'CONFIRMED'} />;
        case 'rejected':
            return <Badge {...props} variant={variant} value={'REJECTED'} />;
        case 'invitesent':
            return <Badge {...props} variant={variant} value={'INVITE SENT'} />;
        case 'invitepending':
            return <Badge {...props} variant={variant} value={'INVITE PENDING'} />;
        case 'internalstatus':
            return <Badge {...props} variant={variant} value={'INTERNAL'} />;
        case 'manual':
            return <Badge {...props} variant={variant} value={'MANAGED'} />;
        case 'external':
            return <Badge {...props} variant={variant} value={'EXTERNAL'} />;
        case 'beta':
            return <Badge {...props} variant={variant} value={'BETA'} />;
        case 'noshow':
            return <Badge {...props} variant={variant} value={'NO SHOWED'} />;
        case 'skipped':
            return <Badge {...props} variant={variant} value={'NO SHOW'} />;
        case 'visited':
            return <Badge {...props} variant={variant} value={'VISITED'} />;
        case 'dismiss':
        case 'dismissed':
            return <Badge {...props} variant={variant} value={'DISMISSED'} />;
        case "declined":
            return <Badge {...props} variant={variant} value={'DECLINED'} />;
        case "going":
            return <Badge {...props} variant={variant} value={'GOING'} />;
        case "registry-funded":
            return <Badge {...props} variant={variant} value={'Fund Item'} Icon={<FundIcon width={14} height={14} />} />;
        case 'registry-gifted':
            return <Badge {...props} variant={variant} value={'Gift Item'} Icon={<GiftIcon width={14} height={14} />} />;
        case 'registry-high':
            return <Badge {...props} variant={variant} value={'Urgent'} Icon={<UrgencyIcon alt={"Urgency"} src={icon_urgency_high} />} />;
        case 'registry-medium':
            return <Badge {...props} variant={variant} value={'Need'} Icon={<UrgencyIcon alt={"Urgency"} src={icon_urgency_medium} />} />;
        case 'registry-standard':
            return <Badge {...props} variant={variant} value={'Wish'} Icon={<UrgencyIcon alt={"Urgency"} src={icon_urgency_standard} />} />;
        default:
            return <Badge/>;
    }
};

export default withTheme(Index);

Index.propTypes = {
    variant: PropTypes.string
};

Index.defaultProps = {
    variant: 'internal'
};

const UrgencyIcon = styled.img`
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
`;
