import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import * as Cookies from 'js-cookie';
import { ConfirmPopup, Details, RightBar, DetailsOpportunities, InstructionPopup } from './components';
import SignIn from '../../Authentication/SignIn';
import SignUp from '../../Authentication/SignUp';
import SignInOkta from '../../Authentication/SignInOkta';
import { getClientEvent, getEventReservations, getEventShare } from '../../../actions/eventsActions';
import { customPostToken, resendEmailVerificationEmail } from '../../../actions/accountActions';
import { getOrganizationSearch } from '../../../actions/profileActions';
import styles from '../../../constants/styles';
import { Content, Wrapper } from '../../../components/Elements';
import waitingResult from '../../../_helpers/waitingResult';
import { P2 } from '../../../components/Typography';
import { Modal, ModalImage, ModalTitle } from '../../../components/Modal';
import { HelmetMetaData, CompleteProfilePopup } from '../components';
import ResultsPopup from './components/ResultsPopup';
import { HOME_LINK, POINT_DASH_URL } from '../../../constants/links';
import getVerificationEmail from '../verification/email';
import { EVENT_SOURCES } from '@pointapp/constants';
import { onAuthStateChanged } from 'firebase/auth';
import FireBaseTools, { firebaseAuth } from '../../../api/firebase';
import 'antd/dist/antd.min.css';

import verification from '../../../assets/images/svg/verification-email.svg';


class EventDetails extends Component {
  constructor (props) {
    super(props);
    this.state = {
      bottomBoxFixed: 0,
      googleMapsReady: false,
      selectedShifts: [],
      showSignUp: false,
      showSignIn: false,
      location: null,
      showConfirmPopup: false,
      counter: 0,
      showEmailVerifyPopUp: false,
      isChangeCancel: false,
      loading: false,
      isPrivate: false,
      token: null,
      reservations: [],
      event: null,
      detailsClient: null,
      claimSpotItem: null,
      showSignInOkta: false,
      fromPopup: null,
      currentOrganization: null,
      resultsPopup: false,
      resultsByShift: [],
      messageShift: null,
      showCompleteProfilePopUp: false,
      showPopupCancel: false,
      showInfoPopup: false,
      firstRender: true
    };
  }

  updateSelectedShifts = (event, isLogOut) => {
    if (!event) return false;
    let { selectedShifts } = this.state;

    if (event.source && event.source === EVENT_SOURCES.VOLUNTEER_MATCH) {
      selectedShifts = [];
      event.shifts.forEach((item) => {
        selectedShifts.push({
          ...item,
          isAttending: !event.shifts[0].isAttending
        });
      });
    } else {
      event.shifts.forEach((item) => {
        let index = selectedShifts.findIndex(e => e.eventShiftId === item.eventShiftId);
        if (index > -1) {
          let isAttending = selectedShifts[index].isAttending ? selectedShifts[index].isAttending : (('isAttending' in item) ? item.isAttending : false);
          selectedShifts[index] = { ...item };
          selectedShifts[index].isAttending = !isLogOut ? isAttending : false;
        } else {
          selectedShifts.push({
            ...item,
            isAttending: ('isAttending' in item) ? item.isAttending : false
          });
        }
      });
    }

    this.setState({ selectedShifts });
  };

  updateReserve = async () => {
    const resultsReserve = await getEventReservations({
      eventId: this.props.match.params.id,
      params: { includeGroupReservations: true }
    });

    if (resultsReserve && resultsReserve.items) {
      let reservations = [];
      resultsReserve.items.forEach((item) => {
        let index = reservations.findIndex((el) => el.eventShiftId === item.eventShiftId);
        if (index < 0) {
          reservations.push({ eventShiftId: item.eventShiftId });
        }
      });
      this.setState({ reservations: reservations });
    }
  };

  updateSingleEvent = async () => {
    const profileData = JSON.parse(localStorage.getItem('POINT.profileData'));
    this.setState({ isChangeCancel: false });

    if (profileData && profileData.id) {
      const result = await getClientEvent(this.props.match.params.id, { accessKey: this.state.token });
      if (result && result.id) {
        this.updateSelectedShifts(result);
        this.setState({
          isPrivate: false,
          detailsClient: result
        });
      } else {
        this.setState({ isPrivate: true });
      }
      return result;
    }
    this.setState({ counter: this.state.counter + 1 });
    return false;
  };

  getStatusOrganization = async (event) => {
    if (!event || !event.organization) {
      this.setState({ currentOrganization: null });
      return false;
    }

    const results = await getOrganizationSearch({
      page: 1,
      size: 1000,
      search: event.organization.name,
      requests: true,
      invites: true,
      joined: true
    });

    if (results && results.items) {
      let index = results.items.findIndex(e => e.id === event.organization.id);
      if (index > -1) {
        this.setState({ currentOrganization: results.items[index] });
      } else {
        this.setState({ currentOrganization: null });
      }
    } else {
      this.setState({ currentOrganization: null });
    }
  };

  updateData = async (result) => {
    if (result && result.id) {
      this.setState({ event: result });
      this.updateSelectedShifts(result);
    }
    if (!result || result.error) {
      this.setState({ isPrivate: true });
    }

    if (result && result.title) {
      document.title = `${result.title} | POINT`;
    }
    this.setState({ loading: false });
  }

  async componentDidMount () {
    this.setState({ loading: true });

    if (!this.props.match.params.id || !Number(this.props.match.params.id)) {
      this.props.history.push('/');
      return false;
    }

    const profileData = JSON.parse(localStorage.getItem('POINT.profileData'));

    let accessKey;
    const url_string = window.location.href;
    const url = new URL(url_string);
    accessKey = url.searchParams.get('token');
    this.setState({ token: accessKey });

    if (profileData && profileData.id) {
      onAuthStateChanged(firebaseAuth,  async (user) => {
        if (this.state.firstRender) {
          if (!user) {
            localStorage.removeItem('POINT.profileData');
            const eventShare = await getEventShare(this.props.match.params.id, { accessKey: accessKey });
            await this.updateData(eventShare);
          } else {
            const result = await getClientEvent(this.props.match.params.id, { accessKey: accessKey });
            await this.updateData(result);
            await this.getStatusOrganization(result);
            await this.updateReserve();
          }
          this.setState({ firstRender: false });
        } else {
          this.setState({ loading: false });
        }
      });
    } else {
      const results = await getEventShare(this.props.match.params.id, { accessKey: accessKey });
      await this.updateData(results);
    }
  }

  selectShift = (id, isSelected) => {
    const { selectedShifts, reservations } = this.state;
    const index = selectedShifts.findIndex((el) => el.eventShiftId === id);

    let isReserved = false;
    if (reservations.findIndex(el => el.eventShiftId === id) > -1) {
      isReserved = true;
    }

    let spotsTaken = selectedShifts[index].spotsTaken;
    selectedShifts[index].isAttending = isSelected;
    if (isSelected) {
      selectedShifts[index].spotsTaken = isReserved ? spotsTaken : spotsTaken + 1;
    } else {
      selectedShifts[index].spotsTaken = isReserved ? spotsTaken : spotsTaken - 1;
    }

    this.setState({ selectedShifts });
  };

  closePopup = async (newState) => {
    if (this.state.claimSpotItem && this.state.claimSpotItem.eventShiftId) {
      this.selectShift(this.state.claimSpotItem.eventShiftId, false);
      this.setState({ claimSpotItem: null });
    }
    this.setState(newState);
  };

  showPopupSignIn = () => {
    this.setState({ showSignIn: true });
  };

  showPopupSignUp = () => {
    this.setState({ showSignUp: true });
  };

  claimSpot = async (item) => {
    this.selectShift(item.eventShiftId, true);
    this.setState({ claimSpotItem: item });
    await this.showConfirm();
  };

  showConfirm = async (isCancel, showPopupCancel = false) => {
    this.setState({ isChangeCancel: isCancel || false });
    const res = await getVerificationEmail(this.props.history);

    if (!res.isPrimaryEmailVerified) {
      this.setState({ showEmailVerifyPopUp: true });
    } else if (!res.isCompleteProfile) {
      this.setState({ showCompleteProfilePopUp: true });
    } else {

      const { detailsClient, event } = this.state;
      let singleEvent = {};
      if (event && Object.keys(event).length && event.id) {
        singleEvent = event;
      }
      if (detailsClient && Object.keys(detailsClient).length && detailsClient.id) {
        singleEvent = detailsClient;
      }

      if (singleEvent && singleEvent.source === EVENT_SOURCES.POINT) {
        this.setState({ showConfirmPopup: true });
      } else {
        this.setState({ showInfoPopup: true, showPopupCancel: showPopupCancel });
      }
    }
  };

  logOutUser = async () => {
    this.setState({ loading: true });
    const res = await getEventShare(this.props.match.params.id, { accessKey: this.state.token });
    if (res && res.id) {
      this.updateSelectedShifts(res, true);
      this.setState({ event: res });
    }
    if (!res || res.error) {
      this.setState({ isPrivate: true, event: null, selectedShifts: [] });
    }

    this.setState({
      detailsClient: null,
      reservations: [],
      loading: false,
      currentOrganization: null
    });
  };

  onClickConfirm = async (newState) => {
    this.setState(newState);
    this.setState({ loading: true });

    let token = await customPostToken();

    let shifts = '';
    let accessKey = this.state.token || '';

    if (this.state.selectedShifts?.length) {
      let shiftsIds = [];
      this.state.selectedShifts.forEach((e) => {
        if (e.isAttending) {
          shiftsIds.push(e.eventShiftId);
        }
      });
      shifts = shiftsIds.join(',');
    }

    window.open(`${POINT_DASH_URL}?key=${token}&event_id=${this.props.match.params.id}&shifts=${shifts}&access_key=${accessKey}`, '_self');

    localStorage.removeItem('POINT.profileData');
    localStorage.removeItem('POINT.dictionary');
    FireBaseTools.logoutUser();

    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  };

  render () {
    if (this.state.loading) return waitingResult(true, 'Loading...');

    let isPrivate = this.state.isPrivate;

    const { detailsClient, event } = this.state;
    const profileData = JSON.parse(localStorage.getItem('POINT.profileData'));

    let singleEvent = {};
    if (event && Object.keys(event).length && event.id) {
      singleEvent = event;
    }
    if (detailsClient && Object.keys(detailsClient).length && detailsClient.id && profileData && profileData.id) {
      singleEvent = detailsClient;
    }

    let isSignUp = false;
    if (detailsClient && detailsClient.isAttending && profileData && profileData.id) {
      isSignUp = true;
    }

    let isEnded = false;
    let today = moment().format('x');
    if (singleEvent && singleEvent.endTime && (+singleEvent.endTime < +today)) {
      isEnded = true;
    }


    console.log('this.state.selectedShifts =', this.state.selectedShifts);

    return (
      <Wrapper className={'wrapper'}>
        <ResultsPopup
          resultsPopup={this.state.resultsPopup}
          setInfo={(data) => this.setState(data)}
          resultsByShift={this.state.resultsByShift}
          messageShift={this.state.messageShift}
          isOneShift={singleEvent && singleEvent.shifts && singleEvent.shifts.length === 1}
          isOpportunity={singleEvent.source && singleEvent.source === EVENT_SOURCES.VOLUNTEER_MATCH}
        />
        {singleEvent && singleEvent.id ? (
          <HelmetMetaData
            description={singleEvent.volunteerTasks}
            title={singleEvent.title}
            image={(singleEvent.images && singleEvent.images[0]) || ''}
          />
        ) : (
          <HelmetMetaData title="EVENTS | POINT"/>
        )}
        <Content>
          <SignIn
            location={this.state.location}
            visible={this.state.showSignIn}
            closeForm={() => this.setState({ showSignIn: false })}
            openSignUp={() => this.setState({ showSignIn: false, showSignUp: true })}
            onClickConfirm={() => this.onClickConfirm({ showSignIn: false })}
            setInfo={(newState) => this.setState(newState)}
          />
          <SignUp
            visible={this.state.showSignUp}
            closeForm={() => this.closePopup({ showSignUp: false })}
            showSignIn={() => this.setState({ showSignIn: true, showSignUp: false })}
            onClickConfirm={() => this.onClickConfirm({ showSignUp: false })}
            setInfo={(newState) => this.setState(newState)}
          />
          <SignInOkta
            visible={this.state.showSignInOkta}
            closeForm={() => this.closePopup({ showSignInOkta: false })}
            onClickConfirm={() => this.onClickConfirm({ showSignInOkta: false })}
            setInfo={(newState) => this.setState(newState)}
            fromPopup={this.state.fromPopup}
          />
          <CompleteProfilePopup
            history={this.props.history}
            setData={(data) => this.setState(data)}
            visible={this.state.showCompleteProfilePopUp}
            eventId={this.props.match.params.id}
            token={this.state.token}
          />
          {singleEvent.source && singleEvent.source === EVENT_SOURCES.VOLUNTEER_MATCH ? (
            <InstructionPopup
              showInfoPopup={this.state.showInfoPopup}
              setInfo={(newState) => this.setState(newState)}
              history={this.props.history}
              singleEvent={singleEvent}
              updateEvent={() => this.updateSingleEvent()}
              showPopupCancel={this.state.showPopupCancel}
            />
          ) : null}
          <Modal
            width={styles.modal.size.normal}
            visible={this.state.showEmailVerifyPopUp}
            onClose={() => this.setState({ showEmailVerifyPopUp: false })}
            buttons={[
              {
                onPress: async () => {
                  await resendEmailVerificationEmail({ email: profileData.email });
                  this.setState({ showEmailVerifyPopUp: false });
                },
                value: 'Resend Verification Email',
                variant: 'primary',
                dataTestId: 'verification-modal-button-resend'
              }
            ]}
          >
            <ModalImage src={verification} alt="Verification Email"/>
            <ModalTitle>Verify Your Email</ModalTitle>
            <ModalDesc>We need to make sure you're a real human! Quickly verify your email to finish signing
              up. (Once you've made an account, you don't have to do this again).</ModalDesc>
            <ModalDesc>If you didn't get the email, hit the button below to resend one.</ModalDesc>
          </Modal>
          <ConfirmPopup
            isChangeCancel={this.state.isChangeCancel}
            selectedShifts={this.state.selectedShifts}
            showConfirmPopup={this.state.showConfirmPopup && !isPrivate && !isEnded}
            closePopup={() => this.closePopup({ showConfirmPopup: false })}
            singleEvent={singleEvent}
            onClickConfirm={() => {
              this.updateSingleEvent();
              this.updateReserve();
            }}
            reservations={this.state.reservations}
            setInfo={(data) => this.setState(data)}
          />
          <Container short={isPrivate}>
            <LeftCol id={'content'}>
              {singleEvent.source && singleEvent.source === EVENT_SOURCES.VOLUNTEER_MATCH ? (
                <DetailsOpportunities
                  singleEvent={singleEvent}
                  history={this.props.history}
                  setInfo={(newState) => this.setState(newState)}
                />
              ) : (
                <Details
                  currentOrganization={this.state.currentOrganization}
                  singleEvent={singleEvent}
                  history={this.props.history}
                  isPrivate={isPrivate}
                  updateStatus={() => this.getStatusOrganization(singleEvent)}
                  setInfo={(newState) => this.setState(newState)}
                />
              )}
            </LeftCol>
            <RightCol>
              <RightBar
                history={this.props.history}
                singleEvent={singleEvent}
                bottomBoxFixed={this.state.bottomBoxFixed}
                selectedShifts={this.state.selectedShifts}
                selectShift={this.selectShift}
                showPopupSignUp={this.showPopupSignUp}
                showPopupSignIn={this.showPopupSignIn}
                showConfirm={this.showConfirm}
                logoutUser={() => this.logOutUser()}
                isSignUp={isSignUp}
                isPrivate={isPrivate}
                token={this.state.token}
                reservations={this.state.reservations}
                claimSpot={this.claimSpot}
                onClickConfirm={() => this.onClickConfirm()}
              />
            </RightCol>
          </Container>
        </Content>
      </Wrapper>
    );
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(EventDetails);

EventDetails.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      id: PropTypes.string
    }),
    path: PropTypes.string,
    url: PropTypes.string
  }).isRequired,
  history: PropTypes.object.isRequired,
};

EventDetails.defaultProps = {
  event: null,
  detailsClient: null,
};

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: ${props => props.short ? 'flex-start' : 'inherit'}
`;
const LeftCol = styled.div`
  display: flex;
  width: calc(100% * 2 / 3);
  padding: 0;
  @media screen and (max-width: 1020px) {
    width: 100%;
  }
`;
const RightCol = styled.div`
  display: flex;
  width: calc(100% * 1 / 3);
  padding: 0;
  @media screen and (max-width: 1020px) {
    width: 100%;
  }
`;
const ModalDesc = styled(P2)`
  font-family: ${styles.fonts.family.primaryBook};
  color: ${styles.colors.medium};
  margin-bottom: 16px;
`;
