import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const CoHostEventConfirmedIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M10,20H3c-0.6,0-1-0.4-1-1v-9h16v3.1c0,0.6,0.4,1,1,1s1-0.4,1-1V5c0-1.7-1.3-3-3-3h-2V1c0-0.6-0.4-1-1-1s-1,0.4-1,1v1H7V1c0-0.6-0.4-1-1-1S5,0.4,5,1v1H3C1.3,2,0,3.3,0,5v14c0,1.7,1.3,3,3,3h7c0.6,0,1-0.4,1-1S10.6,20,10,20z M3,4h2v1c0,0.6,0.4,1,1,1s1-0.4,1-1V4h6v1c0,0.6,0.4,1,1,1s1-0.4,1-1V4h2c0.6,0,1,0.4,1,1v3H2V5C2,4.4,2.4,4,3,4z"/>
            <path d="M6.4,13.1c1,0,1.3,0.7,1.4,1.1L9,13.8c-0.1-0.8-0.9-1.9-2.6-1.9c-1.6,0-2.9,1.2-2.9,3c0,1.7,1.3,2.9,2.9,2.8c1.8,0,2.5-1.2,2.7-2l-1.2-0.3c-0.1,0.4-0.5,1.1-1.5,1.1c-0.8,0-1.6-0.6-1.6-1.7C4.8,13.6,5.6,13.1,6.4,13.1z"/>
            <path d="M23.7,13.4c-0.4-0.4-1-0.4-1.4,0l-6.2,6.2l-2.4-2.4c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l3.1,3.1c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l6.9-6.9C24.1,14.4,24.1,13.8,23.7,13.4z"/>
        </Icon>
    );

export default withTheme(CoHostEventConfirmedIcon);
