import styled from 'styled-components';
import styles from '../../constants/styles';

export const H1 = styled.h1`
    font-family: ${styles.fonts.family.primaryBold};
    font-size: 32px;
    line-height: 40px;
`;

export const H2 = styled(H1.withComponent('h2'))`
    font-size: 24px;
    line-height: 28px;
`;

export const H3 = styled(H1.withComponent('h3'))`
    font-size: 20px;
    line-height: 24px;
`;

export const H4 = styled(H1.withComponent('h4'))`
    font-size: 16px;
    line-height: 20px;
`;

export const H5 = styled(H1.withComponent('h5'))`
    font-size: 14px;
    line-height: 18px;
`;

export const H6 = styled(H1.withComponent('h6'))`
    font-size: 12px;
    line-height: 16px;
`;
