import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const SignedFormIcon = props => (
    <Icon {...props} viewBox={'0 0 24 24'}>
      <path d="M8.41112 13.944L8.14705 15.2646C8.0771 15.6144 8.38555 15.9229 8.73541 15.8529L10.056 15.5888C10.2496 15.5501 10.4274 15.4549 10.567 15.3153L15.2929 10.5895C15.6834 10.1989 15.6834 9.56577 15.2929 9.17524L14.8247 8.70709C14.4342 8.31657 13.801 8.31658 13.4105 8.70709L8.68461 13.4329C8.54499 13.5726 8.44983 13.7504 8.41112 13.944Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8 4C6.89543 4 6 4.89543 6 6V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V6C18 4.89543 17.1046 4 16 4H8ZM4 6C4 3.79086 5.79086 2 8 2H16C18.2091 2 20 3.79086 20 6V18C20 20.2091 18.2091 22 16 22H8C5.79086 22 4 20.2091 4 18V6Z" />
    </Icon>
);

export default withTheme(SignedFormIcon);
