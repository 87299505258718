import React from 'react';
import styled from 'styled-components';
import { TitlePage } from '../../../../components/Header';

const Header = () => {
  return (
    <Wrapper>
      <TitlePage text={'Event Details'}/>
    </Wrapper>
  );
};

export default Header;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  @media screen and (max-width: 991px) {
    align-items: flex-start;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    min-width: inherit;
    flex-wrap: wrap;
    & > div:first-of-type {
      max-width: 100%;
    }
  }
`;
