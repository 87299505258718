import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../constants/styles';
import { H2, P1 } from '../Typography';
import { CloseIcon } from '../Icons/General';
import { Button } from '../Buttons';

class Modal extends Component {
  constructor (props) {
    super(props);
    this.state = {
      height: window.innerHeight
    };
  }

  render () {
    if (this.props.visible) {
      return (
          <PageContainer dataTestId={this.props.dataTestId || 'modal-container'}>
            <PageBg>
              <ModalBlock
                  width={this.props.width ? this.props.width : styles.modal.size.medium}
                  height={this.state.height}
                  className={this.props.className}
              >
                <Close onClick={this.props.onClose} data-testid={'close-modal'}>
                  <CloseIcon
                      width={20}
                      height={20}
                      defaultFill={styles.colors.silver}
                  />
                </Close>
                {this.props.title ? <ModalHeader>{this.props.title}</ModalHeader> : null}
                <ModalBody>
                  {this.props.children}
                  <BlockPadding />
                </ModalBody>

                {(this.props.buttons && Array.isArray(this.props.buttons) && this.props.buttons.length > 0) ?
                    (<ModalFooter>
                      {this.props.buttons.map((button) => (
                          <MarginBlock>
                            <Button
                                size={'lg'}
                                value={button.value ? button.value : 'Done'}
                                variant={button.variant ? button.variant : 'primary'}
                                onClick={button.onPress}
                                Icon={button.Icon}
                                isLoading={!!button.isLoading}
                                dataTestId={`modal-button-${(button.value || 'done').toLowerCase()}`}
                                isMinWidth={true}
                                disabled={button.disabled}
                                hint={button.hint}
                            />
                          </MarginBlock>
                      ))}
                    </ModalFooter>) : null}
              </ModalBlock>
            </PageBg>
          </PageContainer>
      );
    }
    return false;
  }
}


Modal.propTypes = {
  title: PropTypes.string,
  width: PropTypes.number,
  Icon: PropTypes.element,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  buttons: PropTypes.array,
  dataTestId: PropTypes.string,
  className: PropTypes.string
};

Modal.defaultProps = {
  title: null,
  width: styles.modal.size.medium,
  buttons: [ {
    onPress: () => null,
    disabled: false,
    value: '',
    variant: 'primary',
    isLoading: false,
    hint: null
  } ],
  Icon: null,
  dataTestId: 'modal-container',
  className: ''
};
const PageContainer = styled.div`
  display: block;
  position: absolute;
`;
const PageBg = styled.div`
  background-color: ${styles.colors.dark}99;
  position: fixed;
  z-index: 101;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Close = styled.div`
  cursor: pointer;
  position: absolute;
  top: 32px;
  right: 32px;
  z-index: 2;
`;
const ModalBlock = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: auto;
  width: ${props => props.width ? props.width : styles.modal.size.medium}px;
  max-width: calc(100% - 60px);
  max-height: calc(100% - 60px);
  border-radius: 12px;
  background-color: white;
  z-index: 101;
  margin: 30px;
  text-align: left;
  padding: 0;
  overflow: hidden;
  @media screen and (max-width: 550px) {
    max-height: ${props => props.height ? `${props.height - 60}px` : 'calc(100% - 60px)'};
  }
  @media screen and (max-width: 425px) {
    max-width: calc(100% - 20px);
    margin: 30px 10px;
  }
  &.transparent-modal {
    background: transparent;
    ${Close} {
      right: 120px;
      svg {
        g { fill: white; }
      }
    }
  }
`;
const ModalHeader = styled(H2)`
  padding: 32px 60px 0 32px;
  margin-bottom: 0;
  color: ${styles.colors.dark};
  font-family: ${styles.fonts.family.primaryBold};
  word-break: break-word;
`;
const ModalImage = styled.img`
  min-width: 64px;
  min-height: 64px;
  width: 64px;
  height: 64px;
  margin-top: 0;
  margin-bottom: 16px;
`;
const ModalTitle = styled(H2)`
  margin-bottom: 8px;
`;
const ModalDesc = styled(P1)`
  font-family: ${styles.fonts.family.primaryBook};
  color: ${styles.colors.medium};
  margin-bottom: 0;
`;
const ModalParagraph = styled(ModalDesc)`
  margin-bottom: 8px;
`;
const ModalBody = styled.div`
  padding: 32px 32px 0;
  font-family: ${styles.fonts.family.primaryMedium};
  font-size: 16px;
  line-height: 24px;
  color: ${styles.colors.medium};
  display: flex;
  flex-direction: column;
  overflow: auto;
`;
const ModalFooter = styled.div`
  background: ${styles.colors.mostlyWhite};
  padding: 17px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  min-height: min-content;

  @media screen and (max-width: 425px) {
    flex-direction: column-reverse;
  }
`;
const MarginBlock = styled.div`
  margin: 7px 8px 8px 7px;
  @media screen and (max-width: 425px) {
    width: calc(100% - 15px);
    .btn {
      width: 100%;
    }
  }
`;
const BlockPadding = styled.div`
  display: flex;
  width: 100%;
  height: 32px;
  min-height: 32px;
`;

export {
  Modal,
  ModalImage,
  ModalTitle,
  ModalDesc,
  ModalParagraph
};
