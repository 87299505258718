import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const UnlockEmptyIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M18,19c0,0.6-0.4,1-1,1H7c-0.6,0-1-0.4-1-1v-6c0-0.6,0.4-1,1-1h10
	c0.6,0,1,0.4,1,1V19z M17,10h-7V6c0-1.1,0.9-2,2-2s2,0.9,2,2c0,0.6,0.4,1,1,1s1-0.4,1-1c0-2.2-1.8-4-4-4S8,3.8,8,6v4H7
	c-1.7,0-3,1.3-3,3v6c0,1.7,1.3,3,3,3h10c1.7,0,3-1.3,3-3v-6C20,11.3,18.7,10,17,10z"/>
        </Icon>
    );

export default withTheme(UnlockEmptyIcon);
