import React from 'react';
import { withTheme } from 'styled-components';
import Icon from './components/Icon';

const AdminsMenu = props => (
    <Icon {...props}>
        <path d="m12 23c-.2 0-.3 0-.4-.1-.4-.2-8.6-4.4-8.6-10.9v-7c0-.4.3-.8.6-.9l8-3c.2-.1.5-.1.7 0l8 3c.4.1.7.5.7.9v7c0 6.5-8.2 10.7-8.6 10.9-.1.1-.2.1-.4.1zm-7-17.3v6.3c0 4.5 5.4 7.9 7 8.9 1.6-.9 7-4.3 7-8.9v-6.3l-7-2.6z"/>
        <path d="m6.8 19.1c-.2 0-.3 0-.5-.1-.5-.3-.7-.9-.4-1.4 1.2-2.2 3.6-3.6 6.1-3.6s4.9 1.4 6.1 3.6c.3.5.1 1.1-.4 1.4s-1.1.1-1.4-.4c-.8-1.6-2.5-2.6-4.3-2.6s-3.5 1-4.4 2.6c-.1.3-.5.5-.8.5z"/>
        <path d="m12 12.5c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5 3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5zm0-5c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5-.7-1.5-1.5-1.5z"/>
    </Icon>
);

export default withTheme(AdminsMenu);
