import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const UnArchiveRegistryIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M20,22H4c-1.1,0-2-0.9-2-2v-7c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2l3-9c0.2-0.5,0.7-0.8,1.3-0.6c0.5,0.2,0.8,0.7,0.6,1.3
		L4.4,12H8c0.6,0,1,0.4,1,1c0,1.7,1.3,3,3,3s3-1.3,3-3c0-0.6,0.4-1,1-1h3.6l-2.6-7.7c-0.2-0.5,0.1-1.1,0.6-1.3
		c0.5-0.2,1.1,0.1,1.3,0.6l3,9c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2v7C22,21.1,21.1,22,20,22z M4,14v6h16v-6h-3.1
		c-0.5,2.3-2.5,4-4.9,4c-2.4,0-4.4-1.7-4.9-4H4z"/>
            <path d="M12,12c-0.6,0-1-0.4-1-1V5.4L9.7,6.7c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4l3-3c0.1-0.1,0.2-0.1,0.3-0.2c0,0,0,0,0,0
		c0,0,0,0,0,0C11.7,2,11.8,2,12,2h0c0,0,0,0,0,0h0c0,0,0,0,0,0l0,0c0,0,0,0,0,0s0,0,0,0c0.1,0,0.3,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2
		l3,3c0.4,0.4,0.4,1,0,1.4s-1,0.4-1.4,0L13,5.4V11C13,11.6,12.6,12,12,12z"/>
        </Icon>
    );

export default withTheme(UnArchiveRegistryIcon);
