import React from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import styles from '../../../constants/styles';
import { LocationSolidIcon } from '../../../components/Icons/General';
import { Button } from '../../../components/Buttons';
import { Input } from '../../../components/Fields';
import Tip from '../../../components/Tip';
import ErrorMessage from '../../../components/ErrorMessage';

const myStyles = {
  input: {
    flex: 1,
    fontSize: '14px',
    cursor: 'default',
    outline: 'none',
    border: 'none',
    background: 'none',
    padding: '13px 16px',
    fontFamily: styles.fonts.family.primaryBook,
    color: styles.colors.dark,
    focus: {
      outline: 'none'
    }
  },
  autocompleteItem: { color: styles.colors.medium },
  autocompleteItemActive: { color: styles.colors.dark },
  autocompleteContainer: { zIndex: 100, backgroundColor: 'red' }
};

class Location extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      geocodeResults: null,
      loading: false,
      isSearching: false
    };
  }

  handleSelect = (address) => {
    const { updateParentState } = this.props;
    updateParentState({ address });
    this.setState({ loading: true });

    if (this.props.changeFullAddress) {
      geocodeByAddress(address)
        .then(results => {
          geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
              this.props.changeFullAddress(results[0].formatted_address, results[0].address_components, {
                lat: latLng.lat ? +latLng.lat.toFixed(7) : latLng.lat,
                lng: latLng.lng ? +latLng.lng.toFixed(7) : latLng.lng
              });
            });
        });

    } else {
      geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          this.setState({
            geocodeResults: this.renderGeocodeSuccess(),
            loading: false,
            isSearching: false
          });
          updateParentState({
            lat: lat ? +lat.toFixed(7) : lat,
            lng: lng ? +lng.toFixed(7) : lng
          });
        })
        .catch((error) => {
          this.setState({
            geocodeResults: this.renderGeocodeFailure(error),
            loading: false
          });
          updateParentState({
            lat: null,
            lng: null,
            address: ''
          });
        });
    }
  };

  handleChange = (address) => {
    this.props.updateParentState({ address });
    if (address.length < 1) {
      return this.setState({ isSearching: false });
    }
    return this.setState({ isSearching: true });
  };

  renderGeocodeFailure = err => (
    <div className="alert alert-danger" role="alert">
      <strong>Error!</strong>
      {' '}
      {err}
    </div>
  );

  renderGeocodeSuccess = () => (<GeocodeSuccess>Success! Your location is valid!</GeocodeSuccess>);

  renderMessage = () => {
    if (this.props.errorMessage) {
      return <ErrorMessage
        text={this.props.errorMessage}
        dataTestId={(this.props.dataTestId || '') + '-error'}
      />;
    }
    if (this.props.warningMessage) {
      return <ErrorMessage
        text={this.props.warningMessage}
        dataTestId={(this.props.dataTestId || '') + '-warning'}
      />;
    }
    return null;
  };

  renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions }) => {
    const {
      disabled,
      error,
      errorBorder,
      success,
      errorMessage,
      warningMessage,
      size,
      placeHolder,
      dataTestId = null,
      hideLabel = false
    } = this.props;
    return (
      <BlockRoot
        className="autocomplete-root"
        disabled={disabled}
        success={success}
        errorMessage={errorMessage}
        error={error}
        errorBorder={errorBorder}
        warningMessage={warningMessage}
      >
        <Input
          placeholder={placeHolder || 'e.g., 100 John Glenn Way, Columbus, Ohio 43215'}
          name={'address'}
          label={!hideLabel ? (this.props.label || 'Address') : ''}
          size={size}
          dataTestId={dataTestId}
          {...getInputProps()}
          disabled={this.props.disabled}
        />
        <DropdownContainer className="autocomplete-dropdown-container">
          {suggestions.map(suggestion => (
            <div key={suggestion.description} className="suggestion" {...getSuggestionItemProps(suggestion)}>
              <Text>{suggestion.description}</Text>
            </div>
          ))}
        </DropdownContainer>
      </BlockRoot>
    );
  };

  renderBtn = () => this.props.isMap ? (
    <ButtonMap
      size="lg"
      variant="secondary"
      Icon={<LocationSolidIcon width={20} height={20} defaultFill={styles.colors.medium}/>}
      onClick={() => this.props.openMap()}
      dataTestId={'events-button-location'}
      disabled={this.props.disabled}
    />
  ) : null;

  render () {
    const { address, hintMessage, dataTestId = null } = this.props;

    return (
      <LocationContainer isSearching={this.state.isSearching}>
        <Block marginBottom={hintMessage}>
          <PlacesAutocomplete
            data-testid={dataTestId}
            value={address}
            onChange={this.handleChange}
            onSelect={this.handleSelect}
            styles={myStyles}
            shouldFetchSuggestions={({ value }) => value.length > 2}
            highlightFirstSuggestion
            renderFooter={() => null}
            autoComplete="off"
          >
            {this.renderFunc}
          </PlacesAutocomplete>
          {this.renderBtn()}
        </Block>
        {hintMessage ? (<Tip text={hintMessage}/>) : ''}
        {this.renderMessage()}
      </LocationContainer>
    );
  }
}

Location.propTypes = {
  errorMessage: PropTypes.string,
  size: PropTypes.string,
  maxLength: PropTypes.string,
  updateParentState: PropTypes.func.isRequired,
  warningMessage: PropTypes.string,
  address: PropTypes.string.isRequired,
  isMap: PropTypes.bool,
  hideLabel: PropTypes.bool,
  openMap: PropTypes.func
};

Location.defaultProps = {
  errorMessage: '',
  maxLength: '',
  warningMessage: '',
  size: '',
  isMap: false,
  openMap: null
};

export default Location;

const LocationContainer = styled.div`
  .autocomplete-dropdown-container {
    z-index: 3;
  }
`;
const Text = styled.p`
  min-height: 24px;
  font-family: ${styles.fonts.family.primaryBook};
  font-size: 14px;
  color: #53627C;
  margin: 0 8px;
  padding: 10px;
  cursor: pointer;
  flex: 1;

  &:hover {
    background-color: rgb(250, 250, 250);
  }
`;
const GeocodeSuccess = styled.div`
  margin: 0 auto;
  color: ${styles.colors.primary};
`;
const DropdownContainer = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 9;
  width: 100%;
  border-radius: 0 0 10px 10px;
  background-color: white !important;
  box-shadow: 0 0 0 0 rgba(10, 31, 68, 0.08), 0 3px 14px 0 rgba(10, 31, 68, 0.1);
  border: none !important;

  & > div {
    padding: 0 8px;

    &:first-of-type {
      margin-top: 8px;
    }

    &:last-of-type {
      margin-bottom: 8px;
    }
  }

  p {
    border-radius: 2px;
    padding: 8px;
    min-height: 22px;
    font-family: ${styles.fonts.family.primaryBook};
    font-size: 14px;
    color: ${styles.colors.medium};
    margin: 0;
  }
`;
const BlockRoot = styled.div`
  width: 100%;
  position: relative;
`;
const Block = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${props => props.marginBottom ? '8px' : 0};
`;
const ButtonMap = styled(Button)`
  margin-left: 10px;
  padding: 8px 14px;

  span.btn-icon {
    margin-left: 0;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;
