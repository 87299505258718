import React, { Component } from 'react';
import { withRouter } from 'react-router';
import styled, { ThemeProvider } from 'styled-components';
import styles from './constants/styles';
import MainRoute from './routes';
import { HelmetMetaData } from './containers/Events/components';
import { firebaseAuth } from './api/firebase';
import { onAuthStateChanged } from 'firebase/auth';

class App extends Component {
  constructor (props) {
    super(props);
    this.state = {
      firstRender: true
    }
    this.setRoute = this.setRoute.bind(this);
  }

  componentDidMount () {
    onAuthStateChanged(firebaseAuth,  async (user) => {
      if (!user && this.state.firstRender) {
        localStorage.removeItem('POINT.profileData');
        this.setState({ firstRender: false });
      }
    });
  }

  setRoute = Component => (
    <ThemeProvider theme={styles} styles={styles}>
      <HelmetMetaData/>
      <Wrapper>
        {Component}
      </Wrapper>
    </ThemeProvider>
  );

  render () {
    return this.setRoute(<MainRoute/>);
  }
}

export default withRouter(App);

const Wrapper = styled.div`
  height: 100%;
`;
