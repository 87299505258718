import FireBaseTools from '../api/firebase';
import { message } from 'antd';
import axios from 'axios';
import { API_PATH } from './types';
import { HTTP_METHODS } from '../constants';

const buildRequest = async ({
  method,
  url,
  apiPath = null,
  queryData,
  bodyData,
  isAuth = true,
  dispatchSuccess,
  dispatchError,
  actionsWhenSuccess = false,
  actionsWhenError = false,
  showNotifications = true,
  returnErrorMessage = true,
  messages = {
    error: 'Argh! Error! Please, try again later.'
  }
}) => {
  try {
    let response, user, userToken;
    let config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };
    let fUrl = url;

    if (queryData) {
      config = Object.assign({}, config, { params: queryData });
    }

    if (isAuth) {

      const profileData = await JSON.parse(localStorage.getItem('POINT.profileData'));
      const oid = profileData && profileData.activeOrganization && profileData.activeOrganization.id ? profileData.activeOrganization.id : 0;

      user = await FireBaseTools.fetchUser();
      userToken = await user.getIdToken();
      config.headers = Object.assign({}, config.headers, { 'X-Point-Auth': userToken });

      if (profileData && profileData.network && profileData.network.id > 0) {
        fUrl = `networks/${profileData.network.id}/${fUrl}`;
      }

      if (oid) {
        fUrl = fUrl.replace(':oid', oid);
      }
    }

    const apiPathConstructor = apiPath || `${API_PATH}/${fUrl}`;
    switch (method) {
      case HTTP_METHODS.GET:
        response = await axios.get(apiPathConstructor, config);
        break;
      case HTTP_METHODS.POST:
        response = await axios.post(apiPathConstructor, bodyData, config);
        break;
      case HTTP_METHODS.PUT:
        response = await axios.put(apiPathConstructor, bodyData, config);
        break;
      case HTTP_METHODS.DELETE:
        response = await axios.delete(apiPathConstructor, config);
        break;
      default:
        response = await axios.get(apiPathConstructor, config);
    }

    if (dispatchSuccess) {
      dispatchSuccess(response.data);
    }

    if (actionsWhenSuccess) {
      actionsWhenSuccess({
        authToken: userToken ? userToken : null,
        responseData: response.data
      });
    }

    if (messages && messages.success && showNotifications) {
      message.success(messages.success, 6);
    }

    if (response.data === 'Ok') {
      return true;
    }
    return response.data;
  } catch (e) {
    if (e.response) {
      switch (e.response.status) {
        case 400:
          if (e.response.data) {
            if ((typeof e.response.data) === 'string' && showNotifications) {
              message.error(e.response.data);
            }
            if ((typeof e.response.data) !== 'string' && e.response.data.error && showNotifications) {
              message.error(e.response.data.error);
            }
          }
          break;
        case 401:
          if (showNotifications) {
            message.error('Unauthorized');
          }
          break;
        case 403:
          if (e.response.data && showNotifications) {
            message.error(e.response.data);
          }
          break;
        case 404:
          if (showNotifications) {
            message.error('NotFound');
          }
          break;
        case 500:
          if (showNotifications) {
            message.error('Server Error');
          }
          break;
        default:
          if (showNotifications) {
            message.error(messages.error);
          }
      }
      if (dispatchError) {
        dispatchError(e.response.data);
      }
    }
    if (returnErrorMessage) {
      if (e.response && e.response.data){
        return e.response.data;
      }
      return e.response;
    }
    if (actionsWhenError) {
      actionsWhenError(e);
    }
    return false;
  }
};
export default buildRequest;
