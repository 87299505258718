import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const VirusIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M21.2,10.5c-0.4,0-0.8,0.3-0.8,0.8H19c-0.1-1.4-0.7-2.6-1.5-3.7l1.1-1.1l0,0c0.3,0.3,0.8,0.3,1.1,0s0.3-0.8,0-1.1l-1.1-1.1
	c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1l-1.1,1.1c-1-0.8-2.3-1.4-3.6-1.5V3.5h0c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8
	h-1.5c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.7,0.7,0.8v1.6C9.9,5.2,8.6,5.7,7.6,6.6L6.5,5.5l0,0c0.3-0.3,0.3-0.8,0-1.1s-0.8-0.3-1.1,0
	L4.4,5.5c-0.3,0.3-0.3,0.8,0,1.1s0.8,0.3,1,0l1.1,1.1c-0.8,1-1.4,2.3-1.5,3.6H3.5c0-0.4-0.3-0.8-0.8-0.8S2,10.8,2,11.2v1.5
	c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8H5c0.2,1.4,0.7,2.6,1.5,3.6l-1.1,1.1c-0.3-0.3-0.8-0.3-1.1,0s-0.3,0.8,0,1.1l1.1,1.1
	c0.3,0.3,0.8,0.3,1.1,0s0.3-0.8,0-1.1l0,0l1.1-1.1c1,0.8,2.3,1.4,3.6,1.5v1.5c-0.4,0-0.7,0.3-0.7,0.8s0.3,0.8,0.8,0.8h1.5
	c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8h0V19c1.4-0.1,2.6-0.7,3.6-1.5l1.1,1.1c-0.3,0.3-0.3,0.8,0,1c0.3,0.3,0.8,0.3,1.1,0l1.1-1.1
	c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.8-0.3-1.1,0l0,0l-1.1-1.1c0.8-1,1.4-2.3,1.5-3.6h1.5c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-1.5
	C22,10.8,21.7,10.5,21.2,10.5z M13.8,8c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S13.2,8,13.8,8z M12,13c-0.6,0-1-0.4-1-1s0.4-1,1-1
	s1,0.4,1,1S12.6,13,12,13z M10.2,8c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S9.7,8,10.2,8z M8.5,13c-0.6,0-1-0.4-1-1s0.4-1,1-1
	s1,0.4,1,1S9.1,13,8.5,13z M10.2,16c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S10.8,16,10.2,16z M13.8,16c-0.6,0-1-0.4-1-1s0.4-1,1-1
	s1,0.4,1,1S14.3,16,13.8,16z M14.5,12c0-0.6,0.4-1,1-1c0.5,0,1,0.4,1,1s-0.5,1-1,1S14.5,12.6,14.5,12z"/>
        </Icon>
    );

export default withTheme(VirusIcon);
