import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const OptionsHorizontalIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <circle cx="12" cy="12" r="2"/>
            <circle cx="5" cy="12" r="2"/>
            <circle cx="19" cy="12" r="2"/>
        </Icon>
    );

export default withTheme(OptionsHorizontalIcon);
