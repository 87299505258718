import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const GoalIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M1,19c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l7.5-7.5c0.4-0.4,1-0.4,1.4,0l4.3,4.3l8.8-8.8
	c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-9.5,9.5c-0.4,0.4-1,0.4-1.4,0l-4.3-4.3l-6.8,6.8C1.5,18.9,1.3,19,1,19z"/>
            <path d="M23,13c-0.6,0-1-0.4-1-1V7h-5c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1v6C24,12.6,23.6,13,23,13z"/>
        </Icon>
    );

export default withTheme(GoalIcon);
