import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const CoHostEventRejectedIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M11,22H3c-1.7,0-3-1.3-3-3V5c0-1.7,1.3-3,3-3h14c1.7,0,3,1.3,3,3v7.1c0,0.6-0.4,1-1,1s-1-0.4-1-1V5c0-0.6-0.4-1-1-1H3C2.4,4,2,4.4,2,5v14c0,0.6,0.4,1,1,1h8c0.6,0,1,0.4,1,1S11.6,22,11,22z"/>
            <path d="M14,6c-0.6,0-1-0.4-1-1V1c0-0.6,0.4-1,1-1s1,0.4,1,1v4C15,5.6,14.6,6,14,6z"/>
            <path d="M6,6C5.4,6,5,5.6,5,5V1c0-0.6,0.4-1,1-1s1,0.4,1,1v4C7,5.6,6.6,6,6,6z"/>
            <path d="M19,10H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h18c0.6,0,1,0.4,1,1S19.6,10,19,10z"/>
            <path d="M3.5,14.9c0-1.8,1.3-3,2.9-3c1.7,0,2.5,1.1,2.6,1.9l-1.2,0.4c-0.1-0.4-0.4-1.1-1.4-1.1c-0.8,0-1.6,0.5-1.6,1.7c0,1.1,0.8,1.7,1.6,1.7c1,0,1.4-0.7,1.5-1.1l1.2,0.3c-0.2,0.8-0.9,2-2.7,2C4.8,17.8,3.5,16.6,3.5,14.9z"/>
            <path d="M14.1,24c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l8.9-8.9c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-8.9,8.9C14.6,23.9,14.4,24,14.1,24z"/>
            <path d="M23,24c-0.3,0-0.5-0.1-0.7-0.3l-8.9-8.9c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l8.9,8.9c0.4,0.4,0.4,1,0,1.4C23.5,23.9,23.3,24,23,24z"/>
        </Icon>
    );

export default withTheme(CoHostEventRejectedIcon);
