import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../constants/styles';

const Jobs = (props) => (
    <Container isActive={props.isActive}>
      <svg
          width="20px"
          height="20px"
          id="jobs"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          enableBackground="new 0 0 24 24"
          xmlSpace="preserve"
      >
        <g id="mask">
          <path fill={props.isActive ? styles.colors.causes.jobs : styles.colors.silver} d="M19,7h-3V5.5C16,4.1,14.9,3,13.5,3h-3C9.1,3,8,4.1,8,5.5V7H5
	c-1.7,0-3,1.3-3,3v8c0,1.7,1.3,3,3,3h14c1.7,0,3-1.3,3-3v-8C22,8.3,20.7,7,19,7z M14,7h-4V5.5C10,5.2,10.2,5,10.5,5h3
	C13.8,5,14,5.2,14,5.5V7z"/>
        </g>
      </svg>
    </Container>
);

export default Jobs;

Jobs.propTypes = {
  isActive: PropTypes.bool
};

Jobs.defaultProps = {
  isActive: true
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: ${props => props.isActive ? `${styles.colors.causes.jobs}1f` : styles.colors.mostlyWhite};
    border-radius: 20px;
`;

