import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const UsersListIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path className="st0" d="M17,13h-6c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1S17.6,13,17,13z M17,8h-6c-0.6,0-1-0.4-1-1s0.4-1,1-1h6
	c0.6,0,1,0.4,1,1S17.6,8,17,8z"/>
            <circle className="st0" cx="7" cy="17" r="1"/>
            <circle className="st0" cx="7" cy="7" r="1"/>
            <circle className="st0" cx="7" cy="12" r="1"/>
            <g>
                <circle className="st0" cx="20" cy="17.2" r="1.8"/>
            </g>
            <g>
                <circle className="st0" cx="20" cy="17.2" r="1.8"/>
            </g>
            <g>
                <path className="st0" d="M16,24c0-2.2,1.8-4,4-4s4,1.8,4,4H16z"/>
            </g>
            <path className="st0"
                  d="M16.2,17.2c0-0.4,0.1-0.8,0.2-1.2H11c-0.6,0-1,0.4-1,1s0.4,1,1,1h5.3C16.2,17.7,16.2,17.5,16.2,17.2z"/>
            <g>
                <path className="st0" d="M14.8,21H5c-1.1,0-2-0.9-2-2V5c0-1.1,0.9-2,2-2h14c1.1,0,2,0.9,2,2v8.6c0.8,0.2,1.5,0.7,2,1.3V5
		c0-2.2-1.8-4-4-4H5C2.8,1,1,2.8,1,5v14c0,2.2,1.8,4,4,4h9.1C14.2,22.3,14.5,21.6,14.8,21z"/>
            </g>
        </Icon>
    );

export default withTheme(UsersListIcon);
