import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const CompaniesIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M19,22H5c-1.7,0-3-1.3-3-3V9c0-1.7,1.3-3,3-3h14c1.7,0,3,1.3,3,3v10C22,20.7,20.7,22,19,22z M5,8
		C4.4,8,4,8.4,4,9v10c0,0.6,0.4,1,1,1h14c0.6,0,1-0.4,1-1V9c0-0.6-0.4-1-1-1H5z"/>
            <path d="M16,8c-0.6,0-1-0.4-1-1V5c0-0.6-0.4-1-1-1h-4C9.4,4,9,4.4,9,5v2c0,0.6-0.4,1-1,1S7,7.6,7,7V5
		c0-1.7,1.3-3,3-3h4c1.7,0,3,1.3,3,3v2C17,7.6,16.6,8,16,8z"/>
        </Icon>
    );
export default withTheme(CompaniesIcon);
