import React from 'react';
import { withTheme } from 'styled-components';
import Icon from './components/Icon';

const SupportMenu = props => (
        <Icon {...props}>
            <path d="M19.8,4.2C19.8,4.2,19.7,4.2,19.8,4.2C17.7,2.2,15,1,12,1C9,1,6.3,2.2,4.3,4.2L4.2,4.2c0,0,0,0-0.1,0.1
	C2.2,6.3,1,9,1,12c0,3,1.2,5.7,3.2,7.7l0.1,0.1c0,0,0,0,0.1,0c2,2,4.7,3.2,7.7,3.2c3,0,5.7-1.2,7.7-3.2l0.1-0.1c0,0,0,0,0.1-0.1
	c2-2,3.2-4.7,3.2-7.7C23,9,21.8,6.3,19.8,4.2L19.8,4.2z M12,3c2.1,0,4.1,0.7,5.6,2l-2.9,2.9C14,7.3,13,7,12,7c-1,0-2,0.3-2.8,0.8
	L6.4,5C7.9,3.7,9.9,3,12,3z M15,12c0,1.7-1.3,3-3,3s-3-1.3-3-3s1.3-3,3-3S15,10.3,15,12z M3,12c0-2.1,0.7-4.1,2-5.6l2.9,2.9
	C7.3,10,7,11,7,12s0.3,2,0.8,2.8L5,17.6C3.7,16.1,3,14.1,3,12z M12,21c-2.1,0-4.1-0.7-5.6-2l2.9-2.9C10,16.7,11,17,12,17
	c1,0,2-0.3,2.8-0.8l2.9,2.9C16.1,20.3,14.1,21,12,21z M19,17.6l-2.9-2.9C16.7,14,17,13,17,12c0-0.9-0.3-1.8-0.7-2.5l-0.1-0.2
	l2.1-2.1L19,6.4c1.2,1.5,2,3.5,2,5.6C21,14.1,20.3,16.1,19,17.6z"/>
        </Icon>
    );

export default withTheme(SupportMenu);
