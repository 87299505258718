import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const OnGoingIcon = props => (
  <Icon {...props} viewBox={'0 0 24 24'}>
    <path d="M21 3C21.5523 3 22 3.44772 22 4V10C22 10.5523 21.5523 11 21 11H15C14.4477 11 14 10.5523 14 10C14 9.44772 14.4477 9 15 9H20V4C20 3.44772 20.4477 3 21 3Z"/>
    <path d="M3 21C2.44772 21 2 20.5523 2 20L2 14C2 13.4477 2.44772 13 3 13L9 13C9.55229 13 10 13.4477 10 14C10 14.5523 9.55229 15 9 15L4 15L4 20C4 20.5523 3.55228 21 3 21Z"/>
    <path d="M7.42133 2.82952C8.65192 2.2826 9.98358 2 11.3302 2H11.3978C14.6089 2 17.6223 3.55075 19.4887 6.16371L21.8137 9.41876C22.1347 9.86817 22.0306 10.4927 21.5812 10.8137C21.1318 11.1347 20.5072 11.0307 20.1862 10.5812L17.8612 7.32619C16.3702 5.23882 13.963 4 11.3978 4H11.3302C10.2634 4 9.20847 4.22387 8.23361 4.65715C6.6176 5.37537 5.30151 6.633 4.51064 8.21473L3.8944 9.44721C3.64741 9.94119 3.04674 10.1414 2.55276 9.89443C2.05878 9.64744 1.85856 9.04676 2.10555 8.55279L2.72179 7.3203C3.72011 5.32367 5.38143 3.73615 7.42133 2.82952Z"/>
    <path d="M16.5787 21.1705C15.3481 21.7174 14.0164 22 12.6698 22L12.6022 22C9.39113 22 6.37773 20.4492 4.51133 17.8363L2.18629 14.5812C1.86528 14.1318 1.96937 13.5073 2.41879 13.1863C2.8682 12.8653 3.49275 12.9693 3.81376 13.4188L6.13879 16.6738C7.62977 18.7612 10.037 20 12.6022 20L12.6698 20C13.7366 20 14.7915 19.7761 15.7664 19.3429C17.3824 18.6246 18.6985 17.367 19.4894 15.7853L20.1056 14.5528C20.3526 14.0588 20.9533 13.8586 21.4472 14.1056C21.9412 14.3526 22.1414 14.9532 21.8945 15.4472L21.2782 16.6797C20.2799 18.6763 18.6186 20.2639 16.5787 21.1705Z"/>
  </Icon>
);

export default withTheme(OnGoingIcon);
