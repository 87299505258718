import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../constants/styles';

const ArtCulture = (props) => (
    <Container isActive={props.isActive}>
      <svg
          width="20px"
          height="20px"
          id="art_culture"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          enableBackground="new 0 0 24 24"
          xmlSpace="preserve"
      >
        <g id="mask">
          <path fill={props.isActive ? styles.colors.causes.artsandCulture : styles.colors.silver} d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10.1,9.9c1.2,0,2.2-1,2.2-2.2c0-0.6-0.2-1-0.6-1.4c-0.2-0.2-0.3-0.4-0.3-0.6
	c0-0.5,0.4-0.9,0.9-0.9h1.9c3.2,0,5.8-2.5,5.8-5.8C22,6,17.5,2,12,2z M6.3,11.7c-0.6,0-1.3-0.6-1.3-1.3c0-0.6,0.6-1.2,1.3-1.2
	s1.2,0.6,1.2,1.3S6.9,11.7,6.3,11.7z M9.4,7.4c-0.6,0-1.3-0.5-1.3-1.1C8.1,5.6,8.7,5,9.4,5c0.7,0,1.2,0.6,1.2,1.2
	C10.6,6.9,10,7.4,9.4,7.4z M14.6,7.4c-0.6,0-1.2-0.6-1.2-1.2c0-0.6,0.5-1.2,1.2-1.2c0.7,0,1.2,0.6,1.2,1.2
	C15.8,6.9,15.2,7.4,14.6,7.4z M17.7,11.7c-0.6,0-1.2-0.6-1.2-1.2c0-0.7,0.6-1.3,1.2-1.3c0.7,0,1.2,0.6,1.2,1.3S18.3,11.7,17.7,11.7z"/>
        </g>
      </svg>
    </Container>
);

export default ArtCulture;

ArtCulture.propTypes = {
  isActive: PropTypes.bool
};

ArtCulture.defaultProps = {
  isActive: true
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: ${props => props.isActive ? `${styles.colors.causes.artsandCulture}1f` : styles.colors.mostlyWhite};
    border-radius: 20px;
`;