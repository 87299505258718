import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { EVENT_ROUTE, HOME_LINK } from '../constants/links';

import { EventDetails, Events } from '../containers/Events';

const MainRoute = () => {
  return (
    <Switch>
      <Route component={() => window.open(process.env.REACT_APP_DASH_URL, '_self')}/>
    </Switch>
  );
};

export default MainRoute;
