import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const PlusIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M13,20c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1s1,0.4,1,1v14C14,19.6,13.6,20,13,20z"/>
            <path d="M20,13H6c-0.6,0-1-0.4-1-1s0.4-1,1-1h14c0.6,0,1,0.4,1,1S20.6,13,20,13z"/>
        </Icon>
    );

export default withTheme(PlusIcon);
