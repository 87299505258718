import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const HouseIcon = props => (
        <Icon {...props} viewBox={'0 0 14 14'}>
            <path d="M11.1,13.4H2.9c-1,0-1.8-0.8-1.8-1.8V5.2c0-0.2,0.1-0.3,0.2-0.5l5.2-4.1
	c0.2-0.2,0.5-0.2,0.7,0l5.2,4.1c0.1,0.1,0.2,0.3,0.2,0.5v6.4C12.8,12.6,12,13.4,11.1,13.4L11.1,13.4z"/>
        </Icon>
    );

export default withTheme(HouseIcon);
