import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../constants/styles';

const Animals = (props) => (
    <Container isActive={props.isActive}>
        <svg
            id="animals"
            width="20px"
            height="20px"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
            enableBackground="new 0 0 24 24"
            xmlSpace="preserve"
        >
          <g id="mask">
            <path fill={props.isActive ? styles.colors.causes.animals : styles.colors.silver} d="M21.5,11.4C21.8,11,22,10.5,22,10c0-1.3-1-2.3-2.3-2.3
  c-1.1,0-2,0.8-2.2,1.8c-0.1,0.4-0.5,0.7-0.9,0.7H7.4c-0.4,0-0.8-0.3-0.9-0.7C6.3,8.4,5.4,7.7,4.3,7.7C3,7.7,2,8.7,2,10
  c0,0.5,0.2,1.1,0.5,1.5c0.3,0.3,0.3,0.8,0,1.2C2.2,13,2,13.5,2,14c0,1.3,1,2.3,2.3,2.3c1.1,0,2-0.8,2.2-1.8c0.1-0.4,0.5-0.7,0.9-0.7
  h9.1c0.4,0,0.8,0.3,0.9,0.7c0.2,1.1,1.2,1.8,2.2,1.8c1.3,0,2.3-1,2.3-2.3c0-0.5-0.2-1-0.5-1.5C21.2,12.2,21.2,11.8,21.5,11.4"/>
          </g>
        </svg>
    </Container>
);

export default Animals;

Animals.propTypes = {
    isActive: PropTypes.bool
};

Animals.defaultProps = {
    isActive: true
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: ${props => props.isActive ? `${styles.colors.causes.animals}1f` : styles.colors.mostlyWhite};
    border-radius: 20px;
`;