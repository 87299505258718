import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const PublishIcon = props => (
    <Icon {...props} viewBox={'0 0 24 24'}>
        <path d="M16,17c-0.3,0-0.5-0.1-0.7-0.3L12,13.4l-3.3,3.3c-0.4,0.4-1,0.4-1.4,0s-0.4-1,0-1.4l4-4c0.4-0.4,1-0.4,1.4,0l4,4
	c0.4,0.4,0.4,1,0,1.4C16.5,16.9,16.3,17,16,17z"/>
        <path d="M12,22c-0.6,0-1-0.4-1-1v-9c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1v9C13,21.6,12.6,22,12,22z"/>
        <path d="M20.4,19.4c-0.4,0-0.7-0.2-0.9-0.5c-0.3-0.5-0.1-1.1,0.4-1.4c0.9-0.5,1.6-1.4,1.9-2.4c0.3-1,0.2-2.1-0.3-3
	c-0.7-1.3-2-2.1-3.5-2.1h-1.3c-0.5,0-0.9-0.3-1-0.7c-1-3.7-4.8-6-8.5-5C5.4,4.7,3.9,5.8,3,7.4C2,9.1,1.7,10.9,2.2,12.8
	c0.3,1.1,0.8,2.1,1.5,2.9c0.4,0.4,0.3,1-0.1,1.4c-0.4,0.4-1,0.3-1.4-0.1c-0.9-1.1-1.6-2.3-2-3.7c-0.6-2.3-0.3-4.7,1-6.8
	s3.2-3.5,5.5-4.1c4.5-1.2,9.2,1.4,10.7,5.7H18c2.2,0,4.2,1.2,5.3,3.1c0.8,1.4,0.9,3,0.5,4.6c-0.5,1.5-1.5,2.8-2.9,3.6
	C20.7,19.4,20.6,19.4,20.4,19.4z"/>
        <path d="M16,17c-0.3,0-0.5-0.1-0.7-0.3L12,13.4l-3.3,3.3c-0.4,0.4-1,0.4-1.4,0s-0.4-1,0-1.4l4-4c0.4-0.4,1-0.4,1.4,0l4,4
	c0.4,0.4,0.4,1,0,1.4C16.5,16.9,16.3,17,16,17z"/>
    </Icon>
);

export default withTheme(PublishIcon);
