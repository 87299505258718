import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import styles from '../../../../constants/styles';
import { getDataTime, getImageURI, getTypeNameByType } from '../../../../_helpers';
import { H2, H3, H5, H6, P2 } from '../../../../components/Typography';
import Causes from '../../../../components/Causes';
import { EventIcon, LocationSolidIcon, TimeIcon } from '../../../../components/Icons/General';
import { InnerHTML } from '../../../../components/PEditor';
import { ListItemWithIcon, Map, ShortProfile } from '../../../../components/Elements';
import { EventVirtualUrl } from '../../../Events/components';
import { Shift } from '../../../../components/Events';
import { PUBLIC_ORGANIZATION_ROUTE } from '../../../../constants/links';

class Details extends Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  renderCausesItem = () => {
    const primaryCauseObj = filter(this.props.singleEvent.causes, { 'isPrimary': true });
    let primaryCauseId = 0;
    if (primaryCauseObj && Array.isArray(primaryCauseObj) && primaryCauseObj.length > 0) {
      primaryCauseId = primaryCauseObj[0].id
    }

    return (
      <Causes
        causesState={'view'}
        selectedCauses={this.props.singleEvent.causes}
        primaryCauseId={primaryCauseId}
      />
    );
  };

  showShifts = (shifts, timezone) => {
    const { singleEvent } = this.props;
    return shifts.map((item, index) => {
      return (
        <Shift
          key={index}
          item={item}
          eventStartTime={singleEvent.startTime}
          eventEndTime={singleEvent.endTime}
          eventTimeZone={timezone}
          counter={index + 1}
          isHideReserve={true}
          isSpots={true}
          size={'full'}
          isOpportunity={true}
          type={'details'}
        />
      );
    });
  };

  render () {
    const { singleEvent } = this.props;
    const {
      title: eventTitle,
      startTime,
      endTime,
      timezone,
      shifts
    } = singleEvent;

    let eventImg = '';
    if (singleEvent.images && singleEvent.images.length > 0 && typeof (singleEvent.images[0]) === 'string') {
      eventImg = getImageURI(singleEvent.images[0]);
    }

    const isShifts = (shifts.length > 1);

    const dictionary = JSON.parse(localStorage.getItem('POINT.dictionary'));
    const dataDateTime = getDataTime(startTime, endTime, timezone, true);

    let skillsNeeded = '';
    if (singleEvent.skillsNeeded) {
      skillsNeeded = '<p>'+singleEvent.skillsNeeded+'</p>';
    }

    let greatFor = '';
    if (singleEvent.greatFor) {
      if (typeof singleEvent.greatFor !== 'string') {
        greatFor = singleEvent.greatFor.join(', ');
      } else {
        greatFor = singleEvent.greatFor;
      }
      greatFor = '<p>'+greatFor+'</p>';
    }

    return (
      <Wrapper>
        <RowEventWrap>
          {eventImg ? (
            <ColHalfEvent>
              <EventImage src={eventImg} alt={eventTitle || 'Event Image'}/>
            </ColHalfEvent>
          ) : null}

          <ColHalf eventImg={eventImg}>
            <TitleEvent data-testid={'event-detail-title'}>{eventTitle}</TitleEvent>
            <RowTitle>Hosted by</RowTitle>
            <BlockRow>
              <ShortProfile
                onClick={() => singleEvent && singleEvent.organization && singleEvent.organization.id ? window.open(PUBLIC_ORGANIZATION_ROUTE.replace(':oid', singleEvent.organization.id)) : null}
                image={singleEvent.organization.logo}
                title={singleEvent.organization.organizationName || singleEvent.organization.name}
                subtitle={getTypeNameByType(singleEvent.organization.type)}
                dataTestId={'event-creator'}
              />
            </BlockRow>
            <Spots>
              <ParHigh>{singleEvent.spots}</ParHigh>
              <Par>{`Total Spot${singleEvent.spots > 1 ? 's' : ''}`}</Par>
            </Spots>
            {singleEvent.startTime && (
              <EventDateTime>
                <ListItemWithIcon
                  Icon={<EventIcon defaultFill={styles.colors.dark} width={16} height={16}/>}
                  content={dataDateTime.dateString}
                />
                <ListItemWithIcon
                  Icon={<TimeIcon defaultFill={styles.colors.dark} width={16} height={16}/>}
                  content={dataDateTime.timeString}
                />
                <RemoverMarginBottom8/>
              </EventDateTime>
            )}
          </ColHalf>
        </RowEventWrap>

        {isShifts ? (
          <RowEvent>
            <ColEvent>
              <ShiftPlace>
                {this.showShifts(singleEvent.shifts, timezone)}
              </ShiftPlace>
            </ColEvent>
          </RowEvent>
        ) : null}
        <HorizontalLine/>

        {singleEvent.isVirtual ? (
          <>
            <RowEvent>
              <ColEvent>
                <RowTitle>INSTRUCTIONS ON HOW TO PARTICIPATE VIRTUALLY OR AT HOME</RowTitle>
                {singleEvent.onlineDescription ?
                  <EventInstruction><InnerHTML content={singleEvent.onlineDescription}/></EventInstruction> : null}
                <EventVirtualUrl onlineAddressUrl={singleEvent.onlineAddressUrl}/>
              </ColEvent>
            </RowEvent>
            <HorizontalLine/>
          </>
        ) : null}

        <RowEvent>
          <ColEvent>
            <RowTitle>ABOUT</RowTitle>
            <SubRowTitle>About the opportunity</SubRowTitle>
            <EventText>
              {singleEvent.description ? <InnerHTML content={singleEvent.description}/> : null}
            </EventText>
            <SubRowTitle>Causes supported</SubRowTitle>
            {this.renderCausesItem()}
            <SubRowTitle>Location</SubRowTitle>
            {(singleEvent.lat && singleEvent.lng) ? <Map lat={singleEvent.lat} lng={singleEvent.lng}/> : null}
            {singleEvent.address && (
              <AddressUnderMap>
                <ListItemWithIcon
                  Icon={<LocationSolidIcon width={14} height={14} defaultFill={styles.colors.dark}/>}
                  content={singleEvent.address}
                />
              </AddressUnderMap>
            )}
          </ColEvent>
        </RowEvent>
        {(singleEvent.locationDescription && !singleEvent.isVirtual) || singleEvent.greatFor
        || singleEvent.skillsNeeded || singleEvent.volunteerDressCode ? (
          <RowEventInfo>
            <ColEvent>
              <RowTitle>ADDITIONAL INFO</RowTitle>
              {skillsNeeded ? (
                <div>
                  <SubRowTitle>{'Skill Needed'}</SubRowTitle>
                  <EventText><InnerHTML content={skillsNeeded}/></EventText>
                </div>
              ) : null}
              {greatFor ? (
                <div>
                  <SubRowTitle>{'Good For'}</SubRowTitle>
                  <EventText><InnerHTML content={greatFor}/></EventText>
                </div>
              ) : null}
              {(singleEvent.locationDescription && !singleEvent.isVirtual) && (
                <div>
                  <SubRowTitle>{(this.props.singleEvent.coHosts && this.props.singleEvent.coHosts.length > 0) ? 'What should volunteers know about the location?' : ((dictionary && dictionary._events_text_volunteersaboutlocation) ? dictionary._events_text_volunteersaboutlocation : 'What should volunteers know about the location?')}</SubRowTitle>
                  <EventText><InnerHTML content={singleEvent.locationDescription}/></EventText>
                </div>
              )}
              {singleEvent.volunteerDressCode && (
                <div>
                  <SubRowTitle>{(this.props.singleEvent.coHosts && this.props.singleEvent.coHosts.length > 0) ? 'What will volunteers need to bring or wear?' : ((dictionary && dictionary._events_text_whatwillvolunteersneed) ? dictionary._events_text_whatwillvolunteersneed : 'What will volunteers need to bring or wear?')}</SubRowTitle>
                  <EventText><InnerHTML content={singleEvent.volunteerDressCode}/></EventText>
                </div>
              )}
            </ColEvent>
          </RowEventInfo>
        ) : null}
      </Wrapper>
    );
  }
}

Details.propTypes = {
  singleEvent: PropTypes.shape({}).isRequired,
  setInfo: PropTypes.func.isRequired
};

Details.defaultTypes = {};

export default Details;

const Wrapper = styled.div`
  padding-top: 32px;
  border-radius: 20px;
  border: 1px solid ${styles.borders.color};
  margin: 16px;
  width: calc(100% - 32px);
  @media screen and (max-width: 425px) {
    border-radius: 0;
    border-width: 1px 0;
  }
`;
const ColEvent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  p {
    font-size: 16px;
    line-height: 24px;
  }
`;
const RowEventWithoutSpace = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;
const RowEvent = styled(RowEventWithoutSpace)`
  padding: 0 32px 32px;
  @media screen and (max-width: 425px) {
    padding: 0 0 32px;
  }
`;
const RowEventInfo = styled(RowEvent)`
  margin: 0;
  padding-top: 32px;
  padding-bottom: 16px;
  border-top: 1px solid ${styles.borders.color};
  .block-text {
    margin-bottom: 0;
  }
`;
const TitleEvent = styled(H2)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 24px;
  line-height: 30px;
  word-break: break-word;
`;
const EventImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: auto;
  border-radius: 12px;
`;
const RowTitle = styled(H5)`
  color: ${styles.colors.dark};
  font-family: ${styles.fonts.family.primaryBold};
  text-transform: uppercase;
  margin: 0 0 24px;
`;
const SubRowTitle = styled(H3)`
  color: ${styles.colors.dark};
  font-family: ${styles.fonts.family.primaryBold};
  margin: 8px 0;
`;
const EventDateTime = styled.div``;
const EventText = styled(P2)`
  margin: 0;
  color: ${styles.colors.medium};
  font-family: ${styles.fonts.family.primaryBook};
`;
const AddressUnderMap = styled(H5)`
  color: ${styles.colors.dark};
  font-family: ${styles.fonts.family.primaryMedium};
  margin-top: 16px;
  margin-bottom: 0;

  & > div {
    margin-bottom: 0;
  }
`;
const HorizontalLine = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  margin-bottom: 32px;
  background-color: ${styles.borders.color};
`;
const EventInstruction = styled(P2)`
  color: ${styles.colors.medium};
  font-family: ${styles.fonts.family.primaryMedium};
  word-break: break-word;
  margin: 0 0 8px;
`;
const RemoverMarginBottom8 = styled.div`
  margin-bottom: -8px;
`;
const BlockRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 475px) {
    flex-direction: column;
    align-items: flex-start;
    .btn {
      margin-top: 12px;
    }
  }
`;
const ParHigh = styled(H2)`
  margin: 0 0 2px;
  color: ${styles.colors.dark};
`;
const Par = styled(H5)`
  margin: 0;
  font-family: ${styles.fonts.family.primaryMedium};
  color: ${styles.colors.silver};
`;
const Spots = styled.div`
  margin: 16px 0 24px;
`;
const ShiftPlace = styled(ColEvent)`
  & > div {
    margin: 0 0 16px 0;
    width: 100% !important;
    &:last-of-type {
      margin: 0;
    }
  }
`;
const ColHalfEvent = styled(ColEvent)`
  padding: 0 16px 32px;
  width: 50%;
  @media screen and (max-width: 575px) {
    width: 100%;
  }
  @media screen and (max-width: 425px) {
    padding: 0 0 32px;
  }
`;
const ColHalf = styled(ColHalfEvent)`
  width: ${props => props.eventImg ? '50%' : '100%'};
  @media (min-width: 1200px) and (max-width: 1300px) {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;
const RowEventWrap = styled(RowEventWithoutSpace)`
  padding: 0 16px 0;
  flex-wrap: wrap;
  @media screen and (max-width: 425px) {
    padding: 0;
  }
`;
