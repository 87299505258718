import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const CloudDownloadIcon = props => (
  <Icon {...props} viewBox={'0 0 24 24'}>
    <path d="m12 22c-.3 0-.5-.1-.7-.3l-4-4c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l3.3 3.3 3.3-3.3c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-4 4c-.2.2-.4.3-.7.3z"/>
    <path d="m12 22c-.6 0-1-.4-1-1v-9c0-.6.4-1 1-1s1 .4 1 1v9c0 .6-.4 1-1 1z"/>
    <path d="m20.9 19.1c-.3 0-.6-.1-.8-.4-.3-.5-.2-1.1.2-1.4.9-.6 1.5-1.5 1.6-2.6s-.1-2.1-.7-3c-.7-1.1-2-1.7-3.3-1.7h-1.3c-.5 0-.9-.3-1-.8-1-3.7-4.8-6-8.5-5-1.7.5-3.2 1.6-4.1 3.2-1 1.6-1.3 3.5-.8 5.3.3 1.1.8 2.1 1.5 2.9.4.4.3 1-.1 1.4s-1 .3-1.4-.1c-.9-1.1-1.6-2.4-2-3.7-.6-2.3-.3-4.7 1-6.8 1.2-2.1 3.2-3.5 5.5-4.1 4.6-1.2 9.3 1.3 10.8 5.7h.5c2 0 3.8 1 4.9 2.5.9 1.3 1.3 2.9 1 4.5s-1.1 3-2.5 3.9c-.1.1-.3.2-.5.2z"/>
  </Icon>
);

export default withTheme(CloudDownloadIcon);
