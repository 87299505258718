import React from 'react';
import styled from 'styled-components';
import styles from '../../../constants/styles';
import PropTypes from 'prop-types';

const Poverty = (props) => (
  <Container isActive={props.isActive}>
    <svg
      width="20px"
      height="20px"
      id="poverty"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      enableBackground="new 0 0 24 24"
      xmlSpace="preserve"
    >
      <g id="mask">
        <path fill={props.isActive ? styles.colors.causes.poverty : styles.colors.silver}
              d="m9.2 6.8c.2 0 .5 0 .7.1l6.6 2.5c.4.2.7.6.7 1.1 0 .6-.5 1.1-1.1 1.1h-2.5c-.1 0-.2 0-.3-.1l-1.1-.5c-.2-.1-.5 0-.6.3s0 .5.3.6l1.4.5c.1 0 .2.1.3.1h6.5c1.1 0 1.9.8 1.9 1.9l-7 2.6c-.4.1-.8.2-1.2 0l-6.1-1.7v-8.5zm-5.3 0c1.1.1 1.9.9 1.9 1.9v6.7c0 1-.9 1.9-1.9 1.9s-1.9-.9-1.9-2v-6.6c0-1.1.9-1.9 1.9-1.9z"/>
      </g>
    </svg>
  </Container>
);

export default Poverty;

Poverty.propTypes = {
  isActive: PropTypes.bool
};

Poverty.defaultProps = {
  isActive: true
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: ${props => props.isActive ? `${styles.colors.causes.poverty}1f` : styles.colors.mostlyWhite};
  border-radius: 20px;
`;