import React from 'react';
import styled, {withTheme} from 'styled-components';
import PropTypes from 'prop-types';

const Loader = ({size, fill}) => (
  <Svg
    width={size}
    height={size}
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(25 50)">
      <circle cx="0" cy="0" r="7" fill={fill} transform="scale(0.00136602 0.00136602)">
        <animateTransform attributeName="transform" type="scale" begin="-0.3666666666666667s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1.1s" repeatCount="indefinite" />
      </circle>
    </g>
    <g transform="translate(50 50)">
      <circle cx="0" cy="0" r="7" fill={fill} transform="scale(0.297195 0.297195)">
        <animateTransform attributeName="transform" type="scale" begin="-0.18333333333333335s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1.1s" repeatCount="indefinite" />
      </circle>
    </g>
    <g transform="translate(75 50)">
      <circle cx="0" cy="0" r="7" fill={fill} transform="scale(0.758741 0.758741)">
        <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1.1s" repeatCount="indefinite"/>
      </circle>
    </g>
  </Svg>
);

const Svg = styled.svg`
  height: ${props => props.size};
  width: ${props => props.size};
  margin-right: 0;
  g {
    transition: .25s;
  }
  path, rect{
    fill: ${props => props.fill};
  }
`;

Loader.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string.isRequired
};

Loader.defaultProps = {
  size: 24
};

export default withTheme(Loader);
