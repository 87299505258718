import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const OrganizationIcon = props => (
        <Icon {...props} viewBox={'0 0 48 48'}>
            <path d="M24,42c-9.9,0-18-8.1-18-18c0-9.9,8.1-18,18-18c9.9,0,18,8.1,18,18C42,33.9,33.9,42,24,42z M24,9
		C15.7,9,9,15.7,9,24c0,8.3,6.7,15,15,15c8.3,0,15-6.7,15-15C39,15.7,32.3,9,24,9z"/>
            <path d="M24,42c-0.4,0-0.7-0.1-1-0.4c-4.7-4.3-7.1-10.2-7.1-17.6S18.3,10.7,23,6.4c0.6-0.5,1.4-0.5,2,0
		c4.7,4.3,7.1,10.2,7.1,17.6S29.7,37.3,25,41.6C24.7,41.9,24.4,42,24,42z M24,9.6c-3.4,3.6-5.1,8.4-5.1,14.4s1.7,10.8,5.1,14.4
		c3.4-3.6,5.1-8.4,5.1-14.4S27.4,13.2,24,9.6z"/>
            <path d="M24,31.3c-6.3,0-15.4-0.8-15.8-0.8c-0.8-0.1-1.4-0.8-1.4-1.6c0.1-0.8,0.8-1.4,1.6-1.4
		c0.1,0,9.3,0.8,15.5,0.8s15.5-0.8,15.5-0.8c0.8-0.1,1.6,0.5,1.6,1.4c0.1,0.8-0.5,1.6-1.4,1.6C39.4,30.5,30.3,31.3,24,31.3z
		 M23.8,21.4c-6.9,0-15.6-0.8-15.7-0.8c-0.8-0.1-1.4-0.8-1.3-1.6c0.1-0.8,0.8-1.4,1.6-1.3c0.1,0,8.6,0.8,15.4,0.8
		c6.8,0,15.6-0.8,15.7-0.8c0.8-0.1,1.6,0.5,1.6,1.4c0.1,0.8-0.5,1.6-1.4,1.6C39.7,20.6,30.8,21.4,23.8,21.4z"/>
        </Icon>
    );

export default withTheme(OrganizationIcon);
