import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H1 } from '../Typography';
import styles from '../../constants/styles';

const TitlePage = (props) => (
  <Container
      {...props}
      data-testid={props.dataTestId}
  >{props.text}</Container>
);
export default TitlePage;

TitlePage.propTypes = {
  text: PropTypes.string,
  dataTestId: PropTypes.string
};
TitlePage.defaultProps = {
  text: '',
  dataTestId: ''
};

const Container = styled(H1)`
    color: ${styles.colors.dark};
    margin-bottom: 0;
    text-transform: capitalize;
    word-break: break-word;
`;
