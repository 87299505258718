import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../constants/styles';

const Card = ({children, ...rest}) => <Container {...rest}>{children}</Container>;

export default Card;

const Container = styled.div`
    padding: 30px 40px;
    border: 1px solid ${styles.borders.color};
    position: relative;
    @media screen and (max-width: 575px) {
        padding: 20px 30px 30px;
    }
`;

Card.propTypes = {
    children: PropTypes.element.isRequired
};
