import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../constants/styles';
import { H6 } from '../Typography';
import { CloseCircleSolidIcon } from '../Icons/General';

const ErrorMessage = (props) => {
  return (
    <Container {...props}>
      <IconPlace>
        <CloseCircleSolidIcon
          width={16}
          height={16}
          defaultFill={styles.colors.system.error}
        />
      </IconPlace>
      <ErrorText data-testid={props.dataTestId || ''}>{props.text || props.children}</ErrorText>
    </Container>
  );
};

export default ErrorMessage;

ErrorMessage.propTypes = {
  children: PropTypes.element,
  text: PropTypes.string,
  dataTestId: PropTypes.string
};
ErrorMessage.defaultProps = {
  children: '',
  text: '',
  dataTestId: ''
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 8px;
`;
const IconPlace = styled.div`
  display: flex;
  margin-right: 4px;
`;
const ErrorText = styled(H6)`
  color: ${styles.colors.system.error};
  font-family: ${styles.fonts.family.primaryBook};
  margin-bottom: 0;
  margin-top: 0;
`;
