import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const CheckIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M9.8,18c-0.3,0-0.5-0.1-0.7-0.3l-4.9-5.2c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l4.1,4.4l8.4-9.2
	c0.3-0.4,1-0.5,1.4-0.2c0.4,0.3,0.5,1,0.2,1.4c0,0-0.1,0.1-0.1,0.1l-9.1,10C10.4,17.9,10.1,18,9.8,18L9.8,18z"/>
        </Icon>
    );

export default withTheme(CheckIcon);
