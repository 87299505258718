import React from 'react';
import { withTheme } from 'styled-components';
import Icon from './components/Icon';

const CharitiesMenu = props => (
        <Icon {...props}>
            <path d="M16.9,18.3c-0.2-0.4-0.4-0.8-0.6-1.1c-0.1-0.2-0.2-0.3-0.3-0.5c-0.4-0.7-0.2-1,0.4-2l0.1-0.2
	c0.4-0.7,0.6-1.6,0.5-2.4c0-0.3,0-0.7,0.1-1c0.2-0.7,1.7-0.9,2.7-1C20.6,13.2,19.4,16.4,16.9,18.3L16.9,18.3z M5,15.8
	c0.7,0.2,1.3,0.3,2,0.3c1.1,0,2.2-0.3,3.1-1c1.7-1.3,1.7-3.1,1.7-4.4c-0.1-0.8,0.1-1.6,0.4-2.3c0.3-0.4,0.7-0.7,1.1-0.9
	C13.7,7.2,14,7,14.3,6.8c0.6-0.5,1.1-1.1,1.4-1.9c1.4,0.7,2.6,1.8,3.3,3.2c-1.4,0.2-3.5,0.7-3.9,2.5C15,11.1,15,11.5,15,12
	c0,0.5-0.1,1-0.3,1.5l-0.1,0.2c-0.7,1.1-1.4,2.3-0.4,4c0.1,0.2,0.2,0.4,0.4,0.6c0.3,0.3,0.4,0.7,0.5,1.1c-1,0.4-2,0.7-3.1,0.7
	C9.1,20,6.4,18.4,5,15.8L5,15.8z M12,4c0.6,0,1.2,0.1,1.8,0.2c-0.2,0.4-0.4,0.7-0.8,1c-0.2,0.2-0.5,0.3-0.7,0.5
	c-0.8,0.4-1.4,1-1.9,1.7c-0.5,1-0.7,2.2-0.6,3.3c0,1.4,0,2.2-0.9,2.9c-1.4,1.1-3.5,0.5-4.8-0.1C4,13,4,12.5,4,12C4,7.6,7.6,4,12,4
	L12,4z M12,2C6.5,2,2,6.5,2,12c0,5.5,4.5,10,10,10s10-4.5,10-10C22,6.5,17.5,2,12,2L12,2z"/>
        </Icon>
    );

export default withTheme(CharitiesMenu);
