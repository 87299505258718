import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../constants/styles';
import { CheckboxIcon, MinusIcon } from '../../Icons/General';

const Checkbox = ({ onChange, checked = false, title, disabled = false, ...props }) =>  {
  if (disabled) {
    return (
        <Block {...props}>
          <Minus />
        </Block>
    );
  }

  return (
    <CheckboxContainer
      data-testid={props.dataTestId}
      onClick={() => onChange()}
      {...props}
    >
      <HiddenCheckbox checked={checked} {...props} onChange={(e) => onChange(e)} />
      <StyledCheckbox checked={checked}>
        {checked ? (<CheckboxIcon defaultFill={'white'} width={12} height={12} />) : null}
      </StyledCheckbox>
      {title ? <TitleCheckbox>{title}</TitleCheckbox> : null}
    </CheckboxContainer>
  );
};

Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  dataTestId: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool
};

Checkbox.defaultTypes = {
  title: '',
  dataTestId: '',
  disabled: false,
  checked: false
};

export default Checkbox;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 0;
`;
const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  transition: all 150ms;
  border-radius: 4px;
  border: 2px solid ${props => props.checked ? styles.colors.primary : styles.colors.grey};
  background: ${props => props.checked ? styles.colors.primary : 'white'};
  cursor: pointer;
  &:hover {
    border: 2px solid ${props => props.checked ? styles.colors.primary : styles.colors.primary};
    transition: 0.5s;
  }
`;
const TitleCheckbox = styled.label`
  font-family: ${styles.fonts.family.primaryBook};
  color: ${styles.colors.dark};
  font-size: 14px;
  line-height: 18px;
  margin-left: 8px;
`;
const Block = styled(StyledCheckbox)`
  border: 2px solid ${styles.colors.light};
  background: transparent;
  cursor: auto;
  &:hover {
    border: 2px solid ${styles.colors.light};
  }
`;
const Minus = styled.div`
  background ${styles.colors.light};
  width: 8px;
  height: 2px;
  border-radius: 1px;
`;

