import React, { Component } from 'react';
import styled, { withTheme } from 'styled-components';
import { PieChart } from 'react-minimal-pie-chart';
import PropTypes from 'prop-types';
import styles from '../../constants/styles';
import { HelpIcon } from "../../components/Icons/General";

class ProgressDonut extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showHintText: false
        };
        this.showTooltip = this.showTooltip.bind(this);
    }

    showTooltip = () => {
        this.setState({showHintText: !this.state.showHintText});
    };

    render() {
        const remainingPercentage = 100 - this.props.percent;
        const type = this.props.type ? this.props.type : 'none';

        switch (type) {
            case 'signUps':
                return (
                    <Wrapper type={this.props.type}>
                        <SignUpsChartWrapper type={this.props.type}>
                            <PieChart
                                animate
                                lineWidth={8}
                                startAngle={-90}
                                lengthAngle={360}
                                data={[
                                    {value: this.props.percent, color: this.props.fill || styles.colors.primary},
                                    {value: remainingPercentage, color: this.props.secondColor || styles.colors.light},
                                ]}
                            />
                            <Percent>{`${this.props.percent} %`}</Percent>
                        </SignUpsChartWrapper>
                        <Label type={this.props.type}>{this.props.label}</Label>
                    </Wrapper>
                );
            case 'eventAttendance':
                return (
                    <Wrapper>
                        <WrapperAttendance>
                            <PieChart
                                animate
                                lineWidth={16}
                                startAngle={-90}
                                lengthAngle={360}
                                data={this.props.data}
                            />
                        </WrapperAttendance>
                    </Wrapper>
                );
            default:
                return (
                    <Wrapper>
                        <ChartWrapper large={this.props.large}>
                            <PieChart
                                animate
                                rounded
                                lineWidth={this.props.large ? 8 : 24}
                                startAngle={-90}
                                lengthAngle={-360}
                                data={[
                                    {value: remainingPercentage, color: this.props.secondColor || styles.colors.light},
                                    {value: this.props.percent, color: this.props.fill || styles.colors.primary},
                                ]}
                            />
                            {this.props.large && (
                                <Percent>
                                    {this.props.percent && Math.round(this.props.percent)}
                                    %
                                </Percent>
                            )}
                        </ChartWrapper>
                        {this.props.large && this.props.label && (
                            <Label>
                                {this.props.label}
                                {this.props.hintText &&
                                <Span onMouseEnter={this.showTooltip} onMouseLeave={this.showTooltip}><HelpIcon
                                    defaultFill={styles.colors.silver} width={16} height={16}/></Span>}
                                {this.state.showHintText && <HintText>{this.props.hintText}</HintText>}
                                {this.state.showHintText && <Triangle/>}
                            </Label>
                        )}
                    </Wrapper>
                );
        }
    }
}

ProgressDonut.propTypes = {
    large: PropTypes.bool,
    label: PropTypes.string,
    hintText: PropTypes.string,
    percent: PropTypes.number
};

ProgressDonut.defaultProps = {
    large: false,
    label: null,
    hintText: '',
    percent: 0
};

export default withTheme(ProgressDonut);

const Wrapper = styled.div`
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    @media (min-width: 1200px) and (max-width: 1440px) {
        flex-direction: ${props => (props.type && props.type === 'signUps') ? 'column-reverse;' : 'column'};
    }
    @media screen and (max-width: 630px) {
        flex-direction: ${props => (props.type && props.type === 'signUps') ? 'column-reverse;' : 'column'};
    }
    @media screen and (max-width: 425px) {
        flex-direction: column;
    }
`;
const WrapperAttendance = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    width: calc(100% - 20px);
    margin: 8px 20px 0 0;
    @media (min-width: 1200px) and (max-width: 1440px) {
        margin: 0 10px 20px;
    }
    @media screen and (max-width: 630px) {
        margin: 0 10px 20px;
    }
`;
const Percent = styled.div`
    position: absolute;
    color: #2e373e;
    font-size: 24px;
    line-height: 32px;
    font-family: ${styles.fonts.family.primaryBold};
    @media screen and (max-width:1420px){
        font-size: 20px;
    }
    @media screen and (max-width:1199px){
        font-size: 24px;
    }
    @media screen and (max-width:575px){
        font-size: 18px;
    }
    @media screen and (max-width:375px){
        font-size: 16px;
    }
`;
const ChartWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${props => props.large ? '70px' : '32px'};
    width: ${props => props.large ? '70px' : '32px'};
    margin: auto;
`;
const SignUpsChartWrapper = styled(ChartWrapper)`
    height: 100%;
    width: calc(100% - 10px);
    margin: 37px 10px 0 0;
    @media (min-width: 1200px) and (max-width: 1440px) {
        margin: 0 auto;
        max-width: 130px;
        ${Percent} {
            font-size: 20px;
        }
    }
    @media screen and (max-width: 630px) {
        margin: 0 auto;
        max-width: 130px;
        ${Percent} {
            font-size: 20px;
        }
    }
`;
const Label = styled.div`
    margin-top: 15px;
    color: #53627c;
    font-family: ${styles.fonts.family.primaryMedium};
    font-size: 14px;
    line-height: 22px;
    position: relative;
    display: flex;
    @media (min-width: 1200px) and (max-width: 1440px) {
        margin: ${props => (props.type && props.type === 'signUps') ? '0 auto 15px' : '15px auto 0'};
    }
    @media screen and (max-width: 630px) {
        margin: ${props => (props.type && props.type === 'signUps') ? '0 auto 15px' : '15px auto 0'};
    }
    @media screen and (max-width: 425px) {
        margin: 15px auto 0;
    }
`;
const Span = styled.span`
    display: inline-flex;
    cursor: pointer;
    margin-left: 4px;
`;
const HintText = styled.div`
    position: absolute;
    bottom: calc(100% + 15px);
    right: -45px;
    width: 250px;
    background: white;
    box-shadow: 0 0 0 0 rgba(10,31,68,0.08), 0 3px 14px 4px rgba(10,31,68,0.1);
    border-radius: 4px;
    padding: 20px;
    color: #53627c;
    font-family: ${styles.fonts.family.primaryMedium};
    font-size: 14px;
    line-height: 22px;
    z-index: 9;
    &::after{
        position: absolute;
        bottom: 0;
        right: 100px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid white;
    }
`;
const Triangle = styled.div`
    position: absolute;
    bottom: calc(100% + 5px);
    right: 0;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 10px solid white;
    z-index: 9;
`;
