import FireBaseTools from '../../../api/firebase';
import { AUTH_PROVIDERS } from '../../../constants';
import { createUserProfile, getIsUserEmailAvailable } from '../../../actions/accountActions';
import { getFirebaseTokenByOktaToken, getOktaUserInfoByToken } from '../../../actions/usersActions';
import validateUserProfile from '../validate/validateUserProfile';

const loginToPOINT = async ({ getAccountLogin, setInfo, history, onClickConfirm }) => {
  const userData = await getAccountLogin(history);

  if (userData && userData.id) {

    let dataObj = userData;
    dataObj.isCompleteProfile = true;
    const errors = validateUserProfile(userData);
    if (errors && Object.keys(errors).length) dataObj.isCompleteProfile = false;
    localStorage.setItem('POINT.profileData', JSON.stringify(userData));

    onClickConfirm();

    setInfo({ errors: {} });
    return true;
  } else {
    localStorage.removeItem('POINT.profileData');

    setInfo({
      isTypeUser: true,
      errors: {
        error: userData && userData.isRequestError ? userData.error : 'Error! Please try again later!'
      },
      loading: false,
      errorMessage: true
    });
    setTimeout(() => {
      setInfo({ errorMessage: false, errors: {} });
    }, 6000);

    return false;
  }
};

const loginGoogleAuthProvider = async (props) => {
  props.setInfo({ loadingGoogle: true });

  const fCredential = await FireBaseTools.loginWithProvider(AUTH_PROVIDERS.GOOGLE);

  if (fCredential.errorCode) {
    props.setInfo({ loadingGoogle: false });
    return false;
  }

  if (
    fCredential &&
    fCredential._tokenResponse &&
    fCredential._tokenResponse.email
  ) {
    const isUserAvailable = await getIsUserEmailAvailable(fCredential._tokenResponse.email);
    if (isUserAvailable) {
      await createUserProfile({
        firstName: fCredential._tokenResponse.firstName || fCredential._tokenResponse.displayName || fCredential._tokenResponse.email,
        lastName: fCredential._tokenResponse.lastName || undefined,
        email: fCredential._tokenResponse.email,
        avatar: fCredential._tokenResponse.photoUrl || undefined,
        phone: fCredential._tokenResponse.phoneNumber || undefined,
        isTermsAccepted: true
      });
    }
  }

  const isLogin = await loginToPOINT({
    setInfo: props.setInfo,
    history: props.history,
    getAccountLogin: props.getAccountLogin,
    onClickConfirm: props.onClickConfirm
  });

  props.setInfo({ loadingGoogle: false });
  if (isLogin) {
    return true;
  } else {
    FireBaseTools.logoutUser();
    return false;
  }
};

const loginAppleAuthProvider = async (props) => {
  props.setInfo({ loadingApple: true });

  const fCredential = await FireBaseTools.loginWithProvider(AUTH_PROVIDERS.APPLE);

  if (fCredential.errorCode) {
    props.setInfo({ loadingApple: false });
    return false;
  }

  if (
    fCredential &&
    fCredential._tokenResponse &&
    fCredential._tokenResponse.email
  ) {
    const isUserAvailable = await getIsUserEmailAvailable(fCredential._tokenResponse.email);
    if (isUserAvailable) {
      await createUserProfile({
        firstName: fCredential._tokenResponse.firstName || fCredential._tokenResponse.displayName || fCredential._tokenResponse.email,
        lastName: fCredential._tokenResponse.lastName || undefined,
        email: fCredential._tokenResponse.email,
        avatar: fCredential._tokenResponse.photoUrl || undefined,
        phone: fCredential._tokenResponse.phoneNumber || undefined,
        isTermsAccepted: true
      });
    }
  }

  const isLogin = await loginToPOINT({
    setInfo: props.setInfo,
    history: props.history,
    getAccountLogin: props.getAccountLogin,
    onClickConfirm: props.onClickConfirm
  });

  props.setInfo({ loadingApple: false });
  if (isLogin) {
    return true;
  } else {
    FireBaseTools.logoutUser();
    return false;
  }
};

const loginOktaAuthProvider = async (props) => {
  props.setInfo({ loadingOkta: true, isOktaAuthenticated: false, loading: true });

  try {
    const tokenRes = await getFirebaseTokenByOktaToken({ token: props.token.accessToken.value, connectionId: props.credentials.connectionId });

    if (tokenRes && tokenRes.firebaseCustomToken && tokenRes.email) {
      await FireBaseTools.loginUserWithToken(tokenRes.firebaseCustomToken);
      const isUserAvailable = await getIsUserEmailAvailable(tokenRes.email);
      if (isUserAvailable) {
        let userInfo = await getOktaUserInfoByToken({
          baseUrl: props.credentials.issuer,
          accessToken: props.token.accessToken.value
        });

        await createUser({
          firstName: userInfo.given_name,
          lastName: userInfo.family_name,
          email: tokenRes.email,
          allowNotifications: true,
          isTermsAccepted: true,
          gender: 4
        });
      }
    }

    const isLogin = await loginToPOINT({
      setInfo: props.setInfo,
      history: props.history,
      getAccountLogin: props.getAccountLogin,
      onClickConfirm: props.onClickConfirm
    });

    props.setInfo({ oktaLoading: false });
    if (isLogin) {
      return true;
    } else {
      FireBaseTools.logoutUser();
      return false;
    }
  } catch (e) {
    props.setInfo({ loadingOkta: false });
    FireBaseTools.logoutUser();
    return false;
  }
};


export {
  loginToPOINT,
  loginOktaAuthProvider,
  loginGoogleAuthProvider,
  loginAppleAuthProvider
};
