import React from 'react';
import { withTheme } from 'styled-components';
import Icon from './components/Icon';

const CharityEventsMenu = props => (
        <Icon {...props}>
            <path d="M12,17h4c0.6,0,1-0.4,1-1s-0.4-1-1-1h-4c-0.6,0-1,0.4-1,1S11.4,17,12,17z"/>
            <path d="M19,4h-1V3V2.9C17.9,2.4,17.5,2,17,2c-0.6,0-1,0.4-1,1v1h-1.6c0.3,0.6,0.4,1.3,0.5,2H16v1v0.1
	C16.1,7.6,16.5,8,17,8c0.6,0,1-0.4,1-1V6h1h0.1C19.6,6.1,20,6.5,20,7v4h-6.1c-0.4,0.8-1,1.4-1.6,2H20v6v0.1c-0.1,0.5-0.5,0.9-1,0.9
	H7H6.9C6.4,19.9,6,19.5,6,19v-4.1c-0.7-0.1-1.4-0.3-2-0.5V19v0.2C4.1,20.8,5.4,22,7,22h12h0.2c1.6-0.1,2.8-1.4,2.8-3V7V6.8
	C21.9,5.2,20.6,4,19,4z"/>
            <circle cx="8" cy="16" r="1"/>
            <path d="M7,1C3.7,1,1,3.7,1,7s2.7,6,6,6s6-2.7,6-6S10.3,1,7,1z M7,3c0.3,0,0.6,0,0.9,0.1C7.8,3.3,7.7,3.5,7.5,3.6
	C7.4,3.7,7.2,3.8,7.2,3.8C6.8,4,6.5,4.3,6.2,4.7C6,5.2,5.8,5.8,5.9,6.3c0,0.7,0,1.1-0.5,1.5C4.8,8.3,3.7,8,3,7.8C3,7.5,3,7.2,3,7
	C3,4.8,4.8,3,7,3z M4.5,9C5,9,5.6,8.9,6,8.5C6.9,7.9,6.9,7,6.9,6.3C6.8,6,7,5.5,7.1,5.2C7.2,5,7.5,4.8,7.7,4.8
	C7.8,4.6,8,4.5,8.2,4.4c0.3-0.2,0.5-0.6,0.7-1c0.7,0.3,1.3,0.9,1.7,1.6c-0.7,0.1-1.8,0.4-2,1.2c0,0.2,0,0.5,0,0.7s0,0.5-0.2,0.8
	l0,0.1C8,8.4,7.6,9,8.1,9.8C8.2,10,8.2,10,8.3,10.2c0.2,0.1,0.2,0.3,0.2,0.5C8,10.9,7.5,11,7,11c-1.5,0-2.8-0.8-3.5-2.1
	C3.8,9,4.2,9,4.5,9z M9.5,10.2C9.3,10,9.2,9.8,9.2,9.6C9.1,9.5,9,9.5,9,9.3C8.8,9,8.9,8.8,9.2,8.3l0-0.1C9.5,7.9,9.5,7.5,9.5,7
	c0-0.1,0-0.3,0-0.5c0.1-0.3,0.9-0.5,1.4-0.5C11.3,7.6,10.7,9.2,9.5,10.2z"/>
        </Icon>
    );

export default withTheme(CharityEventsMenu);
