import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const ArrowUpIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M12,8c0.3,0,0.5,0.1,0.7,0.3l6,6c0.4,0.4,0.4,1,0,1.4s-1,0.4-1.4,0L12,10.4l-5.3,5.3c-0.4,0.4-1,0.4-1.4,0
	s-0.4-1,0-1.4l6-6C11.5,8.1,11.7,8,12,8z"/>
        </Icon>
    );

export default withTheme(ArrowUpIcon);
