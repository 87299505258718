import React, { Component } from 'react';
import styled from "styled-components";
import { CheckCircleSolidIcon } from "../Icons/General";
import styles from "../../constants/styles";
import PropTypes from "prop-types";

class Steps extends Component {
    constructor (props) {
        super (props);
        this.state = {};
    }

    renderDot = (step, currentStep) => {
        if (currentStep > step) {
            return (
                <DotCheck>
                    <CheckCircleSolidIcon
                        width={14}
                        height={14}
                        defaultFill={styles.colors.system.info}
                    />
                </DotCheck>
            );
        }
        return (<Dot isActive={currentStep === step} />);
    };

    renderItems = (steps, currentStep) => {
        return steps.map((item, index) => {
            return (
                <Item count={steps.length} key={index}>
                    <Name step={item.step} currentStep={currentStep}>
                        {item.title}
                    </Name>
                    {this.renderDot(item.step, currentStep)}
                </Item>
            );
        });
    }

    render() {
        return (
            <Block>
                <Line count={this.props.steps.length}>
                    <ChildrenLine percent={((this.props.currentStep - 1)/(this.props.steps.length - 1))*100} />
                </Line>
                {this.renderItems(this.props.steps, this.props.currentStep)}
            </Block>
        );
    }
}
export default Steps;

Steps.propTypes = {
    steps: PropTypes.array.isRequired,
    currentStep: PropTypes.number.isRequired
};

const Block = styled.div`
    display: flex;
    margin: 0 auto 16px;
    width: 100%;
    justify-content: space-between;
    position: relative;
    @media screen and (max-width:630px){
      width: 100%;
    }
`;
const Item = styled.div`
    display: flex;
    width: calc(100%/${props => props.count});
    flex: 1;
    position: relative;
    flex-direction: column;
    align-items: center;
    padding: 0 6px;
`;
const Line = styled.div`
    height: 2px;
    display: flex;
    background-color: ${styles.colors.grey};
    position: absolute;
    bottom: 11px;
    width: calc(100% - (100%/${props => props.count}));
    left: calc(100%/${props => props.count*2});
`;
const ChildrenLine = styled.div`
    height: 2px;
    width: ${props => props.percent}%;
    background-color: ${styles.colors.system.info};
`;
const Name = styled.span`
    font-size: 12px;
    line-height: 16px;
    font-family: ${styles.fonts.family.primaryMedium};
    color: ${props => {
        if (props.currentStep === props.step) return styles.colors.dark;
        if (props.currentStep > props.step) return styles.colors.system.info;
        return styles.colors.silver;
    }};
    word-break: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    @media screen and (max-width: 575px){
        display: none;
    }
`;
const Dot = styled.div`
    height: 24px;
    width: 24px;
    display: flex;
    border: 8px solid ${props => props.theme.colors.background.white};
    background-color: ${props => props.isActive ? styles.colors.system.info : styles.colors.grey};
    border-radius: 50%;
    justify-content: center;
    align-items: center;
`;
const DotCheck = styled(Dot)`
    display: flex;
    border: 4px solid ${props => props.theme.colors.background.white};
    background-color: ${props => props.theme.colors.background.white};
    border-radius: 50%;
`;


