import React, { Component } from 'react';
import styled from 'styled-components';
import styles from '../../constants/styles';
import { APPLE_STORE, GOOGLE_PLAY, POINT_URL } from '../../constants/links';

import logo from '../../assets/images/svg/point-logo.svg';
import appStore from '../../assets/images/svg/apple-white.svg';
import googlePlay from '../../assets/images/svg/google-play.svg';

class Header extends Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  render () {
    return (
      <Wrapper>
        <Logo>
          <Link href={POINT_URL} target="_blank">
            <Image src={logo} alt="Point Logo" className="logo-desktop"/>
          </Link>
        </Logo>
        <StoreBtn>
          <LinkApp href={APPLE_STORE} target="_blank">
            <ImageStore src={appStore} alt="App Store"/>
            <SubTitle>Download on the</SubTitle>
            <Title>App Store</Title>
          </LinkApp>
          <LinkApp href={GOOGLE_PLAY} target="_blank">
            <ImagePlay src={googlePlay} alt="Google Play"/>
            <SubTitle>Get it on</SubTitle>
            <Title>Google Play</Title>
          </LinkApp>
        </StoreBtn>
      </Wrapper>
    );
  }
}

export default Header;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 22px 32px;
  box-sizing: border-box;
  @media screen and (max-width: 450px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 0;
  }
`;
const Logo = styled.div`
  display: flex;
  margin: -2px 0 -2px -8px;
  @media screen and (max-width: 450px) {
    margin: -2px -8px 16px -8px;
  }
`;
const Image = styled.img`
  height: 25px;
`;
const ImageStore = styled.img`
  height: 18px;
  position: absolute;
  left: 12px;
  top: 8px;
`;
const ImagePlay = styled(ImageStore)`
  top: 9px;
`;
const StoreBtn = styled.div`
  display: flex;
  flex-direction: row;
  margin: -8px -8px -8px 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 450px) {
    margin: -8px;
  }
`;
const Link = styled.a`
  display: flex;
  margin: 8px;
`;
const LinkApp = styled.a`
  position: relative;
  padding: 4px 16px 7px 34px;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  background: black;
  margin: 8px;
  text-decoration: none;
`;
const Title = styled.span`
  font-family: ${styles.fonts.family.primaryMedium};
  margin: 0;
  font-size: 13px;
  line-height: 13px;
  color: white;
  text-transform: none;
  text-decoration: none;
`;
const SubTitle = styled.span`
  font-family: ${styles.fonts.family.primaryMedium};
  margin: 0;
  font-size: 8px;
  line-height: 12px;
  color: white;
  text-transform: none;
  text-decoration: none;
`;
