import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const TimeIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M12,6c0.6,0,1,0.4,1,1v4.4l3.4,1.7c0.5,0.2,0.7,0.8,0.4,1.3C16.7,14.8,16.4,15,16,15c-0.1,0-0.3,0-0.4-0.1
	l-4-2C11.2,12.7,11,12.4,11,12V7C11,6.4,11.4,6,12,6z M12,4c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S16.4,4,12,4z M12,2
	c5.5,0,10,4.5,10,10s-4.5,10-10,10S2,17.5,2,12S6.5,2,12,2z"/>
        </Icon>
    );

export default withTheme(TimeIcon);
