import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const TrainingIcon = props => (
    <Icon {...props} viewBox={'0 0 24 24'}>
      <path fillRule="evenodd" clipRule="evenodd" d="M12 8C8.68629 8 6 10.6863 6 14C6 17.3137 8.68629 20 12 20C15.3137 20 18 17.3137 18 14C18 10.6863 15.3137 8 12 8ZM4 14C4 9.58172 7.58172 6 12 6C16.4183 6 20 9.58172 20 14C20 18.4183 16.4183 22 12 22C7.58172 22 4 18.4183 4 14Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9 3C9 2.44772 9.44772 2 10 2H14C14.5523 2 15 2.44772 15 3C15 3.55228 14.5523 4 14 4H10C9.44772 4 9 3.55228 9 3Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.293 14.7071C10.9025 14.3166 10.9025 13.6834 11.293 13.2929L13.293 11.2929C13.6836 10.9024 14.3167 10.9024 14.7073 11.2929C15.0978 11.6834 15.0978 12.3166 14.7073 12.7071L12.7073 14.7071C12.3167 15.0976 11.6836 15.0976 11.293 14.7071Z" />
    </Icon>
);

export default withTheme(TrainingIcon);
