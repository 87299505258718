import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from '../../components/Buttons';
import styles from '../../constants/styles';
import { INPUT_SIZE, INPUT_TYPE } from '../../constants';
import { Modal } from '../../components/Modal';
import { Input, Select } from '../../components/Fields';
import ErrorMessage from '../../components/ErrorMessage';
import { getAccountShortInfo } from '../../actions/accountActions';
import { getConnectedApplicationCredentials } from '../../actions/integrationActions';
import OktaSignInWidget from './components/OktaSignInWidget';
import { loginOktaAuthProvider } from './components/providers';
import { getUser } from '../../actions/profileActions';

import oktaLogo from '../../assets/images/svg/logo_okta.png';

const oktaDomains = {
  OKTA: 'okta.com',
  OKTA_EMEA: 'okta-emea.com',
  OKTAPREVIEW: 'oktapreview.com'
};

class SignInOkta extends Component {
  constructor (props) {
    super(props);

    this.state = {
      errors: {},
      loadingOkta: false,
      errorMessage: false,
      isOktaAuthenticated: false,
      domain: 'okta.com',
      url: '',
      baseUrl: '',
      clientId: '',
    };
  }

  setInfo = (name, value) => {
    let { errors } = this.state;
    let newState = {};
    delete errors[name];
    newState.errors = errors;
    newState[name] = value;
    this.setState(newState);
  }

  singUpOktaUser = async () => {
    this.setState({ loadingOkta: true });
    let { errors, url, domain } = this.state;
    if (!url) {
      errors.url = 'Whoops! Url is required.';
    }
    if (url && url.search('https://') > -1) {
      errors.url = 'Whoops! Please, enter a correct mycompany.';
    }

    if (Object.keys(errors).length) {
      this.setState({
        errors,
        errorMessage: true,
        loadingOkta: false
      });
      setTimeout(() => {
        this.setState({ errorMessage: false });
      }, 6000);
    } else {

      const credentials = await getConnectedApplicationCredentials({ issuer: `https://${url}.${domain}` });

      if (credentials && credentials.clientId) {
        this.setState({
          credentials: credentials
        });
        this.setState({
          isOktaAuthenticated: true,
          loadingOkta: false
        });
      } else {
        errors.url = `Whoops! Please, enter a correct mycompany.`;
        this.setState({
          errors,
          errorMessage: true,
          loadingOkta: false
        });
        setTimeout(() => {
          this.setState({ errorMessage: false });
        }, 6000);
      }
    }
  }

  render () {

    const { errors } = this.state;

    const oktaSignInConfig = {
      logo: oktaLogo,
      brandName: 'POINT',
      customButtons: [{
        title: 'Back',
        className: 'btn-customAuth',
        click: function() {
          window.location.href = '/';
        }
      }],
      clientId: this.state.credentials && this.state.credentials.clientId ? this.state.credentials.clientId : '',
      baseUrl: this.state.credentials && this.state.credentials.issuer ? this.state.credentials.issuer : '',
      redirectUri: `${window.location.origin}/login/callback`,
      scopes: ["openid", "profile", "offline_access"],
      pkce: true,
      authParams: {
        display: 'page',
        responseType: 'id_token, token',
        pkce: true
      }
    };

    return (
      <Modal
        width={styles.modal.size.small}
        visible={this.props.visible}
        onClose={() => {
          this.props.setInfo({ showSignInOkta: false });
          if (this.props.fromPopup && this.props.fromPopup === 'signIn') {
            this.props.setInfo({ showSignIn: true });
          } else {
            this.props.setInfo({ showSignUp: true });
          }
          this.setState({
            isOktaAuthenticated: false,
            domain: 'okta.com',
            url: '',
          });
        }}
        buttons={null}
      >
        {this.state.isOktaAuthenticated ? (
            <Wrapper>
              <OktaSignInWidget
                  config={oktaSignInConfig}
                  onSuccess={(token) => loginOktaAuthProvider({
                    token: token,
                    credentials: this.state.credentials,
                    setInfo: (e) => this.setState(e),
                    getAccountLogin: getUser,
                    history: this.props.history,
                    onClickConfirm: this.props.onClickConfirm
                  })}
                  onError={(error) => console.log('error = ', error)}
              />
            </Wrapper>
        ) : (
            <OktaAccountChooser>
              <AuthHeader>
                <LogoImg src={oktaLogo} alt={'Okta Logo'}/>
              </AuthHeader>
              <AuthContent>
                <AuthContentInner>
                  <AccountChooser>
                    <OFormContent>
                      <OFormHead>Enter your sign-in URL</OFormHead>
                      <OFormInputField>
                        <table>
                          <tbody>
                          <tr>
                            <td>
                              <Input
                                  size={INPUT_SIZE.SMALL}
                                  type={INPUT_TYPE.TEXT}
                                  value={this.state.url}
                                  id="subdomain"
                                  name="subdomain"
                                  placeholder="mycompany"
                                  onChange={(v) => this.setInfo( 'url', v.target.value )}
                                  dataTestId="okta-subdomain"
                              />
                            </td>
                            <td>
                              <Period>
                                <div>.</div>
                              </Period>
                            </td>
                            <td>
                              <div className="select-container">
                                <Select
                                    size={INPUT_SIZE.SMALL}
                                    value={this.state.domain}
                                    options={[
                                      { value: oktaDomains.OKTA, label: oktaDomains.OKTA },
                                      { value: oktaDomains.OKTA_EMEA, label: oktaDomains.OKTA_EMEA },
                                      { value: oktaDomains.OKTAPREVIEW, label: oktaDomains.OKTAPREVIEW }
                                    ]}
                                    onChange={(value) => this.setInfo('domain', value)}
                                    placeholder={'Select domain'}
                                />
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </OFormInputField>
                      {errors.url ? (
                          <ErrorMessage
                              text={this.state.errors.url || "Your organization has not connected Okta with POINT yet."}
                              dataTestId="credentials-error"
                          />
                      ) : null}
                      <OFormButtonBar>
                        <NextButton
                            block
                            size={'lg'}
                            value="Next"
                            variant={'info'}
                            onClick={() => this.singUpOktaUser()}
                            isLoading={this.state.loadingOkta}
                            dataTestId={'okta-next-button'}
                        />
                      </OFormButtonBar>
                    </OFormContent>
                  </AccountChooser>
                </AuthContentInner>
                <RegistrationContainer>
                  <RegistrationLabel>
                    <a title="Don't know your sign-in URL?" aria-label="Don't know your sign-in URL?"
                       className="registration-link" href="https://support.okta.com/help/s/sign-in-url" target="_blank">
                      Don't know your sign-in URL?
                    </a>
                  </RegistrationLabel>
                </RegistrationContainer>
              </AuthContent>
            </OktaAccountChooser>
        )}
      </Modal>
    );
  }
}

SignInOkta.propTypes = {
  getAccountShortInfo: PropTypes.func.isRequired,
  onClickConfirm: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  fromPopup: PropTypes.string,
  location: PropTypes.object
};

SignInOkta.defaultProps = {
  visible: false,
  location: null,
  fromPopup: null
};

const mapStateToProps = () => ({});
export default connect(mapStateToProps, { getAccountShortInfo })(SignInOkta);

const Wrapper = styled.div`
  #okta-sign-in {
    width: 376px;
    min-width: inherit;
    margin-top: 30px;
    border: none;
    max-width: 100%;
    .auth-header {
      padding: 0 0 30px;
      border: none;
    }
    .auth-content {
      padding: 20px 42px 10px;
      border-style: solid;
      border-width: 1px;
      border-color: #ddd #ddd #d8d8d8;
    }

    @media screen and (max-width: 425px) {
      .auth-content {
        padding: 20px 0 10px;
        border-width: 1px 0 0;
      }
    }
  }
`;
const OktaAccountChooser = styled.div`
  border-radius: 3px;
  height: auto;
  margin: 30px auto 0;
  width: 376px;
  min-width: 300px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #777;
  font-family: "proxima nova", Arial, Helvetica, sans-serif;
  background-color: #fff;
  box-shadow: 0 2px 0 rgb(175 175 175 / 12%);
  @media screen and (max-width: 530px) {
    min-width: inherit;
    max-width: 100%;
  }
`;
const AuthHeader = styled.div`
  padding: 0 0 30px;
  position: relative;
  z-index: 10;
  -moz-transition: padding-bottom .4s;
  -webkit-transition: padding-bottom .4s;
  transition: padding-bottom .4s;
`;
const LogoImg = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 200px;
  max-height: 40px;
`;
const AuthContent = styled.div`
  display: block;
  padding: 20px 42px 10px;
  -moz-transition: padding-top .4s;
  -webkit-transition: padding-top .4s;
  transition: padding-top .4s;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd #ddd #d8d8d8;
  @media screen and (max-width: 530px) {
    min-width: inherit;
    max-width: 100%;
    border-width: 1px 0 0;
    padding: 20px 0 10px;
  }
`;
const AuthContentInner = styled.div`
  position: relative;
`;
const AccountChooser = styled.div``;
const OFormContent = styled.div``;
const OFormHead = styled.div`
  color: #5e5e5e;
  font-family: ${styles.fonts.family.primaryBold};
  font-size: 16px;
  line-height: 1.5;
  margin-top: 10px;
  text-transform: none;
  margin-bottom: 16px;
  text-align: center;
`;
const OFormInputField = styled.div`
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
`;
const Period = styled.div`
  width: 23px;
  height: 38px;
  text-align: center;

  div {
    display: block;
    position: relative;
    padding: 10px 0 0 2px;
    font-weight: 700;
  }
`;
const OFormButtonBar = styled.div`
  margin-top: 24px;
  margin-bottom: 20px;
`;
const NextButton = styled(Button)`
  background: linear-gradient(#007dc1, #0073b2) repeat scroll 0 0, #007dc1 none repeat scroll 0 0;
  border-color: #004b75 #004b75 #00456a;
  box-shadow: 0 1px 0 rgb(0 0 0 / 15%), 0 1px 0 0 rgb(255 255 255 / 10%) inset;
  font-family: ${styles.fonts.family.primaryBook};
`;
const RegistrationContainer = styled.div`
  text-align: center;
`;
const RegistrationLabel = styled.div`
  color: #5e5e5e;
  font-weight: 600;
  margin-bottom: 30px;

  a {
    color: #007dc1;
    font-size: 16px;
    font-weight: 400;
  }

  a:active, a:focus, a:hover, a:link {
    text-decoration: none;
    color: #777;
  }
`;
