import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../constants/styles';

const Equality = (props) => (
    <Container isActive={props.isActive}>
      <svg
          width="20px"
          height="20px"
          id="equality"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          enableBackground="new 0 0 24 24"
          xmlSpace="preserve"
      >
        <g id="mask">
          <path fill={props.isActive ? styles.colors.causes.equality : styles.colors.silver} d="M2,12c0,5.5,4.5,10,10,10c5.5,0,10-4.5,10-10c0-5.5-4.5-10-10-10
	C6.5,2,2,6.5,2,12z M15,14c0.6,0,1,0.4,1,1s-0.4,1-1,1H9c-0.6,0-1-0.4-1-1s0.4-1,1-1H15z M15,8c0.6,0,1,0.4,1,1s-0.4,1-1,1H9
	c-0.6,0-1-0.4-1-1s0.4-1,1-1H15z"/>
        </g>
      </svg>
    </Container>
);

export default Equality;

Equality.propTypes = {
  isActive: PropTypes.bool
};

Equality.defaultProps = {
  isActive: true
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: ${props => props.isActive ? `${styles.colors.causes.equality}1f` : styles.colors.mostlyWhite};
    border-radius: 20px;
`;