import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const TwitterIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M18.4,9.2c0,1.5-0.4,2.9-1.1,4.3c-0.8,1.5-1.8,2.7-3.2,3.5c-1.5,1-3.2,1.5-5.1,1.5c-1.8,0-3.5-0.5-5-1.5
	c0.2,0,0.5,0,0.8,0c1.5,0,2.9-0.5,4.1-1.4c-0.7,0-1.4-0.2-1.9-0.6s-0.9-1-1.1-1.6c0.2,0,0.4,0,0.6,0c0.3,0,0.6,0,0.9-0.1
	c-0.8-0.2-1.4-0.6-1.9-1.2S4.7,10.8,4.7,10V9.9c0.5,0.3,0.9,0.4,1.5,0.4C5.8,10,5.4,9.6,5.1,9.1s-0.4-1-0.4-1.6s0.2-1.1,0.5-1.6
	c0.8,1,1.8,1.8,3,2.4s2.4,0.9,3.8,1c0-0.2-0.1-0.5-0.1-0.8c0-0.6,0.1-1.1,0.4-1.6S13,6,13.5,5.7s1-0.4,1.6-0.4
	c0.5,0,0.9,0.1,1.3,0.3s0.8,0.4,1.1,0.8c0.8-0.1,1.4-0.4,2.1-0.8c-0.2,0.8-0.7,1.4-1.4,1.8C18.8,7.5,19.4,7.3,20,7
	c-0.5,0.7-1,1.2-1.7,1.7C18.4,8.8,18.4,9,18.4,9.2z"/>
        </Icon>
    );

export default withTheme(TwitterIcon);
