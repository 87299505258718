import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const WomenIcon = props => (
    <Icon {...props} viewBox={'0 0 24 24'}>
        <path d="M15,2c-3.9,0-7,3.1-7,7c0,1.6,0.5,3.1,1.4,4.2l-2.9,2.8l-2.9-2.9c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4
	l2.8,2.8l-2.8,2.8c-0.4,0.4-0.4,1,0,1.4C2.5,21.9,2.7,22,3,22c0.3,0,0.5-0.1,0.7-0.3l2.9-2.8l2.8,2.8c0.4,0.4,1,0.4,1.4,0
	c0.4-0.4,0.4-1,0-1.4L8,17.5l2.9-2.9C12,15.5,13.5,16,15,16c3.9,0,7-3.1,7-7S18.9,2,15,2z M15,14c-1.3,0-2.6-0.5-3.5-1.4
	c0,0,0,0,0,0c0,0,0,0,0,0C10.6,11.7,10,10.4,10,9c0-2.8,2.2-5,5-5s5,2.2,5,5S17.8,14,15,14z"/>
    </Icon>
);

export default withTheme(WomenIcon);
