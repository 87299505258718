import React from 'react';
import { withTheme } from 'styled-components';
import Icon from './components/Icon';

const VolunteersMenu = props => (
        <Icon {...props}>
            <path d="M5.7,13c-0.2,0-0.3,0.1-0.5,0.2c-0.3,0.2-0.3,0.6-0.1,0.9l3.5,4.7C9.2,19.5,10.1,20,11,20h6
	c1.7,0,3-1.3,3-3v-3c0,0,0-0.1,0-0.1l0-6.4C20,7.2,19.8,7,19.5,7C19.2,7,19,7.2,19,7.5V11c0,0.6-0.4,1-1,1c-0.6,0-1-0.4-1-1V5.5
	C17,5.2,16.8,5,16.5,5C16.2,5,16,5.2,16,5.5V11c0,0.6-0.4,1-1,1c-0.6,0-1-0.4-1-1V4.5C14,4.2,13.8,4,13.5,4S13,4.2,13,4.5V11
	c0,0.6-0.4,1-1,1s-1-0.4-1-1V6.5C11,6.2,10.8,6,10.5,6S10,6.2,10,6.5l0,4.5l0,2c0,0,0,0.1,0,0.1v1.4c0,0.4-0.2,0.8-0.6,0.9
	c-0.4,0.2-0.8,0.1-1.1-0.2l-2-2C6.1,13.1,5.9,13,5.7,13 M17,22h-6c-1.6,0-3.1-0.8-4-2l-3.5-4.7c-0.9-1.2-0.6-2.8,0.5-3.7
	c1.1-0.9,2.7-0.8,3.7,0.2L8,12.1L8,11l0-4.5C8,5.1,9.1,4,10.5,4c0.2,0,0.4,0,0.5,0.1C11.2,2.9,12.3,2,13.5,2c0.9,0,1.7,0.5,2.1,1.2
	C15.9,3.1,16.2,3,16.5,3c1.2,0,2.3,0.9,2.5,2.1C19.1,5,19.3,5,19.5,5C20.9,5,22,6.1,22,7.5l0,6.5c0,0,0,0.1,0,0.1V17
	C22,19.8,19.7,22,17,22"/>
        </Icon>
    );

export default withTheme(VolunteersMenu);
