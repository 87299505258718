import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CopyIcon } from '../../../components/Icons/General';
import styles from '../../../constants/styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { notification } from 'antd';
import { P2 } from '../../../components/Typography';

class EventVirtualUrl extends Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  copyToClipboard = () => {
    notification.success({
      message: 'Copied to Clipboard!'
    });
  };

  render () {
    return (
      <CopyToClipboard onCopy={this.copyToClipboard} text={this.props.onlineAddressUrl}>
        <Block>
          <Span>Virtual Event URL:</Span>
          <Pre>{this.props.onlineAddressUrl}</Pre>
          <Icon><CopyIcon width="16" heigth="16" defaultFill={styles.colors.silver}/></Icon>
        </Block>
      </CopyToClipboard>
    );
  }
}

EventVirtualUrl.propTypes = {
  onlineAddressUrl: PropTypes.string.isRequired,
};

export default EventVirtualUrl;

const Block = styled.div`
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  border: 1px solid ${styles.borders.color};
  border-radius: 4px;
  padding: 5px 40px 5px 12px;
  position: relative;
  margin: 0 0 8px;
`;
const Span = styled.span`
  color: ${styles.colors.silver};
  font-size: 12px;
  line-height: 16px;
  font-family: ${styles.fonts.family.primaryMedium};
  white-space: nowrap;
  padding: 4px 10px 0 0;
  @media screen and (max-width: 530px) {
    display: none;
  }
`;
const Pre = styled(P2)`
  color: ${styles.colors.medium};
  font-family: ${styles.fonts.family.primaryMedium};
  word-break: break-word;
  margin: 0;
`;
const Icon = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 8px;
  width: 16px;
  height: 16px;
`;
