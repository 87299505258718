import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const EditIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M16.7,4.5c-0.7-0.7-1.9-0.7-2.7-0.1l-9,9c-0.3,0.3-0.5,0.8-0.6,1.2L4,18.9c0,0.3,0.1,0.6,0.3,0.8
	C4.5,19.9,4.7,20,5,20h0.1l4.2-0.4c0.5,0,0.9-0.2,1.2-0.6l9-9c0.7-0.8,0.7-2-0.1-2.7L16.7,4.5z M9.1,17.6l-3,0.3l0.3-3L12,9.3
	l2.7,2.7L9.1,17.6z M16,10.6L13.3,8l2-2L18,8.7L16,10.6z"/>
        </Icon>
    );

export default withTheme(EditIcon);
