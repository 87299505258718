import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const BagIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M17.5,3h-11L5,5h14L17.5,3z M16,9c0.6,0,1,0.4,1,1c0,2.8-2.2,5-5,5
	s-5-2.2-5-5c0-0.6,0.4-1,1-1s1,0.4,1,1c0,1.7,1.3,3,3,3s3-1.3,3-3C15,9.4,15.4,9,16,9z M20,7H4v13c0,0.6,0.4,1,1,1h14
	c0.6,0,1-0.4,1-1V7z M18,1c0.3,0,0.6,0.1,0.8,0.4l3,4l0,0c0,0,0,0,0,0.1l-0.1-0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0
	c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0.1,0,0.2,0,0.2v14c0,1.7-1.3,3-3,3H5c-1.7,0-3-1.3-3-3V6c0-0.1,0-0.2,0-0.2c0,0,0,0,0-0.1
	c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0l3-4C5.4,1.1,5.7,1,6,1H18z"/>
        </Icon>
    );

export default withTheme(BagIcon);
