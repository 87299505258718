import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const ShiftIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M5,12c0,0.6,0.4,1,1,1h4c0.6,0,1-0.4,1-1V8c0-0.6-0.4-1-1-1S9,7.4,9,8v3H6C5.4,11,5,11.4,5,12z"/>
            <path d="M24,16L24,16c0-0.2,0-0.3-0.1-0.4c0-0.1-0.1-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.1l-2.9-3c-0.4-0.4-1-0.4-1.4,0
	s-0.4,1,0,1.4l1.2,1.3H15c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h5.6l-1.3,1.3c-0.4,0.4-0.4,1,0,1.4c0,0,0,0,0,0c0.4,0.4,1,0.4,1.4,0
	c0,0,0,0,0,0l3-3c0.1-0.1,0.2-0.2,0.2-0.3C24,16.3,24,16.1,24,16z"/>
            <path d="M14,8L14,8c0,0.2,0,0.3,0.1,0.4c0,0.1,0.1,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.1l2.9,3c0.4,0.4,1,0.4,1.4,0
	c0.4-0.4,0.4-1,0-1.4L17.3,9H23c0.6,0,1-0.4,1-1s-0.4-1-1-1h-5.6l1.3-1.3c0.4-0.4,0.4-1,0-1.4c0,0,0,0,0,0c-0.4-0.4-1-0.4-1.4,0
	c0,0,0,0,0,0l-3,3c-0.1,0.1-0.2,0.2-0.2,0.3C14,7.7,14,7.9,14,8z"/>
            <path d="M13.7,19.1C12.6,19.7,11.4,20,10,20c-4.4,0-8-3.6-8-8c0-4.4,3.6-8,8-8c1.4,0,2.7,0.3,3.8,1l1.5-1.5
	C13.7,2.6,11.9,2,10,2C4.5,2,0,6.5,0,12s4.5,10,10,10c1.9,0,3.7-0.5,5.2-1.5L13.7,19.1z"/>
        </Icon>
    );

export default withTheme(ShiftIcon);
