import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const LocationIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M12,2C7.6,2,4,5.5,4,9.9c0,5.5,7,11.6,7.3,11.8c0.4,0.3,0.9,0.3,1.3,0C13,21.5,20,15.4,20,9.9
		C20,5.5,16.4,2,12,2z M12,19.7c-1.7-1.6-6-6-6-9.7c0-3.3,2.7-6,6-6s6,2.7,6,6C18,13.6,13.7,18.1,12,19.7z"/>
            <path d="M12,6c-1.9,0-3.5,1.6-3.5,3.5S10.1,13,12,13s3.5-1.6,3.5-3.5S13.9,6,12,6z M12,11c-0.8,0-1.5-0.7-1.5-1.5
		S11.2,8,12,8s1.5,0.7,1.5,1.5S12.8,11,12,11z"/>
        </Icon>
    );

export default withTheme(LocationIcon);
