import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const DonationsMenu = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M12,5.7c0.5,0,0.8,0.4,0.8,0.8v1.2c0.3,0.1,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.2,0.5,0.3l0.1,0.1
	c1.1,0.8,0,2.3-0.9,1.5c-0.6-0.5-2.1-0.7-2.1,0.3c0,0.2,0.1,0.4,0.3,0.5c0.2,0.1,0.4,0.2,0.7,0.3c0.3,0,0.6,0.1,0.9,0.2
	c0.3,0.1,0.7,0.2,0.9,0.3c0.3,0.1,0.5,0.4,0.7,0.7c0.2,0.3,0.3,0.8,0.3,1.3c0,0.7-0.2,1.3-0.6,1.8c-0.4,0.4-0.9,0.7-1.6,0.9v1.3
	c0,0.5-0.4,0.8-0.9,0.8c-0.5,0-0.8-0.4-0.8-0.8v-1.3c-0.4-0.1-0.7-0.2-1-0.4c-0.3-0.2-0.5-0.4-0.7-0.5l-0.2-0.2
	c-0.8-1,0.5-2.1,1.2-1.3c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0.3,0.8,0.4,1.1,0.4c0.7,0,1.1-0.3,1.1-0.9c0-0.2-0.1-0.4-0.4-0.5
	c-0.3-0.1-0.6-0.2-1-0.3c-0.4-0.1-0.8-0.2-1.1-0.4c-0.4-0.2-0.7-0.4-1-0.8c-0.3-0.4-0.4-0.8-0.4-1.4c0-0.6,0.2-1.1,0.5-1.5
	c0.4-0.5,0.9-0.8,1.5-0.9V6.5C11.1,6.1,11.5,5.7,12,5.7z M12,4c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S16.4,4,12,4z M12,2
	c5.5,0,10,4.5,10,10s-4.5,10-10,10S2,17.5,2,12S6.5,2,12,2z"/>
        </Icon>
    );

export default withTheme(DonationsMenu);
