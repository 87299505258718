import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const CopyIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M12.3,3C13.8,3,15,4.2,15,5.7V9h3c1.7,0,3,1.3,3,3v6c0,1.7-1.3,3-3,3h-6c-1.7,0-3-1.3-3-3v-3
	H5.7C4.2,15,3,13.8,3,12.3V5.7C3,4.2,4.2,3,5.7,3H12.3z M18,11h-6c-0.6,0-1,0.4-1,1v6c0,0.6,0.4,1,1,1h6c0.6,0,1-0.4,1-1v-6
	C19,11.4,18.6,11,18,11z M12.3,5H5.7C5.3,5,5,5.3,5,5.7v6.7C5,12.7,5.3,13,5.7,13H9v-1c0-1.7,1.3-3,3-3h1V5.7C13,5.3,12.7,5,12.3,5z
	"/>
        </Icon>
    );

export default withTheme(CopyIcon);
