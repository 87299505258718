import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import styles from '../../constants/styles';

const Hint = (props) => {
    return (props.shortValue && props.value.toString() !== props.shortValue.toString()) ? (
        <HintBlock
            data-testid={props.dataTestId}
            className={props.className}
            isDefaultCursor={props.value.toString() === props.shortValue.toString()}
        >
            <span
                data-tip={props.value}
                data-for={props.value.toString()}
                className={props.block ? 'block' : null}
            >
                {props.shortValue}{props.ellipses || ''}
            </span>
            <ReactTooltip
                place="top"
                type="light"
                effect="float"
                id={props.value.toString()}
                textColor={styles.colors.medium}
                borderColor={styles.colors.grey}
                border={true}
                backgroundColor={'white'}
                className={'point-hint'}
            />
        </HintBlock>
    ) : (<HintBlock
            data-testid={props.dataTestId}
            className={props.className}
            isDefaultCursor={true}
        >
            {props.value}
        </HintBlock>);
}

export default Hint;

Hint.propTypes = {
    value: PropTypes.string.isRequired,
    shortValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    ellipses: PropTypes.string,
    dataTestId: PropTypes.string,
    className: PropTypes.string,
    block: PropTypes.bool
};

Hint.defaultProps = {
    ellipses: '',
    dataTestId: null,
    className: null,
    block: false
};

const HintBlock = styled.span`
    display: flex;
    position: relative;
    white-space: nowrap;
    cursor: ${props => props.isDefaultCursor ? 'default' : 'help'};
    font-family: inherit;
    span {
        font-family: inherit;
    }
    .point-hint {
        border-radius: 4px !important;
        opacity: 1 !important;
    }
    .block {
        width: 100% !important;
    }
`;
