import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const NetworkSecretIcon = props => (
    <Icon {...props} viewBox={'0 0 24 24'}>
        <path d="M20,10h-2.1L17,3.9C16.9,3.4,16.5,3,16,3H8C7.5,3,7.1,3.4,7,3.9L6.1,10H4c-0.6,0-1,0.4-1,1s0.4,1,1,1h3h13
	c0.6,0,1-0.4,1-1S20.6,10,20,10z"/>
        <path d="M16.5,14c-1.1,0-2.2,0.5-2.8,1.5l0,0c-0.3-0.2-0.6-0.3-0.9-0.4l0,0C12.5,15,12.3,15,12,15
	c-0.6,0-1.1,0.2-1.7,0.5c-0.6-1-1.7-1.5-2.8-1.5C5.6,14,4,15.6,4,17.5S5.6,21,7.5,21s3.5-1.6,3.5-3.5v-0.1c0.4-0.3,0.7-0.4,1-0.4
	c0.2,0,0.5,0.1,0.8,0.2l0,0c0.1,0,0.2,0.1,0.2,0.2v0.1c0,1.9,1.6,3.5,3.5,3.5s3.5-1.6,3.5-3.5S18.4,14,16.5,14z M7.5,19
	C6.7,19,6,18.3,6,17.5S6.7,16,7.5,16S9,16.7,9,17.5S8.3,19,7.5,19z M16.5,16c0.8,0,1.5,0.7,1.5,1.5S17.3,19,16.5,19S15,18.3,15,17.5
	S15.7,16,16.5,16z"/>
    </Icon>
);

export default withTheme(NetworkSecretIcon);
