import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import FireBaseTools from '../../api/firebase';
import { createUserProfile, getUserEmailsStatus, updateProfile } from '../../actions/accountActions';
import styles from '../../constants/styles';
import { AUTH_PROVIDERS, INPUT_SIZE, INPUT_TYPE } from '../../constants';
import { COMMUNITY_GUIDELINES, POINT_PRIVACY_POLICY, POINT_TERMS } from '../../constants/links';
import { Modal } from '../../components/Modal';
import { H2, H4, H5 } from '../../components/Typography';
import { Checkbox, ImageUploader, Input } from '../../components/Fields';
import ErrorMessage from '../../components/ErrorMessage';
import { Button } from '../../components/Buttons';
import PasswordStrength from './components/PasswordStrength';
import validateSignUp from '../Events/validate/signUp';
import { getUser } from '../../actions/profileActions';
import { loginGoogleAuthProvider, loginAppleAuthProvider } from './components/providers';

import googleIcon from '../../assets/images/svg/google.svg';
import appleIcon from '../../assets/images/svg/apple.svg';
import oktaIcon from '../../assets/images/svg/okta_logo.svg';
import logo from '../../assets/images/svg/point-logo.svg';

class SignUp extends Component {
  constructor (props) {
    super(props);
    this.state = {
      profile: {
        firstName: null,
        lastName: null,
        email: '',
        username: '',
        birthday: null,
        zipCode: '',
        password: '',
        confirmPassword: '',
        avatar: '',
        terms: false,
        useGoogleAccount: false
      },
      isGoogleAuth: false,
      isNewUser: true,
      passwordStrengthShow: false,
      errors: {},
      loading: false,
      loadingGoogle: false,
      loadingApple: false
    };
  }

  clearData = () => {
    this.setState({
      profile: {
        firstName: null,
        lastName: null,
        email: '',
        username: '',
        birthday: null,
        zipCode: '',
        password: '',
        confirmPassword: '',
        avatar: '',
        terms: false,
        useGoogleAccount: false
      },
      errors: {},
    });
  };

  setProfile = (obj) => {
    const objProperty = Object.keys(obj)[0];
    delete this.state.errors[objProperty];
    this.setState({
      ...this.state,
      profile: Object.assign(this.state.profile, obj)
    });
  };

  signUp = async () => {
    this.setState({ loading: true });

    const data = {
      firstName: this.state.profile.firstName,
      lastName: this.state.profile.lastName,
      email: this.state.profile.email,
      username: this.state.profile.username,
      birthday: parseInt(moment(this.state.profile.birthday).format('x'), 10),
      address: {
        zipCode: this.state.profile.zipCode.replace(/\D/g, '')
      },
      password: this.state.profile.password,
      confirmPassword: this.state.profile.confirmPassword,
      avatar: this.state.profile.avatar,
      isTermsAccepted: this.state.profile.terms
    };

    const errors = await validateSignUp(data);

    if (Object.keys(errors).length) {
      this.setState({ errors, loading: false });
    } else {
      if (this.state.isGoogleAuth) {
        try {
          await FireBaseTools.loginWithPassword({email: this.state.profile.email, password: this.state.profile.password});
        } catch (e) {
          console.error('Error! Please try again later.');
        }
      }

      await FireBaseTools.registerUser({ email: data.email, password: data.password });

      const newUser = await createUserProfile({
        firstName: data.firstName,
        lastName: data.lastName,
        username: data.username,
        birthday: data.birthday,
        address: {
          zipCode: data.address.zipCode
        },
        isTermsAccepted: data.isTermsAccepted
      });

      let userFb = await FireBaseTools.fetchUser();
      if (!userFb) {
        userFb = await FireBaseTools.loginUser({
          email: this.state.profile.email,
          password: this.state.profile.password
        });
      }

      if (userFb.errorCode) {
        throw new Error('Error! Please try again later.');
      }

      if (newUser.id) {
        const profilePath = 'users/profile/:userId/'.replace(':userId', newUser.id || 0);
        let avatarURL = '';
        let res;
        if (this.state.profile.avatar && this.state.profile.avatar instanceof Blob) {
          const avatarURL = await FireBaseTools.uploadFile({
            file: this.state.profile.avatar,
            filePath: profilePath,
            fileName: this.state.profile.avatar.name + '.png'
          });
          res = await updateProfile({
            avatar: avatarURL
          });
        }

        const data = await getUserEmailsStatus();
        localStorage.setItem('POINT.profileData', JSON.stringify({
          id: newUser.id,
          email: this.state.profile.email,
          avatar: res ? res.avatar : avatarURL,
          firstName: this.state.profile.firstName,
          lastName: this.state.profile.lastName,
          birthday: this.state.profile.birthday,
          address: {
            zipCode: this.state.profile.zipCode,
          },
          username: this.state.profile.username,
          isPrimaryEmailVerified: (data && data.isPrimaryEmailVerified) || false
        }));
        this.clearData();
        this.props.onClickConfirm();
        this.setState({ errors: {} });
      } else {
        localStorage.removeItem('POINT.profileData');
        localStorage.removeItem('POINT.dictionary');
      }
    }
    this.setState({ loading: false });
  };

  render () {

    const { errors } = this.state;

    if (
      this.props.location &&
      this.props.location.accountData &&
      this.props.location.accountData.email !== this.state.profile.email
    ) {
      this.setState({
        ...this.state,
        isGoogleAuth: this.props.location.accountData.provider === AUTH_PROVIDERS.GOOGLE,
        profile: {
          ...this.state.profile,
          firstName: this.props.location.accountData.firstName,
          lastName: this.props.location.accountData.lastName,
          email: this.props.location.accountData.email,
          avatar: this.props.location.accountData.avatar,
          useGoogleAccount: this.props.location.accountData.provider === AUTH_PROVIDERS.GOOGLE
        }
      });
    }


    return (
      <Modal
        width={styles.modal.size.medium}
        visible={this.props.visible}
        onClose={() => {
          this.clearData();
          this.props.closeForm();
        }}
        buttons={null}
      >
        <Wrapper>
          <Content>
            <Image src={logo} alt="Point Logo" className="logo-desktop"/>
            <Title>To volunteer, nonprofits need a bit of information about you.</Title>
            <ButtonsRow>
              <Button
                  size={'lg'}
                  value={'Google'}
                  onClick={() => loginGoogleAuthProvider({
                    setInfo: (e) => this.setState(e),
                    getAccountLogin: getUser,
                    history: this.props.history,
                    onClickConfirm: this.props.onClickConfirm
                  })}
                  variant={'outline-light'}
                  Icon={<img src={googleIcon} alt={''} width={20} height={20}/>}
                  dataTestId={'continue-with-google-button'}
                  isLoading={this.state.loadingGoogle}
              />
              <Button
                size={'lg'}
                value={'Apple'}
                onClick={() => loginAppleAuthProvider({
                  setInfo: (e) => this.setState(e),
                  getAccountLogin: getUser,
                  history: this.props.history,
                  onClickConfirm: this.props.onClickConfirm
                })}
                variant={'outline-light'}
                block={true}
                Icon={<img src={appleIcon} alt={'apple'} width={20} height={20}/>}
                dataTestId={'continue-apple-button'}
                isLoading={this.state.loadingApple}
              />
              <Button
                  size={'lg'}
                  value={'Okta'}
                  onClick={() => this.props.setInfo({ showSignUp: false, showSignInOkta: true, fromPopup: 'signUp' })}
                  variant={'outline-light'}
                  Icon={<img src={oktaIcon} alt={''} width={20} height={20}/>}
                  dataTestId={'continue-with-okta-button'}
              />
            </ButtonsRow>

            <Separator>
              <SeparatorLine/>
              <SeparatorText>OR</SeparatorText>
            </Separator>

            <Row>
              <Col>
                <Input
                  name={'first_name'}
                  size={INPUT_SIZE.NORMAL}
                  type={INPUT_TYPE.TEXT}
                  value={this.state.profile.firstName}
                  label={'First Name *'}
                  placeholder={'First Name'}
                  onChange={(e) => this.setProfile({ firstName: e.target.value.replace(/ /g, '') })}
                  dataTestId={'first-name-field'}
                />
                {errors && errors.firstName ? (
                  <ErrorMessage text={errors.firstName} dataTestId={'first-name-field-error'}/>
                ) : null}
              </Col>
              <Col>
                <Input
                  name={'last_name'}
                  size={INPUT_SIZE.NORMAL}
                  type={INPUT_TYPE.TEXT}
                  value={this.state.profile.lastName}
                  label={'Last Name *'}
                  placeholder={'Last Name'}
                  onChange={(e) => this.setProfile({ lastName: e.target.value.replace(/ /g, '') })}
                  dataTestId={'last-name-field'}
                />
                {errors && errors.lastName ? (
                  <ErrorMessage text={errors.lastName} dataTestId={'last-name-field-error'}/>
                ) : null}
              </Col>
            </Row>

            <Row>
              <Col>
                <Input
                  name={'email'}
                  size={INPUT_SIZE.NORMAL}
                  type={INPUT_TYPE.EMAIL}
                  value={this.state.profile.email}
                  label={'Email *'}
                  placeholder={'Email Address'}
                  onChange={(v) => this.setProfile({ email: v.replace(/ /g, '').toLowerCase() })}
                  dataTestId={'email-field'}
                />
                {errors && errors.email ? (
                  <ErrorMessage text={errors.email} dataTestId={'email-field-error'}/>
                ) : null}
              </Col>
              <Col>
                <Input
                  name={'username'}
                  size={INPUT_SIZE.NORMAL}
                  type={INPUT_TYPE.USERNAME}
                  value={this.state.profile.username}
                  label={'Username *'}
                  placeholder={'Username'}
                  onChange={(v) => this.setProfile({ username: v })}
                  dataTestId={'username-field'}
                />
                {errors && errors.username ? (
                  <ErrorMessage text={errors.username} dataTestId={'username-field-error'}/>
                ) : null}
              </Col>
            </Row>

            <Row>
              <Col>
                <Input
                  name={'birthday'}
                  size={INPUT_SIZE.NORMAL}
                  type={INPUT_TYPE.DATETIME}
                  value={this.state.profile.birthday}
                  label={'Your Birthday *'}
                  labelUnder={'Month / Day / Year'}
                  placeholder={'MM/DD/YYYY'}
                  onChange={(v) => this.setProfile({ birthday: v })}
                  dataTestId={'birthday-field'}
                />
                {errors && errors.birthday ? (
                  <ErrorMessage text={errors.birthday} dataTestId={'birthday-field-error'}/>
                ) : null}
              </Col>
              <Col>
                <Input
                  name={'zip_code'}
                  size={INPUT_SIZE.NORMAL}
                  type={INPUT_TYPE.ZIP}
                  value={this.state.profile.zipCode}
                  label={'Zip Code *'}
                  placeholder={'Ex.: 43215'}
                  onChange={(e) => this.setProfile({ zipCode: e })}
                  dataTestId={'zip-code-field'}
                />
                {errors && errors.zipCode ? (
                  <ErrorMessage text={errors.zipCode} dataTestId={'zip-code-field-error'}/>
                ) : null}
              </Col>
            </Row>

            <Row>
              <Col>
                <Input
                  name={'password'}
                  size={INPUT_SIZE.NORMAL}
                  type={INPUT_TYPE.PASSWORD}
                  value={this.state.profile.password}
                  label={'Password *'}
                  placeholder={'Enter your password'}
                  onChange={(e) => {
                    this.setProfile({ password: e.target.value });
                    this.setState({ passwordStrengthShow: true });
                  }}
                  onBlur={() => this.setState({ passwordStrengthShow: false })}
                  dataTestId={'password-field'}
                />
                <PasswordStrength password={this.state.profile.password} isShow={this.state.passwordStrengthShow} size={'full'}/>
                {errors && errors.password ? (
                  <ErrorMessage text={errors.password} dataTestId={'password-field-error'}/>
                ) : null}
              </Col>
              <Col>
                <Input
                  name={'confirm_password'}
                  size={INPUT_SIZE.NORMAL}
                  type={INPUT_TYPE.PASSWORD}
                  value={this.state.profile.confirmPassword}
                  label={'Confirm Password *'}
                  placeholder={'Confirm your password'}
                  onChange={(e) => this.setProfile({ confirmPassword: e.target.value })}
                  dataTestId={'confirm-password-field'}
                />
                {errors && errors.confirmPassword ? (
                  <ErrorMessage text={errors.confirmPassword} dataTestId={'confirm-password-field-error'}/>
                ) : null}
              </Col>
            </Row>

            <Text>Photos help nonprofits know who’s who</Text>
            <RowCol>
              <ImageUploader
                name={'avatar'}
                loadImage={this.state.profile.avatar && this.state.profile.avatar !== ''}
                image={this.state.profile.avatar}
                buttonText="Upload Photo"
                onChange={(b) => {
                  this.setProfile({ avatar: b });
                }}
                imgExtension={[ '.jpg', '.png' ]}
                maxFileSize={5242880}
                dataTestId={'avatar-field'}
              />
              {errors && errors.avatar ? (
                <ErrorMessage text={errors.avatar} dataTestId={'avatar-field-error'}/>
              ) : null}
            </RowCol>

            <RowCol>
              <Checkbox
                name={'terms'}
                checked={this.state.profile.terms}
                onChange={() => this.setProfile({ terms: !this.state.profile.terms })}
                dataTestId={'terms-field'}
                title={<Terms>By creating an account, you agree to all this legal stuff: <TermsLink href={POINT_TERMS} target="_blank">Terms of Use</TermsLink>, <TermsLink href={COMMUNITY_GUIDELINES}
                                                                                                                                                                            target="_blank">Community
                  Guidelines</TermsLink> and <TermsLink href={POINT_PRIVACY_POLICY} target="_blank">Privacy Policy</TermsLink></Terms>}
              />
              {errors && errors.terms ? (
                <ErrorMessage text={errors.terms} dataTestId={'terms-field-error'}/>
              ) : null}
            </RowCol>

            <Buttons>
              <Button
                size={'lg'}
                value={'Create a POINT Account'}
                onClick={() => this.signUp()}
                variant={'primary'}
                block={true}
                dataTestId={'sign-up-button'}
                isLoading={this.state.loading}
              />
            </Buttons>
            {errors && Object.keys(errors).length > 0 ? (
              <GeneralError>
                <ErrorMessage
                  text={'Looks like something needs to be fixed. Please check all the fields above.'}
                  dataTestId={'terms-field-error'}
                />
              </GeneralError>
            ) : null}
          </Content>
          <Footer>
            Already have an account? <CustomLink onClick={() => this.props.showSignIn()} data-testid={'signup-button'}>Log In</CustomLink>
          </Footer>
        </Wrapper>
      </Modal>
    );
  }
}

SignUp.propTypes = {
  closeForm: PropTypes.func.isRequired,
  showSignIn: PropTypes.func.isRequired,
  onClickConfirm: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  location: PropTypes.object
};

SignUp.defaultProps = {
  visible: false,
  location: null
};

export default SignUp;

const Content = styled.div`
  padding: 8px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Wrapper = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Image = styled.img`
  height: 25px;
`;
const Title = styled(H2)`
  margin: 30px 0 32px;
  text-align: center;
`;
const CustomLink = styled.a`
  color: ${styles.colors.primary};
  font-family: ${styles.fonts.family.primaryBook};
  font-size: 14px;
  line-height: 18px;

  &:hover {
    color: ${styles.colors.secondary};
  }
`;
const Terms = styled.div`
  color: ${styles.colors.medium};
`;
const TermsLink = styled.a`
  color: ${styles.colors.primary};
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;

  &:hover {
    color: ${styles.colors.secondary};
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
  width: calc(100% + 24px);
  margin: -12px -12px 12px;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: calc(50% - 24px);
  margin: 12px;
  @media screen and (max-width: 575px) {
    width: calc(100% - 24px);
  }
`;
const RowCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: 100%;
  margin-bottom: 24px;
`;
const RowFlex = styled(Row)`
  display: flex;
  flex: none;
  flex-direction: row-reverse;
`;
const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  justify-content: space-between;
  margin-bottom: 12px;
  width: 100%;

  .btn {
    width: 100%;
  }

  @media screen and (max-width: 375px) {
    .btn-lg.btn-content-text {
      padding: 14px;
    }
  }
`;
const Footer = styled(RowFlex)`
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  justify-content: center;
  width: 100%;
  color: ${styles.colors.medium};
  font-family: ${styles.fonts.family.primaryBook};
  font-size: 14px;
  line-height: 18px;
  margin: 0;

  a {
    color: ${styles.colors.primary};
    font-family: ${styles.fonts.family.primaryMedium};
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
    margin-left: 4px;
  }
`;
const Text = styled(H4)`
  font-family: ${styles.fonts.family.primaryMedium};
  margin: 0 auto 16px 0;
`;
const Separator = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 16px;
  position: relative;
`;
const SeparatorLine = styled.div`
  position: absolute;
  width: 100%;
  height: 1px;
  top: calc(50% - 3px);
  background: ${styles.colors.grey};
  z-index: 1;
`;
const SeparatorText = styled(H5)`
  display: inline-block;
  position: relative;
  font-family: ${styles.fonts.family.primaryBook};
  color: ${styles.colors.medium};
  background: white;
  font-size: 14px;
  line-height: 18px;
  padding: 0 8px;
  z-index: 2;
`;
const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
  width: 100%;
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }

  .btn {
    width: calc(33% / 3 - 12px);
    min-width: calc(33% - 12px);
    max-width: calc(33% - 12px);
    @media screen and (max-width: 575px) {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      margin-bottom: 12px;
    }
  }
`;
const GeneralError = styled.div`
  margin-bottom: 24px;
  width: 100%;
  text-align: left;
  &>div {
    margin-top: 0;
  }
`;
