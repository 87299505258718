import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../constants/styles';

const Health = (props) => (
    <Container isActive={props.isActive}>
      <svg
          width="20px"
          height="20px"
          id="health"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          enableBackground="new 0 0 24 24"
          xmlSpace="preserve"
      >
        <g id="mask">
          <path fill={props.isActive ? styles.colors.causes.health : styles.colors.silver} d="M12,20.5c-0.3,0-0.5-0.1-0.7-0.3l-7.8-7.8c-2-2-2-5.3,0-7.4
	c2-2,5.4-2,7.4,0L12,6.1L13.1,5c2-2,5.4-2,7.4,0c2,2.1,2,5.3,0,7.4l-7.8,7.8C12.5,20.4,12.3,20.5,12,20.5"/>
        </g>
      </svg>
    </Container>
);

export default Health;

Health.propTypes = {
  isActive: PropTypes.bool
};

Health.defaultProps = {
  isActive: true
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: ${props => props.isActive ? `${styles.colors.causes.health}1f` : styles.colors.mostlyWhite};
    border-radius: 20px;
`;