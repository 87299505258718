import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const PendingHoursIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M14.8,22H9.2c-1.7,0-3-1.3-3-3v-2.8c0-0.3,0.1-0.5,0.3-0.7L10,12L6.5,8.5C6.3,8.3,6.2,8.1,6.2,7.8V5
	c0-1.7,1.3-3,3-3h5.5c1.7,0,3,1.3,3,3v2.8c0,0.3-0.1,0.5-0.3,0.7L14,12l3.5,3.5c0.2,0.2,0.3,0.4,0.3,0.7V19
	C17.8,20.7,16.4,22,14.8,22z M8.2,16.6V19c0,0.6,0.4,1,1,1h5.5c0.6,0,1-0.4,1-1v-2.4L12,12.8L8.2,16.6z M8.2,7.4l3.8,3.8l3.8-3.8V5
	c0-0.6-0.4-1-1-1H9.2c-0.6,0-1,0.4-1,1V7.4z M16.8,7.8L16.8,7.8L16.8,7.8z"/>
        </Icon>
    );

export default withTheme(PendingHoursIcon);
