import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../constants/styles';
import { H6 } from '../Typography';
import { InfoIcon } from '../Icons/General';

const Tip = (props) => {
  return (
    <Container {...props}>
      <IconPlace>
        <InfoIcon width={16} height={16} defaultFill={styles.colors.system.info} />
      </IconPlace>
      <TipText data-testid={props.dataTestId || ''}>{props.text || props.children}</TipText>
    </Container>
    );
};

export default Tip;

Tip.propTypes = {
  text: PropTypes.string,
  dataTestId: PropTypes.string
};
Tip.defaultProps = {
  text: '',
  dataTestId: ''
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 8px;
`;
const IconPlace = styled.div`
  display: flex;
  margin-right: 4px;
`;
const TipText = styled(H6)`
  color: ${styles.colors.medium};
  font-family: ${styles.fonts.family.primaryBook};
  margin-bottom: 0;
  word-break: break-word;
`;