import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import styles from '../../constants/styles';
import { H6 } from '../Typography';
import Tip from '../Tip';

const TitleSection = (props) => {
  return (
    <Wrapper>
      <TitleContainer>
        <Title data-testid={props.dataTestId}>{props.title}</Title>
      </TitleContainer>
      {props.hintMessage ? <CustomTip text={props.hintMessage}/> : null}
    </Wrapper>
  );
};

export default TitleSection;

TitleSection.propTypes = {
  title: PropTypes.string,
  hintMessage: PropTypes.string
};

TitleSection.defaultProps = {
  title: '',
  hintMessage: null
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
const Title = styled(H6)`
  display: flex;
  margin-bottom: 0;
  color: ${styles.colors.medium};
`;
const CustomTip = styled(Tip)`
  margin-top: 16px;
`;