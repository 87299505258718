import React from 'react';
import { withTheme } from 'styled-components';
import Icon from './components/Icon';

const CharitiesSystemMenu = props => (
    <Icon {...props}>
        <path d="M17,13h-6c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1S17.6,13,17,13z M17,8h-6c-0.6,0-1-0.4-1-1
	s0.4-1,1-1h6c0.6,0,1,0.4,1,1S17.6,8,17,8z"/>
        <path d="M7,8C6.7,8,6.5,7.9,6.3,7.7C6.2,7.6,6.1,7.5,6.1,7.4C6,7.3,6,7.1,6,7c0-0.1,0-0.3,0.1-0.4
	c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2C6.9,6,7.1,6,7.4,6.1c0.1,0.1,0.2,0.1,0.3,0.2C7.9,6.5,8,6.7,8,7
	c0,0.3-0.1,0.5-0.3,0.7C7.5,7.9,7.3,8,7,8z"/>
        <path d="M7,13c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.1-0.3-0.2c0-0.1-0.1-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2
	c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.3,0.1-0.5,0.3-0.7C6.5,11.1,6.9,11,7.2,11c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1
	c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.2,0.3C8,11.7,8,11.9,8,12c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2
	c0,0-0.1,0.1-0.1,0.1C7.5,12.9,7.3,13,7,13z"/>
        <path d="M7,18c-0.3,0-0.5-0.1-0.7-0.3c-0.1-0.1-0.2-0.2-0.2-0.3C6,17.3,6,17.1,6,17s0-0.3,0.1-0.4
	c0.1-0.1,0.1-0.2,0.2-0.3c0.4-0.4,1-0.4,1.4,0c0.1,0.1,0.2,0.2,0.2,0.3C8,16.7,8,16.9,8,17c0,0.3-0.1,0.5-0.3,0.7
	C7.5,17.9,7.3,18,7,18z"/>
        <path d="M23.2,17.7h-0.5c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2l0.4-0.4c0.2-0.2,0.3-0.4,0.2-0.6
	c0-0.2-0.1-0.4-0.2-0.6l-0.7-0.7c0,0,0,0,0,0c-0.3-0.3-0.9-0.3-1.2,0l-0.3,0.4c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.1-0.1v-0.5
	c0-0.5-0.4-0.8-0.8-0.8h-1c-0.5,0-0.8,0.4-0.8,0.8v0.5c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.1,0-0.2,0L17,15.1c-0.2-0.2-0.4-0.3-0.6-0.2
	c-0.2,0-0.4,0.1-0.6,0.2l-0.7,0.7c0,0,0,0,0,0c-0.3,0.3-0.3,0.9,0,1.2l0.4,0.4c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.1-0.1,0.1h-0.5
	c-0.5,0-0.8,0.4-0.8,0.8v0.9c0,0.5,0.4,0.8,0.8,0.8h0.5c0.1,0,0.1,0.1,0.2,0.1c0,0.1,0,0.1,0,0.2L15.1,21c-0.2,0.2-0.2,0.4-0.2,0.6
	c0,0.2,0.1,0.4,0.2,0.6l0.7,0.7c0,0,0,0,0,0c0.3,0.3,0.9,0.3,1.2,0l0.4-0.4c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0.1,0.1,0.1v0.5
	c0,0.5,0.4,0.8,0.8,0.8h0.9c0.5,0,0.8-0.4,0.8-0.8v-0.5c0-0.1,0.1-0.1,0.1-0.2c0.1,0,0.1,0,0.2,0l0.4,0.4c0.2,0.2,0.4,0.2,0.6,0.2
	c0.2,0,0.4-0.1,0.6-0.2l0.7-0.7c0,0,0,0,0,0c0.3-0.3,0.3-0.9,0-1.2l-0.4-0.4c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.1,0.1-0.1h0.5
	c0.5,0,0.8-0.4,0.8-0.8v-1C24,18.1,23.6,17.7,23.2,17.7z M19,20.8c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8
	S20,20.8,19,20.8z"/>
        <path d="M12.9,21.6C12.9,21.6,12.9,21.6,12.9,21.6c-0.2-0.2-0.3-0.4-0.4-0.6H5c-1.1,0-2-0.9-2-2V5c0-1.1,0.9-2,2-2
	h14c1.1,0,2,0.9,2,2v7.4c0.2,0.1,0.4,0.3,0.6,0.4c0,0,0,0,0,0c0.5,0,1,0.1,1.4,0.4V5c0-2.2-1.8-4-4-4H5C2.8,1,1,2.8,1,5v14
	c0,2.2,1.8,4,4,4h8.3C13,22.6,12.9,22.1,12.9,21.6z"/>
        <path d="M12.9,16.5c0-0.2,0-0.3,0-0.5H11c-0.6,0-1,0.4-1,1s0.4,1,1,1h1.1C12.2,17.4,12.5,16.9,12.9,16.5z"/>
    </Icon>
);

export default withTheme(CharitiesSystemMenu);
