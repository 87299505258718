import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import styles from '../../../../constants/styles';
import { P1, H2, H3, H4, H5 } from '../../../../components/Typography';
import { Modal } from '../../../../components/Modal';
import FlexTable from '../../../../components/FlexTable';
import NoData from '../../../../components/NoData';

import checkInCircle from '../../../../assets/images/svg/green-check.svg';
import errorImage from '../../../../assets/images/svg/error-image.svg';

class ResultsPopup extends Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  renderMessageShift = () => {
    const { messageShift } = this.props;
    if (!messageShift) {
      return false;
    }

    if (messageShift.error) {
      return (
        <RowMessage>
          <Image src={errorImage} />
          <Label>{messageShift.error}</Label>
        </RowMessage>
      );
    } else {
      if (this.props.isOpportunity) {
        return (
          <BlockMessage>
            <Image src={checkInCircle} />
            <Title>Success</Title>
            <Text>{messageShift.message}</Text>
          </BlockMessage>
        );
      }
      return (
        <RowMessage>
          <Image src={checkInCircle} />
          <Label>{messageShift.message}</Label>
        </RowMessage>
      );
    }
  }

  renderMessage = (record) => (
    <TextError>
      {record.error ? (
        <Row>
          <ImageSuccess src={errorImage}/>
          <div>{record.error}</div>
        </Row>
      ) : (
        <Row>
          <ImageSuccess src={checkInCircle}/>
          <div>{record.message}</div>
        </Row>
      )}
    </TextError>
  )


  renderTableShifts = (data) => {
    let columns = [ {
      title: 'Shift Name',
      key: 'fullName',
      width: {
        maxWidth: '264px',
        minWidth: '264px',
        default: '264px'
      },
      className: 'cell',
      render: (record) => (
        <div>
          <Name>{record.name}</Name>
          <BlockResponsive>
            {this.renderMessage(record)}
          </BlockResponsive>
        </div>
      )
    }, {
      title: 'Message / Error Reason',
      key: 'text',
      width: {
        maxWidth: '264px',
        minWidth: '264px',
        default: '264px'
      },
      className: 'cell block-md',
      render: (record) => this.renderMessage(record)
    } ];

    return (
      <UsersBlock>
        <FlexTable
          rowKey={'id'}
          NoDataComponent={<NoData minHeight={320} height={320} desc="No data to display."/>}
          columns={columns}
          dataSource={data}
          hideCheckbox={true}
        />
      </UsersBlock>
    );
  };

  render () {
    let title = (this.props.isOneShift || this.props.messageShift) ? 'Result' : 'Results';
    if (this.props.messageShift && this.props.messageShift.message && this.props.isOpportunity) {
      title = null;
    }
    return (
      <div>
        <Modal
          width={this.props.isOpportunity ? styles.modal.size.normal : styles.modal.size.medium}
          visible={this.props.resultsPopup}
          title={title}
          onClose={() => this.props.setInfo({ resultsByShift: [], resultsPopup: false, messageShift: null })}
          buttons={[{
            onPress: () => this.props.setInfo({ resultsByShift: [], resultsPopup: false, messageShift: null }),
            value: 'Close',
            variant: 'outline-light'
          }]}
        >
          <ListShifts>
            {this.props.messageShift ? this.renderMessageShift() : this.renderTableShifts(this.props.resultsByShift)}
          </ListShifts>
        </Modal>
      </div>
    );
  }
}

ResultsPopup.propTypes = {
  resultsPopup: PropTypes.bool,
  resultsByShift: PropTypes.arrayOf(PropTypes.shape({})),
  messageShift: PropTypes.shape(),
  isOneShift: PropTypes.bool,
  isOpportunity: PropTypes.bool
};

ResultsPopup.defaultTypes = {
  resultsPopup: false,
  resultsByShift: [],
  messageShift: null,
  isOpportunity: false,
  isOneShift: false
};

export default ResultsPopup;

const TextPopup = styled(H4)`
  margin: 0 0 24px;
  color: ${styles.colors.medium};
  font-family: ${styles.fonts.family.primaryBook};
`;
const ListShifts = styled.div`
  margin: 0 0 24px;

  &:last-of-type {
    margin: 0;
  }

  ${TextPopup} {
    margin-bottom: 8px;
  }
`;
const UsersBlock = styled.div`
  .header-row {
    background: ${styles.colors.light};

    .cell {
      color: ${styles.colors.medium};
    }
  }

  .body-table {
    padding: 0 8px;
  }

  @media screen and (max-width: 700px) {
    .cell {
      min-width: calc(50% - 16px);
      width: calc(50% - 16px);
      max-width: calc(50% - 16px);
    }
  }
  @media screen and (max-width: 575px) {
    .cell {
      min-width: calc(100% - 16px);
      width: calc(100% - 16px);
      max-width: calc(100% - 16px);
    }
    .block-md {
      display: none;
    }
  }
`;
const TextError = styled(H5)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;;
  margin: 0;
  font-family: ${styles.fonts.family.primaryBook};

  svg {
    margin-right: 8px;
  }

  @media screen and (max-width: 600px) {
    min-height: inherit;
  }
`;
const Name = styled(H5)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;;
  margin: 0;
  font-family: ${styles.fonts.family.primaryBook};
  @media screen and (max-width: 575px) {
    font-family: ${styles.fonts.family.primaryMedium};
  }
`;
const ImageSuccess = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 12px;
`;
const Image = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 16px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
const RowMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Label = styled(H3)`
  font-family: ${styles.fonts.family.primaryBook};
  color: ${styles.colors.medium};
  margin: 0;
`;
const BlockResponsive = styled.div`
  display: none;
  @media screen and (max-width: 575px) {
    display: block;
    margin: 12px 0 0;
  }
`;
const BlockMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  img {
    margin: 8px;
    width: 48px;
    min-width: 48px;
    height: 48px;
  }
`;
const Text = styled(P1)`
  font-family: ${styles.fonts.family.primaryBook};
  color: ${styles.colors.medium};
  margin: 0;
`;

const Title = styled(H2)`
  color: ${styles.colors.dark};
  margin: 24px 0 16px;
`;
