import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../constants/styles';

const Refugees = (props) => (
    <Container isActive={props.isActive}>
      <svg
          width="20px"
          height="20px"
          id="refugees"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          enableBackground="new 0 0 24 24"
          xmlSpace="preserve"
      >
        <g id="mask">
          <path fill={props.isActive ? styles.colors.causes.refugees : styles.colors.silver} d="M14.9,22L14.9,22c-0.6,0-1-0.4-1.1-0.9l-1.7-8.4
	c-0.1-0.4-0.4-0.8-0.9-0.9l-8.4-1.7C2.3,10,1.9,9.4,2,8.8C2.1,8.4,2.4,8.1,2.7,8l17.8-5.9c0.6-0.2,1.2,0,1.4,0.6
	C22,2.9,22,3.2,22,3.5L16,21.2C15.9,21.7,15.4,22,14.9,22"/>
        </g>
      </svg>
    </Container>
);

export default Refugees;

Refugees.propTypes = {
  isActive: PropTypes.bool
};

Refugees.defaultProps = {
  isActive: true
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: ${props => props.isActive ? `${styles.colors.causes.refugees}1f` : styles.colors.mostlyWhite};
    border-radius: 20px;
`;

