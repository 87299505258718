import React from 'react';
import {Link} from 'react-router-dom';
import {isNil} from 'lodash';
import styled, {withTheme} from 'styled-components';
import styles from '../../constants/styles';
import Hint from '../Hint';

const variantArray = ['primary', 'secondary', 'complementary', 'success',
    'danger', 'warning', 'white', 'info', 'dark', 'light', 'link',
    'outline-primary', 'outline-secondary', 'outline-success', 'outline-danger',
    'outline-warning', 'outline-info', 'outline-dark', 'outline-light', 'opacity-dark'];
const sizeArray = ['sm', 'md', 'lg'];

const BUTTON_COLOR_STYLES = {
    'primary': {
        fill: '#ffffff',
        textColor: '#ffffff',
        borderColor: styles.colors.primary,
        backgroundColor: styles.colors.primary,
        hover: {
            fill: '#ffffff',
            textColor: '#ffffff',
            borderColor: `${styles.colors.primary}cc`,
            backgroundColor: `${styles.colors.primary}cc`
        },
        disabled: {
            fill: '#ffffff',
            textColor: '#ffffff',
            borderColor: styles.colors.grey,
            backgroundColor: styles.colors.grey
        }
    },
    'secondary': {
        fill: '#ffffff',
        textColor: '#ffffff',
        borderColor: styles.colors.secondary,
        backgroundColor: styles.colors.secondary,
        hover: {
            fill: '#ffffff',
            textColor: '#ffffff',
            borderColor: `${styles.colors.secondary}cc`,
            backgroundColor: `${styles.colors.secondary}cc`
        },
        disabled: {
            fill: '#ffffff',
            textColor: '#ffffff',
            borderColor: styles.colors.grey,
            backgroundColor: styles.colors.grey
        }
    },
    'complementary': {
        fill: '#ffffff',
        textColor: '#ffffff',
        borderColor: styles.colors.system.success,
        backgroundColor: styles.colors.system.success,
        hover: {
            fill: '#ffffff',
            textColor: '#ffffff',
            borderColor: `${styles.colors.system.success}cc`,
            backgroundColor: `${styles.colors.system.success}cc`
        },
        disabled: {
            fill: '#ffffff',
            textColor: '#ffffff',
            borderColor: styles.colors.grey,
            backgroundColor: styles.colors.grey
        }
    },
    'success': {
        fill: '#ffffff',
        textColor: '#ffffff',
        borderColor: styles.colors.system.success,
        backgroundColor: styles.colors.system.success,
        hover: {
            fill: '#ffffff',
            textColor: '#ffffff',
            borderColor: `${styles.colors.system.success}cc`,
            backgroundColor: `${styles.colors.system.success}cc`
        },
        disabled: {
            fill: '#ffffff',
            textColor: '#ffffff',
            borderColor: styles.colors.grey,
            backgroundColor: styles.colors.grey
        }
    },
    'danger': {
        fill: '#ffffff',
        textColor: '#ffffff',
        borderColor: styles.colors.system.error,
        backgroundColor: styles.colors.system.error,
        hover: {
            fill: '#ffffff',
            textColor: '#ffffff',
            borderColor: `${styles.colors.system.error}cc`,
            backgroundColor: `${styles.colors.system.error}cc`
        },
        disabled: {
            fill: '#ffffff',
            textColor: '#ffffff',
            borderColor: styles.colors.grey,
            backgroundColor: styles.colors.grey
        }
    },
    'warning': {
        fill: '#ffffff',
        textColor: '#ffffff',
        borderColor: styles.colors.system.alert,
        backgroundColor: styles.colors.system.alert,
        hover: {
            fill: '#ffffff',
            textColor: '#ffffff',
            borderColor: `${styles.colors.system.alert}cc`,
            backgroundColor: `${styles.colors.system.alert}cc`
        },
        disabled: {
            fill: '#ffffff',
            textColor: '#ffffff',
            borderColor: styles.colors.grey,
            backgroundColor: styles.colors.grey
        }
    },
    'info': {
        fill: '#ffffff',
        textColor: '#ffffff',
        borderColor: styles.colors.system.info,
        backgroundColor: styles.colors.system.info,
        hover: {
            fill: '#ffffff',
            textColor: '#ffffff',
            borderColor: `${styles.colors.system.info}cc`,
            backgroundColor: `${styles.colors.system.info}cc`
        },
        disabled: {
            fill: '#ffffff',
            textColor: '#ffffff',
            borderColor: styles.colors.grey,
            backgroundColor: styles.colors.grey
        }
    },
    'outline-primary': {
        fill: styles.colors.primary,
        textColor: styles.colors.primary,
        borderColor: styles.colors.primary,
        backgroundColor: '#ffffff',
        hover: {
            fill: `${styles.colors.primary}cc`,
            textColor: `${styles.colors.primary}cc`,
            borderColor: `${styles.colors.primary}cc`,
            backgroundColor: '#ffffff'
        },
        disabled: {
            fill: styles.colors.grey,
            textColor: styles.colors.grey,
            borderColor: styles.colors.grey,
            backgroundColor: '#ffffff'
        }
    },
    'outline-secondary': {
        fill: styles.colors.secondary,
        textColor: styles.colors.secondary,
        borderColor: styles.colors.secondary,
        backgroundColor: '#ffffff',
        hover: {
            fill: `${styles.colors.secondary}cc`,
            textColor: `${styles.colors.secondary}cc`,
            borderColor: `${styles.colors.secondary}cc`,
            backgroundColor: '#ffffff'
        },
        disabled: {
            fill: styles.colors.grey,
            textColor: styles.colors.grey,
            borderColor: styles.colors.grey,
            backgroundColor: '#ffffff'
        }
    },
    'outline-success': {
        fill: styles.colors.system.success,
        textColor: styles.colors.system.success,
        borderColor: styles.colors.system.success,
        backgroundColor: '#ffffff',
        hover: {
            fill: `${styles.colors.system.success}cc`,
            textColor: `${styles.colors.system.success}cc`,
            borderColor: `${styles.colors.system.success}cc`,
            backgroundColor: '#ffffff'
        },
        disabled: {
            fill: styles.colors.grey,
            textColor: styles.colors.grey,
            borderColor: styles.colors.grey,
            backgroundColor: '#ffffff'
        }
    },
    'outline-danger': {
        fill: styles.colors.system.error,
        textColor: styles.colors.system.error,
        borderColor: styles.colors.system.error,
        backgroundColor: '#ffffff',
        hover: {
            fill: `${styles.colors.system.error}cc`,
            textColor: `${styles.colors.system.error}cc`,
            borderColor: `${styles.colors.system.error}cc`,
            backgroundColor: '#ffffff'
        },
        disabled: {
            fill: styles.colors.grey,
            textColor: styles.colors.grey,
            borderColor: styles.colors.grey,
            backgroundColor: '#ffffff'
        }
    },
    'outline-warning': {
        fill: styles.colors.system.alert,
        textColor: styles.colors.system.alert,
        borderColor: styles.colors.system.alert,
        backgroundColor: '#ffffff',
        hover: {
            fill: `${styles.colors.system.alert}cc`,
            textColor: `${styles.colors.system.alert}cc`,
            borderColor: `${styles.colors.system.alert}cc`,
            backgroundColor: '#ffffff'
        },
        disabled: {
            fill: styles.colors.grey,
            textColor: styles.colors.grey,
            borderColor: styles.colors.grey,
            backgroundColor: '#ffffff'
        }
    },
    'outline-info': {
        fill: styles.colors.system.info,
        textColor: styles.colors.system.info,
        borderColor: styles.colors.system.info,
        backgroundColor: '#ffffff',
        hover: {
            fill: `${styles.colors.system.info}cc`,
            textColor: `${styles.colors.system.info}cc`,
            borderColor: `${styles.colors.system.info}cc`,
            backgroundColor: '#ffffff'
        },
        disabled: {
            fill: styles.colors.grey,
            textColor: styles.colors.grey,
            borderColor: styles.colors.grey,
            backgroundColor: '#ffffff'
        }
    },
    'outline-light': {
        fill: styles.colors.dark,
        textColor: styles.colors.dark,
        borderColor: styles.colors.grey,
        backgroundColor: '#ffffff',
        hover: {
            fill: styles.colors.dark,
            textColor: styles.colors.dark,
            borderColor: styles.colors.silver,
            backgroundColor: '#ffffff'
        },
        disabled: {
            fill: styles.colors.grey,
            textColor: styles.colors.grey,
            borderColor: styles.colors.grey,
            backgroundColor: '#ffffff'
        }
    }
};

const BUTTON_CONTENT_TYPE = {
    ONLY_ICON: 'btn-content-icon',
    ONLY_TEXT: 'btn-content-text',
    ICON_TEXT: 'btn-content-icon-text'
};

const Button = props => {
    const {
        visibility = true,
        className = '',
        style = {},
        block,
        dataTestId = '',
        disabled = false,
        href,
        to,
        size = 'md',
        variant = 'primary',
        showHover = true,
        value = null,
        children = '',
        isMinWidth = false,
        isLoading = false,
        Icon = null,
        onClick,
        onChange,
        hint = null
    } = props;

    if (!visibility) {
        return null;
    }

    const classes = [];
    const textDisabled = disabled ? '-disabled' : '';

    const cBlock = block ? 'btn-display-block' : '';
    const cClassType = (variantArray.indexOf(variant) > -1) ? `btn-${variant}${textDisabled}` : `btn-primary${textDisabled}`;
    const cClassSize = (sizeArray.indexOf(size) > -1) ? `btn-${size}` : 'btn-md';
    const cHover = showHover ? '' : 'not-hover';
    const minWidth = isMinWidth ? 'btn-minwidth' : '';

    classes.push('btn', cClassType, cClassSize, cBlock, className, cHover, minWidth);

    const isIcon = !isNil(Icon);
    const isText = !!(value || children);

    let iconContent = '';
    let content = '';
    let contentType = BUTTON_CONTENT_TYPE.ONLY_TEXT;

    if (isIcon) {
        iconContent = (<span className="btn-icon">{Icon}</span>);
        contentType = BUTTON_CONTENT_TYPE.ONLY_ICON;
    }
    if (isText) {
        content = (<span className="btn-content">{value || children}</span>);
        contentType = BUTTON_CONTENT_TYPE.ONLY_TEXT;
    }
    if (isIcon && isText) {
        contentType = BUTTON_CONTENT_TYPE.ICON_TEXT;
    }

    classes.push(contentType);

    const classNames = classes.join(' ');

    let buttonResult = (
        <CustomButton
            type={'button'}
            style={style}
            onClick={(e) => {
                if (disabled || isLoading) {
                    return false;
                }
                return onClick(e);
            }}
            onChange={(e) => {
                if (disabled || isLoading) {
                    return false;
                }
                return onChange(e);
            }}
            className={classNames}
            data-testid={dataTestId}
            variant={variant}
        >
            {!isLoading && iconContent}
            {!isLoading && content}
            {isLoading && (<span className="btn-loader-place"><span className="btn-loader"/></span>)}
        </CustomButton>
    );

    if (!isNil(href)) {
        buttonResult = (
            <HrefButton
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                className={classNames}
                style={style}
                variant={variant}
                as={'a'}
                data-testid={dataTestId}
            >
                {!isLoading && iconContent}
                {!isLoading && content}
                {isLoading && (<span className="btn-loader-place"><span className="btn-loader"/></span>)}
            </HrefButton>
        );
    }

    if (!isNil(to)) {
        buttonResult = (
            <CustomLink
                as={Link}
                to={to}
                className={classNames}
                style={style}
                variant={variant}
                data-testid={dataTestId}
            >
                {!isLoading && iconContent}
                {!isLoading && content}
                {isLoading && (<span className="btn-loader-place"><span className="btn-loader"/></span>)}
            </CustomLink>
        );
    }

    if (hint) {
        return (
            <Hint
                value={hint}
                shortValue={buttonResult}
                block={block}
            />
        );
    }
    return buttonResult;

};

export default withTheme(Button);

const CustomButton = styled.button`
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  border: 1px solid;
  font-family: ${styles.fonts.family.primaryMedium};
  transition: all .25s ease-out;
  min-width: max-content;
  background-clip: padding-box;
  span {
    font-family: inherit;
  }
  .btn-icon {
    display: flex;
  }

  &.btn-display-block {
    width: 100% !important;
  }

  /* Size 48, 36, 24 - 14, 14, 12 - 20, 14, 8 */
  &.btn-sm {
    font-size: 12px;
    line-height: 18px;
    border-radius: 4px;
    height: 24px;
    max-height: 24px;
    &.btn-content-icon {
      padding: 5px;
    }
    &.btn-content-text {
      padding: 2px 8px;
    }
    &.btn-content-icon-text {
      padding: 3px 8px 3px 6px;
      .btn-icon {
        margin-right: 4px;
      }
    }
    &.btn-minwidth {
      min-width: 60px;
    }
    .btn-icon {
      svg {
        height: 14px;
        width: 14px;
        min-width: auto;
      }
    }
    .btn-loader-place {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      position: relative;
      width: 36px;
      height: 18px;
    }
  }
  &.btn-md {
    font-size: 14px;
    line-height: 20px;
    border-radius: 4px;
    height: 36px;
    max-height: 36px;
    &.btn-content-icon {
      padding: 10px;
    }
    &.btn-content-text {
      padding: 8px 20px;
    }
    &.btn-content-icon-text {
      padding: 8px 14px 8px 12px;
      .btn-icon {
        margin-right: 6px;
      }
    }
    &.btn-minwidth {
      min-width: 80px;
    }
    .btn-icon {
      svg {
        height: 16px;
        width: 16px;
        min-width: auto;
      }
    }
    .btn-loader-place {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      position: relative;
      width: 36px;
      height: 20px;
    }
  }
  &.btn-lg {
    font-size: 14px;
    line-height: 22px;
    border-radius: 4px;
    height: 48px;
    max-height: 48px;
    &.btn-content-icon {
      padding: 14px;
    }
    &.btn-content-text {
      padding: 14px 30px;
    }
    &.btn-content-icon-text {
      padding: 14px 20px 14px 18px;
      .btn-icon {
        margin-right: 10px;
      }
    }
    &.btn-minwidth {
      min-width: 120px;
    }
    .btn-icon {
      svg {
        height: 20px;
        width: 20px;
        min-width: auto;
      }
    }
    .btn-loader-place {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      position: relative;
      width: 48px;
      height: 22px;
    }
  }
  
  /* Variant */
    fill: ${props => BUTTON_COLOR_STYLES[props.variant].fill};
    color: ${props => BUTTON_COLOR_STYLES[props.variant].textColor};
    border-color: ${props => BUTTON_COLOR_STYLES[props.variant].borderColor};
    background-color: ${props => BUTTON_COLOR_STYLES[props.variant].backgroundColor};
    &:not(.not-hover):hover {
      fill: ${props => BUTTON_COLOR_STYLES[props.variant].hover.fill};
      color: ${props => BUTTON_COLOR_STYLES[props.variant].hover.textColor};
      border-color: ${props => BUTTON_COLOR_STYLES[props.variant].hover.borderColor};
      background-color: ${props => BUTTON_COLOR_STYLES[props.variant].hover.backgroundColor};
      
      &.btn-${props => props.variant}-disabled {
          cursor: no-drop;
          opacity: 0.5;
      }
    }
    &.btn-disabled, &.btn-${props => props.variant}-disabled {
      cursor: no-drop;
      opacity: 0.5;
    }
    .btn-icon {
      svg {
       g {
         fill: ${props => BUTTON_COLOR_STYLES[props.variant].fill};
       }
      }
    }
    
    .btn-loader{
      width: 6px;
      height: 6px;
      border-radius: 50%;
      animation: typing${props => props.variant} 1s linear infinite alternate;
      position: relative;
      display: flex;
      margin-left: -20px;
    }
    @-webkit-keyframes typing${props => props.variant}{
      0%{
        background-color: ${props => BUTTON_COLOR_STYLES[props.variant].fill};
        box-shadow: 12px 0 0 0 ${props => BUTTON_COLOR_STYLES[props.variant].fill}33, 24px 0 0 0 ${props => BUTTON_COLOR_STYLES[props.variant].fill}33;
      }
      25%{
        background-color: ${props => BUTTON_COLOR_STYLES[props.variant].fill}66;
        box-shadow: 12px 0 0 0 ${props => BUTTON_COLOR_STYLES[props.variant].fill}33, 24px 0 0 0 ${props => BUTTON_COLOR_STYLES[props.variant].fill}33;
      }
      75%{
        background-color: ${props => BUTTON_COLOR_STYLES[props.variant].fill}66;
        box-shadow: 12px 0 0 0 ${props => BUTTON_COLOR_STYLES[props.variant].fill}33, 24px 0 0 0 ${props => BUTTON_COLOR_STYLES[props.variant].fill};
      }
    }
    
    @-moz-keyframes typing${props => props.variant}{
      0%{
        background-color: ${props => BUTTON_COLOR_STYLES[props.variant].fill};
        box-shadow: 12px 0 0 0 ${props => BUTTON_COLOR_STYLES[props.variant].fill}33, 24px 0 0 0 ${props => BUTTON_COLOR_STYLES[props.variant].fill}33;
      }
      25%{
        background-color: ${props => BUTTON_COLOR_STYLES[props.variant].fill}66;
        box-shadow: 12px 0 0 0 ${props => BUTTON_COLOR_STYLES[props.variant].fill}33, 24px 0 0 0 ${props => BUTTON_COLOR_STYLES[props.variant].fill}33;
      }
      75%{
        background-color: ${props => BUTTON_COLOR_STYLES[props.variant].fill}66;
        box-shadow: 12px 0 0 0 ${props => BUTTON_COLOR_STYLES[props.variant].fill}33, 24px 0 0 0 ${props => BUTTON_COLOR_STYLES[props.variant].fill};
      }
    }
    @-ms-keyframes typing${props => props.variant}{
      0%{
        background-color: ${props => BUTTON_COLOR_STYLES[props.variant].fill};
        box-shadow: 12px 0 0 0 ${props => BUTTON_COLOR_STYLES[props.variant].fill}33, 24px 0 0 0 ${props => BUTTON_COLOR_STYLES[props.variant].fill}33;
      }
      25%{
        background-color: ${props => BUTTON_COLOR_STYLES[props.variant].fill}66;
        box-shadow: 12px 0 0 0 ${props => BUTTON_COLOR_STYLES[props.variant].fill}33, 24px 0 0 0 ${props => BUTTON_COLOR_STYLES[props.variant].fill}33;
      }
      75%{
        background-color: ${props => BUTTON_COLOR_STYLES[props.variant].fill}66;
        box-shadow: 12px 0 0 0 ${props => BUTTON_COLOR_STYLES[props.variant].fill}33, 24px 0 0 0 ${props => BUTTON_COLOR_STYLES[props.variant].fill};
      }
    }
    
    @keyframes typing${props => props.variant}{
      0%{
        background-color: ${props => BUTTON_COLOR_STYLES[props.variant].fill};
        box-shadow: 12px 0 0 0 ${props => BUTTON_COLOR_STYLES[props.variant].fill}33, 24px 0 0 0 ${props => BUTTON_COLOR_STYLES[props.variant].fill}33;
      }
      25%{
        background-color: ${props => BUTTON_COLOR_STYLES[props.variant].fill}66;
        box-shadow: 12px 0 0 0 ${props => BUTTON_COLOR_STYLES[props.variant].fill}33, 24px 0 0 0 ${props => BUTTON_COLOR_STYLES[props.variant].fill}33;
      }
      75%{
        background-color: ${props => BUTTON_COLOR_STYLES[props.variant].fill}66;
        box-shadow: 12px 0 0 0 ${props => BUTTON_COLOR_STYLES[props.variant].fill}33, 24px 0 0 0 ${props => BUTTON_COLOR_STYLES[props.variant].fill};
      }
    }
`;
const CustomLink = styled(CustomButton)``;
const HrefButton = styled(CustomButton)``;
