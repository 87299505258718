const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  apiBase: process.env.REACT_APP_FIREBASE_APIBASE,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  clientId: process.env.REACT_APP_FIREBASE_CLIENTID,
  cdnBase: process.env.REACT_APP_FIREBASE_CDNBASE
};

export default firebaseConfig;
