import React  from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import styles from '../../constants/styles';
import { CloseIcon, CheckIcon } from '../Icons/General';
import { P3 } from '../Typography';

const SwitchButton = ({ on, offText, onText, onChange, color, colorNotActive, dataTestId=null }) => (
  <Wrapper on={on} color={color} colorNotActive={colorNotActive}>
    <SwitchInput data-testid={dataTestId} onChange={onChange} />
    {on && onText ? (<Text on={on}>{onText}</Text>) : null}
    <SwitchFlip on={on}>
      {on ? (
        <CheckIcon defaultFill={styles.colors.primary} height={16} width={16}/>
      ) : (
        <CloseIcon defaultFill={styles.colors.medium} height={16} width={16}/>
      )}
    </SwitchFlip>
    {!on && offText ? (<Text on={on}>{offText}</Text>) : null}
  </Wrapper>
);

SwitchButton.propTypes = {
  on: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default SwitchButton;

const Wrapper = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 2px;
  height: 24px;
  border-radius: 18px;
  transition: all .4s ease;
  cursor: pointer;
  box-shadow: 0 1px 4px ${styles.colors.dark}0f;
  background-color: ${props => props.on
    ? (props.color ? props.color : styles.colors.primary)
    : (props.colorNotActive ? props.colorNotActive : styles.colors.grey)};
`;

const SwitchInput = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`;

const SwitchFlip = styled.div`
  height: 20px;
  width: 28px;
  border-radius: 18px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Text = styled(P3)`
  color: ${props => props.on ? '#fff' : styles.colors.medium};
  margin: ${props => props.on ? '0 10px 0 12px' : '0 12px 0 10px'};
`;

