import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../constants/styles';

const Religious = (props) => (
    <Container isActive={props.isActive}>
      <svg
          width="20px"
          height="20px"
          id="religious"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          enableBackground="new 0 0 24 24"
          xmlSpace="preserve"
      >
        <g id="mask">
          <path fill={props.isActive ? styles.colors.causes.religious : styles.colors.silver} d="M9.6,1.9c0.6,0,1.1,0.2,1.4,0.6v12.7l-1.9,1.9l0.2,0.2
	c0.2,0.3,0.2,0.7,0,0.9l-3.7,3.7c-0.1,0.1-0.3,0.2-0.5,0.2h0c-0.2,0-0.3-0.1-0.5-0.2L0.2,17C0,16.9,0,16.7,0,16.5
	c0-0.2,0.1-0.3,0.2-0.5l4-3.1c0.3-0.2,0.6-0.2,0.9,0.1l0.3,0.3l1-0.8l1.1-8.9c0,0,0,0,0,0C7.7,2.6,8.6,1.9,9.6,1.9z M14.4,1.9
	c1,0,1.9,0.8,2.1,1.8c0,0,0,0,0,0l1.1,8.9l1,0.8l0.3-0.3c0.2-0.2,0.6-0.3,0.9-0.1l4,3.1c0.1,0.1,0.2,0.3,0.2,0.5
	c0,0.2,0,0.4-0.2,0.5l-4.6,4.9c-0.1,0.1-0.3,0.2-0.5,0.2h0c-0.2,0-0.3-0.1-0.5-0.2l-3.7-3.7c-0.2-0.3-0.2-0.7,0-0.9l0.2-0.2
	l-1.9-1.9V2.5C13.3,2.1,13.8,1.9,14.4,1.9z"/>
        </g>
      </svg>
    </Container>
);

export default Religious;

Religious.propTypes = {
  isActive: PropTypes.bool
};

Religious.defaultProps = {
  isActive: true
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: ${props => props.isActive ? `${styles.colors.causes.religious}1f` : styles.colors.mostlyWhite};
    border-radius: 20px;
`;


