import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const CheckInIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M21.7,4.2c-0.4-0.4-1-0.3-1.4,0.1l-1.9,2.2l-0.6-0.7c-0.4-0.4-1-0.5-1.4-0.1c-0.4,0.4-0.5,1-0.1,1.4l1.4,1.6
	C17.8,8.9,18.1,9,18.4,9c0.3,0,0.6-0.1,0.7-0.3l2.6-3C22.1,5.2,22.1,4.6,21.7,4.2z"/>
            <path d="M10,11c2.2,0,4-1.8,4-4s-1.8-4-4-4S6,4.8,6,7S7.8,11,10,11z M10,5c1.1,0,2,0.9,2,2s-0.9,2-2,2S8,8.1,8,7
	S8.9,5,10,5z"/>
            <path d="M10,13c-3.9,0-7,3.1-7,7c0,0.6,0.4,1,1,1s1-0.4,1-1c0-2.8,2.2-5,5-5s5,2.2,5,5c0,0.6,0.4,1,1,1
	c0.6,0,1-0.4,1-1C17,16.1,13.9,13,10,13z"/>
        </Icon>
    );

export default withTheme(CheckInIcon);
