import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = ({children, ...rest}) =>  <Contr {...rest}>{children}</Contr>;

export default Container;

const Contr = styled.div``;

Container.propTypes = {
    children: PropTypes.element.isRequired
};