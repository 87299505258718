import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const PdfOutlineIcon = props => (
  <Icon {...props} viewBox={'0 0 30 38'}>
    <path d="m25 37.6h-20c-2.6 0-4.8-2.1-4.8-4.8v-28c0-2.6 2.1-4.8 4.8-4.8h12.8c1.3 0 2.5.5 3.4 1.4l7.2 7.2c.9.9 1.4 2.1 1.4 3.4v20.8c0 2.7-2.2 4.8-4.8 4.8zm-20-36c-1.8 0-3.2 1.5-3.2 3.2v28c0 1.8 1.5 3.2 3.2 3.2h20c1.8 0 3.2-1.5 3.2-3.2v-20.8c0-.9-.3-1.7-1-2.3l-7.2-7.2c-.6-.6-1.4-1-2.3-1h-12.7z"/>
    <path d="m9 20.2c1.4 0 2.3-.9 2.3-2.2s-.9-2.2-2.3-2.2h-2.7v7.1h1.4v-2.7zm-1.3-1.2v-2h1.1c.7 0 1.1.4 1.1 1s-.4 1-1.1 1z"/>
    <path d="m14.9 22.9c2 0 3.5-1.3 3.5-3.5s-1.5-3.5-3.5-3.5h-2.5v7.1h2.5zm-1.1-1.3v-4.5h1.1c1.2 0 2.1.7 2.1 2.3 0 1.5-1 2.3-2.1 2.3h-1.1z"/>
    <path d="m21 22.9v-2.8h2.8v-1.2h-2.8v-1.8h3.1v-1.3h-4.5v7.1z"/>
  </Icon>
);

export default withTheme(PdfOutlineIcon);
