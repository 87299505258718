import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const ArrowRightIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M16,12c0,0.3-0.1,0.5-0.3,0.7l-6,6c-0.4,0.4-1,0.4-1.4,0s-0.4-1,0-1.4l5.3-5.3L8.3,6.7c-0.4-0.4-0.4-1,0-1.4
	s1-0.4,1.4,0l6,6C15.9,11.5,16,11.7,16,12z"/>
        </Icon>
    );

export default withTheme(ArrowRightIcon);
