import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const EventsServerURL = process.env.REACT_APP_EVENTS_SERVER;

export default function HelmetMetaData (props) {
  let location = useLocation();
  let currentUrl = EventsServerURL + location.pathname;
  let title = props.title !== undefined ? props.title : '';
  let image = props.image !== undefined ? props.image : '';
  let description = props.description !== undefined ? props.description : '';

  return (
    <Helmet>
      <meta charSet="utf-8"/>
      <title>{title}</title>

      <meta name="title" content={title}/>
      <meta name="description" content={description}/>
      <meta name="image" content={image}/>
      <meta name="og:title" content={title}/>
      <meta name="og:image" content={image}/>
      <meta name="og:description" content={description}/>
      <meta name="og:type" content="website"/>

      <meta name="twitter:title" content={title}/>
      <meta name="twitter:description" content={description}/>
      <meta name="twitter:image" content={image}/>
      <meta name="twitter:card" content="summary"/>
    </Helmet>
  );
}
