import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const PdfIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M1,10h2.2c1.4,0,2.5,0.8,2.5,2.1s-1.1,2-2.5,2h-1V17H1V10z M3.2,13c0.9,0,1.2-0.3,1.2-0.9
	c0-0.6-0.4-0.9-1.2-0.9h-1V13H3.2z"/>
            <path d="M6.9,10H9c2.4,0,3.7,1.3,3.7,3.6c0,2.2-1.3,3.5-3.7,3.5H7L6.9,10L6.9,10z M8.9,15.9c1.6,0,2.5-0.6,2.5-2.4
	c0-1.7-0.7-2.4-2.4-2.4H8.2v4.8H8.9L8.9,15.9z"/>
            <path d="M14,10h5v1.2h-3.7V13h3.3v1.2h-3.3v3H14V10z"/>
            <path d="M22.7,7.3l-6-6C16.5,1.1,16.3,1,16,1H8C6.3,1,5,2.3,5,4v4h2V4c0-0.6,0.4-1,1-1h7.6L21,8.4V20
	c0,0.6-0.4,1-1,1H8c-0.6,0-1-0.4-1-1v-1H5v1c0,1.7,1.3,3,3,3h12c1.7,0,3-1.3,3-3V8C23,7.7,22.9,7.5,22.7,7.3z"/>
        </Icon>
    );

export default withTheme(PdfIcon);
