import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../constants/styles';

const PeaceJustice = (props) => (
    <Container isActive={props.isActive}>
      <svg
          width="20px"
          height="20px"
          id="peace_justice"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          enableBackground="new 0 0 24 24"
          xmlSpace="preserve"
      >
        <g id="mask">
          <path fill={props.isActive ? styles.colors.causes.peaceJustice : styles.colors.silver} d="M12,2C6.5,2,2,6.5,2,12c0,5.5,4.5,10,10,10s10-4.5,10-10
	C22,6.5,17.5,2,12,2z M20,12c0,1.8-0.6,3.5-1.7,4.9L13,11.6V4.1C16.9,4.6,20,7.9,20,12z M11,19.9c-1.5-0.2-2.8-0.8-3.9-1.6l3.9-3.9
	V19.9z M13,14.4l3.9,3.9c-1.1,0.9-2.4,1.4-3.9,1.6V14.4z M11,4.1v7.5l-5.3,5.3C4.6,15.5,4,13.8,4,12C4,7.9,7.1,4.6,11,4.1z"/>
        </g>
      </svg>
    </Container>
);

export default PeaceJustice;

PeaceJustice.propTypes = {
  isActive: PropTypes.bool
};

PeaceJustice.defaultProps = {
  isActive: true
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: ${props => props.isActive ? `${styles.colors.causes.peaceJustice}1f` : styles.colors.mostlyWhite};
    border-radius: 20px;
`;

