import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const HomeIcon = props => (
        <Icon {...props} viewBox={'0 0 20 22'}>
            <path className="st0" d="M3,22c-1.7,0-3-1.3-3-3V8c0-0.3,0.1-0.6,0.4-0.8l9-7c0.4-0.3,0.9-0.3,1.2,0l9,7
	C19.9,7.4,20,7.7,20,8v11c0,1.7-1.3,3-3,3H3z M10,2.3L2,8.5V19c0,0.6,0.4,1,1,1l3,0v-6c0-0.6,0.4-1,1-1h6c0.6,0,1,0.4,1,1v6l3,0
	c0.6,0,1-0.4,1-1V8.5L10,2.3z M12,15H8v5h4V15z"/>
        </Icon>
    );

export default withTheme(HomeIcon);
