import React from 'react';
import { withTheme } from 'styled-components';
import Icon from './components/Icon';

const OrganizationMenu = props => (
    <Icon {...props}>
        <path d="M19,9h-6V3.9C13,2.3,11.7,1,10,1H5C3.3,1,2,2.3,2,3.9V22c0,0.6,0.4,1,1,1h18c0.6,0,1-0.4,1-1V12C22,10.3,20.7,9,19,9z M11,21H9v-4H6v4H4V3.9C4,3.4,4.4,3,5,3h5c0.6,0,1,0.4,1,0.9V21z M20,21h-7V11h6c0.6,0,1,0.4,1,1V21z"/>
        <rect x="6" y="9" width="3" height="2"/>
        <rect x="6" y="5" width="3" height="2"/>
        <rect x="6" y="13" width="3" height="2"/>
        <rect x="15" y="13" width="3" height="2"/>
        <rect x="15" y="17" width="3" height="2"/>
    </Icon>
);

export default withTheme(OrganizationMenu);
