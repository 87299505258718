import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../constants/styles';

const Consumption = (props) => (
    <Container isActive={props.isActive}>
      <svg
          width="20px"
          height="20px"
          id="consumption"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          enableBackground="new 0 0 24 24"
          xmlSpace="preserve"
      >
        <g id="mask">
          <path fill={props.isActive ? styles.colors.causes.consumption : styles.colors.silver} d="M21.5,8.4c-2-2-5.3-2-7.3,0L12,10.6L9.8,8.4c-2-2-5.3-2-7.3,0
	C1.5,9.3,1,10.6,1,12c0,1.4,0.5,2.7,1.5,3.6c1,1,2.3,1.5,3.6,1.5s2.7-0.5,3.6-1.5l2.2-2.2l2.2,2.2c1,1,2.3,1.5,3.6,1.5
	c1.3,0,2.6-0.5,3.6-1.5C23.5,13.6,23.5,10.4,21.5,8.4z M8.4,14.2c-1.2,1.2-3.3,1.2-4.4,0C3.3,13.6,3,12.8,3,12
	c0-0.8,0.3-1.6,0.9-2.2c0.6-0.6,1.4-0.9,2.2-0.9c0.8,0,1.6,0.3,2.2,0.9l2.2,2.2L8.4,14.2z M20.1,14.2c-1.2,1.2-3.2,1.2-4.4,0
	L13.4,12l2.2-2.2c1.2-1.2,3.2-1.2,4.4,0C21.3,11,21.3,13,20.1,14.2z"/>
        </g>
      </svg>
    </Container>
);

export default Consumption;

Consumption.propTypes = {
  isActive: PropTypes.bool
};

Consumption.defaultProps = {
  isActive: true
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: ${props => props.isActive ? `${styles.colors.causes.consumption}1f` : styles.colors.mostlyWhite};
    border-radius: 20px;
`;

