import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const ConfirmedIcon = props => (
    <Icon {...props} viewBox={'0 0 24 24'}>
        <path d="M19.3,4.7c-0.5-0.3-1.1-0.4-1.6-0.2c-0.7,0.2-1.4,0.3-2.2,0.3
	c-1.1-0.1-2.1-0.3-3.1-0.8C11.2,3.4,9.8,3.1,8.5,3c-2.9,0-4,1-4.2,1.1C4.1,4.3,4,4.6,4,4.9V20c0,0.6,0.4,1,1,1c0.6,0,1-0.4,1-1v-4.3
	c0.8-0.3,1.6-0.4,2.5-0.4c1.1,0.1,2.1,0.3,3.1,0.8c1.2,0.6,2.5,0.9,3.9,0.9c1.2,0,2.4-0.2,3.5-0.7c0.6-0.3,1-0.9,1-1.5V6.1
	C20,5.5,19.7,5,19.3,4.7 M15.7,8.7l-4,4l0,0c-0.4,0.4-1,0.4-1.4,0l-2-2c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l1.3,1.3l3.3-3.3
	c0.4-0.4,1-0.4,1.4,0C16.1,7.7,16.1,8.3,15.7,8.7"/>
    </Icon>
);

export default withTheme(ConfirmedIcon);
