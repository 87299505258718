import { GET_ACCOUNT_LOGIN, } from '../actions/types';

const initialState = {
  account: {
    firstName: '',
    lastName: '',
    email: ''
  },
};

function accountReducer (state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNT_LOGIN:
      return {
        ...state,
        account: action.payload
      };
    default:
      return { ...state };
  }
}

export default accountReducer;
