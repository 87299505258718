import React from 'react';
import { withTheme } from 'styled-components';
import Icon from './components/Icon';

const SystemInfoMenu = props => (
  <Icon {...props}>
    <path d="M12.7,8.3c0,0.1,0.1,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2
	c0,0.1,0,0.1,0,0.2c0,0.3-0.1,0.5-0.3,0.7l-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0
	c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.1-0.3-0.2C11.1,9.5,11,9.3,11,9c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2
	c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.2,0.3-0.2C12,7.9,12.4,8,12.7,8.3z M12,11c0.6,0,1,0.4,1,1v3c0,0.6-0.4,1-1,1s-1-0.4-1-1v-3
	C11,11.4,11.4,11,12,11z M12,4c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S16.4,4,12,4z M12,2c5.5,0,10,4.5,10,10s-4.5,10-10,10
	S2,17.5,2,12S6.5,2,12,2z"/>
  </Icon>
);

export default withTheme(SystemInfoMenu);
