import moment from 'moment';
import { MIN_YEARS_OLD_USER } from '../../../../constants';

const validateProfile = (values) => {
    const errors = {};

    if (!values.birthday) {
        errors.birthday = 'Whoops! Birthday is required.';
    } else if(!moment(values.birthday).isValid()){
        errors.birthday = 'Whoops! Please enter a valid Birthday.';
    }
    else {
        const year = (moment().format('YYYY') - MIN_YEARS_OLD_USER).toString();
        const today = moment().format('MMMM Do');
        const day = moment(`${today} ${year}`, 'MMMM Do YYYY');
        const birthdayUser = moment(values.birthday).format('x');

        if (Number(birthdayUser) > Number(moment(day).format('x'))) {
            errors.birthday = `Whoops! You must be at least ${MIN_YEARS_OLD_USER} years old.`;
        }
    }

    if (!values.address.addressLine1) {
        errors.address = 'Whoops! Address Line 1 is required.';
    }

    if (!values.address.city || values.address.city.trim() < 1) {
        errors.city = 'Whoops! City is required.';
    }

    if (!values.gender || values.gender === 1) {
        errors.gender = 'Whoops! Gender is required.';
    }
    if (!values.address.state) {
        errors.state = 'Whoops! State is required.';
    }
    if (!values.address.zipCode) {
        errors.zipCode = 'Whoops! Zip Code is required.';
    } else {
        const regexp = /^[0-9]{5}?$/;
        if (!regexp.test(values.address.zipCode)) {
            errors.zipCode = 'Whoops! Please enter a valid Zip Code.';
        }
    }
    if (!values.phone) {
        errors.phone = 'Whoops! Phone is required.';
    } else if (values.phone && String(values.phone.replace(/\D/g, '')).length < 11) {
        errors.phone = 'Whoops! Please enter a valid Phone.';
    }
    if (values.causes.length < 1) {
        errors.causes = 'Whoops! Please select at least one cause.';
    }
    return errors;
};

export default validateProfile;
