import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const GiftIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M16.5,3c-1.7,0-2.7,1.8-3.2,3h3.2C17.3,6,18,5.3,18,4.5
	S17.3,3,16.5,3z M7.5,3C6.7,3,6,3.7,6,4.5S6.7,6,7.5,6h3.2C10.2,4.8,9.2,3,7.5,3z M21,8h-8v3h8V8z M11,8H3v3h8V8z M19,13h-6v8h6V13z
	 M11,13H5v8h6V13z M16.5,1C18.4,1,20,2.6,20,4.5c0,0.5-0.1,1-0.3,1.5L22,6c0.6,0,1,0.4,1,1v5c0,0.6-0.4,1-1,1h-1v9c0,0.6-0.4,1-1,1
	H4c-0.6,0-1-0.4-1-1v-9H2c-0.6,0-1-0.4-1-1V7c0-0.6,0.4-1,1-1l2.3,0C4.1,5.5,4,5,4,4.5C4,2.6,5.6,1,7.5,1c2.3,0,3.7,1.6,4.5,3.1
	C12.8,2.6,14.2,1,16.5,1z"/>
        </Icon>
    );

export default withTheme(GiftIcon);
