import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

class Map extends Component{
    constructor (props){
        super (props);
        this.state = {};
    }

    shouldComponentUpdate (nextProps){
        return this.props.lat !== nextProps.lat || this.props.lng !== nextProps.lng;
    }

    render () {
        const AsyncMap = withGoogleMap( () => (
            <GoogleMap
                defaultZoom={16}
                defaultCenter={{lat: this.props.lat, lng: this.props.lng}}
                defaultOptions={{
                    mapTypeControl: false,
                    streetViewControl: false,
                    fullscreenControl: false,
                    disableDefaultUI: this.props.isRightBar,
                    styles: [
                        {
                            'featureType': 'poi',
                            'elementType': 'labels.icon',
                            'stylers': [
                                {
                                    'visibility': 'off'
                                }
                            ]
                        }
                    ]
                }}
            >
                <Marker
                    google={this.props.google}
                    draggable={false}
                    position={{lat: this.props.lat, lng: this.props.lng}}
                />
                <Marker />
            </GoogleMap>
        ));

        return (
            <BlockMap {...this.props}>
                <AsyncMap
                    isMarkerShow
                    loadingElement={
                        <div style={{height: '100%'}} />
                    }
                    containerElement={ <MapContainer isRightBar={this.props.isRightBar} /> }
                    mapElement={
                        <div style={{height: '100%'}} />
                    }
                />
            </BlockMap>
        );
    }
}

Map.propTypes = {
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    isRightBar: PropTypes.bool,
    google: PropTypes.string
};

Map.defaultProps = {
    isRightBar: false
};

export default Map;

const BlockMap = styled.div`
    margin: 0;
    border-radius: 12px;
    overflow: hidden;
`;
const MapContainer = styled.div`
    height: ${props => props.isRightBar ? '150px' : '300px'};
    @media screen and (max-width:575px){
        height: ${props => props.isRightBar ? '150px' : '250px'};
    }
    @media screen and (max-width:425px){
        height: 150px;
    }
`;


