import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const MinimumAgeIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
          <path fillRule="evenodd" clipRule="evenodd" d="M4.53732 6.48489C6.59212 4.51392 9.88403 4.49073 11.8847 6.50119L12.0145 6.63158L12.1442 6.50118L12.1608 6.48489C14.2156 4.51392 17.5075 4.49073 19.5082 6.50119L19.5244 6.51785C21.492 8.58917 21.492 11.7993 19.5244 13.8707L19.5082 13.8873L14.141 19.2808C12.9682 20.4593 11.0607 20.4593 9.88799 19.2808L4.52072 13.8873L4.50452 13.8707C2.56672 11.8307 2.42014 8.51571 4.53732 6.48489ZM10.4671 7.91195C9.26587 6.7049 7.22133 6.68171 5.92178 7.92824C4.68707 9.11258 4.69049 11.1544 5.94708 12.4853L11.3056 17.87C11.6966 18.2629 12.3324 18.2629 12.7233 17.87L18.0818 12.4853C19.306 11.1886 19.3062 9.20076 18.0824 7.90379C16.8826 6.70722 14.8501 6.68461 13.5532 7.92072L12.7233 8.75463C12.5356 8.94322 12.2805 9.04925 12.0145 9.04925C11.7484 9.04925 11.4933 8.94322 11.3056 8.75463L10.4671 7.91195Z" />
        </Icon>
    );

export default withTheme(MinimumAgeIcon);
