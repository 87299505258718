import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const InfoIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M12,4c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S16.4,4,12,4z M12,2c5.5,0,10,4.5,10,10s-4.5,10-10,10S2,17.5,2,12S6.5,2,12,2z"/>
            <path d="M12,16L12,16c-0.6,0-1-0.4-1-1v-3c0-0.5,0.4-1,1-1h0c0.5,0,1,0.4,1,1v3C13,15.6,12.6,16,12,16z"/>
            <circle cx="12" cy="9" r="1"/>
        </Icon>
    );

export default withTheme(InfoIcon);
