import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const InviteVolunteerIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M14,21H4c-1.7,0-3-1.3-3-3V6c0-1.7,1.3-3,3-3h16c1.7,0,3,1.3,3,3v6c0,0.6-0.4,1-1,1s-1-0.4-1-1V6c0-0.6-0.4-1-1-1H4C3.4,5,3,5.4,3,6v12c0,0.6,0.4,1,1,1h10c0.6,0,1,0.4,1,1S14.6,21,14,21z"/>
            <path d="M12,14c-0.2,0-0.4-0.1-0.6-0.2l-10-7C1,6.5,0.9,5.9,1.2,5.4C1.5,5,2.1,4.9,2.6,5.2l9.4,6.6l9.4-6.6c0.5-0.3,1.1-0.2,1.4,0.2c0.3,0.5,0.2,1.1-0.2,1.4l-10,7C12.4,13.9,12.2,14,12,14z"/>
            <path d="M19,21c-0.6,0-1-0.4-1-1v-6c0-0.6,0.4-1,1-1s1,0.4,1,1v6C20,20.6,19.6,21,19,21z"/>
            <path d="M22,18h-6c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0.6,0,1,0.4,1,1S22.6,18,22,18z"/>
        </Icon>
    );

export default withTheme(InviteVolunteerIcon);
