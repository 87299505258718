import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const ShareIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M18,9c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S20.2,9,18,9z M18,3c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2
	S19.1,3,18,3z"/>
            <path d="M18,23c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S20.2,23,18,23z M18,17c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2
	S19.1,17,18,17z"/>
            <path d="M6,16c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4s4,1.8,4,4C10,14.2,8.2,16,6,16z M6,10c-1.1,0-2,0.9-2,2
	c0,1.1,0.9,2,2,2s2-0.9,2-2C8,10.9,7.1,10,6,10z"/>
            <path d="M15.4,18.5c-0.2,0-0.3,0-0.5-0.1l-6.8-4c-0.5-0.3-0.6-0.9-0.4-1.4c0.3-0.5,0.9-0.6,1.4-0.4l6.8,4
	c0.5,0.3,0.6,0.9,0.4,1.4C16.1,18.3,15.8,18.5,15.4,18.5z"/>
            <path d="M8.6,11.5c-0.3,0-0.7-0.2-0.9-0.5c-0.3-0.5-0.1-1.1,0.4-1.4l6.8-4C15.4,5.4,16,5.5,16.3,6
	c0.3,0.5,0.1,1.1-0.4,1.4l-6.8,4C8.9,11.4,8.8,11.5,8.6,11.5z"/>
        </Icon>
    );

export default withTheme(ShareIcon);
