import React from 'react';
import { withTheme } from 'styled-components';
import Icon from '../../components/Icon';

const CakeIcon = props => (
        <Icon {...props} viewBox={'0 0 24 24'}>
            <path d="M11.5,0.3c0.2-0.4,0.7-0.4,0.9,0l1.4,2.4c0.3,0.4,0.4,0.9,0.3,1.4
	c-0.1,0.8-0.7,1.5-1.4,1.8c-1.5,0.5-2.9-0.6-2.9-2.1c0-0.4,0.1-0.8,0.3-1.1L11.5,0.3z M18,12H6c-1.1,0-2,0.9-2,2v1.5
	c0,0.5,0.3,0.9,0.6,1c0.4,0.1,0.8,0,1-0.2l2.1-2.1c0.2-0.2,0.5-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3l2.1,2.1c0.4,0.4,1,0.4,1.4,0
	l2.1-2.1c0.4-0.4,1-0.4,1.4,0l2.1,2.1c0.2,0.2,0.5,0.3,0.8,0.3c0.4-0.1,0.8-0.5,0.8-1.1V14c0-0.5-0.2-1-0.6-1.4
	C19,12.2,18.5,12,18,12z M12,7c0.6,0,1,0.4,1,1v2h5c1.1,0,2.1,0.4,2.8,1.2c0.8,0.8,1.2,1.8,1.2,2.8v1.4c0,1.5-1.1,2.9-2.6,3.1
	c-0.9,0.1-1.8-0.2-2.5-0.8l-1.4-1.4l-1.4,1.4c-0.6,0.6-1.3,0.9-2.1,0.9c-0.8,0-1.5-0.3-2.1-0.9l-1.4-1.4L7,17.6
	c-0.8,0.8-2,1.1-3,0.7c-1.2-0.4-2-1.6-2-2.9V14c0-2.2,1.8-4,4-4h5V8C11,7.5,11.4,7,12,7z M21,19c0.6,0,1,0.4,1,1v1c0,1.7-1.3,3-3,3
	H5c-1.7,0-3-1.3-3-3v-1c0-0.6,0.4-1,1-1s1,0.4,1,1v1c0,0.6,0.4,1,1,1h14c0.6,0,1-0.4,1-1v-1C20,19.4,20.4,19,21,19z"/>
        </Icon>
    );

export default withTheme(CakeIcon);
