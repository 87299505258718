import React from 'react';
import { withTheme } from 'styled-components';
import Icon from './components/Icon';

const SwitchToPersonalMenu = props => (
    <Icon {...props}>
      <path d="M16,4c1.7,0,3,1.3,3,3v4.6l1.3-1.3c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4l-3,3c-0.1,0.1-0.2,0.2-0.3,0.2
	C18.3,15,18.1,15,18,15c-0.1,0-0.3,0-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.2l-3-3c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l1.3,1.3V7
	c0-0.6-0.4-1-1-1H6C5.4,6,5,5.6,5,5s0.4-1,1-1H16z M6,9c0.1,0,0.3,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2l3,3c0.4,0.4,0.4,1,0,1.4
	s-1,0.4-1.4,0L7,12.4V17c0,0.6,0.4,1,1,1h10c0.6,0,1,0.4,1,1s-0.4,1-1,1H8c-1.7,0-3-1.3-3-3v-4.6l-1.3,1.3c-0.4,0.4-1,0.4-1.4,0
	s-0.4-1,0-1.4l3-3c0.1-0.1,0.2-0.2,0.3-0.2C5.7,9,5.9,9,6,9L6,9z"/>
    </Icon>
);

export default withTheme(SwitchToPersonalMenu);
