import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../../constants/styles';

const Conservation = (props) => (
    <Container isActive={props.isActive}>
      <svg
          width="20px"
          height="20px"
          id="conservation"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 24 24"
          enableBackground="new 0 0 24 24"
          xmlSpace="preserve"
      >
        <g id="mask">
          <path fill={props.isActive ? styles.colors.causes.conservation : styles.colors.silver} d="M21.7,2.3c-0.3-0.3-0.6-0.4-1-0.3L8.5,5.5C7.3,5.9,6.2,6.4,5.4,7.1
	C1.9,10,1.2,15.2,4,18.6l-1.7,1.7c-0.4,0.4-0.4,1,0,1.4C2.5,21.9,2.7,22,3,22s0.5-0.1,0.7-0.3L5.4,20c1.4,1.1,3.2,1.7,4.9,1.7
	c2.4,0,4.9-1.1,6.6-3.1c0.7-0.8,1.2-1.9,1.6-3.2L22,3.3C22.1,2.9,22,2.6,21.7,2.3 M12.7,12.7l-5.9,5.9c-0.1-0.1-0.2-0.1-0.3-0.2
	C6.4,18.3,6.2,18.1,6,18c-0.2-0.2-0.3-0.3-0.5-0.5c-0.1-0.1-0.1-0.2-0.2-0.3l5.9-5.9c0.4-0.4,1-0.4,1.4,0S13.1,12.3,12.7,12.7"/>
        </g>
      </svg>
    </Container>
);

export default Conservation;

Conservation.propTypes = {
  isActive: PropTypes.bool
};

Conservation.defaultProps = {
  isActive: true
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: ${props => props.isActive ? `${styles.colors.causes.conservation}1f` : styles.colors.mostlyWhite};
    border-radius: 20px;
`;
