import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '../../constants/styles';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const PEditor = (props) => {
    return (<PEditorContainer data-testid={props.dataTestId || ''}>
        <CKEditor
            editor={ ClassicEditor }
            data={props.data || ''}
            config={{
                toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
                heading: {
                    options: [
                        { model: 'heading3', view: 'h3', title: 'Heading', class: 'pe-heading_heading3' },
                        { model: 'paragraph', title: 'Paragraph', class: 'pe-heading_paragraph' },
                    ]
                },
                ignoreEmptyParagraph: false
            }}
            onChange={ ( event, editor ) => {
                props.onChange({ data: editor.getData() });
            } }
        />
    </PEditorContainer>);
}

export default PEditor;

PEditor.propTypes = {
    data: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    dataTestId: PropTypes.string.isRequired
};

const PEditorContainer = styled.div`
  font-family: ${styles.fonts.family.primaryBook} !important;
  
  .ck.ck-reset_all, .ck.ck-reset_all *{
    max-width: 100%;
  }
  
  .ck-editor__main {
    .ck-content {
      padding: 16px;
      outline: none;
      border: 1px solid ${styles.colors.grey} !important;
      box-shadow: none !important;
      border-bottom-left-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }
  }
  .ck-toolbar {
    border: 1px solid ${styles.colors.grey} !important;
    border-bottom: none !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    background: ${styles.colors.mostlyWhite} !important;
    .ck-toolbar__items {
      margin: 4px;
    }
  }
  .ck-toolbar__separator {
    background: ${styles.colors.grey} !important;
  }
  .ck-button {
    border-radius: 4px !important;
    color: ${styles.colors.dark};
    background-color: white;
    border: 1px solid ${styles.colors.grey};
    font-size: 12px;
    line-height: 16px;
    svg {
      fill: ${styles.colors.dark};
      width: 14px;
      height: 14px;
    }
  }
  .ck-dropdown {
    .ck-list {
      margin-bottom: 0 !important;
      .ck-button {
        margin-bottom: 0 !important;
        border-radius: 0 !important;
        border: none !important;
        &.ck-on {
          color: #ffffff !important;
          background: #F6921E !important;
        }
      }
    }
  }

  .pe-heading_heading3, h3 {
    font-family: ${styles.fonts.family.primaryBold} !important;
    font-size: 14px !important;
    line-height: 22px !important;
    color: ${styles.colors.medium} !important;
    margin: 0 0 16px !important;
  }
  .pe-heading_paragraph, p {
    font-family: ${styles.fonts.family.primaryBook} !important;
    font-size: 14px !important;
    line-height: 22px !important;
    color: ${styles.colors.medium} !important;
    margin: 0 0 16px !important;
  }
  ol, ul, blockquote {
    font-family: ${styles.fonts.family.primaryBook} !important;
    font-size: 14px !important;
    line-height: 22px !important;
    color: ${styles.colors.medium} !important;
    margin: 0 0 16px !important;
  }

  blockquote{
    border-left: 2px solid #F6921E !important;
    p {
      margin: 0 !important;
    }
  }
`;
